import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  TRIGGER_CONTACT_US,
  TRIGGER_CONTACT_US_SUCCESS,
  TRIGGER_CONTACT_US_FAILED,
  TRIGGER_CONTACT,
  TRIGGER_CONTACT_SUCCESS,
  TRIGGER_CONTACT_FAILED,
} from "./actionTypes";

export function triggerContactUs(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlContactUs}`,
      dispatch,
      defaultAction: TRIGGER_CONTACT_US,
      successAction: TRIGGER_CONTACT_US_SUCCESS,
      failedAction: TRIGGER_CONTACT_US_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerContact(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlContact}`,
      dispatch,
      defaultAction: TRIGGER_CONTACT,
      successAction: TRIGGER_CONTACT_SUCCESS,
      failedAction: TRIGGER_CONTACT_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
