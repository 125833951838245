import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/pages/Testimonials.scss";
import config from "../../utils/config";
import TestimonialDetails from "./TestimonialDetails";
import { TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS } from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "@material-ui/lab";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function TestimonialsCard({ testimonial }) {
  const [showDialog, setShowDialog] = useState(false);
  const { testimonialDetails } = useSelector((state) => state.homePageReducer);
  const dispatch = useDispatch();
  const showTestimonialDetails = (testimonial) => {
    setShowDialog(true);
    dispatch({
      type: TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS,
      loading: false,
      data: testimonial,
      error: null,
    });
  };

  return (
    <div className="testimonials-card col-xl-12 col-lg-12 col-md-12 col-sm-6  col-12">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <div className="posts-list__item">
          <div className="post-card post-card--layout--list">
            <div className="post-card__image">
              <Link to={`testimonial/${testimonial?.id}`}>
                {testimonial?.featured_image ? (
                  <img
                    alt={testimonial?.title}
                    src={`${config.imageBasePath}${testimonial?.featured_image}`}
                  />
                ) : (
                  <img
                    alt="no-image"
                    src={`https://www.vanagain.com/public/assets/uploads/no_img.gif`}
                  />
                )}
              </Link>
            </div>
            <div className="post-card__content">
              <div className="post-card__category"></div>
              <div className="post-card__title">
                <h5 className="mb-0">{testimonial?.title}</h5>
              </div>

              <div className="post-card__excerpt">
                <Rating readOnly value={testimonial?.rating} />
              </div>

              <div className="post-card__date">
                <div
                  className="block-reviews__item-text"
                  dangerouslySetInnerHTML={{ __html: testimonial?.description }}
                />
              </div>
              <div className="post-card__more w-100">
                <button
                  onClick={() => {
                    showTestimonialDetails(testimonial);
                  }}
                  className="btn btn-primary btn-sm"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </div>
        {testimonial && testimonialDetails?.data && showDialog && (
          <TestimonialDetails
            open={showDialog}
            setShowDialog={setShowDialog}
            testimonialDetails={testimonialDetails?.data}
          />
        )}
      </AnimationOnScroll>
    </div>
  );
}
