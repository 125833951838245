import { CheckRounded, CloseRounded } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import config from "../utils/config";
import useCartHook from "../hooks/useCartHook";
import { triggerSavePaypalDetails } from "../redux/actions/cartAction";

export default function OrderReceived() {
  const dispatch = useDispatch();

  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
    removePoints,
    removeCoupon,
    cleanCartAfterPayment,
  ] = useCartHook();

  const { expressCheckout, checkout } = useSelector(
    (state) => state.cartReducer
    );

  useEffect(() => {
    const paypalReturnURL = new URLSearchParams(window.location.href);

    if (
      paypalReturnURL.get("cm") &&
      paypalReturnURL.get("st") &&
      paypalReturnURL.get("tx")
    ) {
      const obj = {
        cm: paypalReturnURL.get("cm"),
        payment_status: paypalReturnURL.get("st"),
        txn_id: paypalReturnURL.get("tx"),
      };
      dispatch(
        triggerSavePaypalDetails(obj, (res) => {
          cleanCartAfterPayment();
        })
      );
    }
  }, []);
  return (
    <div className="container order-received">
      <div className="card">
        <div className="block-header__title">Order Received</div>
        <div className="order-sub-title text-center">
          {expressCheckout?.data?.error ? (
            <CloseRounded className="close-icon" />
          ) : (
            <CheckRounded className="check-icon" />
          )}

          {expressCheckout?.data?.message || checkout?.data?.message}
        </div>
        {!expressCheckout?.data?.error ? (
          <div className="alert alert-light text-center">
            Congrats! You have earned{" "}
            {expressCheckout?.data?.points || checkout?.data?.points} points for
            completing order.
          </div>
        ) : (
          ""
        )}
        <div className="table-responsive-md order-details-table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Order Number</th>
                <th scope="col">Date Purchased</th>
                <th scope="col">Total Payable</th>
                <th scope="col">Payment Method</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  #{expressCheckout?.data?.order_no || checkout?.data?.order_id}
                </td>
                <td>
                  {" "}
                  {moment(expressCheckout?.data?.created_at).format("LL") ||
                    moment(checkout?.data?.order?.created_at).format("LL")}
                </td>
                <td>
                  $
                  {expressCheckout?.data?.order?.order_total ||
                    checkout?.data?.order?.order_total}
                </td>
                <td>
                  {checkout?.data &&
                    (checkout?.data?.order?.payment_options === "credit_card"
                      ? "Credit Card"
                      : "Paypal")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div className="table-responsive-lg product-table ">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Description</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {checkout?.data &&
                  checkout?.data?.orders_items?.map((product, i) => {
                    return (
                      <tr key={i}>
                      
                        <td>
                          <img
                            src={product?.image ? `${config.imageBasePath}${product?.image}` : "https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                            alt={product?.name}
                            className="img-fluid product-img"
                          />
                        </td>
                        <td>
                          {product?.name} x {product?.product_quantity}
                        </td>
                        <td>${product?.product_price}</td>
                        <td>
                          $
                          {Number(
                            product?.product_quantity * product?.product_price
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}

                {expressCheckout?.data &&
                  expressCheckout?.data?.orders_items?.map((product, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            src={product?.image ? `${config.imageBasePath}${product?.image}` : "https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                            alt="..."
                            className="img-fluid product-img"
                          />
                        </td>
                        <td>
                          {product?.name} x {product?.product_quantity}
                        </td>
                        <td>${product?.product_price}</td>
                        <td>
                          $
                          {Number(
                            product?.product_quantity * product?.product_price
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="amount-table card">
            <table className="table table-sm ">
              <tbody>
                <tr>
                  <th>Subtotal:</th>
                  <td>
                    $
                    {expressCheckout?.data?.order?.order_subtotal ||
                      checkout?.data?.order?.order_subtotal}
                  </td>
                </tr>
                <tr>
                  <th>Points Redemption:</th>
                  <td>
                    $-
                    {expressCheckout?.data?.order?.points_redeemed_amount ||
                      checkout?.data?.order?.points_redeemed_amount}
                  </td>
                </tr>

                <tr>
                  <th>Coupon Discount:</th>
                  <td>
                    $-
                    {expressCheckout?.data?.order?.discount_amount ||
                      checkout?.data?.order?.discount_amount}
                  </td>
                </tr>
                <tr>
                  <th>Shipping Fee:</th>
                  <td>
                    $
                    {expressCheckout?.data?.order?.order_shipping ||
                      checkout?.data?.order?.order_shipping}
                  </td>
                </tr>
                <tr>
                  <th>Tax:</th>
                  <td>
                    $
                    {expressCheckout?.data?.order?.order_tax ||
                      checkout?.data?.order?.order_tax}
                  </td>
                </tr>
                <tr>
                  <th>Grand Total:</th>
                  <td>
                    $
                    {expressCheckout?.data?.order &&
                      Number(
                        Number(expressCheckout?.data?.order?.order_subtotal) +
                          Number(expressCheckout?.data?.order?.order_tax) +
                          Number(expressCheckout?.data?.order?.order_shipping) -
                          Number(
                            expressCheckout?.data?.order
                              ?.points_redeemed_amount || 0
                          ) -
                          Number(
                            expressCheckout?.data?.order?.discount_amount || 0
                          )
                      ).toFixed(2)}
                    {checkout?.data?.order &&
                      Number(
                        Number(checkout?.data?.order?.order_subtotal) +
                          Number(checkout?.data?.order?.order_tax) +
                          Number(checkout?.data?.order?.order_shipping) -
                          Number(
                            checkout?.data?.order?.points_redeemed_amount || 0
                          ) -
                          Number(checkout?.data?.order?.discount_amount || 0)
                      ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card address">
          <div className="row ">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 className="mb-2">Customer Details</h5>
              <div>
                <label>Name:</label>
                {(expressCheckout?.data?.order &&
                  expressCheckout?.data?.order?.first_name +
                    " " +
                    expressCheckout?.data?.order?.last_name) ||
                  (checkout?.data?.order &&
                    checkout?.data?.order?.first_name +
                      " " +
                      checkout?.data?.order?.last_name)}
              </div>
              <div>
                <label>Phone:</label>
                {expressCheckout?.data?.order?.phone ||
                  checkout?.data?.order?.phone ||
                  "-"}
              </div>
              <div>
                <label>Email:</label>
                <a
                  href={`mailto:${
                    expressCheckout?.data?.order?.email_address ||
                    checkout?.data?.order?.email_address
                  }`}
                >
                  {expressCheckout?.data?.order?.email_address ||
                    checkout?.data?.order?.email_address}
                </a>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 className="mb-2">Billing Address</h5>
              <div>
                <label>
                  {(expressCheckout?.data?.order?.apartment &&
                    expressCheckout?.data?.order?.apartment + ", ") ||
                    (checkout?.data?.order?.apartment &&
                      checkout?.data?.order?.apartment + ", ")}
                  {expressCheckout?.data?.order?.street_address &&
                    expressCheckout?.data?.order?.street_address + ","}
                  {checkout?.data?.order?.street_address &&
                    checkout?.data?.order?.street_address + ","}
                </label>
              </div>
              <div>
                <label>
                  {expressCheckout?.data?.order?.town
                    ? expressCheckout?.data?.order?.town | +`, `
                    : checkout?.data?.order?.town + `,`}
                </label>
              </div>
              <div>
                <label>
                  {expressCheckout?.data?.order?.state &&
                    expressCheckout?.data?.order?.state +
                      +`, ${expressCheckout?.data?.order?.postcode},`}

                  {checkout?.data?.order?.state &&
                    checkout?.data?.order?.state + ", "}
                  {checkout?.data?.order?.postcode &&
                    checkout?.data?.order?.postcode + ", "}
                  {checkout?.data?.order?.country &&
                    checkout?.data?.order?.country}
                </label>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 className="mb-2">Shipping Address</h5>
              <div>
                <label>
                  {expressCheckout?.data?.order &&
                    (expressCheckout?.data?.order?.ship_to_different_address ===
                    1
                      ? expressCheckout?.data?.order?.shipping_apartment &&
                        expressCheckout?.data?.order?.shipping_apartment +
                          ", " +
                          expressCheckout?.data?.order?.shipping_street_address
                      : expressCheckout?.data?.order?.apartment &&
                        expressCheckout?.data?.order?.apartment +
                          ", " +
                          expressCheckout?.data?.order?.street_address)}

                  {`${
                    checkout?.data?.order?.shipping_apartment
                      ? `${checkout?.data?.order?.shipping_apartment}, `
                      : ""
                  }`}
                  {`${checkout?.data?.order?.shipping_street_address}, `}
                </label>
              </div>
              <div>
                <label>
                  {expressCheckout?.data?.order &&
                  expressCheckout?.data?.order?.ship_to_different_address === 1
                    ? expressCheckout?.data?.order?.shipping_town
                    : expressCheckout?.data?.order?.town}

                  {checkout?.data?.order &&
                  checkout?.data?.order?.ship_to_different_address === 1
                    ? checkout?.data?.order?.shipping_town + `,  `
                    : checkout?.data?.order?.town + `, `}
                </label>
              </div>
              <div>
                <label>
                  {expressCheckout?.data?.order &&
                    (expressCheckout?.data?.order?.ship_to_different_address ===
                    1
                      ? expressCheckout?.data?.order?.shipping_state &&
                        expressCheckout?.data?.order?.shipping_state +
                          `, ${expressCheckout?.data?.order?.shipping_postcode}, ` +
                          expressCheckout?.data?.order?.shipping_country
                      : expressCheckout?.data?.order?.state &&
                        expressCheckout?.data?.order?.state +
                          `, ${expressCheckout?.data?.order?.postcode}, ` +
                          expressCheckout?.data?.order?.country)}
                  {checkout?.data?.order?.shipping_state &&
                    checkout?.data?.order?.shipping_state + ", "}
                  {checkout?.data?.order?.postcode &&
                    checkout?.data?.order?.shipping_postcode + ", "}
                  {checkout?.data?.order?.shipping_country &&
                    checkout?.data?.order?.shipping_country}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
