import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginationPage from "../../components/shared/Pagination";
import { getPoints } from "../../redux/actions";
import CircularLoader from "../../components/shared/CircularLoader";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";
import { useHistory, useParams } from "react-router-dom";
export default function Rewards() {
  const dispatch = useDispatch();
  const { points } = useSelector((state) => state.shopReducer);
  const [userData] = useUserDetailsHook();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const startIndex = 0;
  const endIndex = 6;
  const [rewardsList, setRewardsList] = useState([]);
  useEffect(() => {
    return dispatch(getPoints());
  }, []);

  useEffect(() => {
    if (points?.data?.length > 0) {
      alterRewardList();
    }
  }, [page, points?.data]);

  const alterRewardList = () => {
    let list = points?.data;
    const indexOfLastTodo = page * 6;
    const indexOfFirstTodo = indexOfLastTodo - 6;
    const updatedList = list.slice(indexOfFirstTodo, indexOfLastTodo);
    setRewardsList(updatedList);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/my-account/rewards/page/${newPage}`,
    });
  };

  return (
    <div className="card dashboard__orders">
      <div className="card-header">
        <h1 className="mb-0 points-heading">MY POINTS &amp; REWARDS</h1>
      </div>
      <div className="card-divider"></div>
      <div className="card-table">
        {points?.data?.length == 0 && !points?.loading && (
          <div className="center-div">
            <p>No data</p>
          </div>
        )}
        {points?.loading && (
          <div className="center-div">
            <CircularLoader size={60} color={"#32c2ef"} type="overlay-loader" />
          </div>
        )}
        {points?.data?.length > 0 && !points?.loading && (
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Point</th>
                  <th>Event</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {rewardsList?.map((point, i) => {
                  return (
                    <tr>
                      <td>{userData?.email}</td>
                      <td>+{point?.points}</td>
                      <td>{point?.reason}</td>
                      <td>{point.dateTime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {points?.data?.length > 6 && !points?.loading && (
        <>
          <div className="card-divider"></div>
          <div>
            <PaginationPage
              startIndex={startIndex + 1}
              lastIndex={endIndex}
              showText={true}
              onChangePage={onChangePage}
              count={Math.ceil(points?.data?.length / 6)}
              page={page}
              totalCount={points?.data?.length}
            />
          </div>
        </>
      )}
    </div>
  );
}
