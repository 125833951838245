import {
  TRIGGER_ADD_TO_CART,
  TRIGGER_ADD_QUANTITY,
  TRIGGER_SUB_QUANTITY,
  TRIGGER_REMOVE_PRODUCT_FROM_CART,
  TRIGGER_UPDATE_CART,
  TRIGGER_CALCULATE_SHIPPING,
  TRIGGER_CALCULATE_SHIPPING_FAILED,
  TRIGGER_CALCULATE_SHIPPING_SUCCESS,
  TRIGGER_EMPTY_CART,
  TRIGGER_GET_SHIPPING_DETAILS,
  TRIGGER_SHIPPING_CHARGES,
  TRIGGER_SUBSCRIBE_NEWSLETTER,
  TRIGGER_CHECKOUT,
  TRIGGER_CHECKOUT_SUCCESS,
  TRIGGER_CHECKOUT_FAILED,
  TRIGGER_GET_COUPON_DETAILS,
  TRIGGER_GET_COUPON_DETAILS_SUCCESS,
  TRIGGER_GET_COUPON_DETAILS_FAILED,
  TRIGGER_ALL_ADD_TO_CART,
  TRIGGER_BILLING_COUNTRY,
  TRIGGER_BILLING_COUNTRY_FAILED,
  TRIGGER_BILLING_COUNTRY_SUCCESS,
  TRIGGER_SHIPPING_STATES,
  TRIGGER_SHIPPING_STATES_FAILED,
  TRIGGER_SHIPPING_STATES_SUCCESS,
  TRIGGER_SHIPPING_COUNTRY,
  TRIGGER_SHIPPING_COUNTRY_FAILED,
  TRIGGER_SHIPPING_COUNTRY_SUCCESS,
  TRIGGER_BILLING_STATES,
  TRIGGER_BILLING_STATES_FAILED,
  TRIGGER_BILLING_STATES_SUCCESS,
  TRIGGER_REDEEM_POINTS,
  TRIGGER_REDEEM_POINTS_FAILED,
  TRIGGER_REDEEM_POINTS_SUCCESS,
  TRIGGER_CANCEL_ORDER,
  TRIGGER_CANCEL_ORDER_SUCCESS,
  TRIGGER_CANCEL_ORDER_FAILED,
  TRIGGER_PAYPAL_PAYMENT_FORM,
  TRIGGER_PAYPAL_PAYMENT_FORM_FAILED,
  TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS,
  TRIGGER_TAX_AMOUNT,
  TRIGGER_EXPRESS_CHECKOUT,
  TRIGGER_EXPRESS_CHECKOUT_SUCCESS,
  TRIGGER_EXPRESS_CHECKOUT_FAILED,
  TRIGGER_COUPON_CODE,
  TRIGGER_SAVE_CHECKOUT_FORM,
  GET_PAYPAL_APPROVED_DETAILS,
  TRIGGER_SET_CART_LIST,
  TRIGGER_BILLING_ADDRESS,
  TRIGGER_SHIPPING_METHODS,
  TRIGGER_CALCULATE_SHIPPING_DETAILS,
  TRIGGER_CHECKOUT_OBJ,
} from "../actions/actionTypes";

const initialState = {
  data: [],
  cart: [],
  total: 0.0,
  error: null,
  addedItem: {},
  shippingObj: {
    loading: false,
    data: [],
    error: null,
  },
  shippingAddress: {
    country: "US",
    state: "",
    postcode: "",
    cart_products: [],
    address: "",
  },
  quantity: 1,
  shippingMethod: {
    shippingCharges: 0,
    shippingName: "",
    shippingMode: "",
  },
  wishlist: [],

  subsribeToNewsletter: false,
  checkout: {
    loading: false,
    data: [],
    error: null,
  },
  finalTotal: "",
  couponDetails: {
    loading: false,
    data: [],
    error: null,
  },
  shippingCountry: {
    loading: false,
    data: [],
    error: null,
  },
  shippingState: {
    loading: false,
    data: [],
    error: null,
  },

  billingCountry: {
    loading: false,
    data: [],
    error: null,
  },
  billingState: {
    loading: false,
    data: [],
    error: null,
  },

  redeemPoints: {
    loading: false,
    data: [],
    error: null,
  },

  cancelOrder: {
    loading: false,
    data: [],
    error: null,
  },
  paypalForm: {
    loading: false,
    data: [],
    error: null,
  },

  tax: 0.0,

  expressCheckout: {
    loading: false,
    data: [],
    error: null,
  },

  couponCode: "",

  checkoutFormData: {},
  paypalDetails: {},
  shippingMethodsList: [],
  calculateShippingDetails: {
    country: "",
    state: "",
    postcode: "",
  },
  checkOutObject:{}
};

const cartReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_ADD_TO_CART: {
      let existed_item = state.cart.find(
        (item) => action.addedItem.id === item.id
      );

      if (existed_item === undefined) {
        action.addedItem["quantity"] = action.quantity;
        state.cart.push(action.addedItem);

        let newTotal =
          parseFloat(state.total) +
          parseFloat(
            action.quantity
              ? action.addedItem.regular_price * action.quantity
              : action.addedItem.regular_price
          );

        return {
          ...state,
          cart: state.cart,
          total: newTotal,
          shippingMethodsList: [],
        };
      } else {
        let quantity = existed_item.quantity + action.quantity;
        let index = state.cart.findIndex((item) => {
          return item.id === action.addedItem.id;
        });
        state.cart[index].quantity = quantity;
        action.addedItem.quantity = quantity;
        let newTotal =
          parseFloat(state.total) +
          parseFloat(
            action.quantity
              ? action.addedItem.regular_price * action.quantity
              : action.addedItem.regular_price
          );
        return {
          ...state,
          cart: [...state.cart],
          total: newTotal,
          shippingMethodsList: [],
        };
      }
    }

    case TRIGGER_ALL_ADD_TO_CART: {
      let newList = [...state.cart];
      if (newList?.length) {
        action?.wishlist?.map((item) => {
          let existed_item = state.cart.find(
            (item1) => item1.id === item?.product_detail?.id
          );
          if (existed_item === undefined) {
            newList.push(item);
          } else {
            let quantity = existed_item?.quantity + 1;
            let index = newList.findIndex((item) => {
              return item.id === existed_item.id;
            });
            existed_item.quantity = quantity;
            newList[index] = existed_item;
          }
        });
      } else {
        action?.wishlist?.map((item) => {
          let newItem = item?.product_detail;
          newItem.quantity = 1;
          newList.push(newItem);
        });
      }

      let total = 0;
      newList.map((item) => {
        total =
          Number(total) + Number(item.regular_price) * Number(item.quantity);
      });

      return {
        ...state,
        cart: newList,
        total: Number(total).toFixed(2),
      };
    }

    case TRIGGER_ADD_QUANTITY: {
      let newTotal;
      if (action.addedItem?.product_quantity) {
        action.addedItem.product_quantity += 1;
        newTotal = state.total + parseFloat(action.addedItem.regular_price);
      }
      return {
        ...state,
        total: newTotal,
        shippingMethodsList: [],
        shippingObj: {
          loading: false,
          data: [],
          error: null,
        },
      };
    }

    case TRIGGER_SUB_QUANTITY: {
      if (action.addedItem.product_quantity === 1) {
        let newTotal = state.total - parseFloat(action.addedItem.regular_price);

        return {
          ...state,
          cart: [...state.cart],
          total: newTotal,
          shippingMethodsList: [],
          shippingObj: {
            loading: false,
            data: [],
            error: null,
          },
        };
      } else {
        action.addedItem.quantity -= 1;
        let newTotal = state.total - parseFloat(action.addedItem.regular_price);
        return {
          ...state,
          total: newTotal,
          shippingMethodsList: [],
          shippingObj: {
            loading: false,
            data: [],
            error: null,
          },
        };
      }
    }

    case TRIGGER_REMOVE_PRODUCT_FROM_CART: {
      let newTotal =
        state.total -
        action.addedItem.regular_price * parseFloat(action.addedItem.quantity);
      return {
        ...state,
        cart: state.cart.filter((item) => action.addedItem.id !== item.id),
        total: newTotal,
        quantity: 1,
        shippingMethodsList: [],
        shippingObj: {
          loading: false,
          data: [],
          error: null,
        },
      };
    }

    case TRIGGER_SHIPPING_METHODS: {
      return {
        ...state,
        shippingMethodsList: action.shippingMethodsList,
      };
    }

    case TRIGGER_EMPTY_CART: {
      return {
        ...state,
        data: [],
        cart: [],
        total: 0.0,
        error: null,
        addedItem: {},
        shippingObj: {
          loading: false,
          data: [],
          error: null,
        },
        quantity: 1,
        shippingMethodsList: [],
      };
    }

    case TRIGGER_UPDATE_CART: {
      let finalAmount =
        Number(state.total) + Number(state.shippingMethod.shippingCharges);
      return {
        ...state,
        cart: action.cart,
        total: action.total,
        finalTotal: Number(finalAmount).toFixed(2),
        shippingMethodsList: [],
        shippingObj: {
          loading: false,
          data: [],
          error: null,
        },

        // updateTotal: action.updateTotal,
      };
    }

    case TRIGGER_CALCULATE_SHIPPING:
      return {
        ...state,
        shippingObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CALCULATE_SHIPPING_DETAILS:
      return {
        ...state,
        calculateShippingDetails: {
          country: action.country,
          state: action.state,
          postcode: action.postcode,
        },
      };

    case TRIGGER_CALCULATE_SHIPPING_SUCCESS:
      return {
        ...state,
        shippingObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
        shippingMethod: {
          shippingCharges: "",
          shippingName: "",
          shippingMode: "",
        },
      };

    case TRIGGER_CALCULATE_SHIPPING_FAILED:
      return {
        ...state,
        shippingObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_SHIPPING_DETAILS:
      return {
        ...state,
        shippingAddress: {
          country: action.country,
          state: action.state,
          postcode: action.postcode,
          cart_products: [],
          address: action.address,
        },
      };

    case TRIGGER_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: {
          country: action.country,
          address: action.address,
          state: action.state,
          postcode: action.postcode,
          town: action.town,
          // cart_products: [],
        },
      };

    case TRIGGER_SHIPPING_CHARGES:
      let finalTotal = Number(
        Number(state.total) + Number(action.shippingMethod.shippingCharges)
      ).toFixed(2);
      return {
        ...state,
        shippingMethod: {
          shippingCharges: Number(
            action.shippingMethod.shippingCharges
          ).toFixed(2),
          shippingName: action?.shippingMethod?.shippingName,
          shippingMode: action?.shippingMethod?.shippingMode,
        },
        finalTotal: finalTotal,
      };

    case TRIGGER_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        subsribeToNewsletter: action.subsribeToNewsletter,
      };

    case TRIGGER_CHECKOUT:
      return {
        ...state,
        checkout: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkout: {
          loading: false,
          data: action.payload,
          error: null,
        },

        expressCheckout: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CHECKOUT_FAILED:
      return {
        ...state,
        checkout: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_COUPON_DETAILS:
      return {
        ...state,
        couponDetails: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_COUPON_DETAILS_SUCCESS:
      return {
        ...state,
        couponDetails: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_GET_COUPON_DETAILS_FAILED:
      return {
        ...state,
        couponDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_BILLING_COUNTRY:
      return {
        ...state,
        billingCountry: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_BILLING_COUNTRY_SUCCESS:
      return {
        ...state,
        billingCountry: {
          loading: false,
          data: action.payload.countries.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
          error: null,
        },
      };

    case TRIGGER_BILLING_COUNTRY_FAILED:
      return {
        ...state,
        billingCountry: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_BILLING_STATES:
      return {
        ...state,
        billingState: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_BILLING_STATES_SUCCESS:
      return {
        ...state,
        billingState: {
          loading: false,
          data: action.payload.states,
          error: null,
        },
      };

    case TRIGGER_BILLING_STATES_FAILED:
      return {
        ...state,
        billingState: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY:
      return {
        ...state,
        shippingCountry: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY_SUCCESS:
      return {
        ...state,
        shippingCountry: {
          loading: false,
          data: action.payload.countries.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
          error: null,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY_FAILED:
      return {
        ...state,
        shippingCountry: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_SHIPPING_STATES:
      return {
        ...state,
        shippingState: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_SHIPPING_STATES_SUCCESS:
      return {
        ...state,
        shippingState: {
          loading: false,
          data: action.payload.states,
          error: null,
        },
      };

    case TRIGGER_SHIPPING_STATES_FAILED:
      return {
        ...state,
        shippingState: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_REDEEM_POINTS:
      return {
        ...state,
        redeemPoints: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_REDEEM_POINTS_SUCCESS:
      return {
        ...state,
        redeemPoints: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_REDEEM_POINTS_FAILED:
      return {
        ...state,
        redeemPoints: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_CANCEL_ORDER:
      return {
        ...state,
        cancelOrder: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        cancelOrder: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_CANCEL_ORDER_FAILED:
      return {
        ...state,
        cancelOrder: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_PAYPAL_PAYMENT_FORM:
      return {
        ...state,
        paypalForm: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        paypalForm: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_PAYPAL_PAYMENT_FORM_FAILED:
      return {
        ...state,
        paypalForm: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_EXPRESS_CHECKOUT:
      return {
        ...state,
        expressCheckout: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_EXPRESS_CHECKOUT_SUCCESS:
      return {
        ...state,
        expressCheckout: {
          loading: false,
          data: action.payload,
          error: null,
        },
        checkout: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_EXPRESS_CHECKOUT_FAILED:
      return {
        ...state,
        expressCheckout: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_TAX_AMOUNT: {
      return {
        ...state,
        tax: action.tax,
      };
    }

    case TRIGGER_COUPON_CODE: {
      return {
        ...state,
        couponCode: action.code,
      };
    }

    case TRIGGER_SAVE_CHECKOUT_FORM: {
      return {
        ...state,
        checkoutFormData: action.checkoutFormData,
      };
    }

    case GET_PAYPAL_APPROVED_DETAILS: {
      return {
        ...state,
        paypalDetails: action.paypalDetails,
      };
    }

    case TRIGGER_SET_CART_LIST: {
      return {
        ...state,
        cart: action.cart,
      };
    }

    case TRIGGER_CHECKOUT_OBJ:{
      return {
        ...state,
      checkOutObject:action.checkOutObject}
    }

    default:
      return state;
  }
};

export default cartReducer;
