import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import CircularLoader from "../../components/shared/CircularLoader";
import { getOrderDetails } from "../../redux/actions";
import { getStatus } from "../../utils";

export default function OrderDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.shopReducer);

  useEffect(() => {
    dispatch(getOrderDetails({ id: id }));
  }, []);

  return (
    <div className="dashboard__orders card">
      <div className="card-header">
        <h5 className="mb-0">Order Details</h5>
      </div>
      <div className="card-divider"></div>
      <div className="card-table p-3 position-relative order-details-table">
        {orderDetails?.loading ? (
          <div className="center-div">
            <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
          </div>
        ) : (
          <>
            {" "}
            <div className="table-responsive">
              <table className="table-bordered">
                <tbody>
                  <tr className="table-title-1">
                    <th colSpan="1" className="unset-th-border">
                      Order Detail: Order #{atob(id)}
                    </th>
                    <th colSpan="1" className="text-right">
                      {" "}
                      <Link to="/my-account/orders">Return to list</Link>
                    </th>
                  </tr>
                  <tr>
                    <th>Order:</th>
                    <td>{atob(id)}</td>
                  </tr>
                  <tr>
                    <th>Status:</th>
                    <td>
                      <i
                        className={`fas ${
                          getStatus(orderDetails?.data?.order?.order_status)
                            ?.icon
                        } mr-2`}
                        style={{
                          color: getStatus(
                            orderDetails?.data?.order?.order_status
                          )?.color,
                        }}
                      ></i>
                      {
                        getStatus(orderDetails?.data?.order?.order_status)
                          ?.status
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <td>${orderDetails?.data?.order?.order_total}</td>
                  </tr>

                  <tr>
                    <th>Last Modified:</th>
                    <td>
                      {" "}
                      {moment(orderDetails?.data?.order?.updated_at).format(
                        "LL"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th>Name: </th>
                    <td>
                      {orderDetails?.data?.order?.first_name +
                        " " +
                        orderDetails?.data?.order?.last_name}
                    </td>
                  </tr>

                  <tr>
                    <th>Email:</th>
                    <td>{orderDetails?.data?.order?.email_address}</td>
                  </tr>

                  <tr>
                    <th>Phone:</th>
                    <td>{orderDetails?.data?.order?.phone || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mt-4">
              <table className="table-bordered">
                <tbody>
                  <tr className="table-title-1">
                    <th colSpan="5">Order Summary</th>
                  </tr>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Cost</th>
                    <th>Qty</th>
                    <th>Total</th>
                  </tr>
                  {orderDetails?.data?.orderItems?.map((item, i) => {
                    return (
                      <tr>
                        <td>1.</td>
                        <td>{item?.name}</td>
                        <td>${item?.product_price}</td>
                        <td>
                          <small className="times">×</small>{" "}
                          {item?.product_quantity}
                        </td>

                        <td>
                          $
                          {Number(
                            Number(item?.product_price) *
                              Number(item?.product_quantity)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colspan="3"></td>
                    <td>
                      <b>Shipping:</b>
                    </td>
                    <td>${orderDetails?.data?.order?.order_shipping}</td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>
                      <b>Tax:</b>
                    </td>
                    <td>${orderDetails?.data?.order?.order_tax}</td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td>${orderDetails?.data?.order?.order_total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row mt-5  ">
              <div className="col-md-6 col-sm-6">
                <div>
                  <h4>Billing Address</h4>
                  <ul className="list-unstyled">
                    <li>
                      {orderDetails?.data?.order?.first_name +
                        " " +
                        orderDetails?.data?.order?.last_name}
                    </li>
                    <li>
                      {orderDetails?.data?.order?.apartment &&
                        orderDetails?.data?.order?.apartment + ", "}{" "}
                      {orderDetails?.data?.order?.street_address &&
                        orderDetails?.data?.order?.street_address + ", "}{" "}
                      {orderDetails?.data?.order?.town &&
                        orderDetails?.data?.order?.town + ", "}
                      {orderDetails?.data?.order?.postcode &&
                        orderDetails?.data?.order?.postcode + ","}
                      <br />
                      {orderDetails?.data?.order?.state &&
                      orderDetails?.data?.order?.state
                        ? orderDetails?.data?.order?.state + ", "
                        : ""}{" "}
                      {orderDetails?.data?.order?.country &&
                        orderDetails?.data?.order?.country + ", "}{" "}
                      <br />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div>
                  <h4>Shipping Address</h4>
                  <ul className="list-unstyled">
                    <li>
                      {" "}
                      {orderDetails?.data?.order?.shipping_first_name +
                        " " +
                        orderDetails?.data?.order?.shipping_last_name}
                    </li>
                    <li>
                      {(orderDetails?.data?.order?.shipping_apartment &&
                        orderDetails?.data?.order?.shipping_apartment + ", ") ||
                        (orderDetails?.data?.order?.apartment &&
                          orderDetails?.data?.order?.apartment + ", ")}

                      {orderDetails?.data?.order?.shipping_street_address +
                        ", " ||
                        orderDetails?.data?.order?.street_address + ", "}
                      {orderDetails?.data?.order?.shipping_town + ", " ||
                        orderDetails?.data?.order?.town + ", "}

                      <br />
                      {(orderDetails?.data?.order?.shipping_state &&
                        orderDetails?.data?.order?.shipping_state + ", ") ||
                        (orderDetails?.data?.order?.state &&
                          orderDetails?.data?.order?.state + ", ")}
                      {orderDetails?.data?.order?.shipping_postcode + ", " ||
                        orderDetails?.data?.order?.postcode + ", "}
                      {orderDetails?.data?.order?.shipping_country ||
                        orderDetails?.data?.order?.country}
                      <br />
                    </li>
                  </ul>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
}
