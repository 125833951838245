import React from "react";
import { useSelector } from "react-redux";

export default function useExtractDataFromHtml() {
  function extractData(data) {
    const span = document.createElement("span");
    span.innerHTML = data;
    let options = span.getElementsByClassName("form-row-wide");
    let shippingOptionList = [];
    for (let i = 0; i < options.length; i++) {
      shippingOptionList[i] = {
        value: options[i]
          .getElementsByClassName("shipping_method")[0]
          .value.replace(/(<([^>]+)>)/gi, ""),
        name: options[i].innerText || options[i].textContent,
      };
    }
    return shippingOptionList;
  }
  return [extractData];
}
