import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/scss/pages/ProductQuickView.scss";
import CircularLoader from "../shared/CircularLoader";
import { triggerReedemPoints } from "../../redux/actions/cartAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReedemPoints({ close, isOpen }) {
  const [points, setPoints] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const { redeemPoints, total } = useSelector(
    (state) => state.cartReducer
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (points) {
      return dispatch(
        triggerReedemPoints(
          {
            points: points,
            cart_total: Number(total).toFixed(2),
          },
          () => {
            close();
          }
        )
      );
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={onSubmit}>
              <div className="px-4 pb-3 pt-2">
                <div className="mb-2 alert-dialog-text">
                  HOW MANY POINTS WOULD YOU LIKE TO APPLY?
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    id="checkout-billing-address-first-name"
                    className="form-control"
                    placeholder="Enter points to redeem"
                    name="points"
                    value={points}
                    onChange={(e) => {
                      setPoints(e.target.value);
                    }}
                  />

                  {points === "" && isSubmit && (
                    <div className="invalid-feedback">
                      Please enter points before apply.
                    </div>
                  )}
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={redeemPoints?.loading}
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-1">Apply</div>

                    {redeemPoints?.loading && <CircularLoader size={15} />}
                  </div>
                </button>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
