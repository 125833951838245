import {
  TRIGGER_GET_BLOG_CATEGORIES_FAILED,
  TRIGGER_GET_BLOG_CATEGORIES_SUCCESS,
  TRIGGER_GET_BLOG_CATEGORIES,
  TRIGGER_GET_ASK_KEN,
  TRIGGER_GET_ASK_KEN_SUCCESS,
  TRIGGER_GET_ASK_KEN_FAILED,
  TRIGGER_GET_CATEGORY_LIST,
  TRIGGER_GET_CATEGORY_LIST_SUCCESS,
  TRIGGER_GET_CATEGORY_LIST_FAILED,
} from "../actions/actionTypes";

const initialState = {
  blogsCategoriesList: {
    loading: false,
    data: [],
    error: null,
  },
  askKen: {
    loading: false,
    data: [],
    error: null,
  },

  categoryList:{
    loading: false,
    data: [],
    error: null,
  }
};

const blogsReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_GET_BLOG_CATEGORIES:
      return {
        ...state,
        blogsCategoriesList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        blogsCategoriesList: {
          loading: false,
          data: action.payload.blogCategories,
          error: null,
        },
      };

    case TRIGGER_GET_BLOG_CATEGORIES_FAILED:
      return {
        ...state,
        blogsCategoriesList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_ASK_KEN:
      return {
        ...state,
        askKen: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ASK_KEN_SUCCESS:
      return {
        ...state,
        askKen: {
          loading: false,
          data: action.payload.articles,
          error: null,
        },
      };

    case TRIGGER_GET_ASK_KEN_FAILED:
      return {
        ...state,
        askKen: {
          loading: false,
          data: null,
          error: action.error,
        },
      };


      case TRIGGER_GET_CATEGORY_LIST:
        return {
          ...state,
          categoryList: {
            loading: true,
            data: [],
            error: null,
          },
        };
  
      case TRIGGER_GET_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          categoryList: {
            loading: false,
            data: action.payload.articles,
            error: null,
          },
        };
  
      case TRIGGER_GET_CATEGORY_LIST_FAILED:
        return {
          ...state,
          categoryList: {
            loading: false,
            data: null,
            error: action.error,
          },
        };

    default:
      return state;
  }
};

export default blogsReducer;
