import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../../../assets/scss/pages/block/Block.scss";
import {
  TRIGGER_SKU_KEYWORD,
  TRIGGER_VAN_CATEGORY,
  TRIGGER_VAN_TYPE,
} from "../../../redux/actions/actionTypes";

export default function BlockFinder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mainMenuList, categories } = useSelector(
    (state) => state.mainMenuReducer
  );
  const [searchType, setSearchType] = useState("SearchByCategory");
  const [vanType, setVanType] = useState("68-79-bus");
  const [categoryType, setCategoryType] = useState("accessories");
  const [sku, setSku] = useState("");

  useEffect(() => {
    setSearchType("SearchByCategory");
    setVanType("68-79-bus");
    setSku("");
  }, []);
  // history.location.key

  const handleClick = (e) => {
    setSearchType(e.target.name);
  };

  const handleOnChangeVanType = (e) => {
    setVanType(e.target.value);
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    if (type === "sku") {
      dispatch({
        type: TRIGGER_SKU_KEYWORD,
        skuKeyword: sku,
      });
      dispatch({
        type: TRIGGER_VAN_TYPE,
        vanTypeName: "",
      });

      dispatch({
        type: TRIGGER_VAN_CATEGORY,
        vanCategory: "",
      });
    } else {
      dispatch({
        type: TRIGGER_VAN_TYPE,
        vanTypeName: vanType,
      });

      dispatch({
        type: TRIGGER_VAN_CATEGORY,
        vanCategory: categoryType,
      });

      dispatch({
        type: TRIGGER_SKU_KEYWORD,
        skuKeyword: "",
      });
    }

    history.push({
      pathname: "/shop/search",
      state: {
        skuKeyword: sku,
        vanTypeSlug: vanType,
        categorySlug: categoryType,
      },
    });
  };

  return (
    <div className="block-finder-outer-div bg-light">
      <div className="container block-finder-container">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <div className="block block-finder">
            <div className="block-finder_filter">
              <div className="block-finder_filter-grid">
                <div className="block-finder-title">Filter Products:</div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="SearchByCategory"
                      checked={searchType === "SearchByCategory"}
                      onChange={(e) => {
                        handleClick(e);
                      }}
                    />
                    <span>By Category</span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="SearchBySku"
                      checked={searchType === "SearchBySku"}
                      onChange={(e) => {
                        handleClick(e);
                      }}
                    />
                    <span>By SKU or Product</span>
                  </label>
                </div>
              </div>
            </div>

            {searchType === "SearchByCategory" ? (
              <div className="block-finder__body container container--max--xl">
                <form className="block-finder__form">
                  <div className="vehicle-select block-finder__select">
                    <div className="vehicle-select__list">
                      <div className="vehicle-select__item">
                        <label htmlFor="van-type">Type of van...</label>
                        <select
                          className="vehicle-select__item-control"
                          aria-label="Type of van..."
                          id="van-type"
                          name="van_type"
                          onChange={(e) => {
                            handleOnChangeVanType(e);
                          }}
                          value={vanType}
                        >
                          {mainMenuList?.data?.map((item, i) => {
                            return (
                              <option key={i} value={item.slug}>
                                {item.title}
                              </option>
                            );
                          })}
                        </select>
                        <div className="vehicle-select__item-loader"></div>
                      </div>
                      <div className="vehicle-select__item">
                        <label htmlFor="category">Category</label>
                        <select
                          className="vehicle-select__item-control"
                          aria-label="Category"
                          id="category"
                          name="van_cat"
                          value={categoryType}
                          onChange={(e) => {
                            setCategoryType(e.target.value);
                          }}
                        >
                          {categories?.data?.map((item, i) => {
                            return (
                              <option key={i} value={item.slug}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="vehicle-select__item-loader"></div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="block-finder__button"
                    type="submit"
                    onClick={(e) => handleSubmit(e, "vanType")}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            ) : (
              <div className="block-finder__body block-finder-sku container container--max--xl">
                <form className="d-flex align-items-end flex-wrap">
                  <div className="block-finder-input">
                    <label htmlFor="sku">Search by SKU or Keyword:</label>
                    <input
                      id="sku"
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="sku"
                      onChange={(e) => {
                        setSku(e.target.value);
                      }}
                    />
                  </div>

                  <button
                    className="block-finder__button"
                    type="submit"
                    onClick={(e) => handleSubmit(e, "sku")}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            )}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
}
