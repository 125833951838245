import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../../assets/scss/pages/block/Block.scss";
import config from "../../../utils/config";
import SubMenuItems from "../../header/SubMenuItems";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function BlockSlider({ slides }) {
  const [showSubMenu, setShowSubMenu] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setShowSubMenu(true);
    } else {
      setShowSubMenu(false);
    }
  };

  return (
    <div className="block block-slideshow">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <div className="container">
          <div className="d-flex">
            {showSubMenu && (
              <div className="col-xl-3 col-lg-4 ">
                <SubMenuItems />
              </div>
            )}

            <div className="col-xl-9 col-lg-8">
              <div className="block-slideshow__carousel">
                <Slider {...settings}>
                  {slides?.length &&
                    slides.map((slide, slideIdx) => {
                      return (
                        <div key={slideIdx} className="block-slideshow__item">
                          <img
                            className="block-slideshow__item-image reflect-rtl"
                            src={
                              slide?.image
                                ? `${config.imageBasePath}${slide?.image}`
                                : `https://www.vanagain.com/public/assets/uploads/no_img.gif`
                            }
                            alt={slide?.title}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
