import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyAndTerms } from "../redux/actions/privacyAndTermsAction";
import useSetMetaTags from "../hooks/useSetMetaTags";

export default function PrivacyAndTerms() {
  const dispatch = useDispatch();
  const { privacyAndTermsObj } = useSelector(
    (state) => state.privacyAndTermsReducer
  );
  const [setMetaTags]=useSetMetaTags();

  useEffect(() => {
    setMetaTags("Privacy & Terms","Privacy & Terms","Privacy & Terms")
    dispatch(getPrivacyAndTerms());
  }, []);

  return (
    <div className="privacy-and-terms">
      <div className="site__body">
        <div className="block-space block-space--layout--spaceship-ledge-height"></div>
        <div className="block  privacy-policy">
          <div className="">
            <div className="privacy-policy__header">
              <h1 className="privacy-policy__header-title">
                {privacyAndTermsObj?.data?.page?.title}
              </h1>
            </div>
            <div className="privacy-policy__section">
              <div className="privacy-policy__section-body d-block "></div>
              <div
                className="card px-3 py-4"
                dangerouslySetInnerHTML={{
                  __html: privacyAndTermsObj?.data?.page?.description,
                }}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
