import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlockSpace from "../../components/blocks/BlockSpace";
import CircularLoader from "../../components/shared/CircularLoader";
import { triggerResetPassword } from "../../redux/actions";
export default function ResetPassword(props) {
  let initialObject = {
    email: "",
    password: "",
    password_confirmation: "",
  };

  const dispatch = useDispatch();
  const [resetPassword, setResetPassword] = useState(initialObject);
  const [isSubmit, setIsSubmit] = useState(false);
  const { changePassword } = useSelector((state) => state.authReducer);
  const { email, password, password_confirmation } = resetPassword;

  useEffect(() => {
    if (changePassword?.error) {
      toast.error(changePassword?.error?.message, {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  }, [changePassword]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (
      email !== "" &&
      password_confirmation !== "" &&
      password !== "" &&
      password.length >= 6 &&
      password === password_confirmation
    ) {
      return dispatch(
        triggerResetPassword(
          {
            forgotPassword: {
              email: email,
              password: password,
            },

            id: props.match.params.id,
          },
          (resp) => {
            setIsSubmit(false);
            setResetPassword(initialObject);
            toast.success(resp?.message, {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
          }
        )
      );
    }
  };

  const handleOnChange = (e) => {
    let updateObject = { ...resetPassword };
    updateObject[e.target.name] = e.target.value;
    setResetPassword(updateObject);
  };

  return (
    <div className="container ">
      <BlockSpace className="block-space--layout--before-footer" />

      <div className="col-md-6 m-auto offset-md-6">
        <div className="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4 login-page">
          <div className="card-body card-body--padding--2">
            <h3 className="card-title">Reset Password</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="signin-email">Email Address</label>
                <input
                  id="signin-email"
                  type="email"
                  className="form-control"
                  placeholder=""
                  name="email"
                  value={email}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                {isSubmit && email === "" && (
                  <div className="invalid-feedback">Please enter email!</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="signin-email">Password</label>
                <input
                  id="signin-email"
                  type="password"
                  className="form-control"
                  placeholder=""
                  name="password"
                  value={password}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                {isSubmit && password === "" && (
                  <div className="invalid-feedback">Please enter password!</div>
                )}

                {isSubmit && password && password.length < 6 && (
                  <div className="invalid-feedback">
                    Password must be of length 6!
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="signin-email">Confirm Password</label>
                <input
                  id="signin-email"
                  type="password"
                  className="form-control"
                  placeholder=""
                  name="password_confirmation"
                  value={password_confirmation}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                {isSubmit && password_confirmation === "" && (
                  <div className="invalid-feedback">
                    Please confirm password!
                  </div>
                )}

                {isSubmit &&
                  password &&
                  password_confirmation &&
                  password !== password_confirmation && (
                    <div className="invalid-feedback">
                      Password and confirm password must match!
                    </div>
                  )}
              </div>

              <div className="form-group mb-0">
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={changePassword?.loading}
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-1"> Reset Password </div>
                    {changePassword?.loading && <CircularLoader size={15} />}
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
