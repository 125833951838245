import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/shared/Breadcrumb";
import PaginationPage from "../../components/shared/Pagination";
import ProductCategoriesSidebar from "../../components/shop/ProductCategoriesSidebar";
import { getArticles } from "../../redux/actions/articlesAction";
import ArticleCard from "./ArticleCard";
import { useHistory, useParams } from "react-router-dom";
import OverlayLoader from "../../components/shared/OverlayLoader";
import useSetMetaTags from "../../hooks/useSetMetaTags";

export default function ArticlesList(props) {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { pageNo } = useParams();
  const [page, setPage] = useState(1);
  const startIndex = 0;
  const endIndex = 12;
  const { articlesList } = useSelector((state) => state.articlesReducer);
  const [articleList, setArticleList] = useState([]);
  const [setMetaTags] = useSetMetaTags();

  useEffect(() => {
    setPage(pageNo ? pageNo : page);
    dispatch(getArticles());
    setMetaTags("Articles", "Articles", "Articles");
  }, []);

  useEffect(() => {
    if (articlesList?.data?.length > 0) {
      alterArticleList();
    }
  }, [page, articlesList?.data]);

  const alterArticleList = () => {
    let list = articlesList?.data;
    const indexOfLastTodo = page * 12;
    const indexOfFirstTodo = indexOfLastTodo - 12;
    const updatedList = list.slice(indexOfFirstTodo, indexOfLastTodo);
    setArticleList(updatedList);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    navigate.push(`/articles/page/${newPage}`);
  };

  return (
    <div className="product-categories-page">
      <div className="container ">
        <Breadcrumb
          list={[
            {
              title: "Articles",
              url: "/articles",
            },
          ]}
        />
        <h1 className="block-header__title  animate__animated animate__fadeIn">
          VANGON ARTICLES
        </h1>
        {articlesList?.loading && (
          <div className="product-loader-div ">
            <OverlayLoader />
          </div>
        )}

        {!articlesList.loading && (
          <div className="block-split__row d-flex no-gutters">
            <div className="block-split--has-sidebar block-split__item-sidebar">
              <ProductCategoriesSidebar />
            </div>

            <div className="block-split__item block-split__item-content col-auto flex-grow-1">
              {articleList.map((article, i) => {
                return <ArticleCard key={i} article={article} />;
              })}
              <div className="card product-caytegory-pagination">
                <PaginationPage
                  startIndex={startIndex + 1}
                  lastIndex={endIndex}
                  showText={true}
                  onChangePage={onChangePage}
                  count={Math.ceil(articlesList?.data?.length / 12)}
                  page={page}
                  totalCount={articlesList?.data?.length}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
