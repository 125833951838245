import React from "react";
import { useSelector } from "react-redux";
import vwparts from "../../../assets/images/banners/vw-parts.png";
import "../../../assets/scss/pages/block/Block.scss";
import CircularLoader from "../../shared/CircularLoader";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function BlockDetails() {
  const { homePageContent } = useSelector((state) => state.homePageReducer);
  return (
    <div className="block block-banners block-card-vangain-container position-relative">
      {homePageContent?.loading ? (
        <div className="center-div">
          <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
        </div>
      ) : (
        <div className="container">
          <div className="blocks-list__item">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOnce={true}
            >
              <div className="block-card block-card--layout--list block-card-vanagain-layout">
                <div className="block-card__image ">
                  <img src={vwparts} alt="vw" />
                  {/* <Img src={vwparts} /> */}
                </div>
                <div className="block-card__content">
                  <div className="block-card__title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homePageContent?.data?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      )}
    </div>
  );
}
