import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { ClickAwayListener, Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isLogin } from "../../utils";

export default function Indicator({
  icon,
  href,
  label,
  value,
  counter,
  children,
  buttonType,
  type,
  onClick,
}) {
  const hasLabel = label !== undefined && label !== null;
  const hasValue = value !== undefined && value !== null;
  let navigate = useHistory();
  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const showCounter = counter !== undefined && counter !== null;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const buttonContent = (
    <>
      <span className="indicator__icon">
        <img src={icon} alt={icon} />
        {showCounter && <span className="indicator__counter">{counter}</span>}
      </span>

      {hasLabel && <span className="indicator__title">{label}</span>}
      {hasValue && <span className="indicator__value">{value}</span>}
    </>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    if (buttonType === "button") {
      if (type === "wishlist" && !isLogin()) {
        onClick();
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else {
      navigate.push(href);
      setAnchorEl(null);
    }
  };

  return (
    <div className="indicator">
      <button
        className="indicator__button"
        aria-describedby={id}
        id={`indicator_btn_${type}`}
        type="button"
        onClick={handleClick}
      >
        {buttonContent}
      </button>

      <Popper id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <ClickAwayListener onClickAway={handleClose}>
          <Typography className={classes.typography}>
            {" "}
            {React.cloneElement(children, {
              close: handleClose,
            })}
          </Typography>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
