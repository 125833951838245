import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../shared/Breadcrumb";
import ProductView from "../shop/ProductView";
import ProductCategoriesSidebar from "../shop/ProductCategoriesSidebar";
import ProductCard from "../shared/ProductCard";
import "../../assets/scss/pages/ProductCategoriesPage.scss";
import PaginationPage from "../shared/Pagination";
import {
  TRIGGER_NO_OF_PRODUCTS,
  TRIGGER_VIEW_TYPE,
} from "../../redux/actions/actionTypes";
import CircularLoader from "../shared/CircularLoader";
import useGetProductList from "../../hooks/useGetProductList";
import useSetMetaTags from "../../hooks/useSetMetaTags";
export default function ProductCategoriesPage(props) {
  const dispatch = useDispatch();
  let location = useLocation();
  const { productsList, noOfProductsPerPage, typeOfView } = useSelector(
    (state) => state.shopReducer
  );
  const vanType = props.match.params.vanType;
  const categoryType = props.match.params.categoryType;
  const type = props.match.params.type;
  const [layout, setLayout] = useState(typeOfView || "product-grid");
  const [page, setPage] = useState(1);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(12);
  const [breadcrumbList, setBreadCrumbList] = useState([]);
  const [getProductsList] = useGetProductList();
  const [setMetaTags] = useSetMetaTags();

  useEffect(() => {
    setMetaTags("Shop", "Shop", "Shop");
  }, []);
  useEffect(() => {
    getProductsList();
    setPage(1);
    createBreadCrumbList();
  }, [
    location?.state?.vanTypeSlug,
    location?.state?.categorySlug,
    location?.state?.searchSlug,
    location?.state?.skuKeyword,
  ]);

  useEffect(() => {
    if (productsList?.data?.length > 0) {
      if (props?.match?.params?.pageNo) {
        setPage(props.match.params.pageNo);
      }
      alterProductList();
    }
  }, [page, productsList?.data, noOfProductsPerPage]);

  const changeLayout = (layout) => {
    setLayout(layout.class);
    dispatch({
      type: TRIGGER_VIEW_TYPE,
      typeOfView: layout.class,
    });
  };

  const createBreadCrumbList = () => {
    let list = [];
    if (location.pathname.includes("shop")) {
      list.push({
        title: "Shop",
        url: "",
      });
    }

    if (location?.state?.vanTypeSlug) {
      list.push({
        title: location?.state?.title,
        url: location?.state?.vanTypeSlug,
      });
    }

    if (location?.state?.categorySlug) {
      list.push({
        title: location?.state?.categorySlug,
        url: location?.state?.categorySlug,
      });
    }

    setBreadCrumbList(list);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    let path;
    if (vanType && newPage) {
      (vanType && newPage && !categoryType) || categoryType === "page"
        ? (path = `/categories/${vanType}/page/${newPage}`)
        : (path = `/categories/${vanType}/${categoryType}/page/${newPage}`);
    } else {
      type === "search"
        ? (path = `/shop/search/page/${newPage}`)
        : (path = `/shop/page/${newPage}`);
    }
    props.history.replace(
      { pathname: path },
      {
        vanTypeSlug: location?.state?.vanTypeSlug,
        categorySlug: location?.state?.categorySlug,
        searchSlug: location?.state?.searchSlug,
      }
    );
  };

  const onNoOfProductsPerPage = (e) => {
    alterProductList();

    dispatch({
      type: TRIGGER_NO_OF_PRODUCTS,
      noOfProductsPerPage: e.target.value,
    });
  };

  const alterProductList = () => {
    let list = productsList.data;
    if (categoryType === "page") {
      setPage(props.match.params.type);
    }
    const indexOfLastTodo =
      page * (noOfProductsPerPage ? noOfProductsPerPage : 12);
    const indexOfFirstTodo =
      indexOfLastTodo - (noOfProductsPerPage ? noOfProductsPerPage : 12);
    setEndIndex(indexOfLastTodo);
    setStartIndex(indexOfFirstTodo);

    if (noOfProductsPerPage !== "View All") {
      const currentTodos = list.slice(indexOfFirstTodo, indexOfLastTodo);
      setListOfProducts(currentTodos);
    } else {
      setListOfProducts(list);
    }
  };

  return (
    <div className="product-categories-page">
      <div className="container ">
        <Breadcrumb list={breadcrumbList} />

        <>
          <h1 className="block-header__title  animate__animated animate__fadeIn">
            {props?.location?.state?.title}
          </h1>
          <div className="block-split__row d-flex no-gutters">
            <div className="block-split--has-sidebar block-split__item-sidebar">
              <ProductCategoriesSidebar />
            </div>

            <div className="block-split__item block-split__item-content col-auto flex-grow-1">
              {productsList?.loading && (
                <div className="product-loader-div card">
                  <div className="center-div">
                    <CircularLoader
                      size={60}
                      color={"#32c2ef"}
                      type="overlay-loader"
                    />
                  </div>
                </div>
              )}

              {!productsList?.loading &&
                (productsList?.data?.length === 0 || productsList?.message) && (
                  <div className="product-loader-div card">
                    <div className="center-div">
                      <p>{productsList?.message || "No Data"}</p>
                    </div>
                  </div>
                )}

              {!productsList?.loading && productsList?.data?.length > 0 && (
                <>
                  <ProductView
                    changeLayout={(layout) => changeLayout(layout)}
                    layout={layout}
                    startIndex={startIndex + 1}
                    endIndex={endIndex}
                    totalCount={productsList?.data?.length}
                    onNoOfProductsPerPage={onNoOfProductsPerPage}
                    productsPerPage={noOfProductsPerPage}
                  />

                  {layout !== "d-table" ? (
                    <div className={layout}>
                      {listOfProducts?.map((product, i) => {
                        return (
                          <ProductCard
                            layout={layout}
                            key={product.id}
                            product={product}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="products-view__list products-list products-list-table">
                      <div className="products-list__head table">
                        <div className="products-list__column products-list__column--image">
                          Image
                        </div>
                        <div className="products-list__column products-list__column--meta">
                          SKU
                        </div>
                        <div className="products-list__column products-list__column--product">
                          Product
                        </div>
                        <div className="products-list__column products-list__column--price">
                          Price
                        </div>
                      </div>

                      {listOfProducts.map((product, i) => {
                        return (
                          <ProductCard
                            layout={layout}
                            key={product.id}
                            product={product}
                          />
                        );
                      })}
                    </div>
                  )}
                  <div className="card product-caytegory-pagination">
                    {productsList?.data?.length > noOfProductsPerPage && (
                      <PaginationPage
                        startIndex={startIndex + 1}
                        lastIndex={endIndex}
                        showText={true}
                        onChangePage={onChangePage}
                        count={Math.ceil(
                          productsList?.data?.length /
                            (noOfProductsPerPage ? noOfProductsPerPage : 12)
                        )}
                        page={page}
                        totalCount={productsList?.data?.length}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
