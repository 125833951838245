import React, { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlockMap from "../components/blocks/BlockMap";
import BlockSpace from "../components/blocks/BlockSpace";
import Breadcrumb from "../components/shared/Breadcrumb";
import CircularLoader from "../components/shared/CircularLoader";
import {
  triggerContactUs,
  triggerContact,
} from "../redux/actions/contactUsAction";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../utils/config";
import { AnimationOnScroll } from "react-animation-on-scroll";
import useSetMetaTags from "../hooks/useSetMetaTags";

function ContactUs() {
  const contactUsInitialObj = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const dispatch = useDispatch();
  const { contactUsObj, contactObj } = useSelector(
    (state) => state.contactUsReducer
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactUsObject, setContactUsObj] = useState(contactUsInitialObj);
  const { name, email, subject, message } = contactUsObject;
  const [captcha, validateCaptcha] = useState(false);
  const recaptchaRef = createRef();
  const [setMetaTags] = useSetMetaTags();

  const gCaptcha = {
    human: false,
    humanKey: "",
  };

  useEffect(() => {
    setMetaTags("Contact Us", "Contact Us", "Contact Us");
    let mounted = true;
    dispatch(triggerContact());
    return () => (mounted = false);
  }, []);

  const verifyCaptcha = function (value) {
    if (value) {
      gCaptcha.human = true;
      gCaptcha.humanKey = value;
      validateCaptcha(true);
    }
  };

  useEffect(() => {
    if (contactUsObj?.error) {
      toast.error(contactUsObj?.error?.message, {
        toastId: "toastMsg",
        autoClose: 2000,
      });
    }
  }, [contactUsObj]);

  const onChangeHandler = (e) => {
    let updatedContactUsObj = { ...contactUsObject };
    updatedContactUsObj[e.target.name] = e.target.value;
    setContactUsObj(updatedContactUsObj);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);

    if (
      name !== "" &&
      email !== "" &&
      subject !== "" &&
      message != "" &&
      captcha
    ) {
      return dispatch(
        triggerContactUs(contactUsObject, () => {
          toast.success("Form submitted successfully!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
          setIsSubmit(false);
          setContactUsObj(contactUsInitialObj);
        })
      );
    }
  };

  return (
    <div className="site__body">
      <BlockMap />
      <div className="block-header block-header--has-title block-header--has-breadcrumb">
        <div className="container">
          <div className="block-header__body">
            <Breadcrumb
              list={[
                {
                  title: "Contact Us",
                  url: "/contact-us",
                },
              ]}
            />

            <h1 className="block-header__title  animate__animated animate__fadeIn text-center">
              {contactObj?.data?.contact?.page_title}
            </h1>
          </div>
        </div>
      </div>
      <div className="block contact-us">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <div className="container">
            <div className="card">
              <div className="card-body ">
                <div className="row body-row">
                  <div className="col-12 col-lg-6 pb-4 pb-lg-0 contact-us-row">
                    <div className="mr-1">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: contactObj?.data?.contact?.description,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="ml-1">
                      <h4 className="contact-us__header card-title">
                        KEEP IN TOUCH
                      </h4>
                      <p>
                        Our helpline is always open to receive any inquiry or
                        feedback. Please feel free to drop us an email from the
                        form below and we will get back to you as soon as we
                        can.
                      </p>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="form-name">Name</label>
                            <input
                              type="text"
                              id="form-name"
                              name="name"
                              onChange={(event) => {
                                onChangeHandler(event);
                              }}
                              className="form-control"
                              placeholder="Your Name"
                              value={name}
                            />
                            {isSubmit && name === "" && (
                              <div className="invalid-feedback">
                                Please enter name!
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="form-email">Email</label>
                            <input
                              type="email"
                              id="form-email"
                              className="form-control"
                              name="email"
                              onChange={(event) => {
                                onChangeHandler(event);
                              }}
                              placeholder="Email Address"
                              value={email}
                            />
                            {isSubmit && email === "" && (
                              <div className="invalid-feedback">
                                Please enter email!
                              </div>
                            )}
                          </div>
                          <div className="form-group  col-md-12">
                            <label htmlFor="form-subject">Subject</label>
                            <input
                              type="text"
                              id="form-subject"
                              name="subject"
                              onChange={(event) => {
                                onChangeHandler(event);
                              }}
                              className="form-control"
                              placeholder="Subject"
                              value={subject}
                            />
                            {isSubmit && subject === "" && (
                              <div className="invalid-feedback">
                                Please enter subject!
                              </div>
                            )}
                          </div>
                          <div className="form-group  col-md-12">
                            <label htmlFor="form-message">Comment</label>
                            <textarea
                              id="form-message"
                              className="form-control"
                              name="message"
                              onChange={(event) => {
                                onChangeHandler(event);
                              }}
                              placeholder="Write comment here..."
                              rows="4"
                              value={message}
                            ></textarea>
                            {isSubmit && message === "" && (
                              <div className="invalid-feedback">
                                Please enter comment!
                              </div>
                            )}
                          </div>

                          <div className="form-group  col-md-6">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={config.captchaSiteKey}
                              onChange={verifyCaptcha}
                            />
                            {isSubmit && !captcha && (
                              <div className="invalid-feedback">
                                Please verify captcha!
                              </div>
                            )}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary ml-2 mb-3"
                          disabled={contactUsObj?.loading}
                        >
                          <div className="d-flex align-items-center">
                            <div className="mr-1"> Send</div>
                            {contactUsObj?.loading && (
                              <CircularLoader size={15} />
                            )}
                          </div>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}

export default React.memo(ContactUs); // Added Memoization
