import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  triggerAuthLogin,
  triggerGetWishList,
  triggerLogout,
} from "../redux/actions";
import {
  TRIGGER_LOGOUT_SUCCESS,
  TRIGGER_TAX_AMOUNT,
  TRIGGER_WISH_LIST_SUCCESS,
} from "../redux/actions/actionTypes";
import { useHistory } from "react-router-dom";

export default function useAuthHook() {
  const dispatch = useDispatch();
  const { logoutObj } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const notify = (msg) => {
    toast.success(msg, {
      toastId: "toastMsg",
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    if (logoutObj?.error) {
      clearToken();
    }
  }, [logoutObj]);

  function login(email, password, loading, close) {
    return dispatch(
      triggerAuthLogin(
        {
          email: email,
          password: password,
        },
        (resp) => {
          if (resp?.code === 200) {
            if (history?.location?.state?.prevPath) {
              history.push(history?.location?.state?.prevPath);
            } else {
              history.push(history?.location?.pathName);
            }
            if (loading) {
              notify("Login successful!");
              loading(false);
            }
            if (close) {
              close();
            }
            dispatch(triggerGetWishList({}));
          }
        }
      )
    );
  }

  function logout(close) {
    return dispatch(
      triggerLogout({}, (resp) => {
        if (resp) {
          notify("Logout successful!");
          clearToken();
          close();
        }
      })
    );
  }

  function clearToken() {
    dispatch({
      type: TRIGGER_LOGOUT_SUCCESS,
      logoutObj: { loading: false, data: [], error: null },
    });
    dispatch({
      type: TRIGGER_WISH_LIST_SUCCESS,
      payload: { wishlists: { loading: false, data: [], error: null } },
    });
    dispatch({
      type: TRIGGER_TAX_AMOUNT,
      tax: 0.0,
    });
    history.push(history?.location?.pathname);
  }

  return [login, logout];
}
