import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverlayLoader from "../components/shared/OverlayLoader";
import {
  TRIGGER_GET_SHIPPING_DETAILS,
  TRIGGER_SHIPPING_CHARGES,
  TRIGGER_SHIPPING_METHODS,
  TRIGGER_TAX_AMOUNT,
} from "../redux/actions/actionTypes";
import useCalculateShippingHook from "./useCalculateShippingHook";
import useExtractDataFromHtml from "./useExtractDataFromHtml";
import { ClickAwayListener, Popper } from "@material-ui/core";
import { triggerShippingState } from "../redux/actions/cartAction";
import CircularLoader from "../components/shared/CircularLoader";
import { countryOptions } from "../utils/constants";

export default function useShippingMethodsList() {
  const {
    cart,
    total,
    shippingObj,
    shippingAddress,
    shippingMethod,
    couponDetails,
    tax,
    shippingCountry,
    shippingState,
    redeemPoints,
    pointDiscount,
    expressCheckout,
    cancelOrder,
    shippingMethodsList,
  } = useSelector((state) => state.cartReducer);

  const [extractData] = useExtractDataFromHtml();
  const { homePageData } = useSelector((state) => state.homePageReducer);
  const [shippingMethodList, setShippingMethodList] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [shippingOption, setShippingOption] = useState(
    shippingMethod?.shippingName
  );
  const [noShippingAvailable, setNoShippingAvailable] = useState(true);

  useEffect(() => {
    setShippingOption(shippingMethod?.shippingName);
  }, [shippingMethod]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (shippingObj?.data?.response === 1) {
      setShippingMethodList(extractData(shippingObj?.data?.message));
      dispatch({
        type: TRIGGER_SHIPPING_METHODS,
        shippingMethodsList: extractData(shippingObj?.data?.message),
      });
    } else if (shippingObj?.error) {
    } else {
      setShowMsg(true);
      setShippingMethodList([]);
      setTimeout(() => {
        setShowMsg(false);
      }, 5000);
    }
  }, [shippingObj]);

  useEffect(() => {
    setShippingMethodList(shippingMethodsList);
  }, [shippingMethodsList]);

  const selectShippingMethod = (e) => {
    let charges = e.target.value.split(":").pop();
    setShippingOption(e.target.value);
    let shippingMode = e?.target?.value.split(":");
    if (shippingMode[0].toUpperCase() == "UPS") {
      shippingMode = shippingMode[1].split("_").join(" ");
    } else if (shippingMode[0].toUpperCase() == "USPS") {
      shippingMode = shippingMode[1].split("_").join(" ");
    } else {
      shippingMode =
        shippingMode[0].toUpperCase() +
        ": " +
        shippingMode[1].split("_").join(" ");
    }
    dispatch({
      type: TRIGGER_SHIPPING_CHARGES,
      shippingMethod: {
        shippingCharges: charges,
        shippingName: e.target.value,
        shippingMode: shippingMode,
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const [initShippingAddress, calculateShippingFunction] =
    useCalculateShippingHook();
  const [calcShippingObj, setCalcShippingObj] = useState(initShippingAddress);

  useEffect(() => {
    setCalcShippingObj({
      calc_shipping_country: shippingAddress?.country || "US",
      calc_shipping_state: shippingAddress?.state || "",
      calc_shipping_postcode: shippingAddress?.postcode || "",
    });
  }, [shippingAddress]);

  const { calc_shipping_country, calc_shipping_state, calc_shipping_postcode } =
    calcShippingObj;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    let dialogBox = document.getElementById("alert-dialog-checkout");

    if (dialogBox != null) {
      dialogBox.style.display = "none";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    if (
      calc_shipping_country &&
      (calc_shipping_country == "PR" ||
        calc_shipping_country == "FR" ||
        calc_shipping_country == "GB") &&
      shippingState?.data?.length
    ) {
      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: calc_shipping_country,
        state: shippingState?.data[0]["sortname"],
        postcode: calc_shipping_postcode,
      });
    }
  }, [calc_shipping_country, shippingState?.data?.length]);

  const onChangeHandler = (e) => {
    let updatedObj = { ...calcShippingObj };
    if (e.target.name === "calc_shipping_country") {
      let index = shippingCountry?.data?.findIndex((item) => {
        return item.sortname === e.target.value;
      });

      let countryId = shippingCountry?.data[index]?.id
        ? shippingCountry?.data[index]?.id
        : 231;
      if (countryId) {
        dispatch(triggerShippingState({ country_id: countryId }));
      }
    }
    updatedObj[e.target.name] = e.target.value;
    if (e.target.name == "calc_shipping_country") {
      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: updatedObj?.calc_shipping_country,
        state: "",
        postcode: "",
      });
    } else {
      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: updatedObj?.calc_shipping_country,
        state: updatedObj?.calc_shipping_state,
        postcode: updatedObj?.calc_shipping_postcode,
      });
    }
    setCalcShippingObj(updatedObj);
  };

  const calculateTax = () => {
    let tax = homePageData?.data?.settings?.taxRate;
    let taxRate;
    if (tax > 0 && shippingAddress?.state === "NJ") {
      let rate =
        (Number(total) + Number(shippingMethod?.shippingCharges)) *
        (Number(tax) / 100.0);
      taxRate = rate;
    } else {
      taxRate = 0.0;
    }
    dispatch({
      type: TRIGGER_TAX_AMOUNT,
      tax: Number(taxRate),
    });
  };

  const onSubmit = (e) => {
    setIsSubmit(true);
    if (
      calc_shipping_country !== "" &&
      calc_shipping_postcode !== "" &&
      calc_shipping_state !== ""
    ) {
      e.preventDefault();
      calculateTax();
      calculateShippingFunction(
        cart,
        calcShippingObj,
        setCalcShippingObj,
        setNoShippingAvailable
      );
      setTimeout(handleClose, 2000);
    }
  };

  function shippingMethods() {
    return (
      <>
        <div>
          <>
            {" "}
            <button
              className="btn btn-primary  btn-sm"
              aria-describedby={id}
              onClick={handleClick}
              type="button"
            >
              Calculate Shipping
            </button>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className="shippingPopover"
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div className="m-3 calculate-shipping-popper">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="calc_shipping_country"
                        onChange={(e) => onChangeHandler(e)}
                        value={calc_shipping_country}
                      >
                        <option value="none" name="none">
                          Select country
                        </option>

                        {shippingCountry?.data?.length &&
                          countryOptions(shippingCountry?.data)}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        id="calc_shipping_state"
                        className="form-control"
                        disabled={
                          calc_shipping_country == "PR" ||
                          calc_shipping_country == "FR" ||
                          calc_shipping_country == "GB"
                        }
                        name="calc_shipping_state"
                        onChange={(e) => onChangeHandler(e)}
                        value={calc_shipping_state}
                      >
                        <option value="empty" name="empty">
                          {/* {calc_shipping_country == "PR"
                            ? "Select Municipality"
                            : calc_shipping_country == "FR"
                            ? "Select Region"
                            : "Select Province"} */}
                          Select Province
                        </option>

                        {shippingState?.data?.length !== 0 &&
                          shippingState?.data?.map((e, i) => {
                            return (
                              <option
                                value={e.sortname}
                                key={i}
                                name={e.name}
                                id={e}
                              >
                                {e.name}
                              </option>
                            );
                          })}
                      </select>
                      {calc_shipping_state === "" && isSubmit && (
                        <div className="invalid-feedback">
                          Please select state
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="calc_shipping_postcode"
                        className="form-control"
                        disabled=""
                        placeholder="Postcode / ZIP"
                        name="calc_shipping_postcode"
                        onChange={(e) => onChangeHandler(e)}
                        value={calc_shipping_postcode}
                      />
                      {calc_shipping_postcode === "" && isSubmit && (
                        <div className="invalid-feedback">
                          Please enter postcode.
                        </div>
                      )}
                    </div>
                    <div className="form-group  m-auto text-center">
                      <button
                        type="button"
                        className="btn btn-primary  btn-sm"
                        disabled={shippingObj?.loading}
                        onClick={(e) => {
                          onSubmit(e);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="mr-1">CALCULATE SHIPPING</div>

                          {shippingObj?.loading && <CircularLoader size={15} />}
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </ClickAwayListener>
            </Popper>
          </>
        </div>
        {shippingMethodList?.length > 0
          ? shippingMethodList.map((item, i) => {
              return (
                <label className="shipping-methods__item-header">
                  <span className="input-radio payment-methods__item-radio">
                    <span className="input-radio__body">
                      <input
                        type="radio"
                        value={item.value}
                        checked={shippingOption === item.value}
                        onChange={(e) => selectShippingMethod(e)}
                      />
                      <span className="check"></span>
                    </span>
                  </span>
                  <span className="payment-methods__item-title">
                    {item.name}
                  </span>
                </label>
              );
            })
          : showMsg && (
              <div className="invalid-feedback">
                {shippingObj?.data?.message}
              </div>
            )}
      </>
    );
  }

  return [shippingMethods()];
}
