import React from "react";
import { Link } from "react-router-dom";
import BlockSpace from "../components/blocks/BlockSpace";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from "../redux/actions/FAQAction";
import OverlayLoader from "../components/shared/OverlayLoader";
import useSetMetaTags from "../hooks/useSetMetaTags";
export default function Faq() {
  const dispatch = useDispatch();
  const [setMetaTags] = useSetMetaTags();
  const { faqObj } = useSelector((state) => state.FAQReducer);
  useEffect(() => {
    dispatch(getFAQ());
    setMetaTags("FAQ", "FAQ", "FAQ");
  }, []);

  return (
    <div className="block faq">
      <div className="container container--max--xl">
        <div className="faq__header">
          <h1 className="faq__header-title">Frequently Asked Questions</h1>
        </div>

        {faqObj?.loading && <OverlayLoader />}

        {faqObj?.data?.faq?.map((e, i) => {
          return (
            <div className="faq__section" key={i}>
              <h3 className="faq__section-title">{e?.title}</h3>
              <div className="faq__section-body">
                <div className="faq__question mt-0">
                  <div className="faq__question-answer">
                    <div
                      className="typography"
                      dangerouslySetInnerHTML={{
                        __html: e?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="faq__footer">
          <div className="faq__footer-title">Still Have A Questions?</div>
          <div className="faq__footer-subtitle">
            We will be happy to answer any questions you may have.
          </div>
          <Link to={"contact-us"} className="btn btn-primary">
            Contact Us
          </Link>
        </div>
      </div>

      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
