import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
 TRIGGER_GET_ARTICLES_LIST,
 TRIGGER_GET_ARTICLES_LIST_SUCCESS,
 TRIGGER_GET_ARTICLES_LIST_FAILED,
 TRIGGER_GET_ARTICLES_DETAILS,
 TRIGGER_GET_ARTICLES_DETAILS_SUCCESS,
 TRIGGER_GET_ARTICLES_DETAILS_FAILED,
TRIGGER_ADD_COMMENTS,
TRIGGER_ADD_COMMENTS_SUCCESS,
TRIGGER_ADD_COMMENTS_FAILED,
TRIGGER_GET_ARTICLES_COMMENTS,
TRIGGER_GET_ARTICLES_COMMENTS_FAILED,
TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS,

} from "./actionTypes";



export function getArticles(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetArticles}`,
        dispatch,
        defaultAction:  TRIGGER_GET_ARTICLES_LIST,
        successAction:  TRIGGER_GET_ARTICLES_LIST_SUCCESS,
        failedAction:  TRIGGER_GET_ARTICLES_LIST_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }



  export function getArticleDetails(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetArticleDetails}/${obj.id}`,
        dispatch,
        defaultAction:  TRIGGER_GET_ARTICLES_DETAILS,
        successAction:  TRIGGER_GET_ARTICLES_DETAILS_SUCCESS,
        failedAction:  TRIGGER_GET_ARTICLES_DETAILS_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }


  export function getArticlesComments(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetArticleComments}/${obj.blog_id}`,
        dispatch,
        defaultAction:  TRIGGER_GET_ARTICLES_COMMENTS,
        successAction:  TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS,
        failedAction:  TRIGGER_GET_ARTICLES_COMMENTS_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }



  export function triggerPostComment(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlPostArticleComment}`,
        dispatch,
        defaultAction: TRIGGER_ADD_COMMENTS,
        successAction: TRIGGER_ADD_COMMENTS_SUCCESS,
        failedAction: TRIGGER_ADD_COMMENTS_FAILED,
        type: "POST",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }