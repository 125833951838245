import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { triggerProfileUpdate } from "../../redux/actions/profileUpdateAction";
import { triggerCountry } from "../../redux/actions/countryAndStateAction";
import { triggerState } from "../../redux/actions/countryAndStateAction";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";
import CircularLoader from "../../components/shared/CircularLoader";
import { Link } from "react-router-dom";

export default function Profile() {
  const [userData] = useUserDetailsHook();
  const profileIntialObj = {
    email: userData?.email,
    first_name: userData?.name,
    last_name: userData?.last_name,
    password: "",
    password_confirmation: "",
    address: userData?.address,
    apartment: userData?.apartment,
    city: userData?.city,
    state: userData?.state,
    country: userData?.country,
    postcode: userData?.postcode,
    phone: userData?.phone || "",
  };

  const dispatch = useDispatch();
  const { profileObj, loginObj } = useSelector((state) => state.authReducer);
  const { countriesObj } = useSelector((state) => state.countryAndStateReducer);
  const { statesObj } = useSelector((state) => state.countryAndStateReducer);
  const [isSubmit, setIsSubmit] = useState(false);
  const [profileObject, setProfileObject] = useState(profileIntialObj);
  const {
    email,
    first_name,
    last_name,
    password_confirmation,
    password,
    address,
    apartment,
    city,
    state,
    country,
    postcode,
    phone,
  } = profileObject;

  useEffect(() => {
    dispatch(triggerCountry());
  }, []);

  useEffect(() => {
    let newProfileObj = {
      email: userData?.email,
      first_name: userData?.name,
      last_name: userData?.last_name,
      password: "",
      password_confirmation: "",
      address: userData?.address,
      apartment: userData?.apartment,
      city: userData?.city,
      state: userData?.state,
      country: userData?.country,
      postcode: userData?.postcode,
      phone: userData?.phone || "",
    };
    setProfileObject(newProfileObj);
  }, [userData]);

  useEffect(() => {
    if (profileObj?.error) {
      toast.error(profileObj?.error, {
        toastId: "toastMsg",
        autoClose: 2000,
      });
    }
  }, [profileObj]);

  const customId = "toastMsg";

  const onChangeHandler = (e) => {
    let updateProfileObj = { ...profileObject };
    if (e.target.name === "country") {
      let index = countriesObj.data.findIndex((item) => {
        return item.name === e.target.value;
      });

      let countryId = countriesObj.data[index].id;
      if (countryId) {
        dispatch(triggerState({ country_id: countryId }));
      }
    }
    updateProfileObj[e.target.name] = e.target.value;
    setProfileObject(updateProfileObj);
  };

  const notify = () =>
    toast.success("Profile Updated successfully!", {
      toastId: customId,
      autoClose: 2000,
      hideProgressBar: true,
    });
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (
      country !== "" &&
      first_name !== "" &&
      last_name !== "" &&
      postcode !== "" &&
      (password != "" ? password === password_confirmation : true)
    ) {
      let newObj = { ...profileObject };
      if (password === "") {
        delete newObj.password;
        delete newObj.password_confirmation;
      }
      return dispatch(
        triggerProfileUpdate(newObj, (resp) => {
          // history.push("/");
          if (resp) {
            setProfileObject(profileIntialObj);
            setIsSubmit(false);
            notify();
          }
        })
      );
    }
  };

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">MY ACCOUNT</h5>
      </div>
      <div className="card-divider"></div>
      <div className="card-body card-body--padding--2">
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="form-group col-12">
              <label htmlFor="profile-first-name">Country</label>
              <select
                value={country}
                className="form-control"
                name="country"
                onChange={(e) => onChangeHandler(e)}
              >
                <option value="none" name="none">
                  Select country
                </option>

                {countriesObj?.data?.map((e, i) => {
                  return (
                    <option value={e.name} key={i} name={e.name} id={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
              {isSubmit && country === "none" && (
                <div className="invalid-feedback">* Please select country</div>
              )}
            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-first-name">First Name</label>
              <input
                type="text"
                id="profile-first-name"
                className="form-control"
                placeholder="Mark"
                name="first_name"
                value={first_name}
                onChange={(e) => onChangeHandler(e)}
              />
              {isSubmit && first_name === "" && (
                <div className="invalid-feedback">Please enter First Name</div>
              )}
            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-last-name">Last Name</label>
              <input
                type="text"
                id="profile-last-name"
                className="form-control"
                placeholder="Twain"
                name="last_name"
                value={last_name}
                onChange={(e) => onChangeHandler(e)}
              />
              {isSubmit && last_name === "" && (
                <div className="invalid-feedback">Please enter Last Name</div>
              )}
            </div>
            <div className="form-group col-12">
              <label htmlFor="profile-address">Address</label>
              <div className="form-group">
                <input
                  type="text"
                  id="stree-address"
                  className="form-control"
                  placeholder="Street Address"
                  name="address"
                  value={address}
                  onChange={(e) => onChangeHandler(e)}
                />
                {isSubmit && address === "" && (
                  <div className="invalid-feedback">Please enter address</div>
                )}
              </div>
              <input
                type="text"
                id="address-optional"
                className="form-control"
                placeholder="Apartment, suite,unit etc. (optional)"
                name="apartment"
                value={apartment}
                onChange={(e) => onChangeHandler(e)}
              />
              <div className="invalid-feedback"></div>
            </div>
            <div className="form-group col-12">
              <label htmlFor="profile-city">Town / City</label>
              <input
                type="text"
                id="profile-city"
                className="form-control"
                placeholder="Town / City"
                name="city"
                value={city}
                onChange={(e) => onChangeHandler(e)}
              />

            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-state">State</label>
              <select
                className="form-control"
                value={state}
                name="state"
                onChange={(e) => onChangeHandler(e)}
              >
                <option value="empty" name="empty">
                  Select State
                </option>
                {statesObj?.data?.map((e, i) => {
                  return (
                    <option value={e.name} key={i} name={e.name} id={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>

            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-postcode">Postcode / postcode</label>
              <input
                type="number"
                id="profile-postcode"
                className="form-control"
                placeholder="Postcode / postcode"
                name="postcode"
                value={postcode}
                onChange={(e) => onChangeHandler(e)}
              />

            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-email">Email Address</label>
              <input
                type="email"
                id="profile-email"
                className="form-control"
                placeholder="Email Address"
                name="email"
                value={email}
                disabled
                onChange={(e) => onChangeHandler(e)}
              />
              {isSubmit && email === "" && (
                <div className="invalid-feedback">Please enter email</div>
              )}
              {isSubmit && email && !email.match(mailformat) && (
                <div className="invalid-feedback">Invalid email</div>
              )}
            </div>
            <div className="form-group col-xxl-6  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label htmlFor="profile-phone">Phone</label>
              <input
                type="number"
                id="profile-phone"
                className="form-control"
                placeholder="Phone"
                name="phone"
                value={phone}
                onChange={(e) => onChangeHandler(e)}
              />

            </div>
            <div className="form-group col-12">
              <label htmlFor="profile-password">Change Password</label>
              <input
                type="password"
                id="profile-password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChangeHandler(e)}
              />
       
              {isSubmit && password && password.length < 6 && (
                <div className="invalid-feedback">
                  Password must be atleast 6 characters
                </div>
              )}
            </div>
            <div className="form-group col-12">
              <label htmlFor="profile-repeat-password">Repeat Password</label>
              <input
                type="password"
                id="profile-repeat-password"
                className="form-control"
                placeholder="Password"
                name="password_confirmation"
                value={password_confirmation}
                onChange={(e) => onChangeHandler(e)}
              />
              {isSubmit && password && password_confirmation === "" && (
                <div className="invalid-feedback">
                  Please enter repeat password
                </div>
              )}
              {isSubmit &&
                password_confirmation &&
                password !== password_confirmation && (
                  <div className="invalid-feedback">
                    Password and repeat password must be same.
                  </div>
                )}
              <p className="mb-0 mt-2">
                Hint: The password should be at least six characters long.
              </p>
              <p className="mb-0">
                To make it stronger, use upper and lower case letters, numbers,
                and symbols like ! " ? $ % ^ &amp; ).
              </p>
            </div>
            <div className="form-group mb-0 col-12">
              <button
                type="submit"
                className="btn btn-primary mt-3 mr-2"
                disabled={profileObj?.loading}
              >
                <div className="d-flex align-items-center">
                  <div className="mr-1"> Save</div>
                  {isSubmit && profileObj?.loading && (
                    <CircularLoader size={15} />
                  )}
                </div>
              </button>
              <Link type="button" className="btn btn-secondary mt-3" to="/">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
