import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  PRIVACY_AND_TERMS,
  PRIVACY_AND_TERMS_SUCCESS,
  PRIVACY_AND_TERMS_FAILED,
} from "./actionTypes";

export function getPrivacyAndTerms(obj, callback) {
  // console.log(obj);
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.privacyAndTerms}`,
      dispatch,
      defaultAction: PRIVACY_AND_TERMS,
      successAction: PRIVACY_AND_TERMS_SUCCESS,
      failedAction: PRIVACY_AND_TERMS_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
