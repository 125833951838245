import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/scss/pages/ProductQuickView.scss";
import ImageSlider from "../shared/ImageSlider";
import config from "../../utils/config";
import { Wishlist16Svg, HeartOutlineSvg } from "../../svg/index";
import CircularLoader from "../shared/CircularLoader";
import useCounter from "../../hooks/useCounter";
import useCartHook from "../../hooks/useCartHook";
import AlertMessage from "../shared/AlertMessage";
import { isLogin } from "../../utils";
import useRemoveFromWishlistHook from "../../hooks/useRemoveFromWishlist";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ProductQuickView({ close, isOpen, addToWishList }) {
  const { quickViewDetails, wishList, addProductToWishList } = useSelector(
    (state) => state.shopReducer
  );
  const [count, incrementCount, decrementCount, setCount] = useCounter();
  const [alertMsg, setAlertMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [markWishList, setMarkWishList] = useState(false);
  const [removeFromWishList] = useRemoveFromWishlistHook();

  const [addToCartProduct] = useCartHook();
  const showAlertMsg = () => {
    setAlertMsg(true);
  };

  const hideAlertMsg = () => {
    setAlertMsg(false);
  };

  const [images, setImages] = useState([]);

  const addProductToWishlist = (product) => {
    if (isLogin()) {
      addToWishList(product);
    } else {
      setAlertMsg(true);
    }
  };

  useEffect(() => {
    let imageArray = [];
    if (quickViewDetails?.data?.product_images?.length > 0) {
      quickViewDetails?.data?.product_images.map((item, i) => {
        imageArray.push({
          original: `${config.imageBasePath}${item?.image}`,
          thumbnail: `${config.imageBasePath}${item?.image}`,
        });
      });

      setImages(imageArray);
    } else {
      setImages([
        {
          original: `${config.imageBasePath}${quickViewDetails?.data?.product_detail?.featured_image}`,
          thumbnail: `${config.imageBasePath}${quickViewDetails?.data?.product_detail?.featured_image}`,
        },
      ]);
    }
  }, [quickViewDetails]);

  useEffect(() => {
    if (isLogin() && wishList?.data?.length) {
      let list = wishList?.data;
      list.forEach((item) => {
        if (
          item?.product_detail?.id ===
          quickViewDetails?.data?.product_detail?.id
        ) {
          setMarkWishList(true);
        }
      });
    }
  }, [wishList, quickViewDetails]);

  const handleClose = () => {
    close();
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  });

  const addToCart = (product) => {
    let productDetails = { ...product };
    setLoading(true);
    addToCartProduct(productDetails, count);
    setTimeout(() => {
      handleClose();
      setLoading(false);
      setCount(1);
    }, 800);
  };
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={false}
      >
        <DialogTitle />

        <DialogContent>
          <div className="product-quick-view">
            {quickViewDetails?.loading ? (
              <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
            ) : (
              <>
                <form className="quickview__body">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      {" "}
                      <div className="product-gallery product-gallery--layout--quickview quickview__gallery">
                        {images.length && <ImageSlider images={images} />}
                        <div id="productGalleyMagnify"></div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="quickview__product">
                        <div className="quickview__product-name">
                          {quickViewDetails?.data?.product_detail?.name}
                        </div>

                        <div className="quickview__product-description">
                          {
                            quickViewDetails?.data?.product_detail
                              ?.page_description
                          }
                        </div>
                        <div className="d-flex align-items-center mt-1">
                          <div className="quickview__sku-label">SKU : </div>
                          <div className="quickview__sku-value">
                            {quickViewDetails?.data?.product_detail?.sku}
                          </div>
                        </div>
                        <div className="quickview__product-prices-stock">
                          <div className="quickview__product-prices">
                            <div className="quickview__product-price quickview__product-price--current">
                              $
                              {
                                quickViewDetails?.data?.product_detail
                                  ?.regular_price
                              }
                            </div>
                          </div>
                          <div className="status-badge status-badge--style--success status-badge--has-text quickview__product-stock">
                            <div className="status-badge__body">
                              <div className="status-badge__text">In Stock</div>
                            </div>
                          </div>
                        </div>
                        <div className="quickview__product-actions">
                          <div className="quickview__product-actions-item quickview__product-actions-item--quantity">
                            <div className="input-number">
                              <input
                                className="form-control input-number__input"
                                type="number"
                                min="1"
                                name="quantity"
                                value={count}
                                readOnly
                              />
                              <div
                                className="input-number__add"
                                onClick={incrementCount}
                              ></div>
                              <div
                                className="input-number__sub"
                                onClick={decrementCount}
                              ></div>
                            </div>
                          </div>

                          <div className="quickview__product-actions-item quickview__product-actions-item--addtocart">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={() => {
                                addToCart(
                                  quickViewDetails?.data?.product_detail
                                );
                              }}
                              disabled={loading}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                {loading && <CircularLoader size={20} />}
                                <div className="ml-2">Add to cart</div>
                              </div>
                            </button>
                          </div>
                          <div className="quickview__product-actions-item quickview__product-actions-item--wishlist">
                            <button
                              type="button"
                              className="btn btn-muted btn-icon product-card__wishlist-icon"
                              onClick={() =>
                                !markWishList
                                  ? addProductToWishlist(
                                      quickViewDetails?.data?.product_detail
                                    )
                                  : removeFromWishList(
                                      quickViewDetails?.data?.product_detail,
                                      setMarkWishList
                                    )
                              }
                            >
                              {addProductToWishList?.loading ? (
                                <CircularLoader size={12} color="#6c757d" />
                              ) : (
                                <ReactSVG
                                  src={
                                    markWishList
                                      ? Wishlist16Svg
                                      : HeartOutlineSvg
                                  }
                                />
                              )}
                            </button>
                          </div>
                          {showAlertMsg && (
                            <AlertMessage
                              isOpen={alertMsg}
                              close={hideAlertMsg}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <NavLink
                  to={`/product/${quickViewDetails?.data?.product_detail?.slug}`}
                  className="quickview__see-details"
                >
                  See full details
                </NavLink>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
