
export const NUMBER_OF_PRODUCTS = [
    12,24,36,48,60,100,"View All"
];


export const panels = [
    {
      title: "categoryPanel1",
      status: true,
    },
    {
      title: "categoryPanel2",
      status: true,
    },
    {
      title: "categoryPanel3",
      status: true,
    },

    {
      title: "categoryPanel4",
      status: true,
    },

    {
      title: "categoryPanel5",
      status: true,
    },
  ];


    export const countryOptions = (countryList) => {
      let list = [...countryList];
      let newList = list.filter((item) => {
        return (
          item.sortname === "US" ||
          item.sortname === "GB" ||
          item.sortname === "JP" ||
          item.sortname === "AU" ||
          item.sortname === "FR" ||
          item.sortname === "CA" ||
          item.sortname === "PR"
        );
      });

      newList.sort((a, b) => b.name.localeCompare(a.name));
      let newlist = [];
      newList.forEach((item) => {
        if (item.sortname === "US") {
          newlist[0] = item;
        }

        if (item.sortname === "CA") {
          newlist[1] = item;
        }
        if (item.sortname === "PR") {
          newlist[2] = item;
        }

        if (item.sortname === "GB") {
          newlist[3] = item;
        }

        if (item.sortname === "AU") {
          newlist[4] = item;
        }

        if (item.sortname === "JP") {
          newlist[5] = item;
        }
        if (item.sortname === "FR") {
          newlist[6] = item;
        }
      });

      let newList1 = list.filter((item) => {
        return (
          item.sortname != "US" &&
          item.sortname != "GB" &&
          item.sortname != "JP" &&
          item.sortname != "AU" &&
          item.sortname != "FR" &&
          item.sortname != "CA" &&
          item.sortname != "PR"
        );
      });

      return (
        <>
          {newlist.map((e, i) => {
            return (
              <option value={e.sortname} key={e.id} name={e.name} id={e.id}>
                {e.name}
              </option>
            );
          })}

          <option disabled="disabled" value="---">
            ---
          </option>
          {newList1.map((e, i) => {
            return (
              <option value={e.sortname} key={e.id} name={e.name} id={e.id}>
                {e.name}
              </option>
            );
          })}
        </>
      );
    };
