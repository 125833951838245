import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AnimationOnScroll } from "react-animation-on-scroll";
import BlockSpace from "../components/blocks/BlockSpace";
import BlockReviews from "../components/blocks/BlockReviews/BlockReviews";
import BlockFeatures from "../components/blocks/BlockFeatures";
import { triggerAboutPageDetails } from "../redux/actions";
import OverlayLoader from "../components/shared/OverlayLoader";
import useSetMetaTags from "../hooks/useSetMetaTags";
import van from "../assets/images/van1.jpg";
import config from "../utils/config";

export default function AboutUs() {
  const dispatch = useDispatch();
  const [setMetaTags] = useSetMetaTags();

  const { homePageData, aboutPageContent } = useSelector(
    (state) => state.homePageReducer
  );

  useEffect(() => {
    let mounted = true;
    setMetaTags("About", "About", "About", "About", "About Us");
    dispatch(triggerAboutPageDetails());
    return () => (mounted = false);
  }, []);

  return (
    <div className="about">
      {aboutPageContent?.loading && <OverlayLoader />}

      {!aboutPageContent?.loading && aboutPageContent?.data?.page && (
        <>
          <div className="about__body">
            <div className="about__image">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                <div
                  className="about__image-bg"
                  style={{
                    backgroundImage: `url(${van})`,
                  }}
                />
              </AnimationOnScroll>
            </div>

            {aboutPageContent?.data?.page && (
              <>
                <div className="about__card">
                  <AnimationOnScroll
                    animateIn="animate__fadeInLeft"
                    animateOnce={true}
                  >
                    <h1 className="about__card-title mb-0">
                      {aboutPageContent?.data?.page?.title}
                    </h1>
                    <div
                      className="about__card-text"
                      dangerouslySetInnerHTML={{
                        __html: aboutPageContent?.data?.page?.description,
                      }}
                    ></div>
                    <div className="about__card-author">Ken Wilford</div>
                    <div className="about__card-signature">
                      <img
                        src={
                          config.imageBasePath +
                          homePageData?.data?.settings?.logo
                        }
                        alt={homePageData?.data?.settings?.site_title}
                        width="160"
                        height="55"
                        className="image_about"
                      />
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="about__indicators">
                  <AnimationOnScroll
                    animateIn="animate__fadeIn"
                    animateOnce={true}
                  >
                    <div className="about__indicators-body">
                      <div className="about__indicators-item">
                        <div className="about__indicators-item-value">
                          {aboutPageContent?.data?.totalProducts || 0}
                        </div>
                        <div className="about__indicators-item-title">
                          Original auto parts
                        </div>
                      </div>
                      <div className="about__indicators-item">
                        <div className="about__indicators-item-value">
                          {aboutPageContent?.data?.satisfiedCustomer || 0}
                        </div>
                        <div className="about__indicators-item-title">
                          Satisfied customers
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </>
            )}
          </div>

          <BlockSpace className="block-space--layout--before-footer" />

          <BlockReviews reviewList={homePageData?.data?.testimonials} />

          <BlockFeatures />
        </>
      )}
    </div>
  );
}
