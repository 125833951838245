import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/shared/Breadcrumb";
import PaginationPage from "../../components/shared/Pagination";
import ProductCategoriesSidebar from "../../components/shop/ProductCategoriesSidebar";
import "../../assets/scss/pages/Accessories.scss";
import CategoryCard from "./CategoryCard";
import { getCategoryList } from "../../redux/actions/blogCategoriesAction";
import { titleCase } from "../../utils";
import { useNa, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import CircularLoader from "../../components/shared/CircularLoader";
import useSetMetaTags from "../../hooks/useSetMetaTags";

export default function CategoryList(props) {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { pageNo } = useParams();
  const [page, setPage] = useState(1);
  const startIndex = 0;
  const endIndex = 12;
  const { categoryList } = useSelector((state) => state.blogsReducer);
  const [articleList, setArticleList] = useState([]);
  const [setMetaTags] = useSetMetaTags();

  useEffect(() => {
    setMetaTags("Articles", "Articles", "Articles");
  }, []);
  useEffect(() => {
    setPage(pageNo ? pageNo : page);
    dispatch(
      getCategoryList({
        slug: props.match.params.slug,
      })
    );
  }, [props.match.params.slug]);

  useEffect(() => {
    if (categoryList?.data?.length > 0) {
      alterArticleList();
    }
  }, [page, categoryList?.data]);

  const alterArticleList = () => {
    let list = categoryList?.data;
    const indexOfLastTodo = page * 12;
    const indexOfFirstTodo = indexOfLastTodo - 12;
    const updatedList = list.slice(indexOfFirstTodo, indexOfLastTodo);
    setArticleList(updatedList);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    navigate.push(`/category/${props.match.params.slug}/page/${newPage}`);
  };

  return (
    <div className="product-categories-page accessories-list">
      <div className="container ">
        <Breadcrumb
          list={[
            {
              title: "VW Accessories",
              url: "/vw-accessories",
            },
          ]}
        />
        <h1 className="block-header__title  animate__animated animate__fadeIn">
          ARCHIVE FOR CATEGORY: {titleCase(props.match.params.slug)}
        </h1>
        {categoryList?.loading && (
          <div className="product-loader-div ">
            <div className="center-div">
              <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
            </div>
          </div>
        )}

        {!categoryList?.loading && (
          <div className="block-split__row d-flex no-gutters">
            <div className="block-split--has-sidebar block-split__item-sidebar">
              <ProductCategoriesSidebar />
            </div>

            <div className="block-split__item block-split__item-content col-auto flex-grow-1">
              {articleList.map((article, i) => {
                return <CategoryCard key={i} article={article} />;
              })}
              {categoryList?.data?.length &&
                categoryList?.data?.length > 12 && (
                  <div className="card product-caytegory-pagination">
                    <PaginationPage
                      startIndex={startIndex + 1}
                      lastIndex={endIndex}
                      showText={true}
                      onChangePage={onChangePage}
                      count={Math.ceil(categoryList?.data?.length / 12)}
                      page={page}
                      totalCount={categoryList?.data?.length}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
