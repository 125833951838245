import React from "react";
import { useSelector } from "react-redux";

export default function useUserDetailsHook() {
  const { loginObj } = useSelector((state) => state.authReducer);

  function userData() {
    return loginObj?.data?.user;
  }
  return [userData()];
}
