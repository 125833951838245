import React from "react";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function TopBar() {
  const { headerContent } = useSelector((state) => state.homePageReducer);

  return (
    <div className="topbar_item">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <div className="container topbar_item d-flex justify-content-between">
          <div>
            <span className="topbar_item_label">
              {headerContent?.data?.head_title}
            </span>
          </div>

          {headerContent?.data?.email && (
            <div>
              <span className="topbar_item_label">Email Us:</span>
              <a
                className="topbar_item_value"
                href="mailTo: kenwilfy@comcast.net"
              >
                {headerContent?.data?.email}
              </a>
            </div>
          )}
        </div>
      </AnimationOnScroll>
    </div>
  );
}
