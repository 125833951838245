import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { triggerAddToWishList, triggerGetWishList, triggerRemoveWishList } from "../redux/actions";

export default function useRemoveFromWishlistHook() {
  const dispatch = useDispatch();

  const removeFromWishList = (product, setMarkWishList) => {
    let Obj = { product_id: String(product?.id) };
    dispatch(
      triggerRemoveWishList(Obj, (resp) => {
        if (resp.error) {
          toast.error("Something went wrong!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else {
          setMarkWishList(false);
          toast.success("Successfully Deleted From Wishlist!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
    );
  };

  return [removeFromWishList];
}
