import React from "react";
import Pagination from "@material-ui/lab/Pagination";

export default function PaginationPage({
  showText,
  onChangePage,
  count,
  totalCount,
  startIndex,
  lastIndex,
  page,
}) {
  const pageNew = parseInt(page);

  return (
    <div className="pagination-page">
      <div
        className={
          "products-view__pagination " +
          (showText ? "jusitify-content-between" : "justify-content-center")
        }
      >
        <Pagination
          count={count}
          page={pageNew}
          variant="outlined"
          shape="rounded"
          onChange={onChangePage}
        />
        {showText && (
          <div className="products-view__pagination-legend">
            Showing {startIndex}–{lastIndex} of {totalCount} products
          </div>
        )}
      </div>
    </div>
  );
}
