import React from "react";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import "../../assets/scss/pages/Accessories.scss";
import moment from "moment";

export default function CategoryCard({ article }) {
  return (
    <div className="accessories-card ">
      <div className="posts-list__item">
        <div className="post-card post-card--layout--list">
          <div className="post-card__image">
            <Link to={`/article/${article?.slug}`}>
              {article?.featured_image ? (
                <img
                  alt={article?.title}
                  src={`${config.imageBasePath}${article?.featured_image}`}
                />
              ) : (
                <img
                  alt="no-image"
                  src={`https://www.vanagain.com/admin/public/assets/uploads/no_img.gif`}
                />
              )}
            </Link>
          </div>
          <div className="post-card__content">
            <div className="post-card__title">
              <h4>
                <Link to={`/article/${article.slug}`}>{article?.title}</Link>
              </h4>
            </div>

            <div className="post-card__excerpt">
              <div className="typography">{article?.page_description}</div>
            </div>

            <div className="post-card__date">
              By <Link href="/">Ken Wilford</Link> on{" "}
              {moment(article?.created_at).format("LL")}
            </div>
            <div className="post-card__more w-100">
              <Link
                className="btn btn-primary btn-sm"
                to={`/article/${article.slug}`}
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
