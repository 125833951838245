import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import { GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_FAILED } from "./actionTypes";

export function getFAQ(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlFAQ}`,
      dispatch,
      defaultAction: GET_FAQ,
      successAction: GET_FAQ_SUCCESS,
      failedAction: GET_FAQ_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
