import React from 'react'


export default function OverlayLoader() {
    return (
        <div className="loading-overlay">
        <span className="fas fa-spinner fa-3x fa-spin center-div"></span>
      </div>
    )
}
