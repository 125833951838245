import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
  TRIGGER_GET_PRODUCT_DETAILS,
  TRIGGER_GET_PRODUCT_DETAILS_FAILED,
  TRIGGER_GET_PRODUCT_DETAILS_SUCCESS,
  TRIGGER_ADD_TO_WISH_LIST,
  TRIGGER_ADD_TO_WISH_LIST_SUCCESS,
  TRIGGER_ADD_TO_WISH_LIST_FAILED,
  TRIGGER_WISH_LIST,
  TRIGGER_WISH_LIST_SUCCESS,
  TRIGGER_WISH_LIST_FAILED,
  TRIGGER_REMOVE_FROM_WISH_LIST,
  TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS,
  TRIGGER_REMOVE_FROM_WISH_LIST_FAILED,
  TRIGGER_GET_POINTS,
  TRIGGER_GET_POINTS_SUCCESS,
  TRIGGER_GET_POINTS_FAILED,
  TRIGGER_ORDERS,
  TRIGGER_ORDERS_SUCCESS,
  TRIGGER_ORDERS_FAILED,
  TRIGGER_ORDERS_STATUS,
  TRIGGER_ORDERS_STATUS_SUCCESS,
  TRIGGER_ORDERS_STATUS_FAILED,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILED,
  TRIGGER_GET_ORDER_DETAILS,
  TRIGGER_GET_ORDER_DETAILS_FAILED,
  TRIGGER_GET_ORDER_DETAILS_SUCCESS,
  TRIGGER_QUICK_VIEW_DETAILS,
  TRIGGER_QUICK_VIEW_DETAILS_SUCCESS,
  TRIGGER_QUICK_VIEW_DETAILS_FAILED,
} from "./actionTypes";

export function getProductsByCategory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetProductsByCategory}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
      successAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductsByTypeAndCategory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetProductsByTypeAndCategory}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
      successAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductsBySku(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetProductsBySku}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
      successAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getAllProducts(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetAllProducts}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
      successAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductsBySearch(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlHeaderSearch}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
      successAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetProductDetails}/${obj.product_id}`,
      dispatch,
      defaultAction: TRIGGER_GET_PRODUCT_DETAILS,
      successAction: TRIGGER_GET_PRODUCT_DETAILS_SUCCESS,
      failedAction: TRIGGER_GET_PRODUCT_DETAILS_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductQuickViewDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetProductDetails}/${obj.product_id}`,
      dispatch,
      defaultAction: TRIGGER_QUICK_VIEW_DETAILS,
      successAction: TRIGGER_QUICK_VIEW_DETAILS_SUCCESS,
      failedAction: TRIGGER_QUICK_VIEW_DETAILS_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerAddToWishList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlAddToWishlist}`,
      dispatch,
      defaultAction: TRIGGER_ADD_TO_WISH_LIST,
      successAction: TRIGGER_ADD_TO_WISH_LIST_SUCCESS,
      failedAction: TRIGGER_ADD_TO_WISH_LIST_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerGetWishList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlWishlists}`,
      dispatch,
      defaultAction: TRIGGER_WISH_LIST,
      successAction: TRIGGER_WISH_LIST_SUCCESS,
      failedAction: TRIGGER_WISH_LIST_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerRemoveWishList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlRemoveFromWishlist}`,
      dispatch,
      defaultAction: TRIGGER_REMOVE_FROM_WISH_LIST,
      successAction: TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS,
      failedAction: TRIGGER_REMOVE_FROM_WISH_LIST_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getPoints(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetPoints}`,
      dispatch,
      defaultAction: TRIGGER_GET_POINTS,
      successAction: TRIGGER_GET_POINTS_SUCCESS,
      failedAction: TRIGGER_GET_POINTS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getOrders(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlOrders}`,
      dispatch,
      defaultAction: TRIGGER_ORDERS,
      successAction: TRIGGER_ORDERS_SUCCESS,
      failedAction: TRIGGER_ORDERS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getOrderStatus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetOrderStatus}`,
      dispatch,
      defaultAction: TRIGGER_ORDERS_STATUS,
      successAction: TRIGGER_ORDERS_STATUS_SUCCESS,
      failedAction: TRIGGER_ORDERS_STATUS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getOrderList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlOrdersList}`,
      dispatch,
      defaultAction: GET_ORDERS_LIST,
      successAction: GET_ORDERS_LIST_SUCCESS,
      failedAction: GET_ORDERS_LIST_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getOrderDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlOrdersDetails}/${obj.id}`,
      dispatch,
      defaultAction: TRIGGER_GET_ORDER_DETAILS,
      successAction: TRIGGER_GET_ORDER_DETAILS_SUCCESS,
      failedAction: TRIGGER_GET_ORDER_DETAILS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
