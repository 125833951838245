import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
 TRIGGER_COUNTRY,
 TRIGGER_COUNTRY_SUCCESS,
 TRIGGER_COUNTRY_FAILED,
 TRIGGER_STATES,
 TRIGGER_STATES_SUCCESS,
 TRIGGER_STATES_FAILED,
} from "./actionTypes";



export function triggerCountry(obj, callback) {
    // console.log(obj);
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlCountries}`,
        dispatch,
        defaultAction:  TRIGGER_COUNTRY,
        successAction:  TRIGGER_COUNTRY_SUCCESS,
        failedAction:  TRIGGER_COUNTRY_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }

  export function triggerState(obj, callback) {
    // console.log(obj);
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlStates}`,
        dispatch,
        defaultAction:  TRIGGER_STATES,
        successAction:  TRIGGER_STATES_SUCCESS,
        failedAction:  TRIGGER_STATES_FAILED,
        type: "POST",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }


