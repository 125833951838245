import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/scss/pages/ProductQuickView.scss";
import { DialogActions } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertMessage({ close, isOpen, addToWishList }) {
  useEffect(() => {
    setTimeout(function () {
      close();
    }, 4000);
  }, [isOpen]);

  const handleClose = () => {
    close();
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted

        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle />
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
            <div className="p-3 alert-dialog-text">
              Please login to use Wishlist feature
            </div>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" onClick={handleClose}>
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
