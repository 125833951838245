import React, { useEffect } from "react";
import BlockSpace from "../../components/blocks/BlockSpace";
import useSetMetaTags from "../../hooks/useSetMetaTags";
import Login from "./Login";
import Register from "./Register";

export default function OnboardingPage() {
  const [setMetaTags]=useSetMetaTags();

  useEffect(()=>{
    setMetaTags('Onaboard','Onaboard','Onaboard')
  })
  return (
    <div className="container">
    <BlockSpace  className="block-space--layout--before-footer"/>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <Login />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <Register />
        </div>
      </div>
    <BlockSpace  className="block-space--layout--before-footer"/>

    </div>
  );
}
