import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function Breadcrumb({ list }) {
  const history = useHistory();

  const goToPage = () => {
    if (history?.location?.state?.vanTypeSlug) {
      let pathname = `/categories/${history?.location?.state?.vanTypeSlug}`;
      history.push({
        pathname,
        state: {
          title: history?.location?.state?.title,
          vanTypeSlug: history?.location?.state?.vanTypeSlug,
        },
      });
    }
  };
  return (
    <div className="container">
      <div className="breadcrumb" aria-label="breadcrumb">
        <ol className="breadcrumb__list">
          <li className="breadcrumb__item breadcrumb__item--first breadcrumb__item--parent">
            <Link className="breadcrumb__item-link" to="/">
              Home
            </Link>
          </li>

          {list?.length > 0 &&
            list?.map((item, i) => {
              return (
                <li
                  key={i}
                  className={`breadcrumb__item breadcrumb__item--last breadcrumb__item--current ${
                    list?.length - 1 === i ? "active" : ""
                  }`}
                  aria-current="page"
                >
                  <div
                    className="breadcrumb__item-link"
                    onClick={() => {
                      goToPage();
                    }}
                  >
                    <div className="breadcrumb_name">{item?.title}</div>
                  </div>
                </li>
              );
            })}
        </ol>
      </div>
    </div>
  );
}
