import { combineReducers } from "redux";
import articlesReducer from "./articlesReducer";
import authReducer from "./authReducer";
import blogsReducer from "./blogsReducer";
import mainMenuReducer from "./mainMenuReducer";
import shopReducer from "./shopReducer";
import contactUsReducer from "./contactUsReducer";
import countryAndStateReducer from "./countryAndStateReducer";
import homePageReducer from "./homePageReducer";
import cartReducer from "./cartReducer";
import vwAccessoriesReducer from "./vwAccessoriesReducer";
import FAQReducer from "./FAQReducer";
import { withReduxStateSync } from "../../lib/syncState";
import privacyAndTermsReducer from "./privacyAndTermsReducer"

const rootReducer = combineReducers({
  authReducer,
  shopReducer,
  mainMenuReducer,
  blogsReducer,
  articlesReducer,
  contactUsReducer,
  countryAndStateReducer,
  homePageReducer,
  cartReducer,
  vwAccessoriesReducer,
  FAQReducer,
  privacyAndTermsReducer,
});

export default withReduxStateSync(rootReducer);
