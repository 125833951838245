import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  TRIGGER_HOME_PAGE_DATA,
  TRIGGER_HOME_PAGE_DATA_SUCCESS,
  TRIGGER_HOME_PAGE_DATA_FAILED,
  TRIGGER_NEWSLETTER,
  TRIGGER_NEWSLETTER_SUCCESS,
  TRIGGER_NEWSLETTER_FAILED,
  TRIGGER_GET_HEADER_CONTENT,
  TRIGGER_GET_HEADER_CONTENT_SUCCESS,
  TRIGGER_GET_HEADER_CONTENT_FAILED,
  GET_HOME_PAGE_DATA,
  GET_HOME_PAGE_DATA_SUCCESS,
  GET_HOME_PAGE_DATA_FAILED,
  GET_ABOUT_US_PAGE_DATA,
  GET_ABOUT_US_PAGE_DATA_FAILED,
  GET_ABOUT_US_PAGE_DATA_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY,
  GET_PRODUCTS_BY_CATEGORY_FAILED,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS
} from "./actionTypes";

export function triggerHomePageData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlHomePage}`,
      dispatch,
      defaultAction: TRIGGER_HOME_PAGE_DATA,
      successAction: TRIGGER_HOME_PAGE_DATA_SUCCESS,
      failedAction: TRIGGER_HOME_PAGE_DATA_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}


export function triggerSubscribeNewsletter(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlSubscribeNewsletter}`,
      dispatch,
      defaultAction: TRIGGER_NEWSLETTER,
      successAction: TRIGGER_NEWSLETTER_SUCCESS,
      failedAction: TRIGGER_NEWSLETTER_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerHeaderContent(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlHeaderContent}`,
      dispatch,
      defaultAction: TRIGGER_GET_HEADER_CONTENT,
      successAction: TRIGGER_GET_HEADER_CONTENT_SUCCESS,
      failedAction: TRIGGER_GET_HEADER_CONTENT_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}


export function triggerHomePageDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlHomePageData}`,
      dispatch,
      defaultAction: GET_HOME_PAGE_DATA,
      successAction: GET_HOME_PAGE_DATA_SUCCESS,
      failedAction: GET_HOME_PAGE_DATA_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerAboutPageDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlAboutPageData}`,
      dispatch,
      defaultAction: GET_ABOUT_US_PAGE_DATA,
      successAction: GET_ABOUT_US_PAGE_DATA_SUCCESS,
      failedAction: GET_ABOUT_US_PAGE_DATA_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getProductsByCategory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlHeaderSearch}`,
      dispatch,
      defaultAction: GET_PRODUCTS_BY_CATEGORY,
      successAction: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
      failedAction: GET_PRODUCTS_BY_CATEGORY_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}










