import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import SectionHeader from "../shared/SectionHeader";
import classNames from "classnames";
import ProductCard from "../shared/ProductCard";
import { useDispatch } from "react-redux";
import { getProductsByCategory } from "../../redux/actions/homePageAction";
import CircularLoader from "../shared/CircularLoader";

export default function BlockProductsCarousel({
  title,
  productList,
  category,
}) {
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(productList);
  const history = useHistory();
  const [currentGroup, setCurrentGroup] = useState({});
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },

      {
        breakpoint: 875,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  useEffect(() => {
    let mounted = true;
    setCurrentGroup({});
    setList(productList);
    return () => (mounted = false);
  }, [productList]);

  useEffect(() => {
    if (list?.length < 4) {
      let itemToPush = 4 - list?.length;
      for (let i = 0; i < itemToPush; i++) {
        list.push({});
      }
    }
    setLoading(false);
  }, [list]);

  const onChangeGroup = (group) => {
    setCurrentGroup(group);
    setList(group.products);
    dispatch(
      getProductsByCategory({
        search_key: group.name,
      })
    );
  };

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const carousel = (
    <Slider {...settings} ref={sliderRef}>
      {list?.length >= 4 &&
        list?.map((item, i) => {
          return <ProductCard product={item} key={i} layout={"product-grid"} />;
        })}
    </Slider>
  );
  return (
    <div className="position-relative block_product_caraousel">
      {loading && (
        <div className="center-div">
          <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
        </div>
      )}
      {
        list?.length > 0 &&
      <SectionHeader
        sectionTitle={title}
        list={category}
        next={next}
        previous={previous}
        onChangeGroup={onChangeGroup}
        currentGroup={currentGroup}
      />
      }
      <div
        className={classNames("block-products-carousel__carousel", {
          "block-products-carousel__carousel--loading": loading,
          "block-products-carousel__carousel--has-items": 3 > 0,
        })}
      >
        {carousel}
      </div>
    </div>
  );
}
