import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  TRIGGER_SEARCH_KEYWORD,
  TRIGGER_SKU_KEYWORD,
  TRIGGER_VAN_CATEGORY,
  TRIGGER_VAN_TYPE,
} from "../../redux/actions/actionTypes";
import searchIcon from "../../assets/images/search.svg";

export default function Search({ handleClose }) {
  const [searchKey, setSearchKey] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const search = (e) => {
    e.preventDefault();
    dispatch({
      type: TRIGGER_SKU_KEYWORD,
      skuKeyword: "",
    });
    dispatch({
      type: TRIGGER_SEARCH_KEYWORD,
      searchKey: searchKey,
    });

    dispatch({
      type: TRIGGER_VAN_TYPE,
      vanTypeName: "",
    });

    dispatch({
      type: TRIGGER_VAN_CATEGORY,
      vanCategory: "",
    });

    history.push({
      pathname: "/shop/search",
      state: { searchSlug: searchKey },
    });
    // if (handleClose) {
    //   handleClose();
    // }
  };

  return (
    <div className="search-input">
      <form className="form-inline" onSubmit={search}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search here..."
            aria-label="Search here..."
            aria-describedby="button-addon2"
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
          />
          <div className="input-group-append">
            <button
              className="btn search-button"
              type="submit"
              id="button-addon2"
            >
              <img src={searchIcon} alt="search" height="25px" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
