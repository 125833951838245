import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/scss/pages/header/menuStyle.scss";
import { NavLink } from "react-router-dom";
import config from "../../utils/config";
import {
  getVanCategories,
  getVanTypes,
} from "../../redux/actions/mainMenuAction";
import { useDispatch } from "react-redux";
import { ArrowRoundedRight7x11Svg } from "../../svg/index";
import { ReactSVG } from "react-svg";

export default function MainMenu({ menus }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const items = [
    {
      title: "About Us",
      url: "/about-us",
    },
    {
      title: "Articles",
      url: "/articles",
    },
    {
      title: "Order Status",
      url: "/order-status",
    },
    {
      title: "Contact Us",
      url: "/contact-us",
    },
  ];
  const [showMenu, setShowMenu] = useState(false);
  const [showShopByCategory, setShowShopByCategory] = useState(true);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let mounted = true;
    dispatch(getVanTypes());
    dispatch(getVanCategories());
    return () => (mounted = false);
  }, []);

  const showSubMenu = (catagory) => {
    setShowMenu(true);
    setSelectedSubMenu(catagory);
  };

  const hideSubMenu = () => {
    setSelectedSubMenu(null);
  };

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setShowShopByCategory(true);
    } else {
      setShowShopByCategory(false);
    }
  };

  return (
    <div className="grid_nav">
      <div className="grid_navBar container">
        <div className="header__navbar ">
          {showShopByCategory && (
            <>
              <div className="header__navbar-departments ">
                <div className="departments">
                  <button
                    className="departments__button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse_departments__menu"
                    onMouseEnter={() => {
                      setShowMenu(true);
                    }}
                    onMouseLeave={() => setShowMenu(false)}
                    onClick={() => {
                      showMenu ? setShowMenu(false) : setShowMenu(true);
                    }}
                  >
                    <span className="departments__button-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                      >
                        <path d="M0 7V5h16v2H0zm0-7h16v2H0V0zm12 12H0v-2h12v2z"></path>
                      </svg>
                    </span>

                    <span className="departments__button-title">
                      Shop by Category
                    </span>
                    <span className="departments__button-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="6"
                      >
                        <path d="M.2.4c.4-.4 1-.5 1.4-.1l2.9 3 2.9-3c.4-.4 1.1-.4 1.4.1.3.4.3.9-.1 1.3L4.5 6 .3 1.6C-.1 1.3-.1.7.2.4z"></path>
                      </svg>
                    </span>

                    {showMenu && location?.pathname !== "/" && (
                      <div
                        className="departments__menu  collapse-horizontal"
                        id="collapse_departments__menu"
                      >
                        <div className="departments__body">
                          <ul className="departments__list">
                            {menus?.map((catagory) => {
                              return (
                                <li
                                  key={catagory?.id}
                                  className="departments__item departments__item--has-submenu departments__item--submenu--megamenu"
                                  onMouseEnter={() => showSubMenu(catagory)}
                                  onMouseLeave={() => hideSubMenu()}
                                  onClick={() => {
                                    setShowMenu(false);
                                  }}
                                >
                                  <NavLink
                                    className="departments__item-link"
                                    to={{
                                      pathname: `/categories/${catagory?.slug}`,
                                      state: {
                                        title: catagory?.title,
                                        vanTypeSlug: catagory?.slug,
                                      },
                                    }}
                                  >
                                    <div className="d-flex align-items-center ">
                                      <div className="van-image">
                                        <img
                                          src={catagory?.thumbnail ? `${config.imageBasePath}${catagory?.thumbnail}`: "https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                                          alt={catagory?.title}
                                        />
                                      </div>
                                      <div className="ml-3">
                                        {" "}
                                        {catagory?.title}
                                      </div>
                                    </div>
                                    <span className="departments__item-arrow">
                                      <ReactSVG
                                        src={ArrowRoundedRight7x11Svg}
                                      />
                                    </span>
                                  </NavLink>
                                  {
                                    <div className="departments__menu-container ">
                                      {selectedSubMenu?.id === catagory.id && (
                                        <div className="megamenu departments__megamenu departments__megamenu--size--lg departments__megamenu">
                                          <div className="row">
                                            {catagory?.categories?.map(
                                              (subMenuItem, i) => {
                                                return (
                                                  <div
                                                    className="col-xl-4 col-lg-4 col-md-6 col-4"
                                                    key={subMenuItem?.id}
                                                  >
                                                    <div className="megamenu-links__item">
                                                      <NavLink
                                                        className="megamenu-links__item-link"
                                                        to={{
                                                          pathname: `/categories/${selectedSubMenu?.slug}/${subMenuItem?.slug}`,
                                                          state: {
                                                            title:
                                                              catagory?.title,
                                                            vanTypeSlug:
                                                              catagory?.slug,
                                                            categorySlug:
                                                              subMenuItem?.slug,
                                                          },
                                                        }}
                                                      >
                                                        <div className="d-flex align-items-center">
                                                          <img
                                                            alt=""
                                                            className="mx-2"
                                                            src={subMenuItem?.image ? `${config.imageBasePath}${subMenuItem?.image}` :"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                                                            height="25px"
                                                          />
                                                          <span className="sub-menu__link">
                                                            {subMenuItem?.name}
                                                          </span>
                                                        </div>
                                                      </NavLink>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  }
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <div className="header__vertical_bar"></div>
            </>
          )}
          <div className=" header__navbar-menu">
            <div className="main-menu">
              <ul className="main-menu__list">
                {items.map((menu) => {
                  return (
                    <li key={menu.title} className="main-menu__item">
                      <NavLink
                        to={menu.url}
                        className="main-menu__link"
                        activeClassName="main-menu__link_active"
                      >
                        {menu.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
