import React, { createRef, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { triggerAuthRegister } from "../../redux/actions/authAction";
import ReCAPTCHA from "react-google-recaptcha";
import OverlayLoader from "../../components/shared/OverlayLoader";
import config from "../../utils/config";
import useAuthHook from "../../hooks/useAuthHooks";

export default function Register(props) {
  const registerIntialObj = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [captcha, validateCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = createRef();
  const gCaptcha = {
    human: false,
    humanKey: "",
  };
  const { loginObj } = useSelector((state) => state.authReducer);
  const [isSubmit, setIsSubmit] = useState(false);
  const [registerObject, setRegisterObject] = useState(registerIntialObj);
  const [login] = useAuthHook();

  useEffect(() => {
    if (loginObj?.error?.error?.message) {
      toast.error(loginObj?.error?.error?.message, {
        toastId: "toastMsg",
        autoClose: 2000,
      });
      setLoading(false);
    }
  }, [loginObj]);

  const verifyCaptcha = function (value) {
    if (value) {
      gCaptcha.human = true;
      gCaptcha.humanKey = value;
      validateCaptcha(true);
    }
  };

  const onChangeHandler = (e) => {
    let updateRegisterObj = { ...registerObject };
    updateRegisterObj[e.target.name] = e.target.value;
    setRegisterObject(updateRegisterObj);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    const { firstName, lastName, email, password, repeatPassword } =
      registerObject;
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      password.length >= 6 &&
      repeatPassword !== "" &&
      captcha
    ) {
      setLoading(true);
      return dispatch(
        triggerAuthRegister(
          {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_confirmation: repeatPassword,
          },
          (resp) => {
            toast.success(resp?.msg, {
              toastId: "toastMsg",
              autoClose: 2000,
            });
            login(email, password);
            history.push("/");
            setRegisterObject(registerIntialObj); //set to initial object and empty after successful submission
            setIsSubmit(false);
            setLoading(false);
          }
        )
      );
    }
  };

  const { firstName, lastName, email, password, repeatPassword } =
    registerObject;

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return (
    <div className="card flex-grow-1 mb-0 ml-0 ml-lg-3 mr-0 mr-lg-4 login-page">
      {isSubmit && loading && <OverlayLoader />}
      <div className="card-body card-body--padding--2">
        <h3 className="card-title">Register</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="signup-firstname">First Name</label>
            <input
              id="signup-firstname"
              type="text"
              className="form-control"
              placeholder=""
              name="firstName"
              value={firstName}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {isSubmit && firstName === "" && (
              <div className="invalid-feedback">* Please enter firstname</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="signup-lastname">Last Name</label>
            <input
              id="signup-lastname"
              type="text"
              className="form-control"
              placeholder=""
              name="lastName"
              value={lastName}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {isSubmit && lastName === "" && (
              <div className="invalid-feedback">* Please enter lastname</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="signup-email">Email address</label>
            <input
              id="signup-email"
              type="text"
              className="form-control"
              placeholder=""
              name="email"
              value={email}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {isSubmit && email === "" && (
              <div className="invalid-feedback">* Please enter email</div>
            )}
            {isSubmit && email && !email.match(mailformat) && (
              <div className="invalid-feedback">* Invalid mail</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="signup-password">Password</label>
            <input
              id="signup-password"
              type="password"
              className="form-control"
              placeholder=""
              name="password"
              value={password}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {isSubmit && password === "" && (
              <div className="invalid-feedback">* Please enter password</div>
            )}
            {isSubmit && password && password.length < 6 && (
              <div className="invalid-feedback">
                * Password must be atleast 6 characters{" "}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="signup-confirm">Repeat password</label>
            <input
              id="signup-confirm"
              type="password"
              className="form-control"
              placeholder=" "
              name="repeatPassword"
              value={repeatPassword}
              minLength={6}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {isSubmit && repeatPassword === "" && (
              <div className="invalid-feedback">
                * Please enter repeat password
              </div>
            )}
            {isSubmit && repeatPassword && password !== repeatPassword && (
              <div className="invalid-feedback">
                * password and repeat password must be same
              </div>
            )}
          </div>
          <div className="form-group g-recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              // sitekey={config.captchaSiteKey}
              sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
              onChange={verifyCaptcha}
            />
            {isSubmit && !captcha && (
              <div className="invalid-feedback">Please verify captcha!</div>
            )}
          </div>
          <div className="form-group mb-0">
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={isSubmit && loading}
            >
              Register
            </button>
          </div>
        </form>

        <ToastContainer
          autoClose={2000}
          draggable={false}
          hideProgressBar={true}
        />
      </div>
    </div>
  );
}
