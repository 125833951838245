import {
  GET_VWACCESSORIES,
  GET_VWACCESSORIES_SUCCESS,
  GET_VWACCESSORIES_FAILED,
} from "../actions/actionTypes";
const initialState = {
  vwAccessories: {
    loading: false,
    data: [],
    error: null,
  },
};

const vwAccessoriesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_VWACCESSORIES:
      return {
        ...state,
        vwAccessories: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_VWACCESSORIES_SUCCESS:
        // console.log(action.payload);
      return {
        ...state,
        vwAccessories: {
          loading: false,
          data: action.payload,
          message: action.payload.message,
          error: null,
        },
      };
    case GET_VWACCESSORIES_FAILED:
      return {
        ...state,
        vwAccessories: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default vwAccessoriesReducer;
