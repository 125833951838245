import ProductCategoriesPage from "../../../components/shop/ProductCategoriesPage";
import ProductDetailsPage from "../../../components/shop/ProductDetailsPage";
import AboutUs from "../../../pages/AboutUs";
import AccountLayout from "../../../pages/account/AccountLayout";
import ForgotPassord from "../../../pages/account/ForgotPassword";
import OnboardingPage from "../../../pages/account/Onboard";
import ArticleDetails from "../../../pages/articles/ArticleDetails";
import ArticlesList from "../../../pages/articles/ArticlesList";
import Cart from "../../../pages/Cart";
import Checkout from "../../../pages/Checkout";
import ContactUs from "../../../pages/ContactUs";
import Faq from "../../../pages/Faq";
import HomePage from "../../../pages/home";
import OrderStatus from "../../../pages/OrderStatus";
import PrivacyAndTerms from "../../../pages/PrivacyAndTerms";
import vwAccessories from "../../../pages/vwAccessories/AccessoriesList";
import CategoryList from "../../../pages/category/CategoryList";
import TestimonialsList from "../../../pages/testimonials/TestimonialsList";
import AddTestimonial from "../../../pages/testimonials/AddTestimonial";
import OrderReceived from "../../../pages/OrderReceived";
import OrderCancel from "../../../pages/OrderCancel";
import ResetPassword from "../../../pages/account/ResetPassword";

const publicRoutes = [
  {
    path: "/",
    path_: "/",
    restricted: false,
    private: false,
    component: HomePage,
    name: "home",
    group: "app entry",
    readablename: "home",
  },

  {
    path: "/contact-us",
    path_: "/contact-us",
    restricted: false,
    private: false,
    component: ContactUs,
    name: "contact-us",
    group: "app entry",
    readablename: "contact-us",
  },

  {
    path: "/about-us",
    path_: "/about-us",
    restricted: false,
    private: false,
    component: AboutUs,
    name: "about-us",
    group: "app entry",
    readablename: "about-us",
  },
  {
    path: "/faq",
    path_: "/faq",
    restricted: false,
    private: false,
    component: Faq,
    name: "faq",
    group: "app entry",
    readablename: "faq",
  },

  {
    path: "/privacy-terms",
    path_: "/privacy-terms",
    restricted: false,
    private: false,
    component: PrivacyAndTerms,
    name: "privacy-terms",
    group: "app entry",
    readablename: "privacy-terms",
  },

  {
    path: "/order-status",
    path_: "/order-status",
    restricted: false,
    private: false,
    component: OrderStatus,
    name: "order-status",
    group: "app entry",
    readablename: "order-status",
  },

  {
    path: "/login",
    path_: "/login",
    restricted: true,
    private: false,
    component: OnboardingPage,
    name: "account login",
    group: "app entry",
    readablename: "login",
  },

  {
    path: "/articles/:page?/:pageNo?",
    path_: "/articles",
    restricted: false,
    private: false,
    component: ArticlesList,
    name: "account login",
    group: "app entry",
    readablename: "login",
  },

  {
    path: "/article/:id",
    path_: "/article",
    restricted: false,
    private: false,
    component: ArticleDetails,
    name: "account login",
    group: "app entry",
    readablename: "login",
  },
  {
    path: "/categories/:vanType/:categoryType?/:type?/:pageNo?",
    path_: "/categories/:vanType/:categoryType?/:type?/:pageNo?",
    restricted: false,
    private: false,
    component: ProductCategoriesPage,
    name: "shop",
    group: "app entry",
    readablename: "shop",
  },

  {
    path: "/testimonials/:page?/:pageNo?",
    path_: "/testimonials/:page?/:pageNo?",
    restricted: false,
    private: false,
    component: TestimonialsList,
    name: "testimonials",
    group: "app entry",
    readablename: "testimonials",
  },
  {
    path: "/submit-testimonial",
    path_: "/submit-testimonial",
    restricted: false,
    private: false,
    component: AddTestimonial,
    name: "testimonials",
    group: "app entry",
    readablename: "testimonials",
  },
  {
    path: "/shop/:type?/:page?/:pageNo?",
    path_: "/shop/:type?/:page/:pageNo?",
    restricted: false,
    private: false,
    component: ProductCategoriesPage,
    name: "account login",
    group: "app entry",
    readablename: "login",
  },
  {
    path: "/forgot-password",
    path_: "/forgot-password",
    restricted: true,
    private: false,
    component: ForgotPassord,
    name: "account forgot password",
    group: "app entry",
    readablename: "forgot",
  },

  {
    path: "/password/reset/:id",
    path_: "/password/reset/:id",
    restricted: true,
    private: false,
    component: ResetPassword,
    name: "account forgot password",
    group: "app entry",
    readablename: "forgot",
  },

  {
    path: "/payExp/order-success/:id",
    path_: "/payExp/order-success/:id",
    restricted: false,
    private: false,
    component: OrderReceived,
    name: "order received",
    group: "app entry",
    readablename: "order received",
  },
  {
    path: "/order-received/:id?",
    path_: "/order-received/:id?",
    restricted: false,
    private: false,
    component: OrderReceived,
    name: "order received",
    group: "app entry",
    readablename: "order received",
  },
  {
    path: "/order-cancel/:id",
    path_: "/order-cancel/:id",
    restricted: false,
    private: false,
    component: OrderCancel,
    name: "order received",
    group: "app entry",
    readablename: "order received",
  },

  {
    path: "/product/:id",
    path_: "/product/:id",
    restricted: false,
    private: false,
    component: ProductDetailsPage,
    name: "account login",
    group: "app entry",
    readablename: "shop",
  },

  {
    path: "/checkout",
    path_: "/checkout",
    restricted: false,
    private: false,
    component: Checkout,
    name: "Checkout",
    group: "Checkout",
    readablename: "Checkout",
  },

  {
    path: "/cart",
    path_: "/cart",
    restricted: false,
    private: false,
    component: Cart,
    name: "Cart",
    group: "Cart",
    readablename: "Cart",
  },
  {
    path: "/vw-accessories",
    path_: "/vw-accessories",
    restricted: false,
    private: false,
    component: vwAccessories,
    name: "vw-accessories",
    group: "vw-accessories",
    readablename: "vw-accessories",
  },
  {
    path: "/category/:slug/:page?/:pageNo?",
    path_: "/category/:slug/:page?/:pageNo?",
    restricted: false,
    private: false,
    component: CategoryList,
    name: "category",
    group: "category",
    readablename: "category",
  },
];

export const privateRoutes = [
  {
    path: "/my-account/:name/:id?/:pageNo?",
    path_: "/my-account/:name/:id?/:pageNo?",
    restricted: false,
    private: false,
    component: AccountLayout,
    name: "account details",
    group: "app entry",
    readablename: "details",
  },
];

export default publicRoutes;
