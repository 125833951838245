import React, { useEffect } from "react";
import Breadcrumb from "../../components/shared/Breadcrumb";
import ProductCategoriesSidebar from "../../components/shop/ProductCategoriesSidebar";
import "../../assets/scss/pages/Accessories.scss";
import { useDispatch, useSelector } from "react-redux";
import { getVWAccessories } from "../../redux/actions/vwAccessoriesAction";
import useSetMetaTags from "../../hooks/useSetMetaTags";
import OverlayLoader from "../../components/shared/OverlayLoader";

export default function VwAccessories() {
  const dispatch = useDispatch();
  const [setMetaTags] = useSetMetaTags();
  const { vwAccessories } = useSelector((state) => state.vwAccessoriesReducer);
  useEffect(() => {
    setMetaTags("VW Accessories", "VW Accessories", "VW Accessories");
    dispatch(getVWAccessories());
  }, []);

  return (
    <div className="product-categories-page accessories-list">
      <div className="container ">
        <Breadcrumb
          list={[
            {
              title: "VW Accessories",
              url: "/vw-accessories",
            },
          ]}
        />
        <h1 className="block-header__title  animate__animated animate__fadeIn">
          VW ACCESSORIES
        </h1>

        {vwAccessories?.loading && (
          <div className="product-loader-div ">
            <OverlayLoader />
          </div>
        )}
        <div className="block-split__row d-flex no-gutters">
          <div className="block-split--has-sidebar block-split__item-sidebar">
            <ProductCategoriesSidebar />
          </div>
          <div className="block-split__item block-split__item-content col-auto flex-grow-1 accessories-list-items">
            <div
              className="card px-3 py-4"
              dangerouslySetInnerHTML={{
                __html: vwAccessories?.data["vw-accessories"]?.description,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
