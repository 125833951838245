import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/pages/Accessories.scss";
import config from "../../utils/config";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function ArticleCard({ article }) {
  return (
    <div className="accessories-card ">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <div className="posts-list__item">
          <div className="post-card post-card--layout--list">
            <div className="post-card__image">
              <Link
                to={{
                  pathname: `/article/${article.slug}`,
                  state: { id: article.id },
                }}
              >
                {article?.featured_image ? (
                  <img
                    alt={article.title}
                    src={`${config.imageBasePath}${article?.featured_image}`}
                  />
                ) : (
                  <img
                    alt="no-image"
                    src={`https://www.vanagain.com/public/assets/uploads/no_img.gif`}
                  />
                )}
              </Link>
            </div>
            <div className="post-card__content">
              <div className="post-card__category"></div>
              <div className="post-card__title">
                <h5>
                  <Link
                    to={{
                      pathname: `/article/${article.slug}`,
                      state: { id: article.id },
                    }}
                  >
                    {article.title}
                  </Link>
                </h5>
              </div>

              <div className="post-card__excerpt">
                <div className="typography">{article?.page_description}</div>
              </div>

              <div className="post-card__date">
                By <Link to="">Ken Wilford</Link> on{" "}
                {moment(article?.created_at).format("LL")}
              </div>
              <div className="post-card__more w-100">
                <Link
                  className="btn btn-primary btn-sm"
                  to={{
                    pathname: `/article/${article.slug}`,
                    state: { id: article.id },
                  }}
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
