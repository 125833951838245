import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import ProductQuickView from "../shop/ProductQuickView";
import "../../assets/scss/pages/ProductCard.scss";
import {
  Cart20Svg,
  Quickview16Svg,
  Wishlist16Svg,
  HeartOutlineSvg,
} from "../../svg/index";
import config from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { getProductQuickViewDetails } from "../../redux/actions";
import noImage from "../../assets/images/no_img.gif";
import AlertMessage from "./AlertMessage";
import { isLogin } from "../../utils";
import useAddToWishListHook from "../../hooks/useAddToWishList";
import useCartHook from "../../hooks/useCartHook";
import CircularLoader from "./CircularLoader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import useRemoveFromWishlistHook from "../../hooks/useRemoveFromWishlist";

export default function ProductCard({ layout, product }) {
  const dispatch = useDispatch();
  const [quickView, setOpenQuickView] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [addToWish] = useAddToWishListHook();
  const [removeFromWishList] = useRemoveFromWishlistHook();
  const [addToCartProduct] = useCartHook();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedBtn, setSelectedBtn] = useState({});
  const [markWishList, setMarkWishList] = useState(false);
  const { loginObj } = useSelector((state) => state.authReducer);
  const { addProductToWishList, wishList } = useSelector(
    (state) => state.shopReducer
  );
  const { total } = useSelector((state) => state.cartReducer);
  const [width, setWidth] = useState();

  useEffect(() => {
    // return () => {
    handleResize();
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const {
    regular_price,
    name,
    featured_image,
    page_description,
    sku,
    id,
    slug,
  } = product;

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      setSelectedProduct({});
    }, 500);
    return () => (mounted = false);
  }, [total]);

  useEffect(() => {
    let mounted = true;
    if (isLogin() && wishList?.data?.length) {
      let list = wishList?.data;
      list.forEach((item) => {
        if (item?.product_detail?.id === id) {
          setMarkWishList(true);
        }
      });
    }
    return () => (mounted = false);
  }, [wishList]);

  const addToWishList = (product) => {
    setSelectedBtn("addToWishList");
    if (!isLogin() && !quickView) {
      setAlertMsg(true);
    } else {
      setSelectedProduct(product);
      addToWish(
        loginObj?.data?.user?.id,
        product,
        closeQuickView,
        setSelectedProduct
      );

      if (width > 720) {
        var ele = document.getElementById("wishlist-popover");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
        document.getElementById("indicator_btn_wishlist").click();
      }
    }
  };

  const scroll = (element) => {
    var ele = document.getElementById("cart-popover");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  };

  const closeQuickView = () => {
    setOpenQuickView(false);
  };

  const addProductToCart = (product, quantity) => {
    setSelectedProduct(product);
    addToCartProduct(product, quantity);
    if (width > 590) {
      scroll();
      document.getElementById("indicator_btn_cart").click();
    }
  };

  const openQuickView = (product) => {
    setOpenQuickView(true);
    return dispatch(getProductQuickViewDetails({ product_id: product.slug }));
  };

  const quickViewButton = (
    <button
      type="button"
      className="product-card__action product-card__action--quickview"
      aria-label="Quick view"
      onClick={() => {
        openQuickView(product);
      }}
    >
      <ReactSVG src={Quickview16Svg} />
    </button>
  );

  const wishlistButton = (
    <button
      type="button"
      className="product-card__action product-card__action--wishlist"
      aria-label="Add to wishlist"
      onClick={() =>
        !markWishList
          ? addToWishList(product)
          : removeFromWishList(product, setMarkWishList)
      }
    >
      {product.id === selectedProduct.id &&
      addProductToWishList.loading &&
      selectedBtn === "addToWishList" ? (
        <CircularLoader size={12} color="#6c757d" />
      ) : (
        <ReactSVG src={markWishList ? Wishlist16Svg : HeartOutlineSvg} />
      )}
    </button>
  );

  const addToCart = (
    <button
      type="button"
      className="product-card__addtocart-icon"
      aria-label="Add to cart"
      onClick={() => {
        addProductToCart(product, 1);
      }}
    >
      {product.id === selectedProduct.id && selectedBtn === "addToCart" ? (
        <CircularLoader size={20} />
      ) : (
        <ReactSVG src={Cart20Svg} />
      )}
    </button>
  );

  const showAlertMsg = () => {
    setAlertMsg(true);
  };

  const hideAlertMsg = () => {
    setAlertMsg(false);
  };

  const productImage = (name, url) => {
    return (
      <img
        alt={name}
        className="image__tag"
        src={
          url
            ? url
            : `https://www.vanagain.com/public/assets/uploads/no_img.gif`
        }
      />
    );
  };

  return (
    <div className="products-list__item">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        {showAlertMsg && (
          <AlertMessage isOpen={alertMsg} close={hideAlertMsg} />
        )}

        {product?.id && layout !== "d-block" && layout !== "d-table" && (
          <div className="product-card">
            <div className="product-card__actions-list">
              {width > 768 && quickViewButton}
              {wishlistButton}
            </div>
            <div className="product-card__image">
              <div className="image image--type--product">
                <div className="image__body">
                  {productImage(
                    name,
                    featured_image
                      ? `${config.imageBasePath}${featured_image}`
                      : `${noImage}`
                  )}
                </div>
              </div>
            </div>
            <div className="product-card__info">
              <div className="product-card__name">
                <Link to={`/product/${slug}`}>{name}</Link>
              </div>

              {layout === "product-desc" && (
                <div className="product-card__desc">
                  <h6> Product Description </h6>
                  <p className="mb-1 sku">SKU : {sku}</p>
                  <p>{page_description}</p>
                </div>
              )}
            </div>
            <div className="product-card__footer">
              <div className="product-card__prices">
                <div className="product-card__price product-card__price--current">
                  ${regular_price}
                </div>
              </div>
              {!product?.stock_qty ? (
                <div className="status-badge__text_out">Out of Stock</div>
              ) : (
                <>{addToCart}</>
              )}
            </div>
          </div>
        )}

        {layout === "d-block" && (
          <div className="product-card-list products-list__item">
            {product?.id && (
              <div className="product-card">
                <div className="product-card__actions-list">
                  {width > 768 && quickViewButton}
                </div>
                <div className="product-card__image">
                  <div className="image image--type--product">
                    <Link className="image__body" to={`/product/${slug}`}>
                      {productImage(
                        name,
                        `${config.imageBasePath}${featured_image}`
                      )}
                    </Link>
                  </div>
                </div>
                <div className="product-card__info">
                  <div className="product-card__name">
                    <Link to={`/product/${slug}`}>{name}</Link>
                  </div>
                  <div className="product-card__desc">
                    <p className="product-desc-label">Product Description</p>
                    <p className="product-desc-value">{page_description}</p>
                  </div>
                </div>
                <div className="product-card__footer">
                  <div className="product-card__prices">
                    <div className="product-card__price product-card__price--current">
                      ${regular_price}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary  mb-2"
                    aria-label="Add to cart"
                    disabled={
                      (product.id === selectedProduct.id &&
                        selectedBtn === "addToCart") ||
                      !product?.stock_qty
                    }
                    onClick={() => {
                      addProductToCart(product, 1);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="mr-1">
                        {!product?.stock_qty ? (
                          <div className="status-badge__text_out">
                            Out of Stock
                          </div>
                        ) : (
                          <>Add to cart</>
                        )}
                      </div>
                      {product.id === selectedProduct.id &&
                        selectedBtn === "addToCart" && (
                          <CircularLoader size={15} />
                        )}
                    </div>
                  </button>
                  <button
                    type="button"
                    className="product-card__wishlist d-flex"
                    onClick={() => addToWishList(product)}
                  >
                    {product.id === selectedProduct.id &&
                    addProductToWishList.loading &&
                    selectedBtn === "addToWishList" ? (
                      <CircularLoader size={12} color="#6c757d" />
                    ) : (
                      <ReactSVG src={Wishlist16Svg} />
                    )}

                    <span className="ml-2">Add to wishlist</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {layout === "d-table" && (
          <div className="products-list__item">
            <div className="product-card">
              <div className="product-card__image">
                <div className="image image--type--product">
                  <Link className="image__body" to={`/product/${slug}`}>
                    {productImage(
                      name,
                      `${config.imageBasePath}${featured_image}`
                    )}
                  </Link>
                </div>
              </div>
              <div className="product-card__meta">{sku}</div>
              <div className="product-card__info">
                <Link to={`/product/${slug}`}>{name}</Link>
              </div>
              <div className="product-card__price product-card__price--current">
                ${regular_price}
              </div>
              {wishlistButton}
              {!product?.stock_qty ? (
                <div className="status-badge__text_out">Out of Stock</div>
              ) : (
                <>{addToCart}</>
              )}
            </div>
          </div>
        )}

        {quickView && product && (
          <ProductQuickView
            isOpen={quickView}
            close={closeQuickView}
            addToWishList={addToWishList}
          />
        )}
      </AnimationOnScroll>
    </div>
  );
}
