import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockDetails from "../../components/blocks/BlockHomePage/BlockDetails";
import BlockFinder from "../../components/blocks/BlockHomePage/BlockFinder";
import BlockSlider from "../../components/blocks/BlockHomePage/BlockSlider";
import BlockProductsCarousel from "../../components/blocks/BlockProductsCarousel";
import BlockReviews from "../../components/blocks/BlockReviews/BlockReviews";
import BlockSpace from "../../components/blocks/BlockSpace";
import CircularLoader from "../../components/shared/CircularLoader";
import useSetMetaTags from "../../hooks/useSetMetaTags";
import { triggerHomePageDetails } from "../../redux/actions";
import vwparts from "../../assets/images/banners/vw-parts.png";

export default function HomePage() {
  const { homePageData, homePageContent } = useSelector(
    (state) => state.homePageReducer
  );
  const dispatch = useDispatch();

  const [setMetaTags] = useSetMetaTags();

  useEffect(() => {
    if (homePageContent?.data) {
      setMetaTags(
        homePageContent?.data?.page_title,
        homePageContent?.data?.page_description,
        homePageContent?.data?.page_keyword,
        "https://www.vanagain.com/admin/public/assets/uploads/slider/VanAgain2-32053.jpg",
        "Home page"
      );
    }
  }, [homePageContent]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(triggerHomePageDetails());
    }
    return () => (mounted = false);
  }, []);

  return (
    <div className="block_homepage">
      {homePageData?.loading ? (
        <div className="center-div">
          <CircularLoader size={60} color="#32c2ef" type="overlay-loader" />
        </div>
      ) : (
        <>
          <BlockSlider slides={homePageData?.data?.sliders} />
          <BlockDetails />
          <BlockFinder />
          <BlockSpace className="block-space--layout--before-footer" />
          <div className="container">
            <BlockProductsCarousel
              title={"Featured Products"}
              productList={homePageData?.data?.featured_products}
              category={homePageData?.data?.featured_product_categories}
            />
          </div>
          <BlockReviews reviewList={homePageData?.data?.testimonials} />
        </>
      )}
    </div>
  );
}
