import React from "react";
import RecentOrders from "./RecentOrders";
import { Link } from "react-router-dom";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";

export default function Dashboard() {
  const [userData] = useUserDetailsHook();

  return (
    <div className="dashboard dashboard-user">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="dashboard__profile card profile-card">
            <div className="card-body profile-card__body">
              <div className="profile-card__avatar">
                <div className="center-div">{userData?.name[0]}</div>
              </div>
              <div className="profile-card__name">
                {userData?.name} {userData?.last_name}
              </div>
              <div className="profile-card__email">{userData?.email}</div>
              <div className="profile-card__edit">
                <Link
                  className="btn btn-primary btn-sm"
                  to="/my-account/profile"
                >
                  Edit Profile
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 address-div">
          <div className="card address-card dashboard__address address-card--featured">
            <div className="card__loader"></div>
            <div className="address-card__badge tag-badge tag-badge--theme">
              Default
            </div>
            <div className="address-card__body">
              {userData && (
                <div className="address-card__name">
                  {userData?.name} {userData?.last_name}
                </div>
              )}
              <div className="address-card__row">
                {userData?.address && (
                  <p className="mb-1">{userData?.address},</p>
                )}

                <p className="mb-1">
                  {" "}
                  {userData?.apartment && userData?.apartment + ","}{" "}
                  {userData?.city && userData?.city + ","}
                  {userData?.postcode && userData?.postcode + ","}
                </p>

                <p className="mb-1">
                  {" "}
                  {userData?.state && userData?.state + ","} {userData?.country}
                </p>
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Phone Number:</div>
                <div className="address-card__row-content">
                  {userData?.phone || "-"}
                </div>
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Email Address:</div>
                <div className="address-card__row-content">
                  {userData?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecentOrders />
    </div>
  );
}
