import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/config/routes/privateRoutes";
import PublicRoute from "./utils/config/routes/publicRoutes";
import publicRoutes, { privateRoutes } from "./utils/config/routes/routeConfig";
import "./assets/scss/style.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ScrollToTop from "./components/shared/ScrollToTop";
import PageNotFound from "./pages/404";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
// import RoutePage from "./route";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Header />
        <ScrollToTop>
          <Switch>
            {/* <RoutePage /> */}

            {publicRoutes.map((routes, index) => {
              return (
                <PublicRoute
                  key={index}
                  restricted={routes.restricted}
                  component={routes.component}
                  path={routes.path}
                  exact
                />
              );
            })}

            {privateRoutes.map((routes, index) => {
              return (
                <PrivateRoute
                  key={index}
                  restricted={routes.restricted}
                  component={routes.component}
                  path={routes.path}
                  exact
                  subRoutes={routes.userRoutes}
                />
              );
            })}
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
