import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {
  Fi24Hours48Svg,
  FiPaymentSecurity48Svg,
  FiTag48Svg,
} from "../../svg/index";
import { ReactSVG } from "react-svg";

export default function BlockFeatures() {
  return (
    <div className="block block-features">
      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="container">
          <ul className="block-features__list">
            <li className="block-features__item">
              <div className="block-features__item-icon">
                <ReactSVG src={Fi24Hours48Svg} />
              </div>
              <div className="block-features__item-info">
                <div className="block-features__item-title">Support 24/7</div>
                <div className="block-features__item-subtitle">
                  Call us anytime
                </div>
              </div>
            </li>
            <li className="block-features__item">
              <div className="block-features__item-icon">
                <ReactSVG src={FiPaymentSecurity48Svg} />
              </div>
              <div className="block-features__item-info">
                <div className="block-features__item-title">100% Safety</div>
                <div className="block-features__item-subtitle">
                  Only secure payments
                </div>
              </div>
            </li>
            <li className="block-features__item">
              <div className="block-features__item-icon">
                <ReactSVG src={FiTag48Svg} />
              </div>
              <div className="block-features__item-info">
                <div className="block-features__item-title">Hot Offers</div>
                <div className="block-features__item-subtitle">
                  Discounts up to 90%
                </div>
              </div>
            </li>
          </ul>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
