import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../assets/scss/pages/Testimonials.scss";
import Breadcrumb from "../../components/shared/Breadcrumb";
import CircularLoader from "../../components/shared/CircularLoader";
import PaginationPage from "../../components/shared/Pagination";
import { Link } from "react-router-dom";
import TestimonialsCard from "./TestimonialsCard";
import { useHistory, useParams } from "react-router-dom";
import useSetMetaTags from "../../hooks/useSetMetaTags";

export default function TestimonialsList() {
  const navigate = useHistory();
  const { pageNo } = useParams();
  const { homePageData } = useSelector((state) => state.homePageReducer);
  const [page, setPage] = useState(1);
  const startIndex = 0;
  const endIndex = 12;
  const [testimonialList, setTestimonialList] = useState([]);
  const [setMetaTags] = useSetMetaTags();

  useEffect(() => {
    if (homePageData?.data?.testimonials?.length > 0) {
      setPage(pageNo ? pageNo : page);
      alterArticleList();
    }
  }, [page, homePageData?.data?.testimonials]);

  useEffect(() => {
    setMetaTags("Testimonials", "Testimonials", "Testimonials");
  }, []);

  const alterArticleList = () => {
    let list = homePageData?.data?.testimonials;
    const indexOfLastTodo = page * 12;
    const indexOfFirstTodo = indexOfLastTodo - 12;
    const updatedList = list.slice(indexOfFirstTodo, indexOfLastTodo);
    setTestimonialList(updatedList);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    navigate.push(`/testimonials/page/${newPage}`);
  };

  return (
    <>
      <div className="container">
        <Breadcrumb
          list={[
            {
              title: "Testimonials",
              url: "/testimonials",
            },
          ]}
        />
        <h1 className="block-header__title  animate__animated animate__fadeIn">
          Testimonials
        </h1>
        {homePageData.loading && (
          <div className="product-loader-div">
            <div className="center-div">
              <CircularLoader
                size={60}
                color={"#32c2ef"}
                type="overlay-loader"
              />
            </div>
          </div>
        )}

        {!homePageData.loading && (
          <div className="block-split__item block-split__item-content row">
            {testimonialList?.map((testimonial, i) => {
              return <TestimonialsCard key={i} testimonial={testimonial} />;
            })}
            <div className="card product-caytegory-pagination">
              <PaginationPage
                startIndex={startIndex + 1}
                lastIndex={endIndex}
                showText={true}
                onChangePage={onChangePage}
                count={Math.ceil(homePageData?.data?.testimonials?.length / 12)}
                page={page}
                totalCount={homePageData?.data?.testimonials?.length}
              />
            </div>
          </div>
        )}
      </div>
      <Link
        className="submit-testimonial-btn btn btn-primary"
        to="/submit-testimonial"
      >
        Submit Testimonial
      </Link>
    </>
  );
}
