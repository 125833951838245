import {
  TRIGGER_HOME_PAGE_DATA,
  TRIGGER_HOME_PAGE_DATA_SUCCESS,
  TRIGGER_HOME_PAGE_DATA_FAILED,
  TRIGGER_TESTIMONIAL_DETAILS_DATA,
  TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS,
  TRIGGER_TESTIMONIAL_DETAILS_DATA_FAILED,
  TRIGGER_ADD_TESTIMONIAL_FAILED,
  TRIGGER_ADD_TESTIMONIAL,
  TRIGGER_ADD_TESTIMONIAL_SUCCESS,
  TRIGGER_NEWSLETTER,
  TRIGGER_NEWSLETTER_SUCCESS,
  TRIGGER_NEWSLETTER_FAILED,
  TRIGGER_GET_HEADER_CONTENT,
  TRIGGER_GET_HEADER_CONTENT_SUCCESS,
  TRIGGER_GET_HEADER_CONTENT_FAILED,
  GET_HOME_PAGE_DATA,
  GET_HOME_PAGE_DATA_SUCCESS,
  GET_HOME_PAGE_DATA_FAILED,
  GET_ABOUT_US_PAGE_DATA,
  GET_ABOUT_US_PAGE_DATA_FAILED,
  GET_ABOUT_US_PAGE_DATA_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY,
  GET_PRODUCTS_BY_CATEGORY_FAILED,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  homePageData: {
    loading: false,
    data: [],
    error: null,
  },

  testimonialDetails: {
    loading: false,
    data: [],
    error: null,
  },

  addTestimonial: {
    loading: false,
    data: [],
    error: null,
  },
  subscribeNewsletter: {
    loading: false,
    data: [],
    error: null,
  },

  headerContent:{
    loading: false,
    data: [],
    error: null,
  },

  homePageContent:{
    loading: false,
    data: [],
    error: null,
  },
  aboutPageContent:{
    loading: false,
    data: [],
    error: null,
  },
  productsByCategory:{
    loading: false,
    data: [],
    error: null,
  }
};

const homePageReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_HOME_PAGE_DATA:
      return {
        ...state,
        homePageData: {
          loading: true,
          data: {},
          error: null,
        },
      };

    case TRIGGER_HOME_PAGE_DATA_SUCCESS:
      return {
        ...state,
        homePageData: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_HOME_PAGE_DATA_FAILED:
      return {
        ...state,
        homePageData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_TESTIMONIAL_DETAILS_DATA:
      return {
        ...state,
        testimonialDetails: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_TESTIMONIAL_DETAILS_DATA_FAILED:
      return {
        ...state,
        testimonialDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        testimonialDetails: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case TRIGGER_ADD_TESTIMONIAL:
      return {
        ...state,
        addTestimonial: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ADD_TESTIMONIAL_FAILED:
      return {
        ...state,
        addTestimonial: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_ADD_TESTIMONIAL_SUCCESS:
           return {
        ...state,
        addTestimonial: {
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case TRIGGER_NEWSLETTER:
      return {
        ...state,
        subscribeNewsletter: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_NEWSLETTER_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        subscribeNewsletter: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_NEWSLETTER_FAILED:
           return {
        ...state,
        subscribeNewsletter: {
          loading: false,
          data: action.error,
          error: null,
        },
      };

      case TRIGGER_GET_HEADER_CONTENT:
        return {
          ...state,
          headerContent: {
            loading: true,
            data: [],
            error: null,
          },
        };

      case TRIGGER_GET_HEADER_CONTENT_SUCCESS:
        return {
          ...state,
          headerContent: {
            loading: false,
            data: action.payload.pageContent,
            error: action.error,
          },
        };

      case TRIGGER_GET_HEADER_CONTENT_FAILED:
               return {
          ...state,
          headerContent: {
            loading: false,
            data: action.error,
            error: null,
          },
        };


      case GET_HOME_PAGE_DATA:
        return {
          ...state,
          homePageContent: {
            loading: true,
            data: [],
            error: null,
          },
        };

      case GET_HOME_PAGE_DATA_SUCCESS:
        return {
          ...state,
          homePageContent: {
            loading: false,
            data: action.payload.page,
            error: action.error,
          },
        };

      case GET_HOME_PAGE_DATA_FAILED:
               return {
          ...state,
          homePageContent: {
            loading: false,
            data: action.error,
            error: null,
          },
        };


        case GET_ABOUT_US_PAGE_DATA:
          return {
            ...state,
            aboutPageContent: {
              loading: true,
              data: [],
              error: null,
            },
          };

        case GET_ABOUT_US_PAGE_DATA_SUCCESS:
          return {
            ...state,
            aboutPageContent: {
              loading: false,
              data: action.payload,
              error: action.error,
            },
          };

        case GET_ABOUT_US_PAGE_DATA_FAILED:
                   return {
            ...state,
            aboutPageContent: {
              loading: false,
              data: action.error,
              error: null,
            },
          };




        case GET_PRODUCTS_BY_CATEGORY:
          return {
            ...state,
            productsByCategory: {
              loading: true,
              data: [],
              error: null,
            },
          };

        case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
          return {
            ...state,
            productsByCategory: {
              loading: false,
              data: action.payload.page,
              error: action.error,
            },
          };

        case GET_PRODUCTS_BY_CATEGORY_FAILED:
                   return {
            ...state,
            productsByCategory: {
              loading: false,
              data: action.error,
              error: null,
            },
          };


    default:
      return state;
  }
};

export default homePageReducer;
