import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  TRIGGER_CALCULATE_SHIPPING,
  TRIGGER_CALCULATE_SHIPPING_SUCCESS,
  TRIGGER_CALCULATE_SHIPPING_FAILED,
  TRIGGER_GET_COUPON_DETAILS,
  TRIGGER_GET_COUPON_DETAILS_SUCCESS,
  TRIGGER_GET_COUPON_DETAILS_FAILED,
  TRIGGER_CHECKOUT,
  TRIGGER_CHECKOUT_FAILED,
  TRIGGER_CHECKOUT_SUCCESS,
  TRIGGER_BILLING_COUNTRY,
  TRIGGER_BILLING_COUNTRY_FAILED,
  TRIGGER_BILLING_COUNTRY_SUCCESS,
  TRIGGER_SHIPPING_STATES,
  TRIGGER_SHIPPING_STATES_FAILED,
  TRIGGER_SHIPPING_STATES_SUCCESS,
  TRIGGER_SHIPPING_COUNTRY,
  TRIGGER_SHIPPING_COUNTRY_FAILED,
  TRIGGER_SHIPPING_COUNTRY_SUCCESS,
  TRIGGER_BILLING_STATES,
  TRIGGER_BILLING_STATES_FAILED,
  TRIGGER_BILLING_STATES_SUCCESS,
  TRIGGER_REDEEM_POINTS,
  TRIGGER_REDEEM_POINTS_FAILED,
  TRIGGER_REDEEM_POINTS_SUCCESS,
  TRIGGER_CANCEL_ORDER,
  TRIGGER_CANCEL_ORDER_SUCCESS,
  TRIGGER_CANCEL_ORDER_FAILED,
  TRIGGER_PAYPAL_PAYMENT_FORM,
  TRIGGER_PAYPAL_PAYMENT_FORM_FAILED,
  TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS,
  TRIGGER_EXPRESS_CHECKOUT,
  TRIGGER_EXPRESS_CHECKOUT_SUCCESS,
  TRIGGER_EXPRESS_CHECKOUT_FAILED,
} from "./actionTypes";

export function triggerCalculateShipping(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlCalculateShipping}`,
      dispatch,
      defaultAction: TRIGGER_CALCULATE_SHIPPING,
      successAction: TRIGGER_CALCULATE_SHIPPING_SUCCESS,
      failedAction: TRIGGER_CALCULATE_SHIPPING_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerSavePaypalDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlSavePaypalOrder}`,
      dispatch,
      defaultAction: TRIGGER_EXPRESS_CHECKOUT,
      successAction: TRIGGER_EXPRESS_CHECKOUT_SUCCESS,
      failedAction: TRIGGER_EXPRESS_CHECKOUT_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    }
    makeAPICall(params);
  }
}

export function triggerGetCouponDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlCouponDetails}`,
      dispatch,
      defaultAction: TRIGGER_GET_COUPON_DETAILS,
      successAction: TRIGGER_GET_COUPON_DETAILS_SUCCESS,
      failedAction: TRIGGER_GET_COUPON_DETAILS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerCheckout(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlCheckout}`,
      dispatch,
      defaultAction: TRIGGER_CHECKOUT,
      successAction: TRIGGER_CHECKOUT_SUCCESS,
      failedAction: TRIGGER_CHECKOUT_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerBillingCountry(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlCountries}`,
      dispatch,
      defaultAction: TRIGGER_BILLING_COUNTRY,
      successAction: TRIGGER_BILLING_COUNTRY_SUCCESS,
      failedAction: TRIGGER_BILLING_COUNTRY_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerBillingState(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlStates}`,
      dispatch,
      defaultAction: TRIGGER_BILLING_STATES,
      successAction: TRIGGER_BILLING_STATES_SUCCESS,
      failedAction: TRIGGER_BILLING_STATES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerShippingCountry(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlCountries}`,
      dispatch,
      defaultAction: TRIGGER_SHIPPING_COUNTRY,
      successAction: TRIGGER_SHIPPING_COUNTRY_SUCCESS,
      failedAction: TRIGGER_SHIPPING_COUNTRY_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerShippingState(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlStates}`,
      dispatch,
      defaultAction: TRIGGER_SHIPPING_STATES,
      successAction: TRIGGER_SHIPPING_STATES_SUCCESS,
      failedAction: TRIGGER_SHIPPING_STATES_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerReedemPoints(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlReedemPoints}`,
      dispatch,
      defaultAction: TRIGGER_REDEEM_POINTS,
      successAction: TRIGGER_REDEEM_POINTS_SUCCESS,
      failedAction: TRIGGER_REDEEM_POINTS_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerCancelOrder(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlOrderCancel}`,
      dispatch,
      defaultAction: TRIGGER_CANCEL_ORDER,
      successAction: TRIGGER_CANCEL_ORDER_SUCCESS,
      failedAction: TRIGGER_CANCEL_ORDER_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerPaypalForm(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlPayPalPayment}/${obj.orderId}`,
      dispatch,
      defaultAction: TRIGGER_PAYPAL_PAYMENT_FORM,
      successAction: TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS,
      failedAction: TRIGGER_PAYPAL_PAYMENT_FORM_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerExpressCheckout(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlExpressCheckout}`,
      dispatch,
      defaultAction: TRIGGER_EXPRESS_CHECKOUT,
      successAction: TRIGGER_EXPRESS_CHECKOUT_SUCCESS,
      failedAction: TRIGGER_EXPRESS_CHECKOUT_FAILED,
      type: "POST",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
