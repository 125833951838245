import React from "react";
import { useDispatch } from "react-redux";
import {
  getProductsByCategory,
  getProductsByTypeAndCategory,
  getProductsBySku,
  getAllProducts,
  getProductsBySearch,
} from "../redux/actions";
import { useLocation } from "react-router-dom";

export default function useGetProductList() {
  const dispatch = useDispatch();
  const location = useLocation();
  function getProductsList(
  ) {
    if (location?.state?.vanTypeSlug && !location?.state?.categorySlug) {
      dispatch(
        getProductsByCategory({
          slug: location?.state?.vanTypeSlug,
        })
      );
    } else if (
      location?.state?.skuKeyword &&
      location.pathname.includes("/shop/search")
    ) {
      dispatch(
        getProductsBySku({
          van_sku: location?.state?.skuKeyword,
        })
      );
    } else if (location?.state?.vanTypeSlug && location?.state?.categorySlug) {
      dispatch(
        getProductsByTypeAndCategory({
          type_slug: location?.state?.vanTypeSlug,
          category_slug: location?.state?.categorySlug,
        })
      );
    } else if (location?.state?.searchSlug) {
      dispatch(
        getProductsBySearch({
          search_key: location?.state?.searchSlug,
        })
      );
    } else {
      dispatch(getAllProducts());
    }
  }

  return [getProductsList];
}
