import React from "react";

export default function useUpdateCartProduct() {
  function updateCartProductObj(product) {
    const {
      id,
      name,
      slug,
      quantity,
      regular_price,
      featured_image,
      stock_qty,
    } = product;
    return {
      id: id,
      product_id: id,
      name: name,
      slug: slug,
      quantity: quantity,
      available_quantity: stock_qty,
      price: regular_price,
      order_price: "$"+regular_price,
      img_src: featured_image,
    };
  }
  return [updateCartProductObj];
}
