import React, { createRef, useEffect, useRef, useState } from "react";
import "../assets/scss/pages/Checkout.scss";
import Breadcrumb from "../components/shared/Breadcrumb";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import BlockSpace from "../components/blocks/BlockSpace";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUserDetailsHook from "../hooks/useUserDetailsHook";
import InputMask from "react-input-mask";
import config from "../utils/config";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GET_PAYPAL_APPROVED_DETAILS,
  TRIGGER_BILLING_ADDRESS,
  TRIGGER_CHECKOUT,
  TRIGGER_CHECKOUT_OBJ,
  TRIGGER_CHECKOUT_SUCCESS,
  TRIGGER_GET_SHIPPING_DETAILS,
  TRIGGER_SUBSCRIBE_NEWSLETTER,
} from "../redux/actions/actionTypes";
import useShippingMethodsList from "../hooks/useShippingMethodsList";
import { isLogin } from "../utils";
import { useHistory } from "react-router-dom";
import { Check12x9Svg } from "../svg/index";
import {
  triggerBillingCountry,
  triggerBillingState,
  triggerCheckout,
  triggerExpressCheckout,
  triggerShippingCountry,
  triggerShippingState,
} from "../redux/actions/cartAction";
import useUpdateCartProduct from "../hooks/useUpdateCartProduct";
import useCalculateTotalHook from "../hooks/useCalculateTotalHook";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useValidateCheckout from "../hooks/useValidateCheckout";
import useCartHook from "../hooks/useCartHook";
import OverlayLoader from "../components/shared/OverlayLoader";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import GoogleAddressAutocomplete from "./account/GoogleAddressAutocomplete";
import { countryOptions } from "../utils/constants";
import useSetMetaTags from "../hooks/useSetMetaTags";
import usePaypalCheckoutMethods from "../hooks/usePaypalCheckoutMethods";
import { FastForward } from "@material-ui/icons";
import { unstable_useEnhancedEffect } from "@mui/material";

export default function Checkout() {
  const {
    cart,
    total,
    shippingAddress,
    shippingMethod,
    subsribeToNewsletter,
    billingCountry,
    billingState,
    shippingCountry,
    shippingState,
    redeemPoints,
    tax,
    couponDetails,
    billingAddress,
    calculateShippingDetails,
    checkout,
    checkOutObject,
  } = useSelector((state) => state.cartReducer);

  const [userData] = useUserDetailsHook();
  const history = useHistory();
  const [shippingMethods] = useShippingMethodsList();
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkNewsletter, setCheckNewsletter] = useState(subsribeToNewsletter);
  const [createOrder, onClick, onCancel, onApprove, onError] =
    usePaypalCheckoutMethods();
  const [validateCheckout, createCheckoutObj] = useValidateCheckout();
  const [captcha, validateCaptcha] = useState(false);
  const [calculateTotal] = useCalculateTotalHook();
  const navigate = useHistory();
  const [setMetaTags] = useSetMetaTags();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    setMetaTags("Checkout", "Checkout", "Checkout");
  });

  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
    removePoints,
    removeCoupon,
    cleanCartAfterPayment,
    emptyShippingData,
  ] = useCartHook();

  let initialObject = {
    billing_country: shippingAddress?.country,
    billing_first_name: userData?.name || "",
    billing_last_name: userData?.last_name || "",
    billing_company: "",
    billing_address: billingAddress?.address,
    billing_apartment: "",
    billing_town: "",
    billing_state: shippingAddress?.state || "",
    billing_postcode: shippingAddress?.postcode || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
    shipping_country: shippingAddress?.country,
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_company: "",
    shipping_address: "",
    shipping_apartment: "",
    shipping_town: "",
    shipping_state: calculateShippingDetails?.state || "",
    shipping_postcode: calculateShippingDetails?.postcode || "",
    order_notes: "",
    shipping_method: "",
    order_id: "",
    card_holder_name: "",
    credit_card_number: "",
    credit_card_date: "",
    credit_validation_code: "",
    password: "",
    acceptTerms: false,
  };

  useEffect(() => {
    checkoutObj.shipping_postcode = calculateShippingDetails?.postcode;
    checkoutObj.shipping_country = calculateShippingDetails?.country;
    checkoutObj.shipping_state = calculateShippingDetails?.state;
    setCheckoutObj(checkoutObj);
  }, [calculateShippingDetails]);
  useEffect(() => {
    initialObject.billing_address = billingAddress?.addresss;
    initialObject.billing_town = billingAddress?.town;
    initialObject.billing_postcode = billingAddress?.postcode;
  }, [billingAddress]);

  const [createNewAc, setCreateNewAc] = useState(false);
  const [captchaval, setCaptchaval] = useState(false);
  const [shipToDiffAddress, setShipToDiffAddress] = useState(false);
  const [checkoutObj, setCheckoutObj] = useState(initialObject);
  const [expanded, setExpanded] = React.useState("credit_card");

  useEffect(() => {
    dispatch({
      type: TRIGGER_CHECKOUT_OBJ,
      checkOutObject: checkoutObj,
    });
  }, [checkoutObj, calculateShippingDetails?.postcode]);

  const {
    billing_country,
    billing_first_name,
    billing_last_name,
    billing_state,
    billing_postcode,
    email,
    phone,
    shipping_country,
    shipping_state,
    shipping_town,
    shipping_apartment,
    shipping_address,
    shipping_company,
    shipping_last_name,
    shipping_first_name,
    card_holder_name,
    credit_card_number,
    credit_card_date,
    credit_validation_code,
    shipping_postcode,
    billing_address,
    billing_company,
    billing_apartment,
    billing_town,
    password,
    order_notes,
    acceptTerms,
  } = checkoutObj;

  const dispatch = useDispatch();
  const gCaptcha = {
    human: false,
    humanKey: "",
  };

  const [country, setCountry] = useState({
    countryName: "",
    countryCode: "",
  });

  useEffect(() => {
    dispatch({
      type: TRIGGER_CHECKOUT_SUCCESS,
      payload: [],
    });

    if (!cart.length) {
      navigate.push("/");
    }
  }, []);
  useEffect(() => {
    setIsValid(
      checkoutUsingPaypal(checkoutObj, acceptTerms, shipToDiffAddress, captcha)
    );
  }, [checkoutObj, acceptTerms, shipToDiffAddress, captcha, expanded]);

  useEffect(() => {
    if (shipToDiffAddress) {
      dispatch(triggerBillingCountry());
      if (shippingAddress?.country ) {
        let index = billingCountry?.data.findIndex((item) => {
          return item.sortname === shippingAddress?.country;
        });
        let countryId = billingCountry?.data[index]?.id
          ? billingCountry?.data[index]?.id
          : 231;
        dispatch(triggerBillingState({ country_id: countryId }));
      }
    } else {
      dispatch(triggerShippingCountry());

      if (shippingAddress?.country) {
        let index = shippingCountry?.data.findIndex((item) => {
          return item.sortname === shippingAddress?.country;
        });
        let countryId = shippingCountry?.data[index]?.id
          ? shippingCountry?.data[index]?.id
          : 231;
        dispatch(triggerShippingState({ country_id: countryId }));
      }
    }
  }, [shipToDiffAddress]);

  const onChangeChoice = (e) => {
    if (e.target.name === "createAccount") {
      setCreateNewAc(!createNewAc);
    } else {
      setShipToDiffAddress(e.target.checked);
      patchBillingData(e.target.checked);
    }
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const verifyCaptcha = function (value) {
    if (value) {
      gCaptcha.human = true;
      gCaptcha.humanKey = value;
      validateCaptcha(true);
    }
  };

  const handleShippingAddress = async (e, newValue) => {
    let updatedObj = { ...checkoutObj };



    updatedObj["shipping_address"] = e;

    dispatch({
      type: TRIGGER_GET_SHIPPING_DETAILS,
      country: updatedObj["shipping_country"],
      address: updatedObj["shipping_address"],
      state: updatedObj["shipping_state"],
      postcode: updatedObj["shipping_postcode"],
      town: updatedObj["shipping_town"],
    });

    setCheckoutObj(updatedObj);
    // } else
    if (!shipToDiffAddress) {
      updatedObj.billing_town = shipping_town;
      updatedObj.billing_postcode = shipping_postcode;
      updatedObj.billing_address = shipping_address;
      updatedObj.billing_apartment = shipping_apartment;
      updatedObj.billing_first_name = shipping_first_name;
      updatedObj.billing_last_name = shipping_last_name;
      updatedObj.billing_country = shipping_country;
      updatedObj.billing_state = updatedObj?.shipping_state;
      updatedObj.billing_company = shipping_company;
    } else {
      updatedObj.billing_address = e;

      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: updatedObj["shipping_country"],
        address: e,
        state: updatedObj["shipping_state"],
        postcode: updatedObj["shipping_postcode"],
        town: updatedObj["shipping_town"],
      });
    }

    setCheckoutObj(updatedObj);
  };
  // const handleShippingAddress = async (e, newValue) => {
  //   let updatedObj = { ...checkoutObj };

  //   if (newValue && !e) {
  //     updatedObj.shipping_address = newValue;

  //     setCheckoutObj(updatedObj);
  //     dispatch({
  //       type: TRIGGER_GET_SHIPPING_DETAILS,
  //       country: updatedObj["shipping_country"],
  //       address: newValue,
  //       state: updatedObj["shipping_state"],
  //       postcode: updatedObj["shipping_postcode"],
  //       town: updatedObj["shipping_town"],
  //     });
  //   } else if (e && !newValue) {
  //     if (e?.place_id) {
  //       const results = await geocodeByAddress(e?.description || newValue);
  //       const latLng = await getLatLng(results[0]);
  //       const [place] = await geocodeByPlaceId(e?.place_id);

  //       updatedObj["shipping_address"] = e?.structured_formatting?.main_text;

  //       const { long_name: postalCode = "" } =
  //         place.address_components.find((c) =>
  //           c.types.includes("postal_code")
  //         ) || {};
  //       const countr = e.terms[e.terms.length - 1].value;

  //       const { short_name: state = "" } =
  //         (shippingState?.data?.length > 0 &&
  //           place.address_components.find((c) =>
  //             c.types.includes("administrative_area_level_1")
  //           )) ||
  //         {};

  //       updatedObj.shipping_state = state;
  //       const currentCountry = billingCountry.data.filter(
  //         (data) =>
  //           JSON.stringify(data).toLowerCase().indexOf(countr.toLowerCase()) !==
  //           -1
  //       );
  //       updatedObj["shipping_postcode"] = postalCode;
  //       updatedObj["shipping_town"] =
  //         e.terms.length > 2 ? e.terms[e.terms.length - 3].value : "";
  //       if (currentCountry.length > 0) {
  //         if (currentCountry[0].sortname !== updatedObj["shipping_country"]) {
  //           dispatch(
  //             triggerShippingState({ country_id: currentCountry[0].id })
  //           );
  //         }
  //         updatedObj["shipping_country"] = currentCountry[0].sortname;
  //       }
  //     }

  //     updatedObj["shipping_address"] = e?.structured_formatting?.main_text;

  //     dispatch({
  //       type: TRIGGER_GET_SHIPPING_DETAILS,
  //       country: updatedObj["shipping_country"],
  //       address: e?.structured_formatting?.main_text,
  //       state: updatedObj["shipping_state"],
  //       postcode: updatedObj["shipping_postcode"],
  //       town: updatedObj["shipping_town"],
  //     });
  //     setCheckoutObj(updatedObj);
  //   } else if (e && newValue) {
  //     if (e?.place_id) {
  //       const results = await geocodeByAddress(newValue);
  //       const latLng = await getLatLng(results[0]);
  //       const [place] = await geocodeByPlaceId(e?.place_id);

  //       updatedObj["shipping_address"] = newValue;

  //       const { long_name: postalCode = "" } =
  //         place.address_components.find((c) =>
  //           c.types.includes("postal_code")
  //         ) || {};
  //       const countr = e.terms[e.terms.length - 1].value;

  //       const { short_name: state = "" } =
  //         (shippingState?.data?.length > 0 &&
  //           place.address_components.find((c) =>
  //             c.types.includes("administrative_area_level_1")
  //           )) ||
  //         {};

  //       updatedObj.shipping_state = state;
  //       const currentCountry = billingCountry.data.filter(
  //         (data) =>
  //           JSON.stringify(data).toLowerCase().indexOf(countr.toLowerCase()) !==
  //           -1
  //       );
  //       updatedObj["shipping_postcode"] = postalCode;
  //       updatedObj["shipping_town"] =
  //         e.terms.length > 2 ? e.terms[e.terms.length - 3].value : "";
  //       if (currentCountry.length > 0) {
  //         if (currentCountry[0].sortname !== updatedObj["shipping_country"]) {
  //           dispatch(
  //             triggerShippingState({ country_id: currentCountry[0].id })
  //           );
  //         }
  //         updatedObj["shipping_country"] = currentCountry[0].sortname;
  //       }
  //     }

  //     updatedObj["shipping_address"] = newValue;

  //     dispatch({
  //       type: TRIGGER_GET_SHIPPING_DETAILS,
  //       country: updatedObj["shipping_country"],
  //       address: updatedObj["shipping_address"],
  //       state: updatedObj["shipping_state"],
  //       postcode: updatedObj["shipping_postcode"],
  //       town: updatedObj["shipping_town"],
  //     });

  //     setCheckoutObj(updatedObj);
  //   } else if (!shipToDiffAddress) {
  //     updatedObj.billing_town = shipping_town;
  //     updatedObj.billing_postcode = shipping_postcode;
  //     updatedObj.billing_address = shipping_address;
  //     updatedObj.billing_apartment = shipping_apartment;
  //     updatedObj.billing_first_name = shipping_first_name;
  //     updatedObj.billing_last_name = shipping_last_name;
  //     updatedObj.billing_country = shipping_country;
  //     updatedObj.billing_state = updatedObj?.shipping_state;
  //     updatedObj.billing_company = shipping_company;
  //   }
  //   setCheckoutObj(updatedObj);
  // };

  const handleBillingAddress = async (e, newValue) => {
    let updatedObj = { ...checkoutObj };
    if (newValue && !e) {
      updatedObj.billing_address = newValue;
      setCheckoutObj(updatedObj);
    }

    if (e && !newValue) {
      if (e?.place_id) {
        const results = await geocodeByAddress(e?.description || newValue);
        const latLng = await getLatLng(results[0]);
        const [place] = await geocodeByPlaceId(e?.place_id);
        updatedObj["billing_address"] = e?.structured_formatting?.main_text;
        const { long_name: postalCode = "" } =
          place.address_components.find((c) =>
            c.types.includes("postal_code")
          ) || {};
        const countr = e.terms[e.terms.length - 1].value;

        const { short_name: state = "" } =
          place.address_components.find(
            (c) =>
              billingState?.data?.length > 0 &&
              c.types.includes("administrative_area_level_1")
          ) || {};

        updatedObj.billing_state = state;
        const currentCountry = billingCountry.data.filter(
          (data) =>
            JSON.stringify(data).toLowerCase().indexOf(countr.toLowerCase()) !==
            -1
        );
        updatedObj["billing_postcode"] = postalCode;
        updatedObj["billing_town"] =
          e.terms.length > 2 ? e.terms[e.terms.length - 3].value : "";
        if (currentCountry.length > 0) {
          if (currentCountry[0].sortname !== updatedObj["billing_country"]) {
            dispatch(
              triggerShippingState({ country_id: currentCountry[0].id })
            );
          }
          updatedObj["billing_country"] = currentCountry[0].sortname;
        } else {
          if (!updatedObj["billing_address"] && !shipToDiffAddress) {
            updatedObj.billing_address = newValue
              ? newValue
              : e.structured_formatting.main_text;
          }
        }
      }

      updatedObj["billing_address"] = e?.structured_formatting?.main_text;

      dispatch({
        type: TRIGGER_BILLING_ADDRESS,
        country: updatedObj?.billing_country,
        address: updatedObj.billing_address,
        state: updatedObj.billing_state,
        postcode: updatedObj.billing_postcode,
        town: updatedObj["billing_town"],
      });

      setCheckoutObj(updatedObj);
    }

    if (e && newValue) {
      if (e?.place_id) {
        const results = await geocodeByAddress(newValue);
        const latLng = await getLatLng(results[0]);
        const [place] = await geocodeByPlaceId(e?.place_id);

        updatedObj["billing_address"] = newValue;

        const { long_name: postalCode = "" } =
          place.address_components.find((c) =>
            c.types.includes("postal_code")
          ) || {};
        const countr = e.terms[e.terms.length - 1].value;
        const { short_name: state = "" } =
          (billingState?.data?.length > 0 &&
            place.address_components.find((c) =>
              c.types.includes("administrative_area_level_1")
            )) ||
          {};

        updatedObj.shipping_state = state;
        const currentCountry = billingCountry.data.filter(
          (data) =>
            JSON.stringify(data).toLowerCase().indexOf(countr.toLowerCase()) !==
            -1
        );
        updatedObj["billing_postcode"] = postalCode;
        updatedObj["billing_town"] =
          e.terms.length > 2 ? e.terms[e.terms.length - 3].value : "";
        if (currentCountry.length > 0) {
          if (currentCountry[0].sortname !== updatedObj["billing_country"]) {
            dispatch(
              triggerShippingState({ country_id: currentCountry[0].id })
            );
          }
          updatedObj["billing_country"] = currentCountry[0].sortname;
        }
      }

      updatedObj["billing_address"] = newValue;

      dispatch({
        type: TRIGGER_BILLING_ADDRESS,
        country: updatedObj?.billing_country,
        address: updatedObj.billing_address,
        state: updatedObj.billing_state,
        postcode: updatedObj.billing_postcode,
        town: updatedObj["billing_town"],
      });

      setCheckoutObj(updatedObj);
    } else {
      updatedObj["billing_address"] = e;

      dispatch({
        type: TRIGGER_BILLING_ADDRESS,
        country: updatedObj?.billing_country,
        address: updatedObj.billing_address,
        state: updatedObj.billing_state,
        postcode: updatedObj.billing_postcode,
        town: updatedObj["billing_town"],
      });

      setCheckoutObj(updatedObj);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    event.stopPropagation();
    setExpanded(newExpanded ? panel : expanded);
  };
  // Set reCaptcha Ref
  const setCaptchaRef = (ref) => {
    if (ref) {
      setCaptchaval(ref);
      return captchaval;
    }
  };
  // Reset reCaptcha
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    if (captchaval) {
      captchaval.reset();
      validateCaptcha(false);
    }
  };
  // Recaptcha Error
  const handleCaptchaError = () => {
    // Reset Captcha
    resetCaptcha();
    validateCaptcha(false);
  };
  // Recaptcha Expire
  const handleCaptchaExpired = () => {
    // Reset Captcha
    resetCaptcha();
    validateCaptcha(false);
  };
  const handleInputChange = (e) => {
    let updatedObj = { ...checkoutObj };

    if (e.target.name === "billing_country") {
      let index = billingCountry?.data.findIndex((item) => {
        return item.sortname === e.target.value;
      });
      let countryId = billingCountry?.data[index]?.id
        ? billingCountry?.data[index]?.id
        : 231;

      if (countryId) {
        dispatch(triggerBillingState({ country_id: countryId }));
      }

      // let btn = document.getElementById("billingid");
      // btn.click();

      dispatch({
        type: TRIGGER_BILLING_ADDRESS,
        address: "",
        state: "",
        postcode: "",
        town: "",
        country: updatedObj?.billing_country,
      });
      updatedObj.billing_town = "";
      updatedObj.billing_postcode = "";
      updatedObj.billing_address = "";
      if (
        document.querySelectorAll(
          ".billing-address .css-1uccc91-singleValue"
        )[0] != null
      ) {
        document.querySelectorAll(
          ".billing-address .css-1uccc91-singleValue"
        )[0].innerText = "Street Address";
        if (
          document.querySelectorAll(
            ".billing-address  .css-1wa3eu0-placeholder"
          )[0] != null
        ) {
          document.querySelectorAll(
            "..billing-address  .css-1wa3eu0-placeholder"
          )[0].innerText = "Street Address";
        }
      }
    } else if (e.target.name === "shipping_country") {
      let index = shippingCountry?.data.findIndex((item) => {
        return item.sortname === e.target.value;
      });

      let countryId = shippingCountry?.data[index]?.id
        ? shippingCountry?.data[index]?.id
        : 231;
      if (countryId) {
        dispatch(triggerShippingState({ country_id: countryId }));
      }

      let btn = document.getElementById("shippingid");
      if (btn) {
        btn.click();
      }

      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: updatedObj?.shipping_country,
        state: "",
        postcode: "",
        address: "",
      });

      updatedObj.shipping_town = "";
      updatedObj.shipping_postcode = "";
      updatedObj.shipping_address = "";
      updatedObj.shipping_state = null;
      if (
        document.querySelectorAll(
          ".shipping-address .css-1uccc91-singleValue"
        )[0] != null
      ) {
        document.querySelectorAll(
          ".shipping-address .css-1uccc91-singleValue"
        )[0].innerText = "Street Address";
        if (
          document.querySelectorAll(
            ".shipping-address  .css-1wa3eu0-placeholder"
          )[0] != null
        ) {
          document.querySelectorAll(
            "..shipping-address  .css-1wa3eu0-placeholder"
          )[0].innerText = "Street Address";
        }
      }
    }

    if (e.target.name === "acceptTerms") {
      updatedObj[e.target.name] = e.target.checked;
    } else {
      updatedObj[e.target.name] = e.target.value;
    }

    setCheckoutObj(updatedObj);
  };


   useEffect(() => {
    if (
      shipping_country &&
      (shipping_country == "PR" ||
        shipping_country == "FR" ||
        shipping_country == "GB") &&
      shippingState?.data?.length
    ) {
      dispatch({
        type: TRIGGER_GET_SHIPPING_DETAILS,
        country: shipping_country,
        state: shippingState?.data[0]["sortname"],
        postcode: shipping_postcode,
      });
    }
  }, [shipping_country, shippingState?.data?.length]);



   useEffect(() => {
     if (
       billing_country &&
       (billing_country == "PR" || billing_country == "FR" || billing_country == "GB") &&
       billingState?.data?.length
       ) {
       dispatch({
         type: TRIGGER_BILLING_ADDRESS,
         country: billing_country,
         state: billingState?.data[0]["sortname"],
         postcode: billing_postcode,
       });
     }
   }, [billing_country, billingState?.data?.length]);

  const handleBlur = (e) => {
    let updatedObj = { ...checkoutObj };

    if (!shipToDiffAddress) {
      if (updatedObj.billing_first_name == "") {
        updatedObj.billing_first_name = updatedObj.shipping_first_name;
      }
      if (updatedObj.billing_last_name == "") {
        updatedObj.billing_last_name = updatedObj.shipping_last_name;
      }

      if (updatedObj.billing_apartment == "") {
        updatedObj.billing_apartment = updatedObj.shipping_apartment;
      }
      if (updatedObj.billing_company == "") {
        updatedObj.billing_company = updatedObj.shipping_company;
      }

      if (updatedObj.billing_address == "") {
        updatedObj.billing_address = updatedObj.shipping_address;
      }

      if (updatedObj.billing_town == "") {
        updatedObj.billing_town = updatedObj.shipping_town;
      }
      if (updatedObj.billing_postcode == "") {
        updatedObj.billing_postcode = updatedObj.shipping_postcode;
      }

      if (updatedObj.billing_state == "") {
        updatedObj.billing_state = updatedObj.shipping_state;
      }
      setCheckoutObj(updatedObj);

      dispatch({
        type: TRIGGER_BILLING_ADDRESS,
        country: updatedObj?.billing_country,
        address: updatedObj.billing_address,
        state: updatedObj.billing_state,
        postcode: updatedObj.billing_postcode,
        town: updatedObj.billing_town,
      });
    }
  };

  const patchBillingData = (shipTodiff) => {
    let updatedObj = { ...checkoutObj };

    if (shipTodiff) {
      updatedObj.billing_town = "";
      updatedObj.billing_postcode = "";
      updatedObj.billing_address = "";
      updatedObj.billing_apartment = "";
      updatedObj.billing_first_name = "";
      updatedObj.billing_last_name = "";
      // updatedObj.billing_state = "";
      updatedObj.billing_company = "";
    } else {
      updatedObj.billing_town = updatedObj.shipping_town;
      updatedObj.billing_postcode = updatedObj.shipping_postcode;
      updatedObj.billing_address = updatedObj.shipping_address;
      updatedObj.billing_apartment = updatedObj.shipping_apartment;
      updatedObj.billing_first_name = updatedObj.shipping_first_name;
      updatedObj.billing_last_name = updatedObj.shipping_last_name;
      updatedObj.billing_country = updatedObj.shipping_country;
      updatedObj.billing_state = updatedObj.shipping_state;
      updatedObj.billing_company = updatedObj.shipping_company;
    }

    setCheckoutObj(updatedObj);
    dispatch({
      type: TRIGGER_BILLING_ADDRESS,
      country: updatedObj?.billing_country,
      address: updatedObj.billing_address,
      state: updatedObj.billing_state,
      postcode: updatedObj.billing_postcode,
      town: updatedObj.billing_town,
    });
  };

  const onSubmit = (event) => {
    setIsSubmit(true);
    event.preventDefault();

    if (expanded === "credit_card") {
      if (createNewAc && !password) {
        return;
      }
      if (!shipping_last_name) {
        return;
      }
      if (!shipping_first_name) {
        return;
      }

      if (!shipping_town) {
        return;
      }

      if (!shipping_country) {
        return;
      }

      if (!shippingAddress) {
        return;
      }

      if (!shippingState) {
        return;
      }
      if (
        shipping_address &&
        shipping_apartment &&
        (checkIfAddressIsDifferent(shipping_address, shipping_apartment) ||
          checkIfAddressIsDifferent(shipping_apartment, shipping_address))
      ) {
        return;
      }
      if (
        shipToDiffAddress &&
        billing_address &&
        billing_apartment &&
        (checkIfAddressIsDifferent(billing_address, billing_apartment) ||
          checkIfAddressIsDifferent(billing_apartment, billing_address))
      ) {
        return;
      }

      if (shippingState?.data?.length > 0 && !shipping_state) {
        return;
      }

      if (!shipping_town) {
        return;
      }
      if (
        shipToDiffAddress &&
        !billing_country &&
        !billing_first_name &&
        !billing_last_name &&
        !billing_postcode &&
        !billing_address
      ) {
        return;
      }

      if (
        shipToDiffAddress &&
        billingState?.data?.length > 0 &&
        !billing_state
      ) {
        return;
      }

      if (shippingState?.data?.length > 0 && !shipping_state) {
        return;
      }
      if (
        !card_holder_name &&
        !credit_card_date &&
        !credit_card_number &&
        !credit_card_number
      ) {
        return;
      }

      if (!acceptTerms && !captcha) {
        resetCaptcha();
        return;
      }
      if (!acceptTerms) {
        return;
      }
      if (!captcha) {
        resetCaptcha();
        return;
      }
      if (!shipping_postcode) {
        return;
      }
      if (!email) {
        return;
      }

      if (
        (shippingMethod["shippingCharges"] == "" ||
          shippingMethod["shippingCharges"] == "0.00") &&
        shippingMethod["shippingName"] == ""
      ) {
        return;
      }
      if (
        shipping_postcode !== "" &&
        calculateShippingDetails.postcode !== shipping_postcode
      ) {
        setOpenDialog(true);
        return;
      } else {
        triggerCheckoutApi();
      }
    } else if (expanded === "paypal") {
      if (
        !billing_country &&
        !billing_first_name &&
        !billing_last_name &&
        !billing_postcode &&
        !billing_address &&
        !billing_town
      ) {
        resetCaptcha();
        return;
      }

      if (
        shipToDiffAddress &&
        billingState?.data?.length > 0 &&
        !billing_state
      ) {
        return;
      }

      if (
        shipping_address &&
        shipping_apartment &&
        (checkIfAddressIsDifferent(shipping_address, shipping_apartment) ||
          checkIfAddressIsDifferent(shipping_apartment, shipping_address))
      ) {
        return;
      }
      if (
        shipToDiffAddress &&
        billing_address &&
        billing_apartment &&
        (checkIfAddressIsDifferent(billing_address, billing_apartment) ||
          checkIfAddressIsDifferent(billing_apartment, billing_address))
      ) {
        return;
      }
      if (!acceptTerms && !captcha) {
        resetCaptcha();
        return;
      }
      if (!acceptTerms) {
        return;
      }
      if (!captcha) {
        resetCaptcha();
        return;
      }

      if (
        shipping_postcode !== "" &&
        calculateShippingDetails.postcode !== shipping_postcode
      ) {
        setOpenDialog(true);
        return;
      }
      if (
        shippingMethod["shippingCharges"] == "" ||
        (shippingMethod["shippingCharges"] == "0.00" &&
          shippingMethod["shippingName"] == "")
        // shippingMethod["shippingMode"] == ""
      ) {
        return;
      }
      // triggerCheckoutApi();
    }
  };

  const triggerCheckoutApi = (transactionDetails) => {
    // return;
    return dispatch(
      triggerCheckout(
        createCheckoutObj(
          checkoutObj,
          expanded,
          createNewAc,
          checkNewsletter,
          shipToDiffAddress,
          acceptTerms,
          transactionDetails
        ),
        (response) => {
          if (response.error) {
            toast.error(
              toTitleCase(
                response.Message || response.message || "Something went wrong!!"
              ),
              {
                toastId: "toastMsg",
                autoClose: 2500,
                hideProgressBar: true,
              }
            );
          }

          if (response["Success"] === false) {
            if (
              (!response["CardNumber"] &&
                !response["Code"] &&
                !response["CardDate"]) ||
              (!response["CardNumber"] && !response["Code"]) ||
              (!response["CardNumber"] && !response["CardDate"]) ||
              (!response["code"] && !response["CardDate"])
            ) {
              toast.error("Invalid card details", {
                toastId: "toastMsg",
                autoClose: 2000,
                hideProgressBar: true,
              });
            } else if (
              !response["CardNumber"] ||
              !response["Code"] ||
              !response["CardDate"]
            ) {
              toast.error(
                !response["CardNumber"]
                  ? "Invalid card number"
                  : !response["CardDate"]
                  ? "Invalid card date"
                  : "Invalid cvv",
                {
                  toastId: "toastMsg",
                  autoClose: 2000,
                  hideProgressBar: true,
                }
              );
            } else if (response.error) {
              toast.error(response["Message"], {
                toastId: "toastMsg",
                autoClose: 2000,
                hideProgressBar: true,
              });
            }
          } else {
            if (expanded === "paypal") {
              if (!response.error) {
                // return;
                if (response.paypalFormFields) {
                  navigate.push(
                    `/payExp/order-success/${response?.paypalFormFields?.order?.order_id}`
                  );
                  cleanCartAfterPayment();
                }
                // paypalPayment("sandbox", response.paypalFormFields);
              } else {
                toast.error(response.error[0], {
                  toastId: "toastMsg",
                  autoClose: 2000,
                  hideProgressBar: true,
                });
              }
            }
            // if (expanded === "credit_card") {
            if (response.error) {
              toast.error(response.message, {
                toastId: "toastMsg",
                autoClose: 2500,
                hideProgressBar: true,
              });
            } else {
              if (response.message) {
                toast.success(response.message, {
                  toastId: "toastMsg",
                  autoClose: 2500,
                  hideProgressBar: true,
                });
              }
            }
            if (response?.order_id) {
              navigate.push(`/payExp/order-success/${response?.order_id}`);
              cleanCartAfterPayment();
            }
            // }
          }
        },
        (error) => {
          console.log(
            "🚀 ~ file: Checkout.js:713 ~ triggerCheckoutApi ~ error",
            error
          );
        }
      )
    );
  };

  function paypalPayment(mode = "sandbox", $fields) {
    const PAYPAL_API_URL =
      mode == "sandbox"
        ? "https://www.sandbox.paypal.com/cgi-bin/webscr"
        : "https://www.paypal.com/cgi-bin/webscr";
    const BASE_URL =
      mode == "sandbox"
        ? "https://www.development.vanagain.com"
        : "https://www.vanagain.com";

    // return

    let form = document.createElement("form");

    let charset = document.createElement("input");
    let cmd = document.createElement("input");
    let upload = document.createElement("input");
    let business = document.createElement("input");
    let currency_code = document.createElement("input");
    let rm = document.createElement("input");
    // order id in base64 btoa
    // Define Form Fields
    form.target = "_self";
    form.method = "POST";
    form.action = PAYPAL_API_URL;

    // Input element name and value assign
    charset.name = "charset";
    charset.value = "utf-8";
    form.appendChild(charset);
    // Paypal required hidden fields
    cmd.name = "cmd";
    cmd.value = "_cart";
    form.appendChild(cmd);
    upload.name = "upload";
    upload.value = "1";
    form.appendChild(upload);
    business.name = "business";
    business.value = "sb-hdado26093437@personal.example.com"; //"kenwilfy@comcast.net";
    form.appendChild(business);
    currency_code.name = "currency_code";
    currency_code.value = "USD";
    form.appendChild(currency_code);
    rm.name = "rm";
    rm.value = "2";
    form.appendChild(rm);
    if ($fields.order_item) {
      $fields.order_item.forEach((order, index) => {
        let count = index + 1;
        let item_name = document.createElement("input");
        let item_number = document.createElement("input");
        let amount = document.createElement("input");
        let quantity = document.createElement("input");
        item_name.name = "item_name_" + count;
        item_name.value = order.name;
        form.appendChild(item_name);
        item_number.name = "item_number_" + count;
        item_number.value = count;
        form.appendChild(item_number);
        amount.name = "amount_" + count;
        amount.value = order.product_price;
        form.appendChild(amount);
        quantity.name = "quantity_" + count;
        quantity.value = order.product_quantity;
        form.appendChild(quantity);
      });
    }
    // order details

    let tax_cart = document.createElement("input");
    tax_cart.name = "tax_cart";
    tax_cart.value = $fields.order.order_tax;
    form.appendChild(tax_cart);
    let shipping = document.createElement("input");
    shipping.name = "shipping_1";
    shipping.value = $fields.order.order_shipping;
    form.appendChild(shipping);
    let custom = document.createElement("input");
    custom.name = "custom";
    custom.value = btoa($fields.order.order_id);
    form.appendChild(custom);
    let cancel_return = document.createElement("input");
    cancel_return.name = "cancel_return";
    cancel_return.value =
      $fields.order && mode !== "sandbox"
        ? $fields.order.cancel_return
        : `${BASE_URL}/order-cancel/` + btoa($fields.order.order_id);
    form.appendChild(cancel_return);
    let returnURL = document.createElement("input");
    returnURL.name = "return";
    returnURL.value =
      $fields.order && $fields.order.return && mode !== "sandbox"
        ? $fields.order.return
        : `${BASE_URL}/order-received`;
    let notify_url = document.createElement("input");
    notify_url.name = "notify_url";
    notify_url.value =
      $fields.order && $fields.order.notify_url
        ? $fields.order.notify_url
        : "https://www.vanagain.com/admin/ipn";
    form.appendChild(returnURL);
    form.appendChild(notify_url);
    // Add Discount
    if (
      $fields.order.points_redeemed_amount > 0.0 ||
      $fields.order.discount_amount_cart > 0.0
    ) {
      let discount = document.createElement("input");
      discount.name = "discount_amount_cart";
      discount.value = $fields.order.discount_amount_cart;
      form.appendChild(discount);
    }

    document.body.appendChild(form);
    form.submit();
  }
  // };
  const [data, setData] = useState("");

  useEffect(() => {
    data === "" ? setData("") : setData(data.label);
  }, [data]);

  // useEffect(() => {
  //   dispatch({
  //     type: TRIGGER_BILLING_ADDRESS,
  //     address: "",
  //   });
  // }, [billing_country]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    emptyShippingData();
  };

  const checkIfAddressIsDifferent = (val1, val2) => {
    let val1new = val1 && val1.toLowerCase().split(" ");
    let val2new = val2 && val2.toLowerCase();
    let isSame = false;
    for (let i = 0; i < val1new?.length; i++) {
      if (val2new.includes(val1new[i])) {
        isSame = val2new.includes(val1new[i]);
      }

      return isSame;
    }
  };

  const checkoutUsingPaypal = (
    obj,
    acceptTerms,
    shipToDiffAddress,
    captcha
  ) => {
    let formIsValid = true;

    const {
      billing_country,
      billing_first_name,
      billing_last_name,
      billing_state,
      billing_postcode,
      email,
      phone,
      shipping_country,
      shipping_state,
      shipping_town,
      shipping_apartment,
      shipping_address,
      shipping_company,
      shipping_last_name,
      shipping_first_name,
      card_holder_name,
      credit_card_number,
      credit_card_date,
      credit_validation_code,
      shipping_postcode,
      billing_address,
      billing_company,
      billing_apartment,
      billing_town,
      password,
      order_notes,
      order_id,
    } = obj;

    if (
      shipping_country == "" ||
      shipping_first_name == "" ||
      shipping_last_name == "" ||
      shipping_postcode == "" ||
      shipping_address == "" ||
      shipping_town == ""
    ) {
      // resetCaptcha();

      formIsValid = false;
    }

    if (expanded != "paypal") {
      formIsValid = false;
    }

    if (
      shipToDiffAddress &&
      (!billing_country ||
        !billing_first_name ||
        !billing_last_name ||
        !billing_postcode ||
        !billing_address ||
        !billing_town)
    ) {
      // resetCaptcha();
      formIsValid = false;
    }

    if (shipToDiffAddress && billingState?.data?.length > 0 && !billing_state) {
      formIsValid = false;
    }

    if (
      shipping_address &&
      shipping_apartment &&
      (checkIfAddressIsDifferent(shipping_address, shipping_apartment) ||
        checkIfAddressIsDifferent(shipping_apartment, shipping_address))
    ) {
      formIsValid = false;
    }
    if (
      shipToDiffAddress &&
      billing_address &&
      billing_apartment &&
      (checkIfAddressIsDifferent(billing_address, billing_apartment) ||
        checkIfAddressIsDifferent(billing_apartment, billing_address))
    ) {
      formIsValid = false;
    }
    if (!acceptTerms && !captcha) {
      // resetCaptcha();
      formIsValid = false;
    }
    if (!acceptTerms) {
      formIsValid = false;
    }
    if (!captcha) {
      // resetCaptcha();
      formIsValid = false;
    }
    if (!shipping_state) {
      formIsValid = false;
    }

    if (
      shipping_postcode !== "" &&
      calculateShippingDetails.postcode !== shipping_postcode
    ) {
      // setOpenDialog(true);
      formIsValid = false;
    }
    if (
      shippingMethod["shippingCharges"] == "" ||
      (shippingMethod["shippingCharges"] == "0.00" &&
        shippingMethod["shippingName"] == "")
      // shippingMethod["shippingMode"] == ""
    ) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const expressCheckout = (transactionDetails, obj) => {
    // if (orderId) {
    setIsSubmit(true);

    dispatch(
      triggerCheckout(
        createCheckoutObj(
          checkOutObject,
          expanded,
          createNewAc,
          checkNewsletter,
          shipToDiffAddress,
          acceptTerms,
          transactionDetails
          // orderId
        ),
        (resp) => {
          if (resp?.success) {
            cleanCartAfterPayment();
            history.push(`/payExp/order-success/${resp?.order?.id}`);
            toast.success("Payment Successful!", {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
            dispatch({
              type: GET_PAYPAL_APPROVED_DETAILS,
              paypalDetails: {},
            });
            dispatch({
              type: TRIGGER_CHECKOUT_OBJ,
              checkOutObject: {},
            });
            // dispatch({
            //   type: TRIGGER_CHECKOUT_SUCCESS,
            //   payload: [],
            // });
            cleanCartAfterPayment();
          } else {
            toast.error("Payment Failed!", {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
          }
        }
      )
    );
    // }
  };

  return (
    <div className="container">
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="alert-dialog-checkout"
      >
        <DialogTitle id="alert-dialog-title">
          You have used two different shipping zipcode. Please calculate
          shipping again.
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClose}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
      {checkout?.loading && isSubmit && <OverlayLoader />}
      <Breadcrumb
        list={[
          {
            title: "Checkout",
            url: "/checkout",
          },
        ]}
      />
      <h1 className="block-header__title  animate__animated animate__fadeIn">
        Checkout
      </h1>
      {/* {checkout?.loading && <OverlayLoader />} */}
      <form className="checkout block" onSubmit={onSubmit}>
        <div className="row">
          {!isLogin() && (
            <div className="col-12 mb-3">
              <div className="alert alert-lg alert-secondary">
                Returning customer?
                <Link
                  to={{
                    pathname: "/login",
                    state: { prevPath: navigate.location.pathname },
                  }}
                  className="ml-2"
                >
                  Click here to login
                </Link>
              </div>
            </div>
          )}

          <div className="col-12 col-lg-6 col-xl-7">
            <div className="card mb-lg-0">
              <div className="card-body card-body--padding--2">
                <h3 className="card-title mt-4 mb-3 mx-2">
                  Shipping addresss{" "}
                </h3>
                <div className="form-group">
                  <div className="mt-1"></div>

                  {
                    <div className="collapse-area row">
                      <div className="pt-2"></div>

                      <div className="form-group col-md-12">
                        <label htmlFor="checkout-shipping-address-country">
                          Country
                        </label>
                        <select
                          id="checkout-shipping-address-country"
                          className="form-control"
                          disabled={true}
                          name="shipping_country"
                          value={shipping_country}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        >
                          {shippingCountry?.data?.length &&
                            countryOptions(shippingCountry?.data)}
                        </select>
                        {shipping_country === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please select shipping country.
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-shipping-address-first-name">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-first-name"
                          className="form-control"
                          disabled=""
                          placeholder="First Name"
                          name="shipping_first_name"
                          value={shipping_first_name}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                        {shipping_first_name === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please enter shipping first name.
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-shipping-address-last-name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-last-name"
                          className="form-control"
                          disabled=""
                          placeholder="Last Name"
                          name="shipping_last_name"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          value={shipping_last_name}
                        />
                        {shipping_last_name === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please enter shippiing last name.
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="checkout-shipping-address-company-name">
                          Company Name
                          <span className="text-muted"> (Optional)</span>
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-company-name"
                          className="form-control"
                          disabled=""
                          placeholder="Company Name"
                          name="shipping_company"
                          value={shipping_company}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                      </div>
                      <div className="form-group col-md-12 shipping-address">
                        <label htmlFor="shipping_address">Address</label>

                        {/* <GoogleAddressAutocomplete
                          name="shipping_address"
                          country={
                            shipping_country?.toLowerCase() ||
                            country.countryCode
                          }
                          key={
                            shipping_country?.toLowerCase() ||
                            country.countryCode
                          }
                          id="shipping_address"
                          type="shipping"
                          placeholder="Street Address"
                          onChangeAddress={(e, newValue) => {
                            handleShippingAddress(e, newValue);
                          }}
                          addressValue={shipping_address}
                        /> */}

                        <input
                          type="text"
                          id="shipping_address"
                          className="form-control mt-2"
                          disabled=""
                          placeholder="Street Address"
                          name="shipping_address"
                          value={shipping_address}
                          onChange={(e) => {
                            handleShippingAddress(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleShippingAddress(e.target.value);
                          }}
                        />

                        {shipping_address === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please enter shipping address.
                          </div>
                        )}
                        <label
                          htmlFor="checkout-shipping-address-address2"
                          style={{ display: "none" }}
                        >
                          Street Address
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-address2"
                          className="form-control mt-2"
                          disabled=""
                          placeholder="Apartment, suite, P.O. Box, unit etc.(optional)"
                          name="shipping_apartment"
                          value={shipping_apartment}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />

                        {shipping_address &&
                          shipping_apartment &&
                          (checkIfAddressIsDifferent(
                            shipping_address,
                            shipping_apartment
                          ) ||
                            checkIfAddressIsDifferent(
                              shipping_apartment,
                              shipping_address
                            )) &&
                          isSubmit &&
                          (expanded === "credit_card" ||
                            expanded === "paypal") && (
                            <div className="invalid-feedback">
                              Please enter different addresses.
                            </div>
                          )}
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="checkout-shipping-address-city">
                          Town / City
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-city"
                          className="form-control"
                          disabled=""
                          placeholder="Town / City"
                          name="shipping_town"
                          value={shipping_town}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                        {shipping_town === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please enter shipping town.
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-shipping-address-state">
                          State / Province / Country
                        </label>
                        <select
                          id="checkout-shipping-address-state"
                          className="form-control"
                          disabled={
                            (shippingState?.data?.length == 0 &&
                              !shippingState?.loading) ||
                            true
                          }
                          name="shipping_state"
                          value={shipping_state}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        >
                          {shippingState?.data?.length == 0 &&
                          !shippingState?.loading ? (
                            <option hidden selected value>
                              Not Applicable
                            </option>
                          ) : (
                            <option hidden selected value>
                              {/* {shipping_country == "PR"
                                ? "Select Municipality"
                                : shipping_country == "FR"
                                ? "Select Region"
                                : "Select Province"} */}
                              Select Province
                            </option>
                          )}

                          {shippingState?.data?.length &&
                            shippingState?.data?.map((e, i) => {
                              return (
                                <option
                                  value={e.sortname}
                                  key={i}
                                  name={e.name}
                                  id={e}
                                >
                                  {e.name}
                                </option>
                              );
                            })}
                        </select>
                        {shippingState?.data?.length !== 0 &&
                          shipping_state === "" &&
                          isSubmit && (
                            <div className="invalid-feedback">
                              Please select shipping state.
                            </div>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-shipping-address-postcode">
                          Postcode / Zip
                        </label>
                        <input
                          type="text"
                          id="checkout-shipping-address-postcode"
                          className="form-control"
                          placeholder="Postcode / Zip"
                          name="shipping_postcode"
                          value={shipping_postcode}
                          disabled={true}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                        {shipping_postcode === "" && isSubmit && (
                          <div className="invalid-feedback">
                            Please enter shipping postcode.
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6 mb-0">
                          <label htmlFor="checkout-billing-address-email">
                            Email address
                          </label>
                          <input
                            type="email"
                            id="checkout-billing-address-email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {email === "" && isSubmit && (
                            <div className="invalid-feedback">
                              Please enter email.
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6 mb-0">
                          <label htmlFor="checkout-billing-address-phone">
                            Phone
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-phone"
                            className="form-control"
                            placeholder="Phone"
                            value={phone}
                            name="phone"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </div>
                        <div className="form-group  col-md-12 mt-2 mb-0">
                          <div className="form-check">
                            <span className="input-check form-check-input">
                              <span className="input-check__body">
                                <input
                                  className="input-check__input"
                                  type="checkbox"
                                  id="sign-in-remember"
                                  name="remember"
                                  checked={checkNewsletter}
                                  onChange={(e) => {
                                    setCheckNewsletter(e.target.checked);
                                    dispatch({
                                      type: TRIGGER_SUBSCRIBE_NEWSLETTER,
                                      subsribeToNewsletter: e.target.checked,
                                    });
                                  }}
                                />
                                <span className="input-check__box"></span>
                                <span className="input-check__icon">
                                  <ReactSVG src={Check12x9Svg} />
                                </span>
                              </span>
                            </span>
                            <label
                              className="form-check-label"
                              htmlFor="sign-in-remember"
                            >
                              Subscribe To Newsletter
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="form-group mx-2">
                    <label htmlFor="checkout-comment">
                      Order notes<span className="text-muted"> (Optional)</span>
                    </label>
                    <textarea
                      id="checkout-comment"
                      className="form-control"
                      placeholder="Note about your order,e.g. special notes for delivery."
                      rows="4"
                      name="order_notes"
                      value={order_notes}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    ></textarea>
                  </div>
                </div>
                <h3 className="card-title mx-2">Billing address</h3>
                <div className="form-group d-block">
                  {!isLogin() && (
                    <div className="form-check mx-2">
                      <span className="input-check form-check-input">
                        <span className="input-check__body">
                          <input
                            className="input-check__input"
                            type="checkbox"
                            id="form-checkout-create-account"
                            name="createAccount"
                            onChange={(e) => onChangeChoice(e)}
                            checked={createNewAc}
                          />
                          <span className="input-check__box"></span>
                          <span className="input-check__icon">
                            <ReactSVG src={Check12x9Svg} />
                          </span>
                        </span>
                      </span>
                      <label
                        className="form-check-label"
                        htmlFor="form-checkout-create-account"
                      >
                        Create an account?
                      </label>
                    </div>
                  )}
                  {createNewAc && (
                    <div className="collapse-area">
                      <div className="pt-2"></div>
                      <p className="login-msg">
                        Create an account by entering the information below.
                        <br /> If you are a returning customer please login.
                      </p>
                      <div className="d-block mb-2 pb-1">
                        <div className="form-group">
                          <label htmlFor="checkout-account-password">
                            Password
                          </label>
                          <input
                            type="password"
                            id="checkout-account-password"
                            className="form-control"
                            disabled=""
                            placeholder="Secret word"
                            name="password"
                            value={password}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {createNewAc && password === "" && isSubmit && (
                            <div className="invalid-feedback">
                              Please enter password.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group mx-2">
                    <div className="form-check">
                      <span className="input-check form-check-input">
                        <span className="input-check__body">
                          <input
                            className="input-check__input"
                            type="checkbox"
                            id="form-checkout-ship-to-different-address"
                            name="shipToDifferentAddress"
                            onChange={(e) => onChangeChoice(e)}
                            checked={shipToDiffAddress}
                          />
                          <span className="input-check__box"></span>
                          <span className="input-check__icon">
                            <svg width="9px" height="7px">
                              <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"></path>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <label
                        className="form-check-label"
                        htmlFor="form-checkout-ship-to-different-address"
                      >
                        Bill to a different address?
                      </label>
                    </div>
                  </div>
                </div>
                {shipToDiffAddress && (
                  <>
                    <div className="form-group d-block">
                      <div className="row">
                        <div className="form-group col-md-12 ">
                          <label htmlFor="checkout-billing-address-country">
                            Country
                          </label>
                          <select
                            id="checkout-billing-address-country"
                            className="form-control"
                            name="billing_country"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            value={billing_country}
                          >
                            <option disabled selected value>
                              Select Country
                            </option>

                            {billingCountry?.data?.length &&
                              countryOptions(billingCountry?.data)}
                          </select>
                          <div className="invalid-feedback"></div>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-billing-address-first-name">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-first-name"
                            className="form-control"
                            placeholder="First Name"
                            name="billing_first_name"
                            value={billing_first_name}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {billing_first_name === "" &&
                            isSubmit &&
                            (expanded === "credit_card" ||
                              expanded === "paypal") && (
                              <div className="invalid-feedback">
                                Please enter billing first name.
                              </div>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-billing-address-last-name">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-last-name"
                            className="form-control"
                            placeholder="Last Name"
                            name="billing_last_name"
                            value={billing_last_name}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {billing_last_name === "" &&
                            isSubmit &&
                            (expanded === "credit_card" ||
                              expanded === "paypal") && (
                              <div className="invalid-feedback">
                                Please enter billing last name.
                              </div>
                            )}
                        </div>

                        <div className="form-group col-md-12">
                          <label htmlFor="checkout-billing-address-company-name">
                            Company Name
                            <span className="text-muted"> (Optional)</span>
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-company-name"
                            className="form-control"
                            placeholder="Company Name"
                            name="billing_company"
                            value={billing_company}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </div>

                        <div className="form-group col-md-12 billing-address">
                          <label htmlFor="checkout-billing-address-address1">
                            Address
                          </label>

                          {/* <GoogleAddressAutocomplete
                            country={
                              billing_country?.toLowerCase() ||
                              country.countryCode
                            }
                            id="checkout-billing-address-address1"
                            key={
                              billing_country?.toLowerCase() ||
                              country.countryCode
                            }
                            type="billing"
                            placeholder="Street Address"
                            onChangeAddress={(e, newValue) =>
                              handleBillingAddress(e, newValue)
                            }
                            addressValue={billing_address}
                          /> */}
                          <input
                            type="text"
                            id="billing_address"
                            className="form-control mt-2"
                            disabled=""
                            placeholder="Street Address"
                            name="billing_address"
                            value={billing_address}
                            onChange={(e) => {
                              handleBillingAddress(e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBillingAddress(e.target.value);
                            }}
                          />

                          {billing_address === "" &&
                            isSubmit &&
                            (expanded === "credit_card" ||
                              expanded === "paypal") && (
                              <div className="invalid-feedback">
                                Please enter billing address.
                              </div>
                            )}

                          <label
                            htmlFor="checkout-billing-address-address2"
                            // className="sr-only"
                            style={{ display: "none" }}
                          >
                            Street Address
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-address2"
                            className="form-control mt-2"
                            placeholder="Apartment, suite, P.O. Box, unit etc.(optional)"
                            name="billing_apartment"
                            value={billing_apartment}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {billing_address &&
                            billing_apartment &&
                            (checkIfAddressIsDifferent(
                              billing_address,
                              billing_apartment
                            ) ||
                              checkIfAddressIsDifferent(
                                billing_apartment,
                                billing_address
                              )) &&
                            isSubmit &&
                            (expanded === "credit_card" ||
                              expanded === "paypal") && (
                              <div className="invalid-feedback">
                                Please enter different addresses.
                              </div>
                            )}
                        </div>

                        <div className="form-group col-md-12">
                          <label htmlFor="checkout-billing-address-city">
                            Town / City
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-city"
                            className="form-control"
                            placeholder="Town / City"
                            name="billing_town"
                            value={billing_town}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {billing_town === "" &&
                            isSubmit &&
                            (expanded === "credit_card" ||
                              expanded === "paypal") && (
                              <div className="invalid-feedback">
                                Please enter billing town.
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-billing-address-state">
                            State / Province / Country
                          </label>
                          <select
                            id="checkout-billing-address-state"
                            className="form-control"
                            name="billing_state"
                            disabled={
                              (billingState?.data?.length == 0 &&
                                !billingState?.loading) ||
                              billing_country == "PR" ||
                              billing_country == "FR" ||
                              billing_country == "GB"
                            }
                            value={billing_state}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          >
                            {billingState?.data?.length == 0 &&
                            !billingState?.loading ? (
                              <option hidden selected value>
                                Not Applicable
                              </option>
                            ) : (
                              <option hidden selected value>
                                Select Province
                                {/* {billing_state == "PR"
                                  ? "Select Municipality"
                                  : billing_state == "FR"
                                  ? "Select Region"
                                  : "Select Province"} */}
                              </option>
                            )}
                            {billingState?.data?.length &&
                              billingState?.data?.map((e, i) => {
                                return (
                                  <option
                                    value={e.sortname}
                                    key={i}
                                    name={e.name}
                                    id={e}
                                  >
                                    {e.name}
                                  </option>
                                );
                              })}
                          </select>
                          {billing_state === "" && isSubmit && (
                            <div className="invalid-feedback">
                              Please enter billing state.
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-billing-address-postcode">
                            Postcode / Zip
                          </label>
                          <input
                            type="text"
                            id="checkout-billing-address-postcode"
                            className="form-control"
                            placeholder="Postcode / Zip"
                            name="billing_postcode"
                            value={billing_postcode}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          {billing_postcode === "" &&
                            isSubmit &&
                            expanded === "credit_card" && (
                              <div className="invalid-feedback">
                                Please enter postcode.
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
            <div className="card mb-0">
              <div className="card-body card-body--padding--2">
                <h3 className="card-title">Your Order</h3>
                <table className="checkout__totals">
                  <thead className="checkout__totals-header">
                    <tr>
                      <th>Product</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody className="checkout__totals-products">
                    {cart.map((product, i) => {
                      return (
                        <tr key={i}>
                          <td className="product_name_">
                            {product?.name} × {product?.quantity}
                          </td>
                          <td>
                            $
                            {(
                              Number(product.regular_price) *
                              Number(product.quantity)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table className="checkout__totals">
                  <tbody className="checkout__totals-subtotals">
                    <tr>
                      <th>Subtotal</th>
                      <td className="sub-mob">${Number(total).toFixed(2)}</td>
                    </tr>
                    {redeemPoints?.data?.redeem_points_amount && (
                      <tr>
                        <th>Points Redemption</th>
                        <td>
                          -${redeemPoints?.data?.redeem_points_amount}
                          <button
                            className="btn  btn-outline-primary  btn-sm ml-2"
                            onClick={removePoints}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>Shipping</th>
                      <td className="shipping-row">
                        {shippingMethods}

                        {isSubmit &&
                          (shippingMethod?.shippingCharges == "" ||
                            shippingMethod?.shippingCharges == "0.00") &&
                          shippingMethod?.shippingName == "" && (
                            <div className="invalid-feedback">
                              Please select shipping method.
                            </div>
                          )}
                      </td>
                    </tr>
                    {calculateShippingDetails?.country && (
                      <tr>
                        <th className="shipping-details-title">
                          Shipping Country
                        </th>
                        <td className="shipping-details ">
                          {calculateShippingDetails?.country}
                        </td>
                      </tr>
                    )}
                    {calculateShippingDetails?.state && (
                      <tr className="shipping-details-title">
                        <th>
                          Shipping State
                        </th>
                        <td className="shipping-details">
                          {calculateShippingDetails?.state}
                        </td>
                      </tr>
                    )}

                    {calculateShippingDetails?.postcode && (
                      <tr>
                        <th className="shipping-details-title">
                          Shipping Postcode
                        </th>
                        <td className="shipping-details">
                          {calculateShippingDetails?.postcode}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th>Tax</th>
                      <td>{Number(tax).toFixed(2)}</td>
                    </tr>

                    {couponDetails?.data?.discount_price && (
                      <tr>
                        <th>Discount</th>
                        <td>
                          {couponDetails?.data?.discount_price}{" "}
                          <button
                            className="btn  btn-danger  btn-sm ml-2"
                            onClick={removeCoupon}
                          >
                            Remove Coupon
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot className="checkout__totals-footer">
                    <tr>
                      <th>Total</th>
                      <td>
                        $
                        {calculateTotal(
                          total,
                          tax,
                          shippingMethod?.shippingCharges,
                          redeemPoints?.data?.redeem_points_amount,
                          couponDetails?.data?.discount_price
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div className="checkout__payment-methods payment-methods">
                  <ul className="payment-methods__list">
                    <li className="payment-methods__item">
                      <Accordion
                        square
                        expanded={expanded === "credit_card"}
                        onChange={handleChange("credit_card")}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <label className="payment-methods__item-header">
                            <span className="input-radio payment-methods__item-radio">
                              <span className="input-radio__body">
                                <span
                                  className={
                                    "input-radio__circle " +
                                    (expanded === "credit_card"
                                      ? "input-radio__check"
                                      : "")
                                  }
                                ></span>
                              </span>
                            </span>
                            <span className="payment-methods__item-title">
                              Credit Card
                            </span>
                          </label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="payment-methods__item-container w-100">
                            <div className="payment-methods__item-details text-muted">
                              Pay securely using your credit card.
                              <div className="card px-3 pt-2 mt-2">
                                <div className="form-group">
                                  <label htmlFor="checkout-billing-address-first-name">
                                    Card Holder Name *
                                  </label>
                                  <input
                                    type="text"
                                    id="checkout-billing-address-first-name"
                                    className="form-control"
                                    placeholder="Card Holder Name"
                                    name="card_holder_name"
                                    value={card_holder_name}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                    }}
                                  />
                                  {card_holder_name === "" && isSubmit && (
                                    <div className="invalid-feedback">
                                      Please enter credit card holder name.
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="credit_card_number">
                                    Card Number *
                                  </label>
                                  <InputMask
                                    mask="9999 9999 9999 9999"
                                    placeholder="•••• •••• •••• ••••"
                                    id="credit_card_number"
                                    name="credit_card_number"
                                    className="form-control"
                                    value={credit_card_number}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                    }}
                                  ></InputMask>
                                  {credit_card_number === "" && isSubmit && (
                                    <div className="invalid-feedback">
                                      Please enter credit card number.
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="credit_card_date">
                                    Expiration (MM/YY) *
                                  </label>
                                  <InputMask
                                    mask="99/99"
                                    id="credit_card_date"
                                    placeholder="MM/YY"
                                    name="credit_card_date"
                                    className="form-control"
                                    value={credit_card_date}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                    }}
                                  ></InputMask>
                                  {credit_card_date === "" && isSubmit && (
                                    <div className="invalid-feedback">
                                      Please enter credit card date.
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="credit-validation-code">
                                    Card Security Code *
                                  </label>
                                  <input
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        handleInputChange(e);
                                      }
                                    }}
                                    type="text"
                                    id="credit-validation-code"
                                    className="form-control"
                                    placeholder="CSE"
                                    name="credit_validation_code"
                                    value={credit_validation_code}
                                    maxLength="4"
                                  />
                                  {credit_validation_code === "" &&
                                    isSubmit && (
                                      <div className="invalid-feedback">
                                        Please enter credit card security code.
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                    <li className="payment-methods__item">
                      <Accordion
                        square
                        expanded={expanded === "paypal"}
                        onChange={handleChange("paypal")}
                      >
                        <AccordionSummary
                          aria-controls="paypald-content"
                          id="paypald-header"
                        >
                          <label className="payment-methods__item-header">
                            <span className="input-radio payment-methods__item-radio">
                              <span className="input-radio__body">
                                <span
                                  className={
                                    "input-radio__circle " +
                                    (expanded === "paypal"
                                      ? "input-radio__check"
                                      : "")
                                  }
                                ></span>
                              </span>
                            </span>
                            <span className="payment-methods__item-title">
                              PayPal
                            </span>
                          </label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="payment-methods__item-container">
                            <div className="payment-methods__item-details text-muted">
                              What is PayPal? - Pay via PayPal; you can pay with
                              your credit card if you don’t have a PayPal
                              account
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>
                </div>
                <div className="form-group g-recaptcha">
                  <ReCAPTCHA
                    ref={(r) => setCaptchaRef(r)}
                    sitekey={config.captchaSiteKey}
                    onChange={verifyCaptcha}
                    onErrored={handleCaptchaError}
                    onExpired={handleCaptchaExpired}
                  />
                  {isSubmit && !captcha && (
                    <div className="invalid-feedback">
                      Please verify captcha!
                    </div>
                  )}
                </div>
                <div className="checkout__agree form-group">
                  <div className="form-check">
                    <span className="input-check form-check-input">
                      <span className="input-check__body">
                        <input
                          className="input-check__input"
                          type="checkbox"
                          id="checkout-form-agree"
                          name="acceptTerms"
                          checked={acceptTerms}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />

                        <span className="input-check__box"></span>
                        <span className="input-check__icon">
                          <ReactSVG src={Check12x9Svg} />
                        </span>
                      </span>
                    </span>
                    <label
                      className="form-check-label"
                      htmlFor="checkout-form-agree"
                    >
                      {/* <span className="mr-1">
                        I have read and agree to the website
                      </span> */}
                      <Link to="/privacy-terms">
                        I've read and accept the Terms &amp; Conditions.
                      </Link>
                    </label>
                  </div>

                  {!acceptTerms && isSubmit && (
                    <div className="invalid-feedback">
                      Please accept terms & conditions.
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center">
                  <div
                    style={{
                      display: !isValid ? "none" : "block",
                    }}
                    className="mt-1"
                  >
                    <PayPalScriptProvider options={config.payPalOptions}>
                      <PayPalButtons
                        style={{ layout: "horizontal", height: 36 }}
                        createOrder={createOrder}
                        onClick={onClick}
                        onCancel={onCancel}
                        onApprove={(data, actions) =>
                          onApprove(data, actions, expressCheckout, checkoutObj)
                        }
                        onError={onError}
                        forceReRender={[
                          total,
                          shippingMethod?.shippingCharges,
                          redeemPoints?.data?.redeem_points_amount,
                          couponDetails?.data?.discount_price,
                          tax,
                          checkoutObj,
                        ]}
                      />
                    </PayPalScriptProvider>
                  </div>

                  <button
                    style={{
                      display: isValid ? "none" : "block",
                    }}
                    type="submit"
                    className="btn btn-primary"
                    disabled={checkout?.loading}
                  >
                    {/* <div className="d-flex align-items-center">
                      <div className="mr-1"> Submit</div>
                      {isSubmit && checkout?.loading && (
                        <CircularLoader size={15} />
                    </div>
                      )} */}
                    Submit
                  </button>

                  <button type="button" className="btn btn-secondary ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
