import React from "react";
import { useSelector } from "react-redux";
import config from "../../utils/config";
import { Link } from "react-router-dom";

export default function DropWishList({close}) {
  const { wishList } = useSelector((state) => state.shopReducer);


  return (
    <div className="dropcart position-relative">
      {wishList?.data?.length > 0  && (
        <div>
          <ul className="dropcart__list">
            {wishList.data
              .map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <li className="dropcart__item" key={item.id}>
                      <div className="dropcart__item-image image image--type--product">
                        <Link
                          className="image__body"
                          to={`/product/${item?.product_detail?.slug}`} onClick={close}
                        >
                          <img
                            alt=""
                            className="image__tag"
                            src={item?.product_detail?.featured_image ? `${config.imageBasePath}${item?.product_detail?.featured_image}`  :"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                          />
                        </Link>
                      </div>
                      <div className="dropcart__item-info">
                        <div className="dropcart__item-name">
                          <Link
                            to={`/product/${item?.product_detail?.slug}`} onClick={close}
                          >
                            {item?.product_detail?.name}{item?.product_detail?.id}
                          </Link>
                        </div>
                        <div className="dropcart__item-meta">
                          <div className="dropcart__item-quantity">{1}</div>
                          <div className="dropcart__item-price">

                            $
                            {Number(item.product_detail.regular_price).toFixed(
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="dropcart__divider" role="presentation"></li>
                  </React.Fragment>
                );
              })
              .filter((a, i) => i < 5)}
          </ul>

          <div className="dropcart__actions">
            <Link className="btn btn-secondary" to="/my-account/wishlist" onClick={close}>
              View All
            </Link>
          </div>
        </div>
      ) }

      { wishList?.data?.length===0  &&
        <div className="dropcart__empty">Your wishlist is empty!</div>
      }
    </div>
  );
}
