import React from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Profile from "./Profile";
import Rewards from "./Rewards";
import Wishlist from "./Wishlist";
import BlockSpace from "../../components/blocks/BlockSpace";
import { useDispatch, useSelector } from "react-redux";
import { triggerLogout } from "../../redux/actions";
import { TRIGGER_WISH_LIST_SUCCESS } from "../../redux/actions/actionTypes";
import OrderDetails from "./OrderDetails";
export default function AccountLayout() {
  const dispatch = useDispatch();
  // const navigate = useHistory();
  const history = useHistory();
  const logout = () => {
    history.push("/");
    return dispatch(
      triggerLogout({}, () => {
        dispatch({
          type: TRIGGER_WISH_LIST_SUCCESS,
          wishList: { loading: false, data: [], error: null },
        });
      })
    );
  };
  return (
    <div className="container account-layout">
      <BlockSpace className="block-space--layout--before-footer" />
      <div className="row width1">
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="account-nav flex-grow-1">
            <h4 className="account-nav__title">Account Details</h4>
            <div
              className="account-nav__divider mt-0"
              role="presentation"
            ></div>
            <ul className="account-nav__list">
              <li className="account-nav__item">
                <NavLink
                  activeClassName="account-nav__item--active"
                  to="/my-account/dashbord"
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="account-nav__item">
                <NavLink
                  activeClassName="account-nav__item--active"
                  to="/my-account/profile"
                >
                  My Account
                </NavLink>
              </li>
              <li className="account-nav__item">
                <NavLink
                  activeClassName="account-nav__item--active"
                  to="/my-account/orders"
                >
                  My Orders
                </NavLink>
              </li>
              <li className="account-nav__item">
                <NavLink
                  activeClassName="account-nav__item--active"
                  to="/my-account/wishlist"
                >
                  My Wish List
                </NavLink>
              </li>
              <li className="account-nav__item">
                <NavLink
                  activeClassName="account-nav__item--active"
                  to="/my-account/rewards"
                >
                  My Points & Rewards
                </NavLink>
              </li>
              <li className="account-nav__divider" role="presentation"></li>
              <li className="account-nav__item">
                <button type="button" onClick={() => logout()}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 my-account-div ">
          <Switch>
            <Route path="/my-account/dashbord" component={Dashboard} />
            <Route exact path="/my-account/profile" component={Profile} />
            <Route
              exact
              path="/my-account/orders/:page?/:pageNo?"
              component={Orders}
            />
            <Route
              exact
              path="/my-account/order/:id"
              component={OrderDetails}
            />

            <Route exact path="/my-account/wishlist" component={Wishlist} />
            <Route path="/my-account/rewards" component={Rewards} />
          </Switch>
        </div>
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
