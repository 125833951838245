import React from 'react'

export default function BlockMap() {
    return (
        <div className="block block-map">
            <div className="block-map__body">
            <iframe title="Google Map" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2001%20Lakeshore%20Dr,%20Millville,%20NJ%2008332,%20USA+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" style={{'width': '100%','height':'100%'}}></iframe>
            </div>
        </div>
    )
}
