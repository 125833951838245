import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/pages/ProductDetails.scss";
import { getProductDetails } from "../../redux/actions";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import Breadcrumb from "../shared/Breadcrumb";
import ImageSlider from "../shared/ImageSlider";
import config from "../../utils/config";
import CircularLoader from "../shared/CircularLoader";
import useCounter from "../../hooks/useCounter";
import useAddToWishListHook from "../../hooks/useAddToWishList";
import { ReactSVG } from "react-svg";
import { Wishlist16Svg, HeartOutlineSvg } from "../../svg/index";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";
import { isLogin } from "../../utils";
import useCartHook from "../../hooks/useCartHook";
import AlertMessage from "../shared/AlertMessage";
import useRemoveFromWishlistHook from "../../hooks/useRemoveFromWishlist";
import useSetMetaTags from "../../hooks/useSetMetaTags";

export default function ProductDetailsPage(props) {
  const dispatch = useDispatch();
  const { productDetails, addProductToWishList, wishList } = useSelector(
    (state) => state.shopReducer
  );
  const productId = props.match.params.id;
  const [count, incrementCount, decrementCount] = useCounter();
  const { total } = useSelector((state) => state.cartReducer);
  const [images, setImages] = useState([]);
  const [markWishList, setMarkWishList] = useState(false);
  const [addProductToWish] = useAddToWishListHook();
  const [removeFromWishList] = useRemoveFromWishlistHook();
  const [setMetaTags] = useSetMetaTags();
  const [userData] = useUserDetailsHook();
  const [alertMsg, setAlertMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
  ] = useCartHook();

  useEffect(() => {
    if (isLogin() && wishList?.data?.length) {
      let list = wishList?.data;
      list.forEach((item) => {
        if (
          item?.product_detail?.id === productDetails?.data?.product_detail?.id
        ) {
          setMarkWishList(true);
        }
      });
    }
  }, [wishList, productDetails]);

  useEffect(() => {
    dispatch(getProductDetails({ product_id: productId }));
  }, [productId]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [total]);

  useEffect(() => {
    setMetaTags(
      productDetails?.data?.product_detail?.page_title,
      productDetails?.data?.product_detail?.page_description,
      productDetails?.data?.product_detail?.page_keyword
    );
    let imageArray = [];
    if (productDetails.data?.product_images?.length > 0) {
      productDetails.data?.product_images.map((item, i) => {
        return imageArray.push({
          original:item?.image? `${config.imageBasePath}${item?.image}`:`https://www.vanagain.com/public/assets/uploads/no_img.gif`,
          thumbnail: item?.image?`${config.imageBasePath}${item?.image}`:`https://www.vanagain.com/public/assets/uploads/no_img.gif`,
          alt: `${productDetails?.data?.product_detail?.name}`,
        });
      });

      setImages(imageArray);
    } else {
      setImages([
        {
          original: productDetails?.data?.product_detail?.featured_image
            ? `${config.imageBasePath}${productDetails?.data?.product_detail?.featured_image}`
            : `https://www.vanagain.com/public/assets/uploads/no_img.gif`,
          thumbnail: productDetails?.data?.product_detail?.featured_image
            ? `${config.imageBasePath}${productDetails?.data?.product_detail?.featured_image}`
            : `https://www.vanagain.com/public/assets/uploads/no_img.gif`,
          alt: `${productDetails?.data?.product_detail?.name}`,
        },
      ]);
    }
  }, [productDetails]);

  const showAlertMsg = () => {
    setAlertMsg(true);
  };

  const hideAlertMsg = () => {
    setAlertMsg(false);
  };

  const addToWishList = (product) => {
    if (isLogin()) {
      addProductToWish(userData.id, product);
    } else {
      showAlertMsg();
    }
  };

  const addToCart = (product) => {
    setLoading(true);
    addToCartProduct(product, count);
  };
  return (
    <div className=" product-details-page position-relative">
      {showAlertMsg && <AlertMessage isOpen={alertMsg} close={hideAlertMsg} />}

      {!productDetails?.loading ? (
        <>
          <Breadcrumb
            list={[
              {
                title: productDetails?.data?.product_detail?.name,
                url: "",
              },
            ]}
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 pl-1">
                <div className="row product-card-full-page">
                  <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                    <ImageSlider images={images} />

                    <div id="productGalleyMagnify"></div>
                  </div>

                  <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="product__header">
                      <h1 className="product__title">
                        {productDetails?.data?.product_detail?.name}
                      </h1>
                    </div>
                    <div className="product__main">
                      <div className="product__excerpt"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 col-4 pr-0 product-add-to-cart">
                <div className="product__features product-card-full-page">
                  <form className="product__info-card">
                    <div className="product__info-body">
                      <div className="product__prices-stock ">
                        <div className="product__prices">
                          <div className="product__price product__price--current">
                            $
                            {
                              productDetails?.data?.product_detail
                                ?.regular_price
                            }
                          </div>
                        </div>
                        <div className="status-badge status-badge--style--success status-badge--has-text product__stock">
                          <div className="status-badge__body">
                            {!productDetails?.data?.product_detail
                              ?.stock_qty ? (
                              <div className="status-badge__text_out">
                                Out of Stock
                              </div>
                            ) : (
                              <>
                                <div className="status-badge__text">
                                  In Stock
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="product__meta animate__fadeInDownBig">
                        <table>
                          <tbody>
                            <tr>
                              <th>SKU</th>
                              <td>
                                {productDetails?.data?.product_detail?.sku}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="product__actions">
                      <div className="product__actions-item product__actions-item--quantity">
                        <div className="input-number">
                          <input
                            type="number"
                            className="form-control input-number__input form-control-lg"
                            min="1"
                            name="quantity"
                            readOnly
                            value={count}
                          />
                          <div
                            className="input-number__add"
                            onClick={incrementCount}
                          ></div>
                          <div
                            className="input-number__sub"
                            onClick={decrementCount}
                          ></div>
                        </div>
                      </div>
                      <div className="product__actions-item product__actions-item--addtocart ">
                        <button
                          type="button"
                          onClick={() => {
                            addToCart(productDetails?.data?.product_detail);
                          }}
                          className="btn btn-primary btn-lg btn-block"
                          disabled={
                            !productDetails?.data?.product_detail?.stock_qty ||
                            loading
                          }
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {loading && <CircularLoader size={20} />}
                            <div className="ml-2">Add to cart</div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-add-wishlist btn-secondary"
                        onClick={() =>
                          !markWishList
                            ? addToWishList(
                                productDetails?.data?.product_detail
                              )
                            : removeFromWishList(
                                productDetails?.data?.product_detail,
                                setMarkWishList
                              )
                        }
                      >
                        <div className="d-flex align-items-center">
                          {addProductToWishList.loading ? (
                            <CircularLoader size={12} color="#6c757d" />
                          ) : (
                            <ReactSVG
                              src={
                                markWishList ? Wishlist16Svg : HeartOutlineSvg
                              }
                            />
                          )}
                          <div className="ml-2">Add to wishlist</div>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-xl-12   product-tabs product-tabs--layout--full product__tabs">
                <div className=" row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <ul className="product-tabs__list">
                      <span className="product-tabs__item">
                        Product Description
                      </span>
                    </ul>
                    <div className="product-tabs__content">
                      <div className="typography">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              productDetails?.data?.product_detail?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 container px-0">
            <BlockProductsCarousel
              title={"Related Products"}
              productList={productDetails?.data?.related_products}
              category={productDetails?.data?.related_product_category}
            />
          </div>
        </>
      ) : (
        <div className="center-div">
          <CircularLoader size={60} color={"#32c2ef"} type="overlay-loader" />
        </div>
      )}
    </div>
  );
}
