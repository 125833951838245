import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/scss/pages/Testimonials.scss";
import config from "../../utils/config";
import { Rating } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TestimonialDetails({
  open,
  setShowDialog,
  testimonialDetails,
}) {
  const {
    client_name,
    job_title,
    rating,
    featured_image,
    state,
    country,
    description,
  } = testimonialDetails;

  const handleClose = () => {
    setShowDialog(false);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  return (
    <div className="testimonial-details">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle />
        <DialogContent>
          <div className="testimonial__body">
            <div className="block-testimonial__item">
              <div className="block-testimonials__grid">
                <div className="block-testimonial__item-avatar">
                  <img
                    alt={client_name}
                    src={
                      featured_image
                        ? `${config.imageBasePath}${featured_image}`
                        : "https://www.vanagain.com/public/assets/uploads/no_img.gif"
                    }
                  />
                </div>
                <div>
                  <div className="block-reviews__item-author">
                    {client_name}
                  </div>
                  <div className="block-testimonial__field">{job_title}</div>

                  <div className="block-testimonial__address">
                    {state},{country}
                  </div>
                  <div className="block-reviews__item-rating">
                    <div className="rating">
                      <div className="rating__body">
                        <Rating count={rating} readOnly value={rating} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div
                className="block-reviews__item-text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
