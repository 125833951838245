import {
  TRIGGER_CONTACT_US,
  TRIGGER_CONTACT_US_SUCCESS,
  TRIGGER_CONTACT_US_FAILED,
  TRIGGER_CONTACT,
  TRIGGER_CONTACT_SUCCESS,
  TRIGGER_CONTACT_FAILED,
} from "../actions/actionTypes";

const initialState = {
  contactUsObj: {
    loading: false,
    data: [],
    error: null,
  },
  contactObj: {
    loading: false,
    data: [],
    error: null,
  },
};

const contactUsReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_CONTACT_US:
      return {
        ...state,
        contactUsObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_CONTACT_US_FAILED:
      return {
        ...state,
        contactUsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case TRIGGER_CONTACT:
      return {
        ...state,
        contactObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CONTACT_SUCCESS:
      return {
        ...state,
        contactObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_CONTACT_FAILED:
      return {
        ...state,
        contactObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default contactUsReducer;
