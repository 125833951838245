import {
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED,
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS,
  TRIGGER_GET_PRODUCTS_BY_CATEGORIES,
  TRIGGER_NO_OF_PRODUCTS,
  TRIGGER_GET_PRODUCT_DETAILS,
  TRIGGER_GET_PRODUCT_DETAILS_SUCCESS,
  TRIGGER_GET_PRODUCT_DETAILS_FAILED,
  TRIGGER_VAN_CATEGORY,
  TRIGGER_VAN_TYPE,
  TRIGGER_SKU_KEYWORD,
  TRIGGER_ADD_TO_WISH_LIST,
  TRIGGER_ADD_TO_WISH_LIST_SUCCESS,
  TRIGGER_ADD_TO_WISH_LIST_FAILED,
  TRIGGER_WISH_LIST,
  TRIGGER_WISH_LIST_SUCCESS,
  TRIGGER_WISH_LIST_FAILED,
  TRIGGER_REMOVE_FROM_WISH_LIST,
  TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS,
  TRIGGER_REMOVE_FROM_WISH_LIST_FAILED,
  TRIGGER_SEARCH_KEYWORD,
  TRIGGER_GET_POINTS,
  TRIGGER_GET_POINTS_SUCCESS,
  TRIGGER_GET_POINTS_FAILED,
  TRIGGER_ORDERS,
  TRIGGER_ORDERS_SUCCESS,
  TRIGGER_ORDERS_FAILED,
  TRIGGER_ORDERS_STATUS,
  TRIGGER_ORDERS_STATUS_SUCCESS,
  TRIGGER_ORDERS_STATUS_FAILED,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILED,
  TRIGGER_GET_ORDER_DETAILS,
  TRIGGER_GET_ORDER_DETAILS_FAILED,
  TRIGGER_GET_ORDER_DETAILS_SUCCESS,
  TRIGGER_VIEW_TYPE,
  TRIGGER_QUICK_VIEW_DETAILS,TRIGGER_QUICK_VIEW_DETAILS_SUCCESS ,TRIGGER_QUICK_VIEW_DETAILS_FAILED
} from "../actions/actionTypes";

const initialState = {
  productsList: {
    loading: false,
    data: [],
    error: null,
  },
  noOfProductsPerPage: 12,
  productDetails: {
    loading: true,
    data: {},
    error: null,
  },

  productQuickViewDetails: {},

  skuKeyword: "",
  vanTypeName: "",
  vanCategory: "",
  searchKey: "",

  addProductToWishList: {
    loading: false,
    data: [],
    error: null,
  },
  wishList: {
    loading: false,
    data: [],
    error: null,
  },
  removeProductFromWishList: {
    loading: false,
    data: [],
    error: null,
  },

  points: {
    loading: false,
    data: [],
    error: null,
  },

  orders: {
    loading: false,
    data: [],
    error: null,
  },

  orderStatus: {
    loading: false,
    data: [],
    error: null,
  },

  orderList:{
    loading: false,
    data: [],
    error: null,
  },
  typeofView:"grid",
   orderDetails:{
    loading: false,
    data: [],
    error: null,
   },

   quickViewDetails:{
    loading: false,
    data: [],
    error: null,
   }
};

const shopReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_GET_PRODUCTS_BY_CATEGORIES:
      return {
        ...state,
        productsList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        productsList: {
          loading: false,
          data: action.payload.products,
          message: action.payload.message,
          error: null,
        },
      };

    case TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED:
      return {
        ...state,
        productsList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_NO_OF_PRODUCTS:
      return {
        ...state,
        noOfProductsPerPage: action.noOfProductsPerPage,
      };

    case TRIGGER_VAN_CATEGORY:
      return {
        ...state,
        vanCategory: action.vanCategory,
      };

    case TRIGGER_VAN_TYPE:
      return {
        ...state,
        vanTypeName: action.vanTypeName,
      };

    case TRIGGER_SKU_KEYWORD:
      return {
        ...state,
        skuKeyword: action.skuKeyword,
      };

    case TRIGGER_SEARCH_KEYWORD: {
      return {
        ...state,
        searchKey: action.searchKey,
      };
    }

    case TRIGGER_VIEW_TYPE:{
      return {
        ...state,
        typeOfView: action.typeOfView,
      };
    }

    case TRIGGER_GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: {
          loading: true,
          data: {},
          error: null,
        },
      };

    case TRIGGER_GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: {
          loading: false,
          data: action.payload.product,
          error: null,
        },
      };

    case TRIGGER_GET_PRODUCT_DETAILS_FAILED:
      return {
        ...state,
        productDetails: {
          loading: false,
          data: action.error,
          error: null,
        },
      };

    case TRIGGER_ADD_TO_WISH_LIST:
      return {
        ...state,
        addProductToWishList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ADD_TO_WISH_LIST_SUCCESS:
      return {
        ...state,
        addProductToWishList: {
          loading: false,
          data: action.payload,
          error: null,
        },

        wishList:{
          loading: false,
          data: action.payload.wishlists,
          error: null,
        }
      };

    case TRIGGER_ADD_TO_WISH_LIST_FAILED:
      return {
        ...state,
        addProductToWishList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_WISH_LIST:
      return {
        ...state,
        wishList: {
          loading: true,
          data: state?.wishList?.data,
          error: null,
        },
      };

    case TRIGGER_WISH_LIST_SUCCESS:
      return {
        ...state,
        wishList: {
          loading: false,
          data: action.payload.wishlists,
          error: null,
        },
      };

    case TRIGGER_WISH_LIST_FAILED:
      return {
        ...state,
        wishList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_REMOVE_FROM_WISH_LIST:

      return {
        ...state,
        removeProductFromWishList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS:
      return {
        ...state,
        removeProductFromWishList: {
          loading: false,
          data: action.payload,
          error: null,
        },
        wishList:{
          loading: false,
          data: action.payload.wishlists,
          error: null,
        }
      };

    case TRIGGER_REMOVE_FROM_WISH_LIST_FAILED:
      return {
        ...state,
        removeProductFromWishList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_POINTS:
      return {
        ...state,
        points: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_POINTS_SUCCESS:
      return {
        ...state,
        points: {
          loading: false,
          data: action.payload.points,
          error: null,
        },
      };

    case TRIGGER_GET_POINTS_FAILED:
      return {
        ...state,
        points: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_ORDERS:
      return {
        ...state,
        orders: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.payload.orders,
          error: null,
        },
      };

    case TRIGGER_ORDERS_FAILED:
      return {
        ...state,
        orders: {
          loading: false,
          data: null,
          error: action.error,
        },
      };


    case TRIGGER_ORDERS_STATUS:
      return {
        ...state,
        orderStatus: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ORDERS_STATUS_SUCCESS:
      return {
        ...state,
        orderStatus: {
          loading: false,
          data: action.payload.orderData.data,
          error: null,
        },
      };

    case TRIGGER_ORDERS_STATUS_FAILED:
      return {
        ...state,
        orderStatus: {
          loading: false,
          data: null,
          error: action.error,
        },
      };


      case GET_ORDERS_LIST:
        return {
          ...state,
          orderList: {
            loading: true,
            data: [],
            error: null,
          },
        };

      case GET_ORDERS_LIST_SUCCESS:
        return {
          ...state,
          orderList: {
            loading: false,
            data: action.payload.orders,
            error: null,
          },
        };

      case GET_ORDERS_LIST_FAILED:
        return {
          ...state,
          orderList: {
            loading: false,
            data: null,
            error: action.error,
          },
        };

        case TRIGGER_GET_ORDER_DETAILS:
          return {
            ...state,
            orderDetails: {
              loading: true,
              data: [],
              error: null,
            },
          };

        case TRIGGER_GET_ORDER_DETAILS_SUCCESS:
          return {
            ...state,
            orderDetails: {
              loading: false,
              data: action.payload,
              error: null,
            },
          };

        case TRIGGER_GET_ORDER_DETAILS_FAILED:
          return {
            ...state,
            orderDetails: {
              loading: false,
              data: null,
              error: action.error,
            },
          };



        case TRIGGER_QUICK_VIEW_DETAILS:
          return {
            ...state,
            quickViewDetails: {
              loading: true,
              data: [],
              error: null,
            },
          };

        case TRIGGER_QUICK_VIEW_DETAILS_SUCCESS:
          return {
            ...state,
            quickViewDetails: {
              loading: false,
              data: action.payload.product,
              error: null,
            },
          };

        case TRIGGER_QUICK_VIEW_DETAILS_FAILED:
          return {
            ...state,
            quickViewDetails: {
              loading: false,
              data: null,
              error: action.error,
            },
          };




    default:
      return state;
  }
};

export default shopReducer;
