import React from "react";
import { Link } from "react-router-dom";
import Arrow from "./Arrow";

export default function SectionHeader({
  sectionTitle,
  links = [],
  next,
  previous,
  onChangeGroup,
  currentGroup,
  list,
}) {
  return (
    <div className="section-header">
      <div className="section-header__body">
        {sectionTitle && (
          <h2 className="section-header__title mb-2">{sectionTitle}</h2>
        )}

        <div className="section-header__spring" />

        {list?.length === 0 && links?.length > 0 && (
          <ul className="section-header__links">
            {links?.map((link, index) => (
              <li key={index} className="section-header__links-item">
                <Link href={link.url} className="section-header__links-link">
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        )}

        {list?.length > 0 && (
          <ul className="section-header__groups">
            {list.map((group, index) => (
              <li key={index} className="section-header__groups-item">
                <button
                  className={
                    "section-header__groups-button " +
                    (JSON.stringify(group) === JSON.stringify(currentGroup)
                      ? "section-header__groups-button--active"
                      : "=")
                  }
                  type="button"
                  onClick={() => onChangeGroup(group)}
                >
                  {group.name}
                </button>
              </li>
            ))}
          </ul>
        )}

        <div className="section-header__arrows">
          <Arrow
            className="section-header__arrow section-header__arrow--prev"
            direction="prev"
            onClickPrev={previous}
          />
          <Arrow
            className="section-header__arrow section-header__arrow--next"
            onClickNext={next}
            direction="next"
          />
        </div>
      </div>
    </div>
  );
}
