import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
 TRIGGER_PROFILE_UPDATE,
 TRIGGER_PROFILE_UPDATE_SUCCESS,
 TRIGGER_PROFILE_UPDATE_FAILED
} from "./actionTypes";



export function triggerProfileUpdate(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlProfileUpdate}`,
        dispatch,
        defaultAction:  TRIGGER_PROFILE_UPDATE,
        successAction:  TRIGGER_PROFILE_UPDATE_SUCCESS,
        failedAction:  TRIGGER_PROFILE_UPDATE_FAILED,
        type: "POST",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }