import {
  PRIVACY_AND_TERMS,
  PRIVACY_AND_TERMS_SUCCESS,
  PRIVACY_AND_TERMS_FAILED,
} from "../actions/actionTypes";
const initialState = {
  privacyAndTermsObj: {
    loading: false,
    data: [],
    error: null,
  },
};

const privacyAndTermsReducer = function (state = initialState, action) {
  switch (action.type) {
    case PRIVACY_AND_TERMS:
      return {
        ...state,
        privacyAndTermsObj: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case PRIVACY_AND_TERMS_SUCCESS:
      return {
        ...state,
        privacyAndTermsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case PRIVACY_AND_TERMS_FAILED:
      return {
        ...state,
        privacyAndTermsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default privacyAndTermsReducer;
