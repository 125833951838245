import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/pages/header/menuStyle.scss";
import { NavLink } from "react-router-dom";
import config from "../../utils/config";
import { ArrowRoundedRight7x11Svg } from "../../svg/index";
import { ReactSVG } from "react-svg";

export default function SubMenuItems({ menus }) {
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const { mainMenuList } = useSelector((state) => state.mainMenuReducer);

  const showSubMenu = (catagory) => {
    setSelectedSubMenu(catagory);
  };

  const hideSubMenu = () => {
    setSelectedSubMenu(null);
  };

  return (
    <>
      <div className="departments">
        {
          <div
            className="departments__menu  collapse-horizontal"
            id="collapse_departments__menu"
          >
            <div className="departments__body">
              <ul className="departments__list">
                {mainMenuList?.data?.map((catagory) => {
                  return (
                    <li
                      key={catagory?.title}
                      className="departments__item departments__item--has-submenu departments__item--submenu--megamenu"
                      onMouseEnter={() => showSubMenu(catagory)}
                      onMouseLeave={() => hideSubMenu()}
                    >
                      <NavLink
                        className="departments__item-link"
                        to={{
                          pathname: `/categories/${catagory?.slug}`,
                          state: {
                            title: catagory?.title,
                            vanTypeSlug: catagory?.slug,
                          },
                        }}
                      >
                        <div className="d-flex align-items-center ">
                          <div className="van-image">
                            <img
                              src={catagory?.thumbnail ? `${config.imageBasePath}${catagory?.thumbnail}` :"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                              alt={catagory?.title}
                            />
                          </div>
                          <div className="ml-3"> {catagory?.title}</div>
                        </div>
                        <span className="departments__item-arrow">
                          <ReactSVG src={ArrowRoundedRight7x11Svg} />
                        </span>
                      </NavLink>
                      {
                        <div className="departments__menu-container ">
                          {selectedSubMenu?.id === catagory.id && (
                            <div className="megamenu departments__megamenu departments__megamenu--size--lg departments__megamenu">
                              <div className="row">
                                {catagory?.categories?.map((subMenuItem, i) => {
                                  return (
                                    <div
                                      className="col-xl-4 col-lg-4 col-md-6 col-4"
                                      key={i}
                                    >
                                      <div className="megamenu-links__item">
                                        <NavLink
                                          className="megamenu-links__item-link"
                                          to={{
                                            pathname: `/categories/${selectedSubMenu?.slug}/${subMenuItem?.slug}`,
                                            state: {
                                              title: catagory?.title,
                                              vanTypeSlug: catagory?.slug,
                                              categorySlug: subMenuItem?.slug,
                                            },
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              alt={subMenuItem?.name}
                                              className="mx-2"
                                              src={
                                                subMenuItem?.image
                                                  ? `${config.imageBasePath}${subMenuItem?.image}`
                                                  : "https://www.vanagain.com/public/assets/uploads/no_img.gif"
                                              }
                                              height="25px"
                                            />
                                            <span className="sub-menu__link">
                                              {subMenuItem?.name}
                                            </span>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        }
      </div>
    </>
  );
}
