import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isLogin } from "../utils";
import useUserDetailsHook from "./useUserDetailsHook";

 export function updateCartList(cartList) {
    let newList = [];
    cartList.map((item) => {
      newList.push({
        product_id: item?.id,
        name: item?.name,
        slug: item?.slug,
        quantity: item?.quantity,
        price: item?.regular_price,
      });
    });

    return JSON.stringify(newList);
  }

export default function useValidateCheckout() {
  const {
    cart,
    total,
    shippingMethod,
    redeemPoints,
    couponCode,
    tax,
    couponDetails,
    billingCountry,
    billingState,
    shippingCountry,
    shippingState,
  } = useSelector((state) => state.cartReducer);

  const history = useHistory();

  function validateCheckout(
    checkoutObj,
    type,
    acceptTerms,
    createNewAc,
    captcha
  ) {
    const {
      shipping_country,
      shipping_first_name,
      shipping_last_name,
      shipping_postcode,
      shipping_address,
      shipping_town,
    } = checkoutObj;

    return (
      !shipping_address &&
      !shipping_last_name &&
      !shipping_first_name &&
      !shipping_postcode &&
      !shipping_town &&
      !shipping_country
    );
  }


  function createCheckoutObj(
    checkoutObj,
    expanded,
    createNewAc,
    checkNewsletter,
    shipToDiffAddress,
    acceptTerms,
    transactionDetails,
    orderId
  ) {
    const {
      billing_country,
      billing_first_name,
      billing_last_name,
      billing_state,
      billing_postcode,
      email,
      phone,
      shipping_country,
      shipping_state,
      shipping_town,
      shipping_apartment,
      shipping_address,
      shipping_company,
      shipping_last_name,
      shipping_first_name,
      card_holder_name,
      credit_card_number,
      credit_card_date,
      credit_validation_code,
      shipping_postcode,
      billing_address,
      billing_company,
      billing_apartment,
      billing_town,
      password,
      order_notes,
      order_id,
    } = checkoutObj;
    let couponAmount = 0;
    if (couponDetails?.data?.discount_price) {
      couponAmount = couponDetails?.data?.discount_price.split("$")[1];
    }

    let newTotal = (
      Number(total) +
      Number(tax || 0) +
      Number(shippingMethod?.shippingCharges || 0) -
      Number(redeemPoints?.data?.redeem_points_amount || 0) -
      Number(couponAmount || 0)
    ).toFixed(2);

    let newObj = {
      billing_address: shipToDiffAddress ? billing_address : shipping_address,
      billing_apartment: shipToDiffAddress
        ? billing_apartment
        : shipping_apartment,
      billing_company: shipToDiffAddress ? billing_company : shipping_company,
      billing_country: shipToDiffAddress ? billing_country : shipping_country,
      billing_first_name: shipToDiffAddress
        ? billing_first_name
        : shipping_first_name,
      billing_last_name: shipToDiffAddress
        ? billing_last_name
        : shipping_last_name,
      billing_postcode: shipToDiffAddress
        ? billing_postcode
        : shipping_postcode,
      billing_state: shipToDiffAddress ? billing_state : shipping_state,
      billing_town: shipToDiffAddress ? billing_town : shipping_town,
      email: email ? email : "",
      phone: phone ? phone : "",
      shipping_country: shipping_country || "",
      shipping_first_name: shipping_first_name || "",
      shipping_last_name: shipping_last_name || "",
      shipping_company: shipping_company || "",
      shipping_address: shipping_address || "",
      shipping_apartment: shipping_apartment || "",
      shipping_town: shipping_town || "",
      shipping_state: shipping_state || "",
      shipping_postcode: shipping_postcode || "",
      order_notes: order_notes || "",
      shipping_method: [
        shippingMethod.shippingName.replace("™", "<sup>™</sup>"),
      ],
      order_id: order_id ? order_id : "",
      payment: expanded,
      "card-holder-name": card_holder_name || null,
      "credit-card-number":
        Number(
          credit_card_number &&
            credit_card_number.replace(/_/g, "").split(" ").join("")
        ) || null,
      "credit-card-date": credit_card_date || null,
      "credit-validation-code": credit_validation_code || null,
      cart_products: updateCartList(cart),
      accept_terms:
        history?.location?.pathname === "/cart"
          ? "on"
          : acceptTerms
          ? "on"
          : "",

      redeem_points: redeemPoints?.data?.redeem_points || 0,
      redeem_points_amount: redeemPoints?.data?.redeem_points_amount || 0,
      shipping_price: shippingMethod?.shippingCharges,
      shipping_mode: shippingMethod?.shippingMode,
      applyed_coupon_code: couponCode,
      applyed_coupon_price: couponDetails?.data?.discount_price?.split("$")[1],
      tax: tax,
      cartTotal: newTotal,
    };

    if (createNewAc) {
      newObj["create_account"] = 1;
      newObj["password"] = password;
    }
    if (shipToDiffAddress) {
      newObj["shipping_different"] = 1;
    }

    if (checkNewsletter) {
      newObj["checkbox_newsletter"] = 1;
    }
    if (expanded == "paypal") {
      newObj["txn_id"] =
        transactionDetails?.purchase_units[0]?.payments?.captures[0]?.id;
      newObj["payment_status"] =
        transactionDetails?.status == "COMPLETED" ? "approved" : "";
      newObj["shipping_city"] = shipping_town || "";

      newObj["billing_city"] = billing_town || "";
    }

    return newObj;
  }

  return [validateCheckout, createCheckoutObj];
}
