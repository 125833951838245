import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  TRIGGER_GET_VAN_TYPES,
  TRIGGER_GET_VAN_TYPES_SUCCESS,
  TRIGGER_GET_VAN_TYPES_FAILED,
  TRIGGER_GET_VAN_CATEGORIES,
  TRIGGER_GET_VAN_CATEGORIES_SUCCESS,
  TRIGGER_GET_VAN_CATEGORIES_FAILED
} from "./actionTypes";

export function getVanTypes(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetVanTypes}`,
      dispatch,
      defaultAction:  TRIGGER_GET_VAN_TYPES,
      successAction:  TRIGGER_GET_VAN_TYPES_SUCCESS,
      failedAction:  TRIGGER_GET_VAN_TYPES_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}



export function getVanCategories(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetVanCategories}`,
      dispatch,
      defaultAction:  TRIGGER_GET_VAN_CATEGORIES,
      successAction:  TRIGGER_GET_VAN_CATEGORIES_SUCCESS,
      failedAction:  TRIGGER_GET_VAN_CATEGORIES_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

