import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  TRIGGER_ADD_TESTIMONIAL,
  TRIGGER_ADD_TESTIMONIAL_SUCCESS,
} from "../../redux/actions/actionTypes";
import Breadcrumb from "../../components/shared/Breadcrumb";
import "../../assets/scss/pages/Testimonials.scss";
import BlockSpace from "../../components/blocks/BlockSpace";
import { Rating } from "@material-ui/lab";
import OverlayLoader from "../../components/shared/OverlayLoader";
import axios from "axios";
import config from "../../utils/config";

export default function AddTestimonial(props) {
  const initialObject = {
    email: "",
    description: "",
    client_name: "",
    company_name: "",
    state: "",
    rating: "",
    vanagon_image: "",
    country: "",
    website: "",
    job_title: "",
  };
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [testimonialObject, setTestimonialObject] =
    React.useState(initialObject);
  const { addTestimonial } = useSelector((state) => state.homePageReducer);
  const {
    email,
    description,
    client_name,
    company_name,
    state,
    rating,
    vanagon_image,
    country,
    website,
    job_title,
  } = testimonialObject;

  useEffect(() => {
    if (addTestimonial?.error) {
      toast.error("Something went wrong!", {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  }, [addTestimonial]);

  const onChangeHandler = (e, name) => {
    let updatedObject = { ...testimonialObject };
    if (name !== "rating") {
      updatedObject[e.target.name] = e.target.value;
    } else {
      updatedObject[name] = e;
    }
    setTestimonialObject(updatedObject);
  };

  const uploadFile = (e) => {
    let updatedObject = { ...testimonialObject };
    updatedObject[e.target.name] = e.target.files[0];
    setTestimonialObject(updatedObject);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let fd = new FormData();
    fd.append("client_name", client_name);
    fd.append("email", email);
    fd.append("description", description);
    if (vanagon_image) {
      fd.append("vanagon_image", vanagon_image);
    }
    fd.append("country", country);
    fd.append("state", state);
    fd.append("company_name", company_name);
    fd.append("rating", rating);
    fd.append("website", website);
    setIsSubmit(true);
    if (
      client_name !== "" &&
      description !== "" &&
      email !== "" &&
      rating !== "" &&
      description &&
      description?.length >= 5
    ) {
      dispatch({
        type: TRIGGER_ADD_TESTIMONIAL,
      });
      axios
        .post(`${config.apiBasePath}${config.urlAddTestimonial}`, fd, {
          headers: {
            Authorization: "Bearer " + window.localStorage.TOKEN_KEY,
            "Content-type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp?.data.message) {
            document.getElementById("vanagain_file").value = null;
            toast.success(resp?.data.message, {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
            dispatch({
              type: TRIGGER_ADD_TESTIMONIAL_SUCCESS,
              payload: resp?.data,
            });
            setTestimonialObject(initialObject);
            setIsSubmit(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="container">
      {isSubmit && addTestimonial?.loading && <OverlayLoader />}
      <Breadcrumb
        list={[
          {
            title: "Testimonials",
            url: "/testimonials",
          },
        ]}
      />
      <h1 className="block-header__title  animate__animated animate__fadeIn">
        Submit Testimonial
      </h1>
      <div className="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4 login-page">
        <div className="card-body card-body--padding--2">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="signin-email">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name"
                  name="client_name"
                  value={client_name}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  minLength={5}
                />
                {isSubmit && client_name === "" && (
                  <div className="invalid-feedback">
                    Please enter your name!
                  </div>
                )}

                {isSubmit && client_name && client_name.length < 5 && (
                  <div className="invalid-feedback">
                    The comment name be at least 5 characters.
                  </div>
                )}
              </div>

              <div className="form-group col-12">
                <label htmlFor="signin-password">Email</label>
                <input
                  id="signin-password"
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  name="email"
                  minLength={5}
                  value={email}
                  onChange={(event) => {
                    onChangeHandler(event);
                  }}
                />

                {isSubmit && email === "" && (
                  <div className="invalid-feedback">
                    Please enter your email!
                  </div>
                )}
              </div>

              <div className="form-group col-12">
                <label htmlFor="form-message">Comment</label>
                <textarea
                  id="form-message"
                  className="form-control"
                  name="description"
                  value={description}
                  onChange={(event) => {
                    onChangeHandler(event);
                  }}
                  placeholder="Enter your comment"
                  rows="4"
                ></textarea>
                {isSubmit && description === "" && (
                  <div className="invalid-feedback">Please enter comment!</div>
                )}

                {isSubmit && description && description.length < 5 && (
                  <div className="invalid-feedback">
                    The comment must be at least 5 characters.
                  </div>
                )}
              </div>

              <div className="form-group col-12">
                <label htmlFor="form-message">Rating</label>
                <div>
                  <Rating
                    value={rating}
                    onChange={(event, newValue) => {
                      onChangeHandler(newValue, "rating");
                    }}
                    size="large"
                  />

                  {isSubmit && rating === "" && (
                    <div className="invalid-feedback">Rating is required!</div>
                  )}
                </div>

                <div className="form-group file-input">
                  <label htmlFor="form-message">
                    Upload image of your Vanagon
                  </label>
                  <input
                    id="vanagain_file"
                    type="file"
                    className="form-control"
                    placeholder="Enter your email"
                    name="vanagon_image"
                    onChange={(e) => {
                      uploadFile(e);
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <h6>Testimonials Details</h6>
                <hr />
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="form-message">Job title</label>
                  <input
                    id="signin-password"
                    type="text"
                    className="form-control"
                    placeholder="Enter your job title"
                    name="job_title"
                    value={job_title}
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </div>
              </div>

              <div className="col-6 p-0">
                <div className="form-group">
                  <label htmlFor="form-message">Company</label>
                  <input
                    id="signin-password"
                    type="text"
                    className="form-control"
                    placeholder="Enter your company name"
                    name="company_name"
                    value={company_name}
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="form-message">URL</label>
                  <input
                    id="signin-password"
                    type="url"
                    className="form-control"
                    placeholder="Enter URL"
                    name="website"
                    value={website}
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="form-message">State</label>
                  <input
                    id="signin-password"
                    type="text"
                    className="form-control"
                    placeholder="Enter your state"
                    name="state"
                    value={state}
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="form-message">Country</label>
                  <input
                    id="signin-password"
                    type="text"
                    className="form-control"
                    placeholder="Enter your country"
                    name="country"
                    value={country}
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group mb-0 col-12">
                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                  disabled={isSubmit && addTestimonial?.loading}
                >
                  Submit

                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
