import {
  TRIGGER_GET_ARTICLES_LIST_FAILED,
  TRIGGER_GET_ARTICLES_LIST_SUCCESS,
  TRIGGER_GET_ARTICLES_LIST,
  TRIGGER_GET_ARTICLES_DETAILS,
  TRIGGER_GET_ARTICLES_DETAILS_FAILED,
  TRIGGER_GET_ARTICLES_DETAILS_SUCCESS,
  TRIGGER_GET_ARTICLES_COMMENTS,
  TRIGGER_GET_ARTICLES_COMMENTS_FAILED,
  TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS,
  TRIGGER_ADD_COMMENTS,
  TRIGGER_ADD_COMMENTS_FAILED,
  TRIGGER_ADD_COMMENTS_SUCCESS,

} from "../actions/actionTypes";

const initialState = {
  articlesList: {
    loading: false,
    data: [],
    error: null,
  },
  articlesDetails: {
    loading: false,
    data: [],
    error: null,
  },

  articlesComments: {
    loading: false,
    data: [],
    error: null,
  },

  articlePostCommentObj: {
    loading: false,
    data: [],
    error: null,
  },
};

const articlesReducer = function (state = initialState, action) {

  switch (action.type) {

    case TRIGGER_GET_ARTICLES_LIST:
      return {
        ...state,
        articlesList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_LIST_SUCCESS:
      return {
        ...state,
        articlesList: {
          loading: false,
          data: action.payload.blogs,
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_LIST_FAILED:
      return {
        ...state,
        articlesList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_ARTICLES_DETAILS:
      return {
        ...state,
        articlesDetails: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_DETAILS_SUCCESS:
           return {
        ...state,
        articlesDetails: {
          loading: false,
          data: action.payload.blogDetail,
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_DETAILS_FAILED:
      return {
        ...state,
        articlesDetails: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_COMMENTS:
      return {
        ...state,
        articlesComments: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS:
      return {
        ...state,
        articlesComments: {
          loading: false,
          data: action.payload.blogComments,
          error: null,
        },
      };

    case TRIGGER_GET_ARTICLES_COMMENTS_FAILED:
      return {
        ...state,
        articlesComments: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ADD_COMMENTS:
      return {
        ...state,
        articlePostCommentObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ADD_COMMENTS_SUCCESS:
           return {
        ...state,
        articlePostCommentObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_ADD_COMMENTS_FAILED:
      return {
        ...state,
        articlePostCommentObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default articlesReducer;
