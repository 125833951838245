import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TRIGGER_LOGIN_FAILED } from "../../redux/actions/actionTypes";
import { getRememberedUserData, rememberUser } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import useAuthHook from "../../hooks/useAuthHooks";
import OverlayLoader from "../../components/shared/OverlayLoader";
import { ReactSVG } from "react-svg";
import { Check12x9Svg } from "../../svg/index";

export default function Login() {
  const { loginObj } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRememberMe] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [login] = useAuthHook();
  useEffect(() => {
    let userData = getRememberedUserData();
    setEmail(userData?.email ? userData?.email : "");
    setPassword(userData?.password ? userData?.password : "");
  }, []);
  useEffect(() => {
    if (loginObj?.error) {
      toast.error(loginObj?.error?.message, {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setLoading(false);

      dispatch({
        type: TRIGGER_LOGIN_FAILED,
        loading: false,
      });
    }
  }, [loginObj]);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (validateForm()) {
      setLoading(true);
      login(email, password, setLoading);
    }
  };

  const rememberMe = (event) => {
    if (event.target.checked) {
      rememberUser({ email: email, password: password });
    }
    setRememberMe(event.target.checked);
  };

  return (
    <div className="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4 login-page">
      {isSubmit && loading && <OverlayLoader />}
      <div className="card-body card-body--padding--2">
        <h3 className="card-title">Login</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="signin-email">Email address</label>
            <input
              id="signin-email"
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {isSubmit && email === "" && (
              <div className="invalid-feedback">Please enter email!</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="signin-password">Password</label>
            <input
              id="signin-password"
              type="password"
              className="form-control"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {isSubmit && password === "" && (
              <div className="invalid-feedback">Please enter password!</div>
            )}

            <small className="form-text text-muted">
              <Link to="/forgot-password">Forgot password?</Link>
            </small>
          </div>
          <div className="form-group">
            <div className="form-check">
              <span className="input-check form-check-input">
                <span className="input-check__body">
                  <input
                    className="input-check__input"
                    type="checkbox"
                    id="sign-in-remember"
                    name="remember"
                    checked={remember}
                    onChange={(event) => rememberMe(event)}
                  />
                  <span className="input-check__box"></span>
                  <span className="input-check__icon">
                    <ReactSVG src={Check12x9Svg} />
                  </span>
                </span>
              </span>
              <label
                className="form-check-label"
                onChange={(e) => {
                  rememberMe(e);
                }}
                htmlFor="sign-in-remember"
              >
                Remember Me
              </label>
            </div>
          </div>

          <div className="form-group mb-0">
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={isSubmit && loading}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
