import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import "../../assets/scss/pages/header/header.scss";
import Logo from "./Logo";
import Search from "./Search";
import { Heart32Svg, Person32Svg, Cart32Svg } from "../../svg/index";
import { Link } from "react-router-dom";
import "../../assets/scss/pages/header/MobileMenu.scss";
import { ArrowRoundedRight7x11Svg } from "../../svg/index";
import { NavLink } from "react-router-dom";
import { isLogin } from "../../utils";
import AlertMessage from "../shared/AlertMessage";
import { SwipeableDrawer } from "@material-ui/core";
import config from "../../utils/config";
import "../../assets/scss/pages/header/header.scss";


export default function MobileMenu({ open, handleClose, isMobile, width }) {
  const { mainMenuList } = useSelector((state) => state.mainMenuReducer);
  const { cart } = useSelector((state) => state.cartReducer);
  const { wishList } = useSelector((state) => state.shopReducer);
  const [alertMsg, setAlertMsg] = useState(false);
  const [isClick, setIsClick] = useState([]);
  const history = useHistory();

  const showAlertMsg = () => {
    setAlertMsg(true);
  };

  const hideAlertMsg = () => {
    setAlertMsg(false);
  };

  const selectMenuItem = (item) => {
    if (item) {
      setIsClick(item);
    } else {
      setIsClick(null);
    }
  };

  const goToWishlist = () => {
    if (isLogin()) {
      history.push("/my-account/wishlist");
    } else {
      showAlertMsg(true);
    }
    handleClose();
  };

  const goToUserDetails = () => {
    if (isLogin()) {
      history.push("/my-account/dashboard");
    } else {
      history.push("/login");
    }
    handleClose();
  };

  const goToCart = () => {
    history.push("/cart");
    handleClose();
  };

  return (
    <div className="mobile-menu indicator">
      <SwipeableDrawer
        anchor={"left"}
        open={open}
        onOpen={handleClose}
        onClose={handleClose}
      >
        <div className="d-flex justify-content-evenly">
          <Link className="navbar-brand ml-3" to="/">
            <Logo />
          </Link>

          <button type="btn" className="btn" onClick={handleClose}>
            <ReactSVG src={ArrowRoundedRight7x11Svg} />
          </button>
        </div>
        {width < 425 && <Search handleClose={handleClose} />}
        <div className="d-flex   my-3">
          {width < 720 && (
            <button
              className="indicator__button mx-2"
              type="button"
              onClick={goToWishlist}
            >
              <span className="indicator__icon">
                <ReactSVG className="heart-svg" src={Heart32Svg} />
                <span className="indicator__counter">
                  {wishList?.data?.length || 0}
                </span>
              </span>
            </button>
          )}

          {width < 650 && (
            <button
              className="indicator__button mx-2"
              type="button"
              onClick={goToUserDetails}
            >
              <span className="indicator__icon">
                <ReactSVG src={Person32Svg} />
              </span>
            </button>
          )}

          {width < 590 && (
            <button
              className="indicator__button"
              type="button"
              onClick={goToCart}
            >
              <span className="indicator__icon">
                <ReactSVG src={Cart32Svg} />
                <span className="indicator__counter">{cart?.length}</span>
              </span>
            </button>
          )}
        </div>

        <ul className="main-menu-mobile">
          {mainMenuList?.data?.map((item, i) => {
            return (
              <li
                key={i}
                onMouseEnter={() => {
                  !isMobile && selectMenuItem(item);
                }}
                onMouseLeave={() => {
                  !isMobile && selectMenuItem();
                }}
                onClick={() => {
                  if (isMobile) {
                    if (isClick && item.id === isClick.id) {
                      selectMenuItem();
                    } else {
                      selectMenuItem(item);
                    }
                  }
                }}
              >
                <div className="d-flex align-items-center mb-1 ">
                  <div className="van-image">
                    <img
                      src={item?.thumbnail ? `${config.imageBasePath}${item?.thumbnail}` :"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                      alt={item?.title}
                    />
                  </div>
                  <div className="ml-3 d-flex align-items-center justify-content-between w-100">
                    <span
                      style={{
                        color:
                          history?.location?.state?.vanTypeSlug === item?.slug
                            ? " #32c2ef"
                            : "",
                      }}
                    >
                      {item?.title}{" "}
                    </span>

                    <i
                      className={
                        item?.id != isClick?.id
                          ? "fa fa-angle-down"
                          : "fa fa-angle-up"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                {isClick?.id === item?.id && (
                  <ul className="sub-menu-mobile">
                    <li className="megamenu-links__item-link all_menu">
                      <NavLink
                        to={{
                          pathname: `/categories/${item?.slug}`,
                          state: {
                            title: item?.title,
                            vanTypeSlug: item?.slug,
                          },
                        }}
                        onClick={handleClose}
                      >
                        All {item?.title} parts
                      </NavLink>
                    </li>

                    {item?.categories?.map((category, i) => {
                      return (
                        <li key={i}>
                          <NavLink
                            className="megamenu-links__item-link"
                            to={{
                              pathname: `/categories/${isClick?.slug}/${category?.slug}`,
                              state: {
                                title: isClick?.title,
                                vanTypeSlug: isClick?.slug,
                                categorySlug: category?.slug,
                              },
                            }}
                            onClick={handleClose}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                alt=""
                                className="mx-2"
                                src={category?.image ? `${config.imageBasePath}${category?.image}`:"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                                height="25px"
                              />
                              <span className="sub-menu__link">
                                {category?.name}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </SwipeableDrawer>
      {showAlertMsg && <AlertMessage isOpen={alertMsg} close={hideAlertMsg} />}
    </div>
  );
}
