import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TRIGGER_CALCULATE_SHIPPING_DETAILS,
  TRIGGER_TAX_AMOUNT,
} from "../redux/actions/actionTypes";
import { triggerCalculateShipping } from "../redux/actions/cartAction";
import useUpdateCartProduct from "./useUpdateCartProduct";

export default function useCalculateShippingHook() {
  const [updateCartProductObj] = useUpdateCartProduct();
  const dispatch = useDispatch();

  const { shippingAddress } = useSelector((state) => state.cartReducer);

  const initShippingAddress = {
    calc_shipping_country: shippingAddress?.country || "US",
    calc_shipping_state: shippingAddress?.state || "",
    calc_shipping_postcode: shippingAddress?.postcode || "",
    cart_products: [],
  };
  useEffect(() => {
    initShippingAddress.calc_shipping_state = shippingAddress?.state || "";
    initShippingAddress.calc_shipping_postcode =
      shippingAddress?.postcode || "";
  }, [shippingAddress]);

  function calculateShippingFunction(
    list,
    calcShippingObj,
    setCalcShippingObj,
    setNoShippingAvailable
  ) {
    let updatedObj = { ...calcShippingObj };
    let cartList = JSON.parse(JSON.stringify(list));
    if (cartList.length > 0 && updatedObj?.calc_shipping_postcode) {
      cartList.map((item, i) => {
        cartList[i] = updateCartProductObj(item);
      });
      updatedObj["cart_products"] = cartList;
      setCalcShippingObj(updatedObj);
      dispatch({
        type: TRIGGER_CALCULATE_SHIPPING_DETAILS,
        country: updatedObj?.calc_shipping_country,
        state: updatedObj?.calc_shipping_state,
        postcode: updatedObj?.calc_shipping_postcode,
      });
      return dispatch(
        triggerCalculateShipping(updatedObj, (resp) => {
          if (resp.response === 2) {
            setNoShippingAvailable(true);
          }
        })
      );
    }
  }

  return [initShippingAddress, calculateShippingFunction];
}
