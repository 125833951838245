import React from "react";
import { useSelector } from "react-redux";
import config from "../../utils/config";
import logo from "./../../assets/images/logo.jpg";

export default function Logo() {
  const { homePageData } = useSelector((state) => state.homePageReducer);
  const headerLogo =
    config.imageBasePath + homePageData?.data?.settings?.logo
      ? config.imageBasePath + homePageData?.data?.settings?.logo
      : logo;
  return (
    <div className="logo-image">
      {homePageData?.loading ? (
        <img src={logo} alt="vanagain-logo" />
      ) : (
        <>
          <img src={headerLogo} alt="vanagain-logo" />
        </>
      )}
    </div>
  );
}
