import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../../assets/scss/pages/ProductCategoriesSidebar.scss";
import BlockFacebookPage from "../blocks/BlockHomePage/BlockFacebookPage";
import { useDispatch, useSelector } from "react-redux";
import {
  TRIGGER_SEARCH_KEYWORD,
  TRIGGER_SKU_KEYWORD,
} from "../../redux/actions/actionTypes";
import { panels } from "../../utils/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function ProductCategoriesSidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sku, setSku] = useState();
  const { mainMenuList, categories } = useSelector(
    (state) => state.mainMenuReducer
  );
  const { askKen } = useSelector((state) => state.blogsReducer);
  const vanType = history?.location?.state?.vanTypeSlug;
  const categoryType = history?.location?.state?.categorySlug;
  const [panel, setPanel] = useState(panels);
  const { blogsCategoriesList } = useSelector((state) => state.blogsReducer);

  const handleChange = (i) => {
    let newArr = [...panel];
    newArr[i].status = !newArr[i].status;
    setPanel(newArr);
  };

  const searchBySku = () => {
    dispatch({
      type: TRIGGER_SKU_KEYWORD,
      skuKeyword: sku,
    });
    history.push({ pathname: "/shop/search", state: { skuKeyword: sku } });
  };

  const navigateByCategory = (vanType, category) => {
    if (!vanType && category) {
      dispatch({
        type: TRIGGER_SEARCH_KEYWORD,
        searchKey: category.name,
      });

      history.push({
        pathname: "/shop/search",
        state: { searchSlug: category.name },
      });
    } else {
      let pathname = `/categories/${vanType}/${category.slug}`;
      history.push({
        pathname,
        state: {
          title: category?.title,
          vanTypeSlug: vanType,
          categorySlug: category.slug,
        },
      });
    }
  };
  return (
    <div className="product-category-sidebar">
      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="widget__header widget-filters__header">
          <h4>Filters</h4>
        </div>
        <div>
          <Accordion
            expanded={panel[0].status}
            onChange={() => handleChange(0)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Categories</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filter__body">
                <div className="filter__container">
                  <div className="filter-category">
                    <ul className="filter-category__list filter-category-list">
                      {categories?.data?.map((category, i) => {
                        return (
                          <li className="filter-category__item" key={i}>
                            <div
                              className={
                                "" +
                                (category?.slug === categoryType
                                  ? "filter-category__item_active"
                                  : "")
                              }
                              onClick={() => {
                                navigateByCategory(vanType, category);
                              }}
                            >
                              {category?.name}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={panel[1].status}
            onChange={() => handleChange(1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Type of Van</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filter__body">
                <div className="filter__container">
                  <div className="filter-category">
                    <ul className="filter-category__list">
                      {mainMenuList?.data?.map((category, i) => {
                        return (
                          <li className="filter-category__item" key={i}>
                            <NavLink
                              className={
                                "" +
                                (category?.slug === vanType
                                  ? "filter-category__item_active"
                                  : "")
                              }
                              activeClassName="filter-category__item_active"
                              to={{
                                pathname: categoryType
                                  ? `/categories/${category?.slug}/${categoryType}`
                                  : `/categories/${category?.slug}`,
                                state: {
                                  title: category?.title,
                                  vanTypeSlug: category?.slug,
                                },
                              }}
                            >
                              {category.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={panel[2].status}
            onChange={() => handleChange(2)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Search by SKU or keyword</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <input
                type="text"
                className="form-control mr-2 my-2"
                placeholder="Search products"
                value={sku}
                onChange={(e) => {
                  setSku(e.target.value);
                }}
              />

              <button
                className="btn btn-primary btn-sm my-2"
                type="button"
                onClick={() => searchBySku()}
              >
                Search
              </button>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={panel[3].status}
            onChange={() => handleChange(3)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Ask KEN</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filter__body">
                <div className="filter__container">
                  <div className="filter-category">
                    <ul className="filter-category__list">
                      {askKen?.data?.map((article, i) => {
                        return (
                          <li className="filter-category__item" key={i}>
                            <NavLink
                              activeClassName="filter-category__item_active"
                              to={{
                                pathname: `/article/${article.slug}`,
                                state: { title: article?.slug, id: article.id },
                              }}
                            >
                              {article.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={panel[4].status}
            onChange={() => handleChange(4)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>FILTER BY CATEGORIES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filter__body">
                <div className="filter__container">
                  <div className="filter-category">
                    <ul className="filter-category__list">
                      {blogsCategoriesList?.data?.map((category, i) => {
                        return (
                          <li className="filter-category__item" key={i}>
                            <NavLink
                              activeClassName="filter-category__item_active"
                              to={`/category/${category?.slug}`}
                            >
                              {category?.name}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <BlockFacebookPage />
        </div>
      </AnimationOnScroll>
    </div>
  );
}
