import React from "react";
import { Link } from "react-router-dom";
export default function PageNotFound() {
  return (
    <div className="block page-not-found">
      <div className="container">
        <div className="not-found">
          <div className="not-found__404">Oops! Error 404</div>

          <div className="not-found__content">
            <h1 className="not-found__title">Page Not Found</h1>

            <p className="not-found__text">
              {"We can't seem to find the page you're looking for."}
              <br />
              Try to use the search.
            </p>

            <p className="not-found__text">
              Or go to the home page to start over.
            </p>

            <Link to={"/"} className="btn btn-secondary btn-sm">
              Go To Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
