export const TRIGGER_LOGIN = "TRIGGER_LOGIN";
export const TRIGGER_LOGIN_SUCCESS = "TRIGGER_LOGIN_SUCCESS";
export const TRIGGER_LOGIN_FAILED = "TRIGGER_LOGIN_FAILED";

export const TRIGGER_LOGOUT = "TRIGGER_LOGOUT";
export const TRIGGER_LOGOUT_SUCCESS = "TRIGGER_LOGOUT_SUCCESS";
export const TRIGGER_LOGOUT_FAILED = "TRIGGER_LOGOUT_FAILED";

export const TRIGGER_REGISTER = "TRIGGER_REGISTER";
export const TRIGGER_REGISTER_SUCCESS = "TRIGGER_REGISTER_SUCCESS";
export const TRIGGER_REGISTER_FAILED = "TRIGGER_REGISTER_FAILED";

export const TRIGGER_SET_PASSWORD = "TRIGGER_SET_PASSWORD";
export const TRIGGER_SET_PASSWORD_SUCCESS = "TRIGGER_SET_PASSWORD_SUCCESS";
export const TRIGGER_SET_PASSWORD_FAILED = "TRIGGER_SET_PASSWORD_FAILED";

export const TRIGGER_CHANGE_PASSWORD = "TRIGGER_CHANGE_PASSWORD";
export const TRIGGER_CHANGE_PASSWORD_SUCCESS =
  "TRIGGER_CHANGE_PASSWORD_SUCCESS";
export const TRIGGER_CHANGE_PASSWORD_FAILED = "TRIGGER_CHANGE_PASSWORD_FAILED";

export const TRIGGER_FORGET_PASSWORD = "TRIGGER_FORGET_PASSWORD";
export const TRIGGER_FORGET_PASSWORD_SUCCESS =
  "TRIGGER_FORGET_PASSWORD_SUCCESS";
export const TRIGGER_FORGET_PASSWORD_FAILED = "TRIGGER_FORGET_PASSWORD_FAILED";

//shop
export const TRIGGER_GET_CATEGORIES_LIST = "TRIGGER_GET_CATEGORIES_LIST";
export const TRIGGER_GET_CATEGORIES_LIST_SUCCESS =
  "TRIGGER_GET_CATEGORIES_LIST_SUCCESS";
export const TRIGGER_GET_CATEGORIES_LIST_FAILED =
  "TRIGGER_GET_CATEGORIES_LIST_FAILED";

export const TRIGGER_GET_PRODUCTS_BY_CATEGORIES =
  "TRIGGER_GET_PRODUCTS_BY_CATEGORIES";
export const TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS =
  "TRIGGER_GET_PRODUCTS_BY_CATEGORIES_SUCCESS";
export const TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED =
  "TRIGGER_GET_PRODUCTS_BY_CATEGORIES_FAILED";

export const TRIGGER_NO_OF_PRODUCTS = "TRIGGER_NO_OF_PRODUCTS";
export const TRIGGER_SELECT_PRODUCT = "TRIGGER_SELECT_PRODUCT";
export const TRIGGER_SKU_KEYWORD = "TRIGGER_SKU_KEYWORD";
export const TRIGGER_SEARCH_KEYWORD = "TRIGGER_SEARCH_KEYWORD";

export const TRIGGER_VAN_TYPE = "TRIGGER_VAN_TYPE";
export const TRIGGER_VAN_CATEGORY = "TRIGGER_VAN_CATEGORY";
export const TRIGGER_VIEW_TYPE = "TRIGGER_VIEW_TYPE";

export const TRIGGER_GET_PRODUCT_DETAILS = "TRIGGER_GET_PRODUCT_DETAILS";
export const TRIGGER_GET_PRODUCT_DETAILS_SUCCESS =
  "TRIGGER_GET_PRODUCT_DETAILS_SUCCESS";
export const TRIGGER_GET_PRODUCT_DETAILS_FAILED =
  "TRIGGER_GET_PRODUCT_DETAILS_FAILED";

export const TRIGGER_GET_POINTS = "TRIGGER_GET_POINTS";
export const TRIGGER_GET_POINTS_SUCCESS = "TRIGGER_GET_POINTS_SUCCESS";
export const TRIGGER_GET_POINTS_FAILED = "TRIGGER_GET_POINTS_FAILED";

export const TRIGGER_GET_HEADER_CONTENT = "TRIGGER_GET_HEADER_CONTENT";
export const TRIGGER_GET_HEADER_CONTENT_SUCCESS =
  "TRIGGER_GET_HEADER_CONTENT_SUCCESS";
export const TRIGGER_GET_HEADER_CONTENT_FAILED =
  "TRIGGER_GET_HEADER_CONTENT_FAILED";

export const TRIGGER_CHECKOUT = "TRIGGER_CHECKOUT";
export const TRIGGER_CHECKOUT_SUCCESS = "TRIGGER_CHECKOUT_SUCCESS";
export const TRIGGER_CHECKOUT_FAILED = "TRIGGER_CHECKOUT_FAILED";

export const TRIGGER_CHECKOUT_OBJ = "TRIGGER_CHECKOUT_OBJ";


export const TRIGGER_GET_ORDER_DETAILS = "TRIGGER_GET_ORDER_DETAILS";
export const TRIGGER_GET_ORDER_DETAILS_SUCCESS =
  "TRIGGER_GET_ORDER_DETAILS_SUCCESS";
export const TRIGGER_GET_ORDER_DETAILS_FAILED =
  "TRIGGER_GET_ORDER_DETAILS_FAILED";

export const TRIGGER_EXPRESS_CHECKOUT = "TRIGGER_EXPRESS_CHECKOUT";
export const TRIGGER_EXPRESS_CHECKOUT_SUCCESS =
  "TRIGGER_EXPRESS_CHECKOUT_SUCCESS";
export const TRIGGER_EXPRESS_CHECKOUT_FAILED =
  "TRIGGER_EXPRESS_CHECKOUT_FAILED";
//main menu start

export const TRIGGER_GET_VAN_TYPES = "TRIGGER_GET_VAN_TYPES";
export const TRIGGER_GET_VAN_TYPES_SUCCESS = "TRIGGER_GET_VAN_TYPES_SUCCESS";
export const TRIGGER_GET_VAN_TYPES_FAILED = "TRIGGER_GET_VAN_TYPES_FAILED";

export const TRIGGER_GET_VAN_CATEGORIES = "TRIGGER_GET_VAN_CATEGORIES";
export const TRIGGER_GET_VAN_CATEGORIES_SUCCESS =
  "TRIGGER_GET_VAN_CATEGORIES_SUCCESS";
export const TRIGGER_GET_VAN_CATEGORIES_FAILED =
  "TRIGGER_GET_VAN_CATEGORIES_FAILED";

//main menu ends

//blog categories starts
export const TRIGGER_GET_BLOG_CATEGORIES = "TRIGGER_GET_BLOG_CATEGORIES";
export const TRIGGER_GET_BLOG_CATEGORIES_SUCCESS =
  "TRIGGER_GET_BLOG_CATEGORIES_SUCCESS";
export const TRIGGER_GET_BLOG_CATEGORIES_FAILED =
  "TRIGGER_GET_BLOG_CATEGORIES_FAILED";

export const TRIGGER_GET_ASK_KEN = "TRIGGER_GET_ASK_KEN";
export const TRIGGER_GET_ASK_KEN_SUCCESS = "TRIGGER_GET_ASK_KEN_SUCCESS";
export const TRIGGER_GET_ASK_KEN_FAILED = "TRIGGER_GET_ASK_KEN_FAILED";

export const TRIGGER_GET_CATEGORY_LIST = "TRIGGER_GET_CATEGORY_LIST";
export const TRIGGER_GET_CATEGORY_LIST_SUCCESS =
  "TRIGGER_GET_CATEGORY_LIST_SUCCESS";
export const TRIGGER_GET_CATEGORY_LIST_FAILED =
  "TRIGGER_GET_CATEGORY_LIST_FAILED";

//blog categories ends

//articles starts

export const TRIGGER_GET_ARTICLES_LIST = "TRIGGER_GET_ARTICLES_LIST";
export const TRIGGER_GET_ARTICLES_LIST_SUCCESS =
  "TRIGGER_GET_ARTICLES_LIST_SUCCESS";
export const TRIGGER_GET_ARTICLES_LIST_FAILED =
  "TRIGGER_GET_BLOG_CATEGORIES_FAILED";

export const TRIGGER_GET_ARTICLES_DETAILS = "TRIGGER_GET_ARTICLES_DETAILS";
export const TRIGGER_GET_ARTICLES_DETAILS_SUCCESS =
  "TRIGGER_GET_ARTICLES_DETAILS_SUCCESS";
export const TRIGGER_GET_ARTICLES_DETAILS_FAILED =
  "TRIGGER_GET_ARTICLES_DETAILS_FAILED";

export const TRIGGER_GET_ARTICLES_COMMENTS = "TRIGGER_GET_ARTICLES_COMMENTS";
export const TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS =
  "TRIGGER_GET_ARTICLES_COMMENTS_SUCCESS";
export const TRIGGER_GET_ARTICLES_COMMENTS_FAILED =
  "TRIGGER_GET_BLOG_CATEGORIES_FAILED";

export const TRIGGER_ADD_COMMENTS = "TRIGGER_ADD_COMMENTS";
export const TRIGGER_ADD_COMMENTS_SUCCESS = "TRIGGER_ADD_COMMENTS_SUCCESS";
export const TRIGGER_ADD_COMMENTS_FAILED = "TRIGGER_ADD_COMMENTS_FAILED";

//articles ends

//contact us starts

export const TRIGGER_CONTACT_US = "TRIGGER_CONTACT_US";
export const TRIGGER_CONTACT_US_SUCCESS = "TRIGGER_CONTACT_US_SUCCESS";
export const TRIGGER_CONTACT_US_FAILED = "TRIGGER_CONTACT_US_FAILED";

//contact us ends

//update profile starts
export const TRIGGER_PROFILE_UPDATE = "TRIGGER_PROFILE_UPDATE";
export const TRIGGER_PROFILE_UPDATE_SUCCESS = "TRIGGER_PROFILE_UPDATE_SUCCESS";
export const TRIGGER_PROFILE_UPDATE_FAILED = "TRIGGER_PROFILE_UPDATE_FAILED";

//update profile ends

// country starts

export const TRIGGER_COUNTRY = "TRIGGER_COUNTRY";
export const TRIGGER_COUNTRY_SUCCESS = "TRIGGER_COUNTRY_SUCCESS";
export const TRIGGER_COUNTRY_FAILED = "TRIGGER_COUNTRY_FAILED";

export const TRIGGER_SHIPPING_COUNTRY = "TRIGGER_SHIPPING_COUNTRY";
export const TRIGGER_SHIPPING_COUNTRY_SUCCESS =
  "TRIGGER_SHIPPING_COUNTRY_SUCCESS";
export const TRIGGER_SHIPPING_COUNTRY_FAILED =
  "TRIGGER_SHIPPING_COUNTRY_FAILED";

export const TRIGGER_SHIPPING_STATES = "TRIGGER_SHIPPING_STATE";
export const TRIGGER_SHIPPING_STATES_SUCCESS = "TRIGGER_SHIPPING_STATE_SUCCESS";
export const TRIGGER_SHIPPING_STATES_FAILED = "TRIGGER_SHIPPING_STATES_FAILED";

export const TRIGGER_BILLING_COUNTRY = "TRIGGER_BILLING_COUNTRY";
export const TRIGGER_BILLING_COUNTRY_SUCCESS =
  "TRIGGER_BILLING_COUNTRY_SUCCESS";
export const TRIGGER_BILLING_COUNTRY_FAILED = "TRIGGER_BILLING_COUNTRY_FAILED";

export const TRIGGER_BILLING_STATES = "TRIGGER_BILLING_STATE";
export const TRIGGER_BILLING_STATES_SUCCESS = "TRIGGER_BILLING_STATE_SUCCESS";
export const TRIGGER_BILLING_STATES_FAILED = "TRIGGER_BILLING_STATES_FAILED";

export const TRIGGER_SUBSCRIBE_NEWSLETTER = "TRIGGER_SUBSCRIBE_NEWSLETTER";

// country ends

// states starts

export const TRIGGER_STATES = "TRIGGER_STATES";
export const TRIGGER_STATES_SUCCESS = "TRIGGER_STATES_SUCCESS";
export const TRIGGER_STATES_FAILED = "TRIGGER_STATES_FAILED";

// states ends

// states addToWishlist

export const TRIGGER_ADD_TO_WISH_LIST = "TRIGGER_ADD_TO_WISH_LIST";
export const TRIGGER_ADD_TO_WISH_LIST_SUCCESS =
  "TRIGGER_ADD_TO_WISH_LIST_SUCCESS";
export const TRIGGER_ADD_TO_WISH_LIST_FAILED =
  "TRIGGER_ADD_TO_WISH_LIST_FAILED";

export const TRIGGER_ADD_TO_CART = "TRIGGER_ADD_TO_CART";
export const TRIGGER_ALL_ADD_TO_CART = "TRIGGER_ALL_ADD_TO_CART";
export const TRIGGER_SET_CART_LIST = "TRIGGER_SET_CART_LIST";

export const TRIGGER_ADD_QUANTITY = "TRIGGER_ADD_QUANTITY";

export const TRIGGER_SUB_QUANTITY = "TRIGGER_SUB_QUANTITY";

export const TRIGGER_REMOVE_PRODUCT_FROM_CART =
  "TRIGGER_REMOVE_PRODUCT_FROM_CART";

export const TRIGGER_UPDATE_CART = "TRIGGER_UPDATE_CART";

export const TRIGGER_SUB_QUANTITY_CART = "TRIGGER_SUB_QUANTITY_CART";

export const TRIGGER_ADD_QUANTITY_CART = "TRIGGER_ADD_QUANTITY_CART";

export const TRIGGER_CALCULATE_SHIPPING = "TRIGGER_CALCULATE_SHIPPING";
export const TRIGGER_CALCULATE_SHIPPING_DETAILS = "TRIGGER_CALCULATE_SHIPPING_DETAILS";
export const TRIGGER_CALCULATE_SHIPPING_SUCCESS =
  "TRIGGER_CALCULATE_SHIPPING_SUCCESS";
export const TRIGGER_CALCULATE_SHIPPING_FAILED =
  "TRIGGER_CALCULATE_SHIPPING_FAILED";

export const TRIGGER_EMPTY_CART = "TRIGGER_EMPTY_CART";

export const TRIGGER_GET_COUPON_DETAILS = "TRIGGER_GET_COUPON_DETAILS";
export const TRIGGER_GET_COUPON_DETAILS_SUCCESS =
  "TRIGGER_GET_COUPON_DETAILS_SUCCESS";
export const TRIGGER_GET_COUPON_DETAILS_FAILED = "TRIGGER_GET_POINTS_FAILED";

export const TRIGGER_GET_SHIPPING_DETAILS = "TRIGGER_GET_SHIPPING_DETAILS";
export const TRIGGER_SHIPPING_CHARGES = "TRIGGER_SHIPPING_CHARGES";
export const TRIGGER_BILLING_ADDRESS = "TRIGGER_BILLING_ADDRESS";
export const TRIGGER_SHIPPING_METHODS = "TRIGGER_SHIPPING_METHODS";
export const TRIGGER_CART_UPDATED_AFTER_SHIPPING_CALCULATION =
  "TRIGGER_CART_UPDATED_AFTER_SHIPPING_CALCULATION";

// states addToWishlist

// states wishlists

export const TRIGGER_WISH_LIST = "TRIGGER_WISH_LIST";
export const TRIGGER_WISH_LIST_SUCCESS = "TRIGGER_WISH_LIST_SUCCESS";
export const TRIGGER_WISH_LIST_FAILED = "TRIGGER_WISH_LIST_FAILED";

// states wishlists

// states removeFromWishlist

export const TRIGGER_REMOVE_FROM_WISH_LIST = "TRIGGER_REMOVE_FROM_WISH_LIST";
export const TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS =
  "TRIGGER_REMOVE_FROM_WISH_LIST_SUCCESS";
export const TRIGGER_REMOVE_FROM_WISH_LIST_FAILED =
  "TRIGGER_REMOVE_FROM_WISH_LIST_FAILED";

// states removeFromWishlist

//home page action start

export const TRIGGER_HOME_PAGE_DATA = "TRIGGER_HOME_PAGE_DATA";
export const TRIGGER_HOME_PAGE_DATA_SUCCESS = "TRIGGER_HOME_PAGE_DATA_SUCCESS";
export const TRIGGER_HOME_PAGE_DATA_FAILED = "TRIGGER_HOME_PAGE_DATA_FAILED";

export const TRIGGER_TESTIMONIAL_DETAILS_DATA =
  "TRIGGER_TESTIMONIAL_DETAILS_DATA";
export const TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS =
  "TRIGGER_TESTIMONIAL_DETAILS_DATA_SUCCESS";
export const TRIGGER_TESTIMONIAL_DETAILS_DATA_FAILED =
  "TRIGGER_TESTIMONIAL_DETAILS_DATA_FAILED";

export const TRIGGER_ADD_TESTIMONIAL = "TRIGGER_ADD_TESTIMONIAL";
export const TRIGGER_ADD_TESTIMONIAL_SUCCESS =
  "TRIGGER_ADD_TESTIMONIAL_SUCCESS";
export const TRIGGER_ADD_TESTIMONIAL_FAILED = "TRIGGER_ADD_TESTIMONIAL_FAILED";

//home page action end
// states contact

export const TRIGGER_CONTACT = "TRIGGER_CONTACT";
export const TRIGGER_CONTACT_SUCCESS = "TRIGGER_CONTACT_SUCCESS";
export const TRIGGER_CONTACT_FAILED = "TRIGGER_CONTACT_FAILED";

// states contact
// states newsletter

export const TRIGGER_NEWSLETTER = "TRIGGER_NEWSLETTER";
export const TRIGGER_NEWSLETTER_SUCCESS = "TRIGGER_NEWSLETTER_SUCCESS";
export const TRIGGER_NEWSLETTER_FAILED = "TRIGGER_NEWSLETTER_FAILED";

// states newletter

// states orders

export const TRIGGER_ORDERS = "TRIGGER_ORDERS";
export const TRIGGER_ORDERS_SUCCESS = "TRIGGER_ORDERS_SUCCESS";
export const TRIGGER_ORDERS_FAILED = "TRIGGER_ORDERS_FAILED";

// states orders

// states getorderstatus

export const TRIGGER_ORDERS_STATUS = "TRIGGER_ORDERS_STATUS";
export const TRIGGER_ORDERS_STATUS_SUCCESS = "TRIGGER_ORDERS_STATUS_SUCCESS";
export const TRIGGER_ORDERS_STATUS_FAILED = "TRIGGER_ORDERS_STATUS_FAILED";

// states getorderstatus

export const TRIGGER_REDEEM_POINTS = "TRIGGER_REDEEM_POINTS";
export const TRIGGER_REDEEM_POINTS_SUCCESS = "TRIGGER_REDEEM_POINTS_SUCCESS";
export const TRIGGER_REDEEM_POINTS_FAILED = "TRIGGER_REDEEM_POINTS_FAILED";

export const TRIGGER_CANCEL_ORDER = "TRIGGER_CANCEL_ORDER";
export const TRIGGER_CANCEL_ORDER_SUCCESS = "TRIGGER_CANCEL_ORDER_SUCCESS";
export const TRIGGER_CANCEL_ORDER_FAILED = "TRIGGER_CANCEL_ORDER_FAILED";

export const TRIGGER_PAYPAL_PAYMENT_FORM = "TRIGGER_PAYPAL_PAYMENT_FORM";
export const TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS =
  "TRIGGER_PAYPAL_PAYMENT_FORM_SUCCESS";
export const TRIGGER_PAYPAL_PAYMENT_FORM_FAILED =
  "TRIGGER_PAYPAL_PAYMENT_FORM_FAILED";

export const TRIGGER_TAX_AMOUNT = "TRIGGER_TAX_AMOUNT";
export const TRIGGER_COUPON_CODE = "TRIGGER_COUPON_CODE";

export const TRIGGER_SAVE_CHECKOUT_FORM = "TRIGGER_SAVE_CHECKOUT_FORM";

export const GET_VWACCESSORIES = "GET_VWACCESSORIES";
export const GET_VWACCESSORIES_SUCCESS = "GET_VWACCESSORIES_SUCCESS";
export const GET_VWACCESSORIES_FAILED = "GET_VWACCESSORIES_FAILED";

export const GET_PAYPAL_APPROVED_DETAILS = "GET_PAYPAL_APPROVED_DETAILS";

export const GET_ORDERS_LIST = "GET_ORDERS_LIST";
export const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS";
export const GET_ORDERS_LIST_FAILED = "GET_ORDERS_LIST_FAILED";

export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILED = "GET_FAQ_FAILED";

export const TRIGGER_UPDATE_PROFILE = "TRIGGER_UPDATE_PROFILE";

export const GET_HOME_PAGE_DATA = "GET_HOME_PAGE_DATA";
export const GET_HOME_PAGE_DATA_SUCCESS = "GET_HOME_PAGE_DATA_SUCCESS";
export const GET_HOME_PAGE_DATA_FAILED = "GET_HOME_PAGE_DATA_FAILED";

export const GET_ABOUT_US_PAGE_DATA = "GET_ABOUT_US_PAGE_DATA";
export const GET_ABOUT_US_PAGE_DATA_SUCCESS = "GET_ABOUT_US_PAGE_DATA_SUCCESS";
export const GET_ABOUT_US_PAGE_DATA_FAILED = "GET_ABOUT_US_PAGE_DATA_FAILED";

export const GET_PRODUCTS_BY_CATEGORY = "GET_PRODUCTS_BY_CATEGORY";
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS =
  "GET_PRODUCTS_BY_CATEGORY_SUCCESS";
export const GET_PRODUCTS_BY_CATEGORY_FAILED =
  "GET_ PRODUCTS_BY_CATEGORY_FAILED";
export const PRIVACY_AND_TERMS = "PRIVACY_AND_TERMS";
export const PRIVACY_AND_TERMS_SUCCESS = "PRIVACY_AND_TERMS_SUCCESS";
export const PRIVACY_AND_TERMS_FAILED = "PRIVACY_AND_TERMS_FAILED";

export const TRIGGER_QUICK_VIEW_DETAILS = "TRIGGER_QUICK_VIEW_DETAILS";
export const TRIGGER_QUICK_VIEW_DETAILS_SUCCESS =
  "TRIGGER_QUICK_VIEW_DETAILS_SUCCESS";
export const TRIGGER_QUICK_VIEW_DETAILS_FAILED =
  "TRIGGER_QUICK_VIEW_DETAILS_FAILED";
