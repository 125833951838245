const config = {};
config.apiBasePath = "https://www.vanagain.com/admin/api/";

// config.apiBasePath = "https://staging.vanagain.com/api/";
// AXdegqb2cxZzL2x -
//   H6SeyLKgD6g1a2q1VITRtUUSCMJyfMR6vu2P_N6QPQmXIIRejWRxN1Yl3V7CEKWT;

config.title = "Vanagain";
// live API key: AXdegqb2cxZzL2x-H6SeyLKgD6g1a2q1VITRtUUSCMJyfMR6vu2P_N6QPQmXIIRejWRxN1Yl3V7CEKWT
// sand box id AWcX_HAUd18YCmvlBjbx6_dAcyNACsNBpt5E10k2RDgIIvta50ary8f4FJKVq4NyQAqgqGAdcF_0RG-Y

config.payPalOptions = {
  "client-id":
    "AXdegqb2cxZzL2x-H6SeyLKgD6g1a2q1VITRtUUSCMJyfMR6vu2P_N6QPQmXIIRejWRxN1Yl3V7CEKWT", //prod,
  // "client-id":
  // "AWcX_HAUd18YCmvlBjbx6_dAcyNACsNBpt5E10k2RDgIIvta50ary8f4FJKVq4NyQAqgqGAdcF_0RG-Y", // dev
  components: "buttons",
  intent: "capture",
  commit: false,
  "disable-funding": "credit,card",
};

config.captchaSiteKey = "6LcWkMUdAAAAAINSDrTX2aisnPDQWDR0ZZbXPiyc"; //prod
// config.captchaSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // dev

config.urlResetPassword = "password/reset";
config.urlLogin = "auth/login";
config.urlLogout = "auth/logout";
config.urlRegister = "register";
config.urlContactUs = "contact/send";
config.urlHomePage = "homePage";
config.urlAddTestimonial = "save-testimonial";
config.urlReedemPoints = "apply-point-discount";
config.urlOrderCancel = "order-cancel";
config.urlPayPalPayment = "payment";
config.urlExpressCheckout = "express/checkout";
config.urlOrdersDetails = "order";

config.urlContact = "contact";
config.urlSubscribeNewsletter = "subscribe-newsletter";
config.urlOrders = "order";
config.urlGetOrderStatus = "getOrderStatus";
config.urlCheckout = "checkout";
config.urlProcessExpressCheckout = "payExp/checkout";
config.urlOrdersList = "orders";
config.urlHeaderContent = "headerContent";
config.urlHomePageData = "page/home";
config.urlContactData = "page/contact";
config.urlAboutPageData = "page/about";
config.urlProfileUpdate = "auth/profile/update";
config.urlCountries = "getCountries";
config.urlStates = "getStates";
config.urlAddToWishlist = "addWishlist";
config.urlWishlists = "wishlists";
config.urlRemoveFromWishlist = "removeWishlist";
config.urlGetPoints = "points";
config.urlGetProductsByCategory = "getProductsByType";
config.urlProfileUpdate = "auth/profile/update";
config.urlGetProductsByTypeAndCategory = "getProductsByTypeAndCategory";
config.urlGetProductDetails = "productDetail";
config.urlGetVanTypes = "getProductsTypes";
config.urlGetVanCategories = "categories";
config.urlGetVanCategoriesSubMenu = "getCategoriesByProductType";
config.urlGetProductsBySku = "searchBySKU";
config.urlGetAllProducts = "products";
config.urlCalculateShipping = "calculate-shipping";
config.urlCouponDetails = "coupon/discount";
config.urlHeaderSearch = "headerSearch";
config.urlGetBlogCategories = "getBlogCategories";
config.urlGetAskKenCategories = "getRandomArticles";
config.urlGetCategoryList = "category";
config.urlSavePaypalOrder = "saveOrder";

config.urlFAQ = "faq";

config.urlGetArticles = "blogs";
config.urlGetArticleDetails = "blogdetail";
config.urlGetArticleComments = "getBlogComments";
config.urlPostArticleComment = "articles/leave-reply";
config.urlRegister = "auth/register";
config.urlSetPassword = "confirm";
config.urlForgetPassword = "auth/resetPassword";

config.vwAccessories = "vw-accessories";

config.privacyAndTerms = "page/privacy-terms";

// config.imageBasePath = "http://localhost/vanagainapi/public/";
// config.imageBasePath = "https://www.staging.vanagain.com/api/";
config.imageBasePath = "https://www.vanagain.com/admin/public/";

export default config;
