import React from "react";
import "../../assets/scss/pages/account/MyAccount.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularLoader from "../../components/shared/CircularLoader";
import { getStatus } from "../../utils";
import moment from "moment";

export default function RecentOrders() {
  const { orderList } = useSelector((state) => state.shopReducer);

  return (
    <div className="dashboard__orders card">
      <div className="card-header">
        <h1 className="points-heading">Recent Orders</h1>
      </div>
      <div className="card-divider"></div>
      <div className="card-table">
        {!orderList?.data?.loading && !orderList?.data?.length && (
          <div className="center-div">No Data </div>
        )}

        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Order Date</th>
                <th>Order Total</th>

                <th>Order Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="position-relative">
              {!orderList?.data?.loading &&
                orderList?.data?.length > 0 &&
                orderList?.data
                  ?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="order-id">#{item?.id}</td>
                        <td> {moment(item?.created_at).format("LL")}</td>
                        <td>${item?.order_total}</td>
                        <td>
                          <i
                            className={`fas ${
                              getStatus(item?.order_status).icon
                            } mr-2`}
                            style={{
                              color: getStatus(item?.order_status)?.color,
                            }}
                          ></i>
                          {getStatus(item?.order_status).status}
                        </td>
                        <td>
                          <button type="btn" className="btn btn-primary btn-sm">
                            {" "}
                            <Link to={`/my-account/order/${btoa(item.id)}`}>
                              View Details
                            </Link>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  .filter((a, i) => i < 3)}

              {orderList?.data?.loading && (
                <div className="center-div">
                  <CircularLoader
                    size={60}
                    color="#32c2ef"
                    type="overlay-loader"
                  />
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
