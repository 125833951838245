import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  TRIGGER_ADD_QUANTITY,
  TRIGGER_ADD_TO_CART,
  TRIGGER_ALL_ADD_TO_CART,
  TRIGGER_CALCULATE_SHIPPING,
  TRIGGER_CALCULATE_SHIPPING_DETAILS,
  TRIGGER_CALCULATE_SHIPPING_SUCCESS,
  TRIGGER_COUPON_CODE,
  TRIGGER_EMPTY_CART,
  TRIGGER_GET_COUPON_DETAILS_SUCCESS,
  TRIGGER_GET_SHIPPING_DETAILS,
  TRIGGER_REDEEM_POINTS_SUCCESS,
  TRIGGER_REMOVE_PRODUCT_FROM_CART,
  TRIGGER_SET_CART_LIST,
  TRIGGER_SHIPPING_CHARGES,
  TRIGGER_SHIPPING_METHODS,
  TRIGGER_SUB_QUANTITY,
  TRIGGER_TAX_AMOUNT,
  TRIGGER_UPDATE_CART,
} from "../redux/actions/actionTypes";
import useCalculateShippingHook from "./useCalculateShippingHook";

export default function useCartHook() {
  const { cart, shippingMethodsList, shippingAddress } = useSelector(
    (state) => state.cartReducer
  );

  const emptyShippingData = () => {
    dispatch({
      type: TRIGGER_SHIPPING_METHODS,
      shippingMethodsList: [],
    });

    dispatch({
      type: TRIGGER_CALCULATE_SHIPPING_SUCCESS,
      shippingObj: {
        loading: false,
        data: [],
        error: null,
      },
    });

    dispatch({
      type: TRIGGER_SHIPPING_CHARGES,
      shippingMethod: {
        shippingCharges: 0,
        shippingName: "",
        shippingMode: "",
      },
    });
    dispatch({
      type: TRIGGER_GET_SHIPPING_DETAILS,
      country: shippingAddress.country,
      state: null,
      postcode: "",
    });
  };
  const dispatch = useDispatch();
  const [initShippingAddress, calculateShippingFunction] =
    useCalculateShippingHook();
  const [calcShippingObj, setCalcShippingObj] = useState(initShippingAddress);
  const [noShippingAvailable, setNoShippingAvailable] = useState(true);
  function calculateShipping(list) {
    calculateShippingFunction(
      list || cart,
      calcShippingObj,
      setCalcShippingObj,
      setNoShippingAvailable
    );
  }
  function addToCartProduct(product, quantity) {
    dispatch({
      type: TRIGGER_ADD_TO_CART,
      addedItem: product,
      quantity: quantity,
    });

    // emptyShippingData();
    calculateShipping();
    let msg = `${product.name} Successfully Added To Cart!`;
    toast.success(msg, {
      toastId: "toastMsg",
      autoClose: 2000,
      hideProgressBar: true,
    });
  }

  function removeProductFromCart(product, i) {
    dispatch({
      type: TRIGGER_REMOVE_PRODUCT_FROM_CART,
      addedItem: product,
    });

    let list = [...cart];
    list.splice(i, 1);
    // emptyShippingData();
    calculateShipping(list);

    dispatch({
      type: TRIGGER_SET_CART_LIST,
      cart: list,
    });

    if (!list.length) {
      cleanCartAfterPayment();
    }
    let msg = `${product.name} Successfully Removed From Cart!`;
    toast.success(msg, {
      autoClose: 2000,
      hideProgressBar: true,
    });
  }

  function addQuantity(product) {
    dispatch({
      type: TRIGGER_ADD_QUANTITY,
      addedItem: product,
    });
    // emptyShippingData();
    calculateShipping();
  }

  function substractQuantity(product) {
    dispatch({
      type: TRIGGER_SUB_QUANTITY,
      addedItem: product,
    });
    // emptyShippingData();
    calculateShipping();
  }

  function updateCart(list, newTotal) {
    calculateShipping(list);
    dispatch({
      type: TRIGGER_UPDATE_CART,
      cart: list,
      total: newTotal,
    });
  }

  function emptyCart() {
    cleanCartAfterPayment();
    emptyShippingData();
  }

  function addAllProductsToCart(list) {
    dispatch({
      type: TRIGGER_ALL_ADD_TO_CART,
      wishlist: list,
    });
    // emptyShippingData();
    calculateShipping();
  }

  function removePoints() {
    dispatch({
      type: TRIGGER_REDEEM_POINTS_SUCCESS,
      payload: [],
    });
  }

  function removeCoupon() {
    dispatch({
      type: TRIGGER_GET_COUPON_DETAILS_SUCCESS,
      payload: [],
    });
  }

  function cleanCartAfterPayment() {
    removeCoupon();
    removePoints();
    dispatch({
      type: TRIGGER_EMPTY_CART,
    });

    dispatch({
      type: TRIGGER_CALCULATE_SHIPPING_SUCCESS,
      payload: [],
    });

    dispatch({
      type: TRIGGER_EMPTY_CART,
    });

    dispatch({
      type: TRIGGER_COUPON_CODE,
      payload: "",
    });
    dispatch({
      type: TRIGGER_GET_SHIPPING_DETAILS,
      country: "US",
      state: "",
      postcode: "",
    });

    dispatch({
      type: TRIGGER_TAX_AMOUNT,
      tax: 0.0,
    });

    dispatch({
      type: TRIGGER_CALCULATE_SHIPPING_DETAILS,
      country: "",
      state: "",
      postcode: "",
    });
    emptyShippingData();
  }

  return [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
    removePoints,
    removeCoupon,
    cleanCartAfterPayment,
    emptyShippingData,
  ];
}
