import React from "react";
import { useSelector } from "react-redux";

export default function OrderCancel() {
  const { cancelOrder } = useSelector((state) => state.cartReducer);

  return (
    <div className="container order-cancel">
      <div className="card">
        <div className="alert alert-light text-center mb-0 mt-1">
          {cancelOrder?.data?.message}
        </div>
      </div>
    </div>
  );
}
