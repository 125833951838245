import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function CircularLoader({ color, size, type }) {
  return (
    <>
      {type === "overlay-loader" ? (
        <div className="loading-overlay">
          <span className="fas fa-spinner fa-3x fa-spin center-div"></span>
        </div>
      ) : (
        <CircularProgress
          size={size}
          style={{ color: color ? color : "#ffffff" }}
        />
      )}
    </>
  );
}
