import {
  TRIGGER_COUNTRY,
  TRIGGER_COUNTRY_SUCCESS,
  TRIGGER_COUNTRY_FAILED,
  TRIGGER_STATES,
  TRIGGER_STATES_SUCCESS,
  TRIGGER_STATES_FAILED,
  TRIGGER_SHIPPING_COUNTRY,
  TRIGGER_SHIPPING_COUNTRY_SUCCESS,
  TRIGGER_SHIPPING_COUNTRY_FAILED,
  TRIGGER_SHIPPING_STATES,
  TRIGGER_SHIPPING_STATES_SUCCESS,
  TRIGGER_SHIPPING_STATES_FAILED,
} from "../actions/actionTypes";

const initialState = {
  countriesObj: {
    loading: false,
    data: [],
    error: null,
  },
  statesObj: {
    loading: false,
    data: [],
    error: null,
  },

  shippingCountryList: {
    loading: false,
    data: [],
    error: null,
  },
  shippingStateList: {
    loading: false,
    data: [],
    error: null,
  },
};

const countryAndStateReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_COUNTRY:
      return {
        ...state,
        countriesObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_COUNTRY_SUCCESS:
          return {
        ...state,
        countriesObj: {
          loading: false,
          data: action.payload.countries.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
          error: null,
        },
      };

    case TRIGGER_COUNTRY_FAILED:
      return {
        ...state,
        countriesObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY:
      return {
        ...state,
        shippingCountryList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY_SUCCESS:
          return {
        ...state,
        shippingCountryList: {
          loading: false,
          data: action.payload.countries.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
          error: null,
        },
      };

    case TRIGGER_SHIPPING_COUNTRY_FAILED:
      return {
        ...state,
        shippingCountryList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_STATES:
      return {
        ...state,
        statesObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_STATES_SUCCESS:
          return {
        ...state,
        statesObj: {
          loading: false,
          data: action.payload.states,
          error: null,
        },
      };

    case TRIGGER_STATES_FAILED:
      return {
        ...state,
        statesObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_SHIPPING_STATES:
      return {
        ...state,
        shippingStateList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_SHIPPING_STATES_SUCCESS:
          return {
        ...state,
        shippingStateList: {
          loading: false,
          data: action.payload.states,
          error: null,
        },
      };

    case TRIGGER_SHIPPING_STATES_FAILED:
      return {
        ...state,
        shippingStateList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default countryAndStateReducer;
