import React from "react";
import { useSelector } from "react-redux";
import config from "../../utils/config";
import { Link } from "react-router-dom";
import useCartHook from "../../hooks/useCartHook";

export default function Dropcart({ close }) {
  const { cart, total, shippingMethod } = useSelector(
    (state) => state.cartReducer
  );
  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
  ] = useCartHook();
  return (
    <div className="dropcart">
      {cart.length > 0 ? (
        <div>
          <ul className="dropcart__list">
            {cart
              .map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <li className="dropcart__item">
                      <div className="dropcart__item-image image image--type--product">
                        <Link
                          className="image__body"
                          to={`/product/${item.slug}`}
                          onClick={close}
                        >
                          <img
                            alt=""
                            className="image__tag"
                            src={
                              item.featured_image
                                ? `${config.imageBasePath}${item.featured_image}`
                                : `https://www.vanagain.com/public/assets/uploads/no_img.gif`
                            }
                          />
                        </Link>
                      </div>
                      <div className="dropcart__item-info">
                        <div className="dropcart__item-name">
                          <Link to={`/product/${item.slug}`} onClick={close}>
                            {item?.name}
                          </Link>
                        </div>
                        <div className="dropcart__item-meta">
                          <div className="dropcart__item-quantity">
                            {item.quantity}
                          </div>
                          <div className="dropcart__item-price">
                            {" "}
                            $ {Number(item.regular_price).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          removeProductFromCart(item, i);
                        }}
                        className="dropcart__item-remove  btn btn-sm btn-icon btn-muted"
                      >
                        <i
                          className="fa fa-minus-circle remove-icon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </li>
                    <li className="dropcart__divider" role="presentation"></li>{" "}
                  </React.Fragment>
                );
              })
              .filter((a, i) => i < 5)}
          </ul>
          <div className="dropcart__totals">
            <table>
              <tbody>
                <tr>
                  <th>Total</th>
                  <td>${Number(total).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="dropcart__actions">
            <Link className="btn btn-secondary" to="/cart" onClick={close}>
              View Cart
            </Link>
            <Link
              className="btn btn-primary"
              to={shippingMethod?.shippingCharges ? "/checkout" : "/cart"}
              onClick={close}
            >
              Checkout
            </Link>
          </div>
        </div>
      ) : (
        <div className="dropcart__empty">Your shopping cart is empty!</div>
      )}
    </div>
  );
}
