import {
   TRIGGER_GET_VAN_TYPES_FAILED,
   TRIGGER_GET_VAN_TYPES_SUCCESS,
   TRIGGER_GET_VAN_TYPES,
  TRIGGER_GET_VAN_CATEGORIES_FAILED,
  TRIGGER_GET_VAN_CATEGORIES_SUCCESS,
  TRIGGER_GET_VAN_CATEGORIES
} from "../actions/actionTypes";

const initialState = {
  mainMenuList: {
    loading: false,
    data: [],
    error: null,
  },
  categories:{
    loading: false,
    data: [],
    error: null,
  }
};

const mainMenuReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_GET_VAN_TYPES:
      return {
        ...state,
        mainMenuList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_VAN_TYPES_SUCCESS:
      return {
        ...state,
        mainMenuList: {
          loading: false,
          data: action.payload.vanTypes.sort((a, b) => a.id - b.id),
          error: null,
        },
      };

    case TRIGGER_GET_VAN_TYPES_FAILED:
      return {
        ...state,
        mainMenuList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

      case  TRIGGER_GET_VAN_CATEGORIES:
      return {
        ...state,
        categories: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case  TRIGGER_GET_VAN_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          loading: false,
          data: action.payload.categories,
          error: null,
        },
      };

    case  TRIGGER_GET_VAN_CATEGORIES_FAILED:
      return {
        ...state,
        categories: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
   
    default:
      return state;
  }
};

export default mainMenuReducer;
