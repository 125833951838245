import React from "react";

export default function useCalculateTotalHook() {
  function calculateTotal(
    total,
    tax,
    shippingCharges,
    pointDiscount,
    couponDiscount
  ) {
    let couponAmount = 0;
    if (couponDiscount) {
      couponAmount = couponDiscount.split("$")[1];
    }
    return Number(
      Number(total) +
        (tax ? Number(tax) : 0) +
        Number(shippingCharges) -
        (pointDiscount ? Number(pointDiscount) : 0) -
        (couponAmount ? Number(couponAmount) : 0)
    ).toFixed(2);
  }
  return [calculateTotal];
}
