import React, { useEffect, useState } from "react";
import BlockSpace from "../components/blocks/BlockSpace";
import { getOrderStatus } from "../redux/actions/shopAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularLoader from "../components/shared/CircularLoader";
import useSetMetaTags from "../hooks/useSetMetaTags";

export default function OrderStatus() {
  const [orderNumber, setOrderNumber] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [setMetaTags] = useSetMetaTags();

  const dispatch = useDispatch();
  const { orderStatus } = useSelector((state) => state.shopReducer);

  useEffect(() => {
    setMetaTags("Order Status", "Order Status", "Order Status");
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (orderNumber !== "") {
      return dispatch(
        getOrderStatus(
          {
            order_id: orderNumber,
          },
          (resp) => {
            if (resp.orderData.status == "error") {
              toast.error("Please enter valid order id!", {
                toastId: "toastMsg",
                autoClose: 2000,
                hideProgressBar: true,
              });
            } else {
              toast.success("Successful!", {
                toastId: "toastMsg",
                autoClose: 2000,
                hideProgressBar: true,
              });
              setIsSubmit(false);
              setOrderNumber("");
            }
          }
        )
      );
    }
  };
  return (
    <div className="block">
      <BlockSpace className="block-space--layout--before-footer" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div className="card ml-md-3 mr-md-3">
              <div className="card-body card-body--padding--2">
                <h1 className="card-title card-title--lg">Track Order</h1>

                <p className="mb-4">
                  Enter the order number that was used to create the order, and
                  then click the get status button.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="track-order-id">Order Number</label>
                    <input
                      id="track-order-id"
                      type="text"
                      className="form-control"
                      placeholder={"Enter Order Number"}
                      value={orderNumber}
                      onChange={(e) => setOrderNumber(e.target.value)}
                    />
                    {isSubmit && orderNumber === "" && (
                      <div className="invalid-feedback">
                        Please enter Order Number!
                      </div>
                    )}
                  </div>
                  <div className="form-group pt-4 mb-1">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      disabled={orderStatus?.loading}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="mr-1"> Get status</div>
                        {isSubmit && orderStatus?.loading && (
                          <CircularLoader size={15} />
                        )}
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
        </div>

        {orderStatus?.data?.length > 0 && (
          <div className=" rewards card px-5 pt-4 ">
            <div className="text-center mb-3">
              <h5>Order Status</h5>
            </div>
            <div className="card-divider"></div>
            <div className="card-table">
              {!orderStatus?.data?.length && (
                <div className="center-div">
                  <p>No data</p>
                </div>
              )}
     
              {orderStatus?.data?.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Order Number</th>
                        <th>Order Status</th>
                        <th>Tracking Number</th>
                        <th>Additional Tracking Number</th>
                        <th>Shiping Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderStatus?.data?.map((i) => {
                        return (
                          <tr key={i?.order_number}>
                            <td>{i?.order_number || "-"}</td>
                            <td>{i?.order_status || "-"}</td>
                            <td>{i?.custom_tracking_number || "-"}</td>
                            <td>{i?.shiprush_tracking_number || "-"}</td>
                            <td>{i?.shipping_notes || "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
