import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
  GET_VWACCESSORIES,
  GET_VWACCESSORIES_SUCCESS,
  GET_VWACCESSORIES_FAILED,
} from "./actionTypes";

export function getVWAccessories(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.vwAccessories}`,
      dispatch,
      defaultAction: GET_VWACCESSORIES,
      successAction: GET_VWACCESSORIES_SUCCESS,
      failedAction: GET_VWACCESSORIES_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
