import React, { useState } from 'react'

export default function useCounter() {
    const [count, setCount] = useState(1);
    function incrementCount() {
      setCount(count + 1);
    }
    function decrementCount() {
        if(count>1){
            setCount(count - 1);
        }
    }
    return [count, incrementCount, decrementCount,setCount];
}
