import React from "react";

export default function BlockFacebookPage() {
  return (
    <div className=" facebook-plugin">
      <iframe
        name="f243c44825c14f4"
        width="1000px"
        height="1000px"
        data-testid="fb:page Facebook Social Plugin"
        title="fb:page Facebook Social Plugin"
        allowFullScreen={true}
        allow="encrypted-media"
        src="https://www.facebook.com/v2.12/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3f11be5ecc7a4c%26domain%3Dwww.vanagain.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.vanagain.com%252Ff1f0f87abfb2714%26relation%3Dparent.parent&amp;container_width=240&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fpages%2FVan-Again%2F296406512340&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false"
        style={{
          border: "none",
          visibility: "visible",
          width: "100%",
          height: "130px",
        }}
        className=""
      ></iframe>
    </div>
  );
}
