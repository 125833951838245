import React from "react";
import ImageGallery from "react-image-gallery";
import ReactImageMagnify from "react-image-magnify";

export default function ImageSlider({ images }) {
  const imageMagnify = (img) => {
    return (
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: img.img,
            isFluidWidth: true,
            src: img.thumbnail,
          },
          largeImage: {
            src: img.original,
            width: 640,
            height: 480,
          },
          shouldUsePositiveSpaceLens: true,
          // enlargedImagePortalId: "productGalleyMagnify",
          enlargedImagePosition: "over",
        }}
      />
    );
  };

  return (
    <ImageGallery
      autoPlay={false}
      renderItem={imageMagnify}
      useBrowserFullscreen={false}
      showPlayButton={false}
      showFullscreenButton={false}
      showNav={false}
      items={images}
    />
  );
}
