import React, { useEffect, useState, useCallback } from "react";
import "../assets/scss/pages/Cart.scss";
import BlockSpace from "../components/blocks/BlockSpace";
import { Link, useN, useHistory, useHistoryavigate } from "react-router-dom";
import Breadcrumb from "../components/shared/Breadcrumb";
import Popover from "@material-ui/core/Popover";
import { useDispatch, useSelector } from "react-redux";
import config from "../utils/config";
import useCartHook from "../hooks/useCartHook";
import { Cross9LightSvg } from "../svg/index";
import { ReactSVG } from "react-svg";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  triggerGetCouponDetails,
  triggerShippingState,
  triggerShippingCountry,
} from "../redux/actions/cartAction";
import CircularLoader from "../components/shared/CircularLoader";
import useExtractDataFromHtml from "../hooks/useExtractDataFromHtml";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import {
  TRIGGER_COUPON_CODE,
  TRIGGER_GET_SHIPPING_DETAILS,
  TRIGGER_SET_CART_LIST,
  TRIGGER_TAX_AMOUNT,
  TRIGGER_GET_COUPON_DETAILS_FAILED,
} from "../redux/actions/actionTypes";
import useCalculateTotalHook from "../hooks/useCalculateTotalHook";
import useShippingMethodsList from "../hooks/useShippingMethodsList";
import usePaypalMethods from "../hooks/usePaypalMethods";
import ReedemPoints from "../components/shared/RedeemPoints";
import { isLogin } from "../utils";
import useUserDetailsHook from "../hooks/useUserDetailsHook";
import OverlayLoader from "../components/shared/OverlayLoader";
import useCalculateShippingHook from "../hooks/useCalculateShippingHook";
import { ClickAwayListener, Popper } from "@material-ui/core";
import useSetMetaTags from "../hooks/useSetMetaTags";
import { CheckOutlined } from "@material-ui/icons";

export default function Cart() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useHistory();
  const {
    cart,
    total,
    shippingAddress,
    shippingMethod,
    couponDetails,
    tax,
    shippingCountry,
    redeemPoints,
    expressCheckout,
    cancelOrder,
    checkout,
  } = useSelector((state) => state.cartReducer);
  const { homePageData } = useSelector((state) => state.homePageReducer);
  const [cartTotal, setCartTotal] = useState(total);
  const [cartList, setCartList] = useState(cart.slice());
  const [calculateTotal] = useCalculateTotalHook();
  const [userData] = useUserDetailsHook();
  const [createOrder, onClick, onCancel, onApprove, onError] =
    usePaypalMethods();
  const [coupon, setCoupon] = useState({
    isSubmit: false,
    code: "",
  });

  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  const [shippingMethodList, setShippingMethodList] = useState();
  const [noShippingAvailable, setNoShippingAvailable] = useState(true);
  const [redeemPointPopUp, setRedeemPointPopUp] = useState(false);
  const [pointsAmount, setPointsAmount] = useState();
  const [initShippingAddress, calculateShippingFunction] =
    useCalculateShippingHook();
  const [calcShippingObj, setCalcShippingObj] = useState(initShippingAddress);

  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
    removePoints,
    removeCoupon,
    cleanCartAfterPayment,
  ] = useCartHook();

  const [extractData] = useExtractDataFromHtml();
  const [isSubmit, setIsSubmit] = useState(false);
  const [shippingMethods] = useShippingMethodsList();
  const [points, setPoints] = useState(0);

  const showRedeemPopUp = () => {
    setRedeemPointPopUp(true);
  };

  const hideRedeemPopUp = () => {
    setRedeemPointPopUp(false);
  };

  const [setMetaTags] = useSetMetaTags();
  useEffect(() => {
    setMetaTags("Cart", "Cart", "Cart");
  });

  useEffect(() => {
    dispatch(triggerShippingCountry());
    let index = shippingCountry?.data?.findIndex((item) => {
      return item.sortname === initShippingAddress.calc_shipping_country;
    });
    let countryId = shippingCountry?.data[index]?.id
      ? shippingCountry?.data[index]?.id
      : 231;
    dispatch(triggerShippingState({ country_id: countryId }));
  }, []);

  useEffect(() => {

    if (couponDetails?.data?.error && coupon?.isSubmit) {
      toast.error(couponDetails?.data?.message || "Something went wrong!", {
        toastId: "toastMsg",
        autoClose: 2100,
        hideProgressBar: true,
      });
    }
  }, [couponDetails]);

  useEffect(() => {
    if (isLogin()) {
      calculatePoints();
      pointAmount(userData?.earned_points);
    }
  }, [total]);

  function calculatePoints() {
    if (homePageData?.data?.settings?.pointSetting) {
      const { earn_points_conversion_points, earn_points_conversion_rate } =
        homePageData?.data?.settings?.pointSetting;
      let point = 0;

      if (
        earn_points_conversion_rate > 0 &&
        earn_points_conversion_points > 0
      ) {
        point =
          (total / earn_points_conversion_rate) * earn_points_conversion_points;
      } else {
        point = total;
      }

      setPoints(Math.ceil(point));
    }
  }

  function pointAmount(points) {
    if (homePageData?.data?.settings?.pointSetting) {
      const { redemption_conversion_points, redemption_conversion_rate } =
        homePageData?.data?.settings?.pointSetting;
      let amount;
      if (redemption_conversion_rate > 0 && redemption_conversion_points > 0) {
        amount =
          (points / redemption_conversion_points) * redemption_conversion_rate;
      } else {
        amount = points;
      }

      setPointsAmount(Number(amount).toFixed(2));
    }
  }

  useEffect(() => {
    calculateTax();
  }, [total, shippingMethod?.shippingCharges]);

  const calculateTax = () => {
    let tax = homePageData?.data?.settings?.taxRate;
    let taxRate;

    if (tax > 0 && shippingAddress?.state === "NJ") {
      let rate =
        (Number(total) + Number(shippingMethod?.shippingCharges)) *
        (Number(tax) / 100.0);
      taxRate = rate;
    } else {
      taxRate = 0.0;
    }
    dispatch({
      type: TRIGGER_TAX_AMOUNT,
      tax: Number(taxRate),
    });
  };

  useEffect(() => {
    !cart ? navigate.push("/") : setCartList(cart);
  }, [cart]);

  useEffect(() => {}, [shippingMethod]);
  const incrementCount = (product) => {
    let list = JSON.parse(JSON.stringify(cartList));
    let index = list.findIndex((item) => {
      return item.id == product.id;
    });
    list[index].quantity += 1;
    list[index].total_price =
      list[index].quantity * parseFloat(list[index].regular_price);
    let newTotal = cartTotal + parseFloat(product.regular_price);
    setCartList(list);
    setCartTotal(newTotal);
  };
  const decrementCount = (product) => {
    let list = JSON.parse(JSON.stringify(cartList));
    let index = list.findIndex((item) => {
      return item.id == product.id;
    });
    if (product.quantity > 1) {
      list[index].quantity -= 1;
      let newTotal = cartTotal - parseFloat(product.regular_price);
      setCartTotal(newTotal);
      setCartList(list);
    }
  };

  const updateCartFun = () => {
    setIsUpdated(false);
    let cartTotalAmount = cartList.reduce(function (accumulator, item) {
      return accumulator + Number(item.quantity) * Number(item.regular_price);
    }, 0);
    // Set the cartTotal amount
    setCartTotal(cartTotalAmount);
    // Calculate Shipping
    // calculateShippingFunction(
    //   cartList,
    //   calcShippingObj,
    //   setCalcShippingObj,
    //   setNoShippingAvailable
    // );
    // Update cart state
    updateCart(cartList, cartTotalAmount);
  };

  const productBeforeUpdate = (product) => {
    let list = JSON.parse(JSON.stringify(cart));
    let index = list.findIndex((item) => {
      return item.id == product.id;
    });
    if (index != -1 && product) {
      const { quantity, regular_price } = list[index];
      return Number(regular_price * quantity).toFixed(2);
    }
  };

  const onClickCheckout = () => {
    setIsSubmit(true);
    if (shippingMethod?.shippingName == "") {
    } else {
      navigate.push("/checkout");
    }
  };

  const removeProduct = (item, i) => {
    removeProductFromCart(item, i);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const submitCouponCode = (e) => {
    e.preventDefault();
    setCoupon({ ...coupon, isSubmit: true });

    dispatch({
      type: TRIGGER_COUPON_CODE,
      code: coupon.code,
    });
    if (coupon.code !== "") {
      if (couponDetails?.data?.discount_price) {
        toast.error("Coupon already exists!", {
          toastId: "toastMsg",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else {
        return dispatch(
          triggerGetCouponDetails(
            {
              coupon_code: coupon.code,
              cartTotal: total,
              cartQty: cart?.length,
              cartItem: cart,
            },
            (resp) => {
              if (resp?.success) {
                toast.success("Your coupon successfully added", {
                  toastId: "toastMsg",
                  autoClose: 2000,
                  hideProgressBar: true,
                });
              }
              setCoupon({
                isSubmit: false,
                code: "",
              });
            },
            (err) => {
              dispatch({
                type: TRIGGER_GET_COUPON_DETAILS_FAILED,
                payload: [],
              });

            }
          )
        );
      }
    }
  };

  return (
    <div className="container cart-container">
      {expressCheckout?.loading || (checkout?.loading && <OverlayLoader />)}

      {cancelOrder?.loading && <OverlayLoader />}
      <Breadcrumb
        list={[
          {
            title: "Cart",
            url: "/cart",
          },
        ]}
      />

      {cartList.length > 0 ? (
        <>
          <h1 className="block-header__title  animate__animated animate__fadeIn">
            Cart
          </h1>

          <p className="cart-discount-points">
            COMPLETE YOUR ORDER AND EARN
            {" " + Math.ceil(total) + " "}
            POINTS FOR A DISCOUNT ON A FUTURE PURCHASE!
          </p>
          {isLogin() && (
            <>
              {!redeemPoints?.data?.redeem_points_amount && (
                <>
                  <p className="cart-discount-points">
                    USE {userData?.earned_points} POINTS FOR $
                    {pointsAmount + " "}
                    DISCOUNT ON THIS ORDER.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary my-3"
                    onClick={showRedeemPopUp}
                  >
                    Apply Discount
                  </button>
                </>
              )}
            </>
          )}

          {/* {cart?.length} cart length */}
          {redeemPointPopUp && (
            <ReedemPoints isOpen={redeemPointPopUp} close={hideRedeemPopUp} />
          )}
          <div className="cart">
            <div className="cart__table cart-table">
              <div className="cart__table cart-table">
                <table className="cart-table__table">
                  <thead className="cart-table__head">
                    <tr className="cart-table__row heading">
                      <th className="cart-table__column cart-table__column--image">
                        Image
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        Product
                      </th>
                      <th className="cart-table__column cart-table__column--quantity">
                        Quantity
                      </th>
                      <th className="cart-table__column cart-table__column--price">
                        Price
                      </th>

                      <th className="cart-table__column cart-table__column--total">
                        Total
                      </th>
                      <th className="cart-table__column cart-table__column--remove">
                        <span className="sr-only">Remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cart-table__body">
                    {cartList.map((item, i) => {
                      return (
                        <tr className="cart-table__row" key={item?.id}>
                          <td className="cart-table__column cart-table__column--image">
                            <div className="image image--type--product">
                              <Link
                                className="image__body"
                                to={`/product/${item?.slug}`}
                              >
                                <img
                                  alt={item?.name}
                                  className="image__tag"
                                  src={
                                    item.featured_image
                                      ? `${config.imageBasePath}${item.featured_image}`
                                      : "https://www.vanagain.com/public/assets/uploads/no_img.gif"
                                  }
                                />
                              </Link>
                            </div>
                          </td>
                          <td className="cart-table__column cart-table__column--product">
                            <div className="product-name-ellipsis">
                              <Link
                                className="cart-table__product-name"
                                to={`/product/${item?.slug}`}
                              >
                                {item?.name}
                              </Link>
                            </div>
                          </td>

                          <td
                            className="cart-table__column cart-table__column--quantity"
                            data-title="Quantity"
                          >
                            <div className="input-number cart-table__quantity">
                              <input
                                className="form-control input-number__input"
                                type="number"
                                min="1"
                                readOnly
                                value={item.quantity}
                              />
                              <div
                                className="input-number__add"
                                onClick={() => {
                                  incrementCount(item);
                                }}
                              ></div>
                              <div
                                className="input-number__sub"
                                onClick={() => {
                                  decrementCount(item);
                                }}
                              ></div>
                            </div>
                          </td>
                          <td
                            className="cart-table__column cart-table__column--price"
                            data-title="Price"
                          >
                            ${item.regular_price}
                          </td>
                          <td
                            className="cart-table__column cart-table__column--total"
                            data-title="Total"
                          >
                            {!isUpdated
                              ? `$${productBeforeUpdate(item)}`
                              : `$${(
                                  Number(item.regular_price) *
                                  Number(item.quantity)
                                ).toFixed(2)}`}
                          </td>
                          <td className="cart-table__column cart-table__column--remove">
                            <button
                              type="button"
                              className="cart-table__remove btn btn-sm btn-icon btn-muted"
                              onClick={() => {
                                removeProduct(item, i);
                              }}
                            >
                              {/* <ReactSVG src={Cross9LightSvg} /> */}
                              <i
                                className="fa fa-minus-circle remove-icon"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot className="cart-table__foot">
                    <tr>
                      <td colSpan="6">
                        <div className="cart-table__actions">
                          <form
                            className="cart-table__coupon-form row flex-wrap"
                            onSubmit={submitCouponCode}
                          >
                            <div className="form-group mb-0 col flex-grow-1">
                              <label htmlFor="coupon-code" className="sr-only">
                                Coupon Code
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="coupon-code"
                                placeholder="Coupon Code"
                                value={coupon.code}
                                onChange={(e) => {
                                  setCoupon({
                                    ...coupon,
                                    code: e.target.value,
                                  });
                                }}
                              />

                              {coupon.code === "" && coupon.isSubmit && (
                                <div className="invalid-feedback">
                                  Please enter coupon code.
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-0 col-auto">
                              <button
                                type="submit"
                                className="btn btn-sm btn-primary"
                                disabled={couponDetails?.loading}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="mr-1">Apply Coupon</div>

                                  {couponDetails?.loading && (
                                    <CircularLoader size={15} />
                                  )}
                                </div>
                              </button>
                            </div>
                          </form>
                          <div className="cart-table__update-button">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary mr-3"
                              disabled=""
                              onClick={() => cleanCartAfterPayment()}
                            >
                              Empty Cart
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              disabled=""
                              onClick={() => {
                                updateCartFun();
                              }}
                            >
                              Update Cart
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="cart__totals">
              <div className="card">
                <div className="card-body card-body--padding--2">
                  <h3 className="card-title">Cart Totals</h3>
                  <table className="cart__totals-table">
                    <thead>
                      <tr>
                        <th>Subtotal</th>
                        <td> ${Number(total).toFixed(2)}</td>
                      </tr>

                      {redeemPoints?.data?.redeem_points_amount && (
                        <tr>
                          <th>Points Redemption</th>
                          <td>
                            -${redeemPoints?.data?.redeem_points_amount}
                            <button
                              className="btn  btn-outline-primary  btn-sm ml-2"
                              onClick={removePoints}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      <tr>
                        <th>Shipping</th>
                        <td>{shippingMethods}</td>
                      </tr>
                      <tr>
                        <th>Tax</th>
                        <td>${Number(tax).toFixed(2)}</td>
                      </tr>

                      {couponDetails?.data?.discount_price && (
                        <tr>
                          <th className="pb-0">Discount</th>
                          <td>
                            <div className="d-flex justify-content-end">
                              {couponDetails?.data?.discount_price}
                              <button
                                className="btn  btn-danger  btn-sm ml-1"
                                onClick={removeCoupon}
                              >
                                Remove Coupon
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Total</th>
                        <td>
                          $
                          {calculateTotal(
                            total,
                            tax,
                            shippingMethod?.shippingCharges,
                            redeemPoints?.data?.redeem_points_amount,
                            couponDetails?.data?.discount_price
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  {isSubmit &&
                    (!setShippingMethodList.length ||
                      shippingMethod?.shippingName == "") && (
                      <div className="mb-3">
                        <Alert severity="error">
                          Please calculate shipping first.
                        </Alert>
                      </div>
                    )}

                  <button
                    className="btn btn-primary btn-xl btn-block"
                    onClick={() => {
                      onClickCheckout();
                    }}
                  >
                    Proceed to checkout
                  </button>

                  {shippingMethod?.shippingName !== "" && (
                    <>
                      <p className="text-center my-2">
                        <small>OR</small>
                      </p>
                      <div>
                        <PayPalScriptProvider options={config.payPalOptions}>
                          <PayPalButtons
                            style={{ layout: "horizontal" }}
                            createOrder={createOrder}
                            onClick={onClick}
                            onCancel={onCancel}
                            onApprove={onApprove}
                            onError={onError}
                            forceReRender={[
                              total,
                              shippingMethod?.shippingCharges,
                              redeemPoints?.data?.redeem_points_amount,
                              couponDetails?.data?.discount_price,
                              tax,
                            ]}
                          />
                        </PayPalScriptProvider>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="block-empty">
          <div className="center-div">
            <h2 className="block-empty__title">Cart is empty</h2>
            <div className="block-empty__message">
              You have no products in your cart.
              <br />
              Go to the home page to start browsing our store.
            </div>
            <Link className="btn btn-primary mt-4" to="/shop">
              Return to shop
            </Link>
          </div>
        </div>
      )}

      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
