import { makeAPICall } from "../../utils";
import config from "../../utils/config";
import {
 TRIGGER_GET_BLOG_CATEGORIES,
 TRIGGER_GET_BLOG_CATEGORIES_SUCCESS,
 TRIGGER_GET_BLOG_CATEGORIES_FAILED,
 TRIGGER_GET_ASK_KEN,
 TRIGGER_GET_ASK_KEN_SUCCESS,
 TRIGGER_GET_ASK_KEN_FAILED,
 TRIGGER_GET_CATEGORY_LIST,
 TRIGGER_GET_CATEGORY_LIST_SUCCESS,
 TRIGGER_GET_CATEGORY_LIST_FAILED,
} from "./actionTypes";

export function getBlogCategories(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${config.apiBasePath}${config.urlGetBlogCategories}`,
      dispatch,
      defaultAction:  TRIGGER_GET_BLOG_CATEGORIES,
      successAction:  TRIGGER_GET_BLOG_CATEGORIES_SUCCESS,
      failedAction:  TRIGGER_GET_BLOG_CATEGORIES_FAILED,
      type: "GET",
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getBlogDetails(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetBlogCategories}`,
        dispatch,
        defaultAction:  TRIGGER_GET_BLOG_CATEGORIES,
        successAction:  TRIGGER_GET_BLOG_CATEGORIES_SUCCESS,
        failedAction:  TRIGGER_GET_BLOG_CATEGORIES_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }


  export function getAskKenCategories(obj, callback) {
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetAskKenCategories}`,
        dispatch,
        defaultAction:  TRIGGER_GET_ASK_KEN,
        successAction:  TRIGGER_GET_ASK_KEN_SUCCESS,
        failedAction:  TRIGGER_GET_ASK_KEN_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }



  export function getCategoryList(obj, callback) {
    // console.log(obj)
    return (dispatch) => {
      const params = {
        url: `${config.apiBasePath}${config.urlGetCategoryList}/${obj.slug}`,
        dispatch,
        defaultAction:  TRIGGER_GET_CATEGORY_LIST,
        successAction:  TRIGGER_GET_CATEGORY_LIST_SUCCESS,
        failedAction:  TRIGGER_GET_CATEGORY_LIST_FAILED,
        type: "GET",
        headers: {},
        params: obj,
        callback,
      };
      makeAPICall(params);
    };
  }