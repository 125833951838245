import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../utils/config";
import {
  triggerCancelOrder,
  triggerExpressCheckout,
} from "../redux/actions/cartAction";
import useValidateCheckout from "./useValidateCheckout";
import useCartHook from "./useCartHook";
import axios from "axios";
import {
  GET_PAYPAL_APPROVED_DETAILS,
  TRIGGER_CHECKOUT,
  TRIGGER_CHECKOUT_SUCCESS,
} from "../redux/actions/actionTypes";

export default function usePaypalCheckoutMethods() {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const { checkout, expressCheckout } = useSelector(
    (state) => state.cartReducer
  );

  const history = useHistory();
  const [validateCheckout, createCheckoutObj] = useValidateCheckout();
  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
    removePoints,
    removeCoupon,
    cleanCartAfterPayment,
  ] = useCartHook();

  const {
    total,
    tax,
    shippingMethod,
    redeemPoints,
    couponDetails,
    paypalDetails,
  } = useSelector((state) => state.cartReducer);

  useEffect(() => {
    if (checkout?.data?.last_order_id) {
      setOrderId(checkout?.data?.last_order_id);
      // expressCheckoutFunction(paypalDetails, "approved");
    }
  }, [checkout?.data]);

  // useEffect(() => {
  //   if (checkout?.data && isCanceled) {
  //     toast.error("Payment Cancelled!", {
  //       toastId: "toastMsg",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //     });

  //     dispatch(
  //       triggerCancelOrder(
  //         {
  //           order_id: checkout?.data?.last_order_id,
  //         },
  //         (response) => {
  //           if (response) {
  //             history.push(`/order-cancel/${checkout?.data?.last_order_id} `);
  //           }
  //         }
  //       )
  //     );
  //   }
  // }, [checkout?.data, isCanceled]);

  const createOrder = (data, actions) => {
    let couponAmount = 0;
    if (couponDetails?.data?.discount_price) {
      couponAmount = couponDetails?.data?.discount_price.split("$")[1];
    }
    let newTotal = (
      Number(total) +
      Number(tax || 0) +
      Number(shippingMethod?.shippingCharges || 0) -
      Number(redeemPoints?.data?.redeem_points_amount || 0) -
      Number(couponAmount || 0)
    ).toFixed(2);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: newTotal,
          },
        },
      ],
    });
  };

  const onClick = (data) => {
    setIsClicked(true);

    // triggerCheckoutApi();
    // console.log("*", document.getElementById("checkout-form"));
    // document.getElementById("checkout-form").submit();
    // axios
    //   .post(
    //     `${config.apiBasePath}${config.urlProcessExpressCheckout}`,
    //     createCheckoutObj({}, "paypal")
    //   )
    //   .then((resp) => {
    //     console.log("🚀 ~ file: usePaypalCheckoutMethods.js:117 ~ .then ~ resp:", resp)
    //     if (resp) {
    //       dispatch({
    //         type: TRIGGER_CHECKOUT_SUCCESS,
    //         payload: resp?.data,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const onCancel = (data, actions) => {
    setIsCanceled(true);
    toast.error("Payment Cancelled!", {
      toastId: "toastMsg",
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  const onApprove = async (data, actions, expressCheckout, checkoutObj) => {
    dispatch({
      type: TRIGGER_CHECKOUT,
      payload: {},
    });
    return actions.order.capture().then(function (details) {
      expressCheckout(details, checkoutObj);
      dispatch({
        type: GET_PAYPAL_APPROVED_DETAILS,
        paypalDetails: details,
      });
    });
    // axios
    //   .post(
    //     `${config.apiBasePath}${config.urlProcessExpressCheckout}`,
    //     createCheckoutObj({}, "paypal")
    //   )
    //   .then((resp) => {
    //     console.log(
    //       "🚀 ~ file: usePaypalCheckoutMethods.js:117 ~ .then ~ resp:",
    //       resp
    //     );
    //     if (resp) {
    //       dispatch({
    //         type: TRIGGER_CHECKOUT_SUCCESS,
    //         payload: resp?.data,
    //       });
    //       return actions.order.capture().then(function (details) {
    //         console.log(
    //           "🚀 ~ file: usePaypalCheckoutMethods.js:135 ~ details:",
    //           details,
    //           resp?.data?.last_order_id
    //         );

    //         expressCheckout(details, resp?.data?.last_order_id);
    //         dispatch({
    //           type: GET_PAYPAL_APPROVED_DETAILS,
    //           paypalDetails: details,
    //         });
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  const onError = (err) => {
    // dispatch({
    //   type: TRIGGER_CHECKOUT_SUCCESS,
    //   payload: [],
    // });
    toast.error("Payment cancelled!", {
      toastId: "toastMsg",
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  const expressCheckoutFunction = (data, status) => {
    if (orderId) {
      let object = {
        order_id: checkout?.data?.last_order_id,
        billing_first_name: data?.payer?.name?.given_name || "",
        billing_last_name: data?.payer?.name?.surname || "",
        billing_company: "",
        billing_address:
          data?.payer?.address?.address_line_1 ||
          data?.purchase_units[0]?.shipping?.address?.address_line_1 ||
          "",
        billing_city:
          data?.payer?.address?.admin_area_2 ||
          data?.purchase_units[0]?.shipping?.address?.admin_area_2 ||
          "",
        billing_state:
          data?.payer?.address?.admin_area_1 ||
          data?.purchase_units[0]?.shipping?.address?.admin_area_1 ||
          "",
        billing_country:
          data?.payer?.address?.country_code ||
          data?.purchase_units[0]?.shipping?.address?.country_code ||
          "",
        billing_postcode:
          data?.purchase_units[0]?.shipping?.address?.postal_code || "",
        shipping_first_name:
          data?.purchase_units[0]?.shipping?.name?.full_name?.split(" ")[0] ||
          "",
        shipping_last_name:
          data?.purchase_units[0]?.shipping?.name?.full_name?.split(" ")[1] ||
          "",
        shipping_city:
          data?.purchase_units[0]?.shipping?.address?.admin_area_2 || "",
        shipping_state:
          data?.purchase_units[0]?.shipping?.address?.admin_area_1 || "",
        shipping_country:
          data?.purchase_units[0]?.shipping?.address?.country_code || "",
        shipping_postcode:
          data?.purchase_units[0]?.shipping?.address?.postal_code || "",
        shipping_address:
          data?.purchase_units[0]?.shipping?.address?.address_line_1 || "",
        shipping_phone:
          data?.purchase_units[0]?.shipping?.phone?.phone_number
            ?.national_number || "",
        shipping_company: "",
        email: data?.payer?.email_address || "",
        billing_phone: data?.payer.phone?.phone_number?.national_number || "",
        txn_id: data?.purchase_units[0]?.payments?.captures[0]?.id,
        payment_status: status,
      };

      dispatch(
        triggerExpressCheckout(object, (resp) => {
          if (resp?.success) {
            cleanCartAfterPayment();
            history.push(`/payExp/order-success/${resp?.order?.id}`);
            toast.success("Payment Successful!", {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
            dispatch({
              type: GET_PAYPAL_APPROVED_DETAILS,
              paypalDetails: {},
            });
            // dispatch({
            //   type: TRIGGER_CHECKOUT_SUCCESS,
            //   payload: [],
            // });
            cleanCartAfterPayment();
          } else {
            toast.error("Payment Failed!", {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
          }
        })
      );
    }
  };

  return [createOrder, onClick, onCancel, onApprove, onError];
}
