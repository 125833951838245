import React from "react";
import { ReactSVG } from "react-svg";
import {
  ArrowRoundedLeft7x11Svg,
  ArrowRoundedRight7x11Svg,
} from "../../svg/index";

export default function Arrow(props) {
  const { direction, className, onClickNext, onClickPrev } = props;
  return (
    <div className={"arrow " + className}>
      <button
        className="arrow__button"
        type="button"
        onClick={direction === "next" ? onClickNext : onClickPrev}
      >
        {direction === "prev" && <ReactSVG src={ArrowRoundedLeft7x11Svg} />}
        {direction === "next" && <ReactSVG src={ArrowRoundedRight7x11Svg} />}
      </button>
    </div>
  );
}
