import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import pollito from "../../assets/images/pollito.png";
import { triggerSubscribeNewsletter } from "../../redux/actions/homePageAction";
import { toast } from "react-toastify";
import CircularLoader from "../shared/CircularLoader";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Footer() {
  const dispatch = useDispatch();
  const { subscribeNewsletter, headerContent } = useSelector(
    (state) => state.homePageReducer
  );
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (subscribeNewsletter?.data?.msg && isSubmit) {
      toast.error(subscribeNewsletter.data.msg, {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setIsSubmit(false);
      setEmail("");
    }
  }, [subscribeNewsletter, isSubmit]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (email !== "") {
      return dispatch(
        triggerSubscribeNewsletter(
          {
            newsletter_email: email,
          },
          (resp) => {
            toast.success(resp?.msg, {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
            setIsSubmit(false);
            setEmail("");
          }
        )
      );
    }
  };

  const menuItems = [
    { title: "HOME", url: "/" },
    { title: "ABOUT", url: "/about-us" },
    { title: "CONTACT US", url: "/contact-us" },
    { title: "ARTICLES", url: "/articles" },
    { title: "FAQ", url: "/faq" },
    { title: "VW ACCESSORIES", url: "/" },
    { title: "PRIVACY & TERMS", url: "/privacy-terms" },
    { title: "ORDER STATUS", url: "/order-status" },
  ];
  return (
    <div className="site__footer">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <div className="site-footer">
          <div className="site-footer__widgets">
            <div className="container">
              <div className="row ">
                <div className="col-12 col-xl-3">
                  <div className="footer-contacts">
                    <h5 className="footer-contacts__title">Support Vanagain</h5>
                    <div
                      className="footer-contacts__text"
                      dangerouslySetInnerHTML={{
                        __html: headerContent?.data?.support_text,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2 footer_link_our_menu">
                  <div className="footer-links">
                    <h5 className="footer-links__title">Our Menu</h5>
                    <ul className="footer-links__list">
                      {menuItems.map((item, i) => {
                        return (
                          <li className="footer-links__item" key={i}>
                            <Link to={item.url}>
                              <span className="footer-links__link">
                                {item.title}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <img alt="pollito" src={pollito} />
                </div>

                <div className="col-6 col-md-3 col-xl-3 2 footer_link_contact_details">
                  <div className="footer-links">
                    <h5 className="footer-links__title">Contact Details</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: headerContent?.data?.contact_detail,
                      }}
                    ></div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                  <div className="footer-newsletter">
                    <h5 className="footer-newsletter__title">Newsletter</h5>
                    <div className="footer-newsletter__text">
                      Enter your email address below to subscribe to our
                      newsletter and keep up to date with discounts and special
                      offers.
                    </div>
                    <form
                      className="footer-newsletter__form"
                      onSubmit={handleSubmit}
                    >
                      <label
                        className="sr-only"
                        htmlFor="footer-newsletter-address"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        id="footer-newsletter-address"
                        className="footer-newsletter__form-input form-control"
                        placeholder="Your Email here"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary footer-newsletter__form-button"
                        disabled={subscribeNewsletter?.loading}
                      >
                        <div className="d-flex align-items-center">
                          <div className="mr-1"> Subscribe</div>
                          {isSubmit && subscribeNewsletter?.loading && (
                            <CircularLoader size={15} />
                          )}
                        </div>
                      </button>
                    </form>
                    {isSubmit && email === "" && (
                      <div className="invalid-feedback">
                        Please enter email!
                      </div>
                    )}

                    <div className="footer-newsletter__text footer-newsletter__text--social">
                      Follow us on social networks
                    </div>
                    <div className="footer-newsletter__social-links social-links">
                      <ul className="social-links__list footer-links__contact">
                        <li className="social-links__item social-links__item--facebook">
                          <a
                            href={headerContent?.data?.facebook_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="social-links__item social-links__item--youtube">
                          <a
                            href={headerContent?.data?.youtube_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="site-footer__bottom">
            <div className="container">
              <div className="site-footer__bottom-row">
                <div className="site-footer__copyright">
                  COPYRIGHT © 2021. ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
