import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/pages/account/MyAccount.scss";
import CircularLoader from "../../components/shared/CircularLoader";
import { TRIGGER_WISH_LIST } from "../../redux/actions/actionTypes";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";
import useAuthHook from "../../hooks/useAuthHooks";

export default function AccountDashboardNav({ close }) {
  const { logoutObj } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [userData] = useUserDetailsHook();
  const [login, logout] = useAuthHook();

  useEffect(() => {
    if (logoutObj?.data) {
      dispatch({
        type: TRIGGER_WISH_LIST,
        wishlists: { loading: false, data: [], error: null },
      });
    }
  }, [logoutObj]);

  return (
    <div className="dashboard account-dashboard-nav">
      <div className="dashboard__profile  profile-card">
        <div className="d-flex">
          <div className="profile-card__avatar drop-profile-card">
            <div className="center-div">{userData?.name[0]}</div>
          </div>
          <div className="">
            <div className="profile-card__name ">
              {userData?.name} {userData?.last_name}
            </div>
            <div className="profile-card__email">{userData?.email}</div>
          </div>
        </div>
      </div>
      <div className="account-menu__divider"></div>
      <ul className="account-menu__links">
        <li className="account-nav__item">
          <NavLink
            activeClassName="account-nav__item--active"
            to="/my-account/dashbord"
            onClick={close}
          >
            Dashboard
          </NavLink>
        </li>
        <li className="account-nav__item">
          <NavLink
            activeClassName="account-nav__item--active"
            to="/my-account/profile"
            onClick={close}
          >
            My Account
          </NavLink>
        </li>
        <li className="account-nav__item">
          <NavLink
            onClick={close}
            activeClassName="account-nav__item--active"
            to="/my-account/orders"
          >
            My Orders
          </NavLink>
        </li>
        <li className="account-nav__item">
          <NavLink
            onClick={close}
            activeClassName="account-nav__item--active"
            to="/my-account/wishlist"
          >
            My Wish List
          </NavLink>
        </li>
        <li className="account-nav__item">
          <NavLink
            onClick={close}
            activeClassName="account-nav__item--active"
            to="/my-account/rewards"
          >
            My Points & Rewards
          </NavLink>
        </li>
        <li className="account-nav__divider" role="presentation"></li>
        <li className="account-nav__item">
          <button
            type="button"
            onClick={() => logout(close)}
            disabled={logoutObj?.loading}
          >
            <div className="d-flex align-items-center">
              <div className="mr-1"> Logout</div>
              {logoutObj?.loading && (
                <CircularLoader size={12} color="#262626" />
              )}
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
}
