import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isLogin } from "../../utils";
import AccountDashboardNav from "../../pages/account/AccountDashboardNav";
import { TRIGGER_LOGIN_FAILED } from "../../redux/actions/actionTypes";
import CircularLoader from "../shared/CircularLoader";
import useAuthHook from "../../hooks/useAuthHooks";
import "../../assets/scss/pages/header/header.scss";
export default function AccountMenu({ close }) {
  const dispatch = useDispatch();
  const { loginObj } = useSelector((state) => state.authReducer);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [login] = useAuthHook();

  useEffect(() => {
    if (loginObj?.error) {
      toast.error(loginObj?.error?.message, {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });

      dispatch({
        type: TRIGGER_LOGIN_FAILED,
        loading: false,
      });
    }
  }, [loginObj]);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (validateForm()) {
      login(email, password, setIsSubmit, close);
    }
  };
  return (
    <>
      {!isLogin() ? (
        <div className="account-menu">
          <form className="account-menu__form" onSubmit={handleSubmit}>
            <div className="account-menu__form-title">
              Log In To Your Account
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-email" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                id="header-signin-email"
                className="form-control form-control-sm"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              {isSubmit && email === "" && (
                <div className="invalid-feedback">Please enter email!</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-password" className="sr-only">
                Password
              </label>
              <div className="account-menu__form-forgot">
                <input
                  type="password"
                  id="header-signin-password"
                  className="form-control form-control-sm"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {isSubmit && password === "" && (
                  <div className="invalid-feedback">Please enter password!</div>
                )}
                <Link to="/forgot-password">
                  <span className="account-menu__form-forgot-link">Forgot?</span>
                </Link>
              </div>
            </div>
            <div className="form-group account-menu__form-button">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={loginObj?.loading}
              >
                <div className="d-flex align-items-center">
                  <div className="mr-1"> Login</div>
                  {loginObj?.loading && <CircularLoader size={10} />}
                </div>
              </button>
            </div>
            <div className="account-menu__form-link">
              <Link to="/login">Create An Account</Link>
            </div>
          </form>
        </div>
      ) : (
        <AccountDashboardNav close={close} />
      )}
    </>
  );
}
