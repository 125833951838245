import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Indicator from "./Indicator";
import Logo from "./Logo";
import Search from "./Search";
import TopBar from "./TopBar";
import { Heart32Svg, Person32Svg, Cart32Svg } from "../../svg/index";
import MainMenu from "./MainMenu";
import AccountMenu from "./AccountMenu";
import Dropcart from "./Dropcart";
import { isLogin } from "../../utils";
import { ToastContainer } from "react-toastify";
import config from "../../utils/config";
import {
  triggerGetWishList,
  triggerHeaderContent,
  triggerHomePageData,
} from "../../redux/actions";
import {
  getAskKenCategories,
  getBlogCategories,
} from "../../redux/actions/blogCategoriesAction";
import DropWishList from "./DropWishList";
import AlertMessage from "../shared/AlertMessage";
import { Menu } from "@material-ui/icons";
import MobileMenu from "./MobileMenu";
import "../../assets/scss/pages/header/header.scss";

export default function Header() {
  const dispatch = useDispatch();
  const { mainMenuList } = useSelector((state) => state.mainMenuReducer);
  const { headerContent, homePageData } = useSelector(
    (state) => state.homePageReducer
  );
  const { loginObj } = useSelector((state) => state.authReducer);
  const { cart, total } = useSelector((state) => state.cartReducer);
  const { wishList } = useSelector((state) => state.shopReducer);
  const [alertMsg, setAlertMsg] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [width, setWidth] = useState();

  useEffect(() => {
    handleResize();
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const showAlertMsg = () => {
    setAlertMsg(true);
  };

  const hideAlertMsg = () => {
    setAlertMsg(false);
  };

  useEffect(() => {
    let mounted = true;
    dispatch(triggerHeaderContent());
    dispatch(getBlogCategories());
    dispatch(getAskKenCategories());
    dispatch(triggerHomePageData());

    if (isLogin()) {
      dispatch(triggerGetWishList());
    }

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (homePageData?.data?.settings?.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href =
        config.imageBasePath + homePageData?.data?.settings?.favicon;
    }
  }, [homePageData?.data?.settings?.favicon]);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <div className="header_topbar_msg">
        {headerContent?.data?.notification}
      </div>
      {showAlertMsg && <AlertMessage isOpen={alertMsg} close={hideAlertMsg} />}

      <TopBar />
      <nav className="navbar fixed-top d-block  navbar-expand-lg navbar-default bg-default">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <Logo />
          </Link>
          {width < 992 && (
            <>
              {width > 400 && width > 425 && <Search />}

              {width > 720 && (
                <Indicator
                  icon={Heart32Svg}
                  buttonType="button"
                  counter={wishList?.data?.length || 0}
                  onClick={showAlertMsg}
                  type="wishlist"
                >
                  {isLogin() && <DropWishList />}
                </Indicator>
              )}

              {width > 650 && (
                <Indicator
                  icon={Person32Svg}
                  label={
                    width >= 877
                      ? isLogin()
                        ? loginObj?.data?.user?.name +
                          " " +
                          loginObj?.data?.user?.last_name
                        : "Hello, Log In"
                      : ""
                  }
                  value={width >= 877 ? "My Account" : ""}
                  buttonType="button"
                  type="login"
                >
                  <AccountMenu />
                </Indicator>
              )}

              {width > 590 && (
                <Indicator
                  icon={Cart32Svg}
                  label={width >= 877 ? "Shopping Cart" : ""}
                  value={width >= 877 ? `$${Number(total).toFixed(2)}` : ""}
                  counter={cart?.length || 0}
                  buttonType="button"
                  type="cart"
                >
                  <Dropcart />
                </Indicator>
              )}
            </>
          )}

          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              !showMobileMenu
                ? setShowMobileMenu(true)
                : setShowMobileMenu(false);
            }}
          >
            <span className="navbar-toggler-icon mt-2">
              <Menu />
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Search />
              </li>
              <li className="nav-item" id="wishlist-popover">
                <Indicator
                  icon={Heart32Svg}
                  buttonType="button"
                  counter={wishList?.data?.length || 0}
                  onClick={showAlertMsg}
                  type="wishlist"
                >
                  {isLogin() && <DropWishList />}
                </Indicator>
              </li>
              <li className="nav-item  nav-item-username">
                <Indicator
                  icon={Person32Svg}
                  label={
                    width >= 877
                      ? isLogin()
                        ? loginObj?.data?.user?.name +
                          " " +
                          loginObj?.data?.user?.last_name
                        : "Hello, Log In"
                      : ""
                  }
                  value={width >= 877 ? "My Account" : ""}
                  buttonType="button"
                  type="login"
                >
                  <AccountMenu />
                </Indicator>
              </li>

              <li className="nav-item" id="cart-popover">
                <Indicator
                  icon={Cart32Svg}
                  label={width >= 877 ? "Shopping Cart" : ""}
                  value={width >= 877 ? `$${Number(total).toFixed(2)}` : ""}
                  counter={cart?.length}
                  buttonType="button"
                  type="cart"
                >
                  <Dropcart />
                </Indicator>
              </li>
            </ul>
          </div>
        </div>
        <MainMenu menus={mainMenuList?.data} />
      </nav>

      <ToastContainer />

      {
        <MobileMenu
          open={showMobileMenu}
          isMobile={showMobileMenu}
          handleClose={toggleDrawer}
          width={width}
        />
      }
    </>
  );
}
