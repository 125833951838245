import {
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAILED,
} from "../actions/actionTypes";

const initialState = {
  faqObj: {
    loading: false,
    data: [],
    error: null,
  },
};
const FAQReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        faqObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_FAQ_SUCCESS:
      return {
        ...state,
        faqObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_FAQ_FAILED:
      return {
        ...state,
        faqObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default FAQReducer;
