import React, { useEffect, useRef, useState, createRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../assets/scss/pages/article/ArticleDetails.scss";
import { ReactSVG } from "react-svg";
import { ArrowRoundedLeft6x9Svg } from "../../svg/index";
import BlockSpace from "../../components/blocks/BlockSpace";
import config from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticleDetails,
  getArticlesComments,
  triggerPostComment,
} from "../../redux/actions/articlesAction";
import moment from "moment";
import CircularLoader from "../../components/shared/CircularLoader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import useUserDetailsHook from "../../hooks/useUserDetailsHook";
import { AnimationOnScroll } from "react-animation-on-scroll";
import useSetMetaTags from "../../hooks/useSetMetaTags";

export default function ArticleDetails(props) {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const articleId = props.match.params.id;
  const [previous, setPrevious] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData] = useUserDetailsHook();
  const location = useLocation();
  const [setMetaTags] = useSetMetaTags();

  const [captcha, validateCaptcha] = useState(false);
  const recaptchaRef = createRef();
  const gCaptcha = {
    human: false,
    humanKey: "",
  };
  const verifyCaptcha = function (value) {
    if (value) {
      gCaptcha.human = true;
      gCaptcha.humanKey = value;
      validateCaptcha(true);
    }
  };

  const {
    articlesDetails,
    articlesComments,
    articlesList,
    articlePostCommentObj,
  } = useSelector((state) => state.articlesReducer);
  const [next, setNext] = useState();
  const myRef = useRef(null);
  const initialCommentObject = {
    article_id: location?.state?.id,
    name: "",
    email: "",
    website: "",
    message: "",
    status: 2,
    user_id: userData?.id,
  };
  const [comment, setComment] = useState(initialCommentObject);
  const { name, email, website, message } = comment;
  const { page_title, created_at, description, featured_image } =
    articlesDetails?.data;

  useEffect(() => {
    setMetaTags("Articles", "Articles", "Articles");
    let mounted = true;
    dispatch(getArticleDetails({ id: articleId }));
    dispatch(getArticlesComments({ blog_id: articleId }));
    getPreviousAndNextArticle();
    return () => (mounted = false);
  }, [articleId]);

  const executeScroll = () => myRef.current.scrollIntoView();

  const goToPage = (type) => {
    let pathname;
    if (type === "prev" && previous?.slug) {
      navigate.push(`/article/${previous.slug}`);
      pathname = `/article/${previous.slug}`;
    } else if (type === "next" && next.slug) {
      navigate.push(`/article/${next.slug}`);
      pathname = `/article/${next.slug}`;
    }

    navigate.push({
      pathname,
      state: { id: type === "prev" ? previous?.id : next?.id },
    });
  };

  const getPreviousAndNextArticle = () => {
    let index = articlesList?.data?.findIndex((item) => {
      return item.slug == articleId;
    });
    let length = articlesList?.data?.length;
    let nextIndex = index + 1;
    let prevIndex = index - 1;
    if (index !== 0) {
      setPrevious(articlesList?.data[prevIndex]);
    }
    if (index != length - 1) {
      setNext(articlesList?.data[nextIndex]);
    }
  };

  const handleInputChange = (e) => {
    let updatedObject = { ...comment };
    updatedObject[e.target.name] = e.target.value;
    setComment(updatedObject);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (name !== "" && email !== "" && comment !== "" && captcha) {
      return dispatch(
        triggerPostComment(comment, () => {
          toast.success("Form submitted successfully!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
          setIsSubmit(false);
          setComment(initialCommentObject);
          dispatch(getArticlesComments({ blog_id: props.match.params.id }));
        })
      );
    }
  };

  return (
    <div className="block post-view">
      {articlesDetails?.loading ? (
        <div className="center-div">
          <CircularLoader size={60} color={"#32c2ef"} type="overlay-loader" />
        </div>
      ) : (
        <>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="post-view__header post-header post-header--has-image">
              <div
                className="post-header__image"
                style={{
                  backgroundImage: featured_image
                    ? `url(${config.imageBasePath}${featured_image})`
                    : "",
                }}
              ></div>
              <div className="post-header__body">
                <h1 className="post-header__title">{page_title}</h1>
                <div className="post-header__meta">
                  <ul className="post-header__meta-list">
                    <li className="post-header__meta-item">By Ken Wilford</li>
                    <li className="post-header__meta-item">
                      {moment(created_at).format("LL")}
                    </li>
                    <li className="post-header__meta-item">
                      <Link className="post-header__meta-link" to="/">
                        {articlesComments?.data?.length}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="decor decor--type--bottom post-header__decor">
                <div className="decor__body">
                  <div className="decor__start"></div>
                  <div className="decor__end"></div>
                  <div className="decor__center"></div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="post-view__body">
                <div className="post-view__item post-view__item-post m-auto">
                  <div className="post-view__card post">
                    <div className="post__body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </div>
                  </div>

                  <div className="post-view__card post-navigation">
                    <div className="post-navigation__body">
                      <div
                        className={
                          "post-navigation__item post-navigation__item--prev " +
                          (previous && "post-navigation__item-hover")
                        }
                        onClick={() => goToPage("prev")}
                      >
                        {previous && (
                          <>
                            <div className="post-navigation__item-image">
                              {previous?.featured_image ? (
                                <img
                                  alt={previous?.page_title}
                                  src={`${config.imageBasePath}${previous?.featured_image}`}
                                />
                              ) : (
                                <img
                                  alt="no-image"
                                  src={`https://www.vanagain.com/public/assets/uploads/no_img.gif`}
                                />
                              )}
                            </div>
                            <div className="post-navigation__item-info">
                              <div className="post-navigation__direction">
                                <div className="post-navigation__direction-arrow">
                                  <ReactSVG src={ArrowRoundedLeft6x9Svg} />
                                </div>
                                <div className="post-navigation__direction-title">
                                  Previous post
                                </div>
                              </div>
                              <div className="post-navigation__item-title">
                                {previous?.page_title}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className={
                          "post-navigation__item post-navigation__item--next " +
                          (next && "post-navigation__item-hover")
                        }
                        onClick={() => goToPage("next")}
                      >
                        {next && (
                          <>
                            <div className="post-navigation__item-info">
                              <div className="post-navigation__direction">
                                <div className="post-navigation__direction-title">
                                  Next post
                                </div>
                                <div className="post-navigation__direction-arrow">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7"
                                    height="11"
                                  >
                                    <path d="M.3 10.7c.4.4.9.4 1.3 0L7 5.5 1.6.3C1.2-.1.7 0 .3.3c-.4.4-.4 1 0 1.3l4 3.9-4 3.9c-.4.4-.4 1 0 1.3z"></path>
                                  </svg>
                                </div>
                              </div>
                              <div className="post-navigation__item-title">
                                {next?.page_title}
                              </div>
                            </div>
                            <div className="post-navigation__item-image">
                              {next?.featured_image ? (
                                <img
                                  alt={next?.page_title}
                                  src={`${config.imageBasePath}${next?.featured_image}`}
                                />
                              ) : (
                                <img
                                  alt="no-image"
                                  src={`https://www.vanagain.com/public/assets/uploads/no_img.gif`}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="post-view__card">
                    <h2 className="post-view__card-title">
                      Comments ({articlesComments?.data?.length})
                    </h2>

                    <div className="post-view__card-body comments-view">
                      <div className="comments-view__list">
                        {articlesComments?.data?.length > 0 ? (
                          <ol className="comments-list comments-list--level--0">
                            {articlesComments?.data?.map((comment, i) => {
                              return (
                                <li className="comments-list__item" key={i}>
                                  <div className="comment">
                                    <div className="comment__body">
                                      <div className="comment__avatar">
                                        <img
                                          alt={comment?.name}
                                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                                        />
                                      </div>
                                      <div className="comment__meta">
                                        <div className="comment__author">
                                          {comment?.name}
                                        </div>
                                        <div className="comment__date">
                                          {moment(comment?.created_at).format(
                                            "LL"
                                          )}
                                        </div>
                                      </div>
                                      <div className="comment__reply">
                                        <button
                                          type="button"
                                          className="btn btn-xs btn-light"
                                          onClick={executeScroll}
                                        >
                                          Reply
                                        </button>
                                      </div>
                                      <div className="comment__content typography">
                                        {comment?.comment}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ol>
                        ) : (
                          <p>No comments yet</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="post-view__card" ref={myRef}>
                    <h2 className="post-view__card-title">Leave a Reply</h2>
                    <p className="post-view__card-sub-title ">
                      Want to join the discussion? Feel free to contribute!
                    </p>
                    <form
                      className="post-view__card-body"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="comment-first-name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="comment-first-name"
                            placeholder="Name"
                            name="name"
                            onChange={(e) => handleInputChange(e)}
                            value={name}
                          />

                          {isSubmit && name === "" && (
                            <div className="invalid-feedback">
                              Please enter your name!
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="comment-email">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="comment-email"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => handleInputChange(e)}
                            value={email}
                          />

                          {isSubmit && email === "" && (
                            <div className="invalid-feedback">
                              Please enter your email!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-12">
                          <label htmlFor="comment-email">Website</label>
                          <input
                            type="url"
                            className="form-control"
                            id="comment-email"
                            placeholder="Website"
                            name="website"
                            onChange={(e) => handleInputChange(e)}
                            value={website}
                          />
                        </div>
                        <div className="form-group  col-md-12">
                          <label htmlFor="comment-content">Comment</label>
                          <textarea
                            className="form-control"
                            id="comment-content"
                            rows="6"
                            placeholder="Write comment here..."
                            name="message"
                            onChange={(e) => handleInputChange(e)}
                            value={message}
                          ></textarea>

                          {isSubmit && name === "" && (
                            <div className="invalid-feedback">
                              Please enter your comment!
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group ml-2 g-recaptcha">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={config.captchaSiteKey}
                          onChange={verifyCaptcha}
                        />
                        {isSubmit && !captcha && (
                          <div className="invalid-feedback">
                            Please verify captcha!
                          </div>
                        )}
                      </div>
                      <div className="form-group mb-0">
                        <button
                          type="submit"
                          className="btn btn-primary m-2"
                          disabled={articlePostCommentObj?.loading}
                        >
                          <div className="d-flex align-items-center">
                            <div className="mr-1"> Post Comment </div>
                            {articlePostCommentObj?.loading && (
                              <CircularLoader size={15} />
                            )}
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                  <BlockSpace className="block-space--layout--before-footer" />
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </>
      )}
    </div>
  );
}
