import {
  TRIGGER_LOGIN,
  TRIGGER_LOGIN_SUCCESS,
  TRIGGER_LOGIN_FAILED,
  TRIGGER_REGISTER,
  TRIGGER_REGISTER_SUCCESS,
  TRIGGER_REGISTER_FAILED,
  TRIGGER_FORGET_PASSWORD,
  TRIGGER_FORGET_PASSWORD_SUCCESS,
  TRIGGER_FORGET_PASSWORD_FAILED,
  TRIGGER_LOGOUT,
  TRIGGER_LOGOUT_FAILED,
  TRIGGER_LOGOUT_SUCCESS,
  TRIGGER_CHANGE_PASSWORD,
  TRIGGER_CHANGE_PASSWORD_SUCCESS,
  TRIGGER_CHANGE_PASSWORD_FAILED,
  TRIGGER_PROFILE_UPDATE,
  TRIGGER_PROFILE_UPDATE_SUCCESS,
  TRIGGER_PROFILE_UPDATE_FAILED,
} from "../actions/actionTypes";
import { login, logout } from "../../utils";

const initialState = {
  loginObj: {
    loading: false,
    data: [],
    error: null,
  },

  logoutObj: {
    loading: false,
    data: [],
    error: null,
  },
  registerObj: {
    loading: false,
    data: [],
    error: null,
  },

  forgetPassword: {
    loading: false,
    data: null,
    error: null,
  },

  changePassword: {
    loading: false,
    data: null,
    error: null,
  },

  profileObj: {
    loading: false,
    data: [],
    error: null,
  },
};

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_LOGIN:
      return {
        ...state,
        loginObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_LOGIN_SUCCESS:
      login(action?.payload?.token);
      return {
        ...state,
        loginObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_LOGIN_FAILED:
      return {
        ...state,
        loginObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_LOGOUT:
      return {
        ...state,
        logoutObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_LOGOUT_SUCCESS:
      logout();

      return {
        ...state,
        logoutObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
        loginObj: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_LOGOUT_FAILED:
      return {
        ...state,
        logoutObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_REGISTER:
      return {
        ...state,
        loginObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_REGISTER_SUCCESS:
      // console.log(action.payload);
      login(action?.payload?.token);
      return {
        ...state,
        loginObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_REGISTER_FAILED:
      return {
        ...state,
        loginObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassword: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        forgetPassword: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_PROFILE_UPDATE:
      return {
        ...state,
        profileObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_PROFILE_UPDATE_SUCCESS:
      let loginObjNew = { ...state.loginObj };
      loginObjNew.data.user=action?.payload?.user;
      return {
        ...state,
        profileObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
        loginObj:loginObjNew
      
      };

    case TRIGGER_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        profileObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
