import React, { useEffect, useState } from "react";
import PaginationPage from "../../components/shared/Pagination";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../redux/actions/shopAction";
import moment from "moment";
import CircularLoader from "../../components/shared/CircularLoader";
import { getStatus } from "../../utils";

export default function Orders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderList } = useSelector((state) => state.shopReducer);
  const { pageNo } = useParams();
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(6);
  const [ordersList, setOrdersList] = useState([]);

  useEffect(() => {
    setPage(pageNo ? pageNo : page);
    dispatch(getOrderList());
  }, []);

  useEffect(() => {
    if (orderList?.data?.length > 0) {
      alterOrderList();
    }
  }, [page, orderList?.data]);

  const alterOrderList = () => {
    let list = orderList?.data;
    const indexOfLastTodo = page * 6;
    const indexOfFirstTodo = indexOfLastTodo - 6;
    const updatedList = list.slice(indexOfFirstTodo, indexOfLastTodo);
    setOrdersList(updatedList);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/my-account/orders/page/${newPage}`,
    });
  };

  return (
    <div className="dashboard__orders card">
      <div className="card-header">
        <h1 className="mb-0 points-heading">Order History</h1>
      </div>
      <div className="card-divider"></div>
      <div className="card-table ">
        {!orderList?.data?.loading && !ordersList?.length && (
          <div className="center-div">No Data </div>
        )}
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Order Date</th>
                <th>Order Total</th>

                <th>Order Status</th>
                <th>Action</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody className="position-relative orders-table-body">
              {!orderList?.data?.loading &&
                ordersList?.length > 0 &&
                ordersList.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="order-id">#{item?.id}</td>
                      <td> {moment(item?.created_at).format("LL")}</td>
                      <td>${item?.order_total}</td>
                      <td>
                        <i
                          className={`fas ${
                            getStatus(item?.order_status).icon
                          } mr-2`}
                          style={{
                            color: getStatus(item?.order_status)?.color,
                          }}
                        ></i>
                        {getStatus(item?.order_status).status}
                      </td>
                      <td>
                        <Link
                          className="btn btn-primary btn-sm"
                          to={`/my-account/order/${btoa(item.id)}`}
                        >
                          View Details
                        </Link>
                      </td>
                      <td>
                        {" "}
                        <Link
                          className="btn btn-primary btn-sm"
                          to={`/checkout}`}
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}

              {orderList?.data?.loading && (
                <div className="center-div">
                  <CircularLoader
                    size={60}
                    color="#32c2ef"
                    type="overlay-loader"
                  />
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {!orderList?.data?.loading && orderList?.data?.length > 6 && (
        <PaginationPage
          startIndex={startIndex + 1}
          lastIndex={endIndex}
          showText={true}
          onChangePage={onChangePage}
          count={Math.floor(orderList?.data?.length / 6)}
          page={page}
          totalCount={orderList?.data?.length}
        />
      )}
    </div>
  );
}
