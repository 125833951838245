import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Rating } from "@material-ui/lab";
import config from "../../../utils/config";

export default function BlockReviewsCard({ review }) {
  return (
    <div className="block-reviews__item ">
      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="block-reviews__grid">
          <div className="block-reviews__item-avatar">
            <img
              alt={review?.title}
              src={
                review?.featured_image
                  ? `${config.imageBasePath}${review?.featured_image}`
                  : `https://www.vanagain.com/public/assets/uploads/no_img.gif`
              }
            />
          </div>
          <div>
            <div className="block-reviews__item-author">
              {review?.client_name}
            </div>
            <div className="block-reviews__item-rating">
              <div className="rating">
                <div className="rating__body">
                  <Rating
                    count={review.rating}
                    readOnly
                    value={review.rating}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-reviews__item-content">
          <div
            className="block-reviews__item-text"
            dangerouslySetInnerHTML={{ __html: review.description }}
          />
        </div>
      </AnimationOnScroll>
    </div>
  );
}
