import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { triggerAddToWishList, triggerGetWishList } from "../redux/actions";

export default function useAddToWishListHook() {
  const dispatch = useDispatch();
  function addToWish(userId, product, close, setSelectedProduct) {
    return dispatch(
      triggerAddToWishList(
        {
          user_id: userId,
          product_id: product.id,
        },
        (resp) => {
          let msg = `${resp?.message}!`;
          toast.success(msg, {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
          close();
          setSelectedProduct({});
          dispatch(triggerGetWishList());
        }
      )
    );
  }

  return [addToWish];
}
