import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/shared/Breadcrumb";
import "../../assets/scss/pages/account/MyAccount.scss";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import { triggerRemoveWishList } from "../../redux/actions/shopAction";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../components/shared/CircularLoader";
import { toast } from "react-toastify";
import useCartHook from "../../hooks/useCartHook";
import OverlayLoader from "../../components/shared/OverlayLoader";

export default function Wishlist() {
  const dispatch = useDispatch();
  const { wishList, removeProductFromWishList } = useSelector(
    (state) => state.shopReducer
  );
  const [selectedProduct, setSelectedProduct] = useState();
  const [list, setWishList] = useState();
  const [action, setAction] = useState();
  const [allCheckedItems, setAllChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [
    addToCartProduct,
    addQuantity,
    substractQuantity,
    removeProductFromCart,
    updateCart,
    emptyCart,
    addAllProductsToCart,
  ] = useCartHook();

  useEffect(() => {
    if (wishList?.data) {
      setWishList(wishList?.data);
    }
  }, [wishList?.data]);

  useEffect(() => {
    onChangeSelect();
  }, [selectedOption]);

  const removeFromWishlist = (product, action) => {
    setSelectedProduct(product);
    setAction(action);
    let Obj = { product_id: String(product?.product_detail?.id) };
    removeFromWishList(Obj);
  };

  const removeFromWishList = (object) => {
    dispatch(
      triggerRemoveWishList(object, (resp) => {
        if (resp.error) {
          toast.error("Something went wrong!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else if (selectedOption === "addToCart") {
          toast.success("Successfully move to Cart!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else {
          setSelectedOption("");
          setSelectedProduct(null);
          toast.success("Successfully Deleted From Wishlist!", {
            toastId: "toastMsg",
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
    );
  };

  const addProductToCart = (product, quantity, action) => {
    setAction(action);
    addToCartProduct(product?.product_detail, quantity);
    removeFromWishList({ product_id: String(product?.product_detail.id) });
  };

  const selectProduct = (product) => {
    let updatedList = [...list];
    let index = updatedList.findIndex((item) => {
      return product.id === item.id;
    });
    updatedList[index]["isChecked"] = updatedList[index]["isChecked"]
      ? !updatedList[index]["isChecked"]
      : true;
    setWishList(updatedList);
    let index1 = updatedList.findIndex((item) => item.isChecked);
    if (index1 >= 0) {
      setAllChecked(false);
    } else {
      setAllChecked(true);
    }
  };

  const allChecked = (e) => {
    let updatedList = [...list];
    setAllChecked(e.target.checked);
    updatedList.map((item) => {
      return (item.isChecked = e.target.checked);
    });
    setWishList(updatedList);
  };

  const allAddToCart = () => {
    addAllProductsToCart(list);
    let ids = [];
    list.forEach((element) => {
      ids.push(element?.product_detail?.id);
    });
    removeFromWishList({ product_id: ids.toString() });
  };

  const onChangeSelect = () => {
    if (selectedOption) {
      let newList = list?.filter((item) => {
        return item.isChecked;
      });

      let ids = [];
      newList.forEach((element) => {
        ids.push(element?.product_detail?.id);
      });

      if (selectedOption === "addToCart") {
        addAllProductsToCart(newList);
      }
      removeFromWishList({ product_id: ids.toString() });
    }
  };

  return (
    <div className="card block-empty">
      <Breadcrumb
        list={[
          {
            title: "Wishlist",
            url: "/my-account/wishlist",
          },
        ]}
      />

      {wishList?.loading && (
        <div className="product-loader-div ">
          <div className="center-div">
            <CircularLoader size={60} color={"#32c2ef"} type="overlay-loader" />
          </div>
        </div>
      )}

      {removeProductFromWishList?.loading && <OverlayLoader />}

      {wishList?.data?.length === 0 && !wishList?.loading && (
        <div className="block-empty__body">
          <h1 className="block-empty__title">Wishlist Is Empty</h1>
          <div className="block-empty__message">
            You have no products in your wishlist.
            <br />
            Go to the home page to start browsing our store.
          </div>
          <div className="block-empty__action">
            <a className="btn btn-primary btn-sm" href="/vanagain-reactjs/">
              Go To Homepage
            </a>
          </div>
        </div>
      )}
      {wishList?.data?.length > 0 && !wishList?.loading && (
        <>
          <div className="wishlist table-responsive">
            <table className="wishlist__table">
              <thead className="wishlist__head">
                <tr className="wishlist__row wishlist__row--head">
                  <th className=" wishlist__column--head td-input-check__box">
                    <span className="input-check position-relative">
                      <span className="input-check__body">
                        <input
                          className="input-check__input"
                          type="checkbox"
                          id="sign-in-remember"
                          name="remember"
                          onChange={(e) => allChecked(e)}
                          checked={allCheckedItems}
                        />
                        <span className="input-check__box "></span>
                        <span className="input-check__icon">
                          <svg width="9px" height="7px">
                            <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th className="wishlist__column wishlist__column--head wishlist__column--image">
                    Image
                  </th>
                  <th className="wishlist__column wishlist__column--head wishlist__column--product">
                    Product
                  </th>
                  <th className="wishlist__column wishlist__column--head wishlist__column--stock">
                    Stock
                  </th>
                  <th className="wishlist__column wishlist__column--head wishlist__column--price">
                    Price
                  </th>

                  <th>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary btn-mar btn-all-add-to-cart"
                      onClick={allAddToCart}
                    >
                      All add to cart
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="wishlist__body">
                {list?.map((product, i) => {
                  return (
                    <tr
                      className="wishlist__row wishlist__row--body"
                      key={product.id}
                    >
                      <td className="wishlist__column position-relative wishlist__column-checkbox wishlist__column--body">
                        <span className="input-check form-check-input">
                          <span className="input-check__body">
                            <input
                              className="input-check__input "
                              type="checkbox"
                              id="sign-in-remember"
                              name="check"
                              checked={product?.isChecked}
                              onChange={() => {
                                selectProduct(product);
                              }}
                            />
                            <span className="input-check__box "></span>
                            <span className="input-check__icon">
                              <svg width="9px" height="7px">
                                <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"></path>
                              </svg>
                            </span>
                          </span>
                        </span>
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--image">
                        <div className="image image--type--product">
                          <Link
                            className="image__body"
                            to={`/product/${product?.product_detail?.slug}`}
                          >
                            <img
                              alt={product?.product_detail?.name}
                              className="image__tag"
                              src={product?.product_detail?.featured_image ? `${config.imageBasePath}${product?.product_detail?.featured_image}` :"https://www.vanagain.com/public/assets/uploads/no_img.gif"}
                            />
                          </Link>
                        </div>
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--product">
                        <div className="wishlist__product-name product-name-ellipsis">
                          <Link
                            to={`/product/${product?.product_detail?.slug}`}
                          >
                            {product?.product_detail?.name}
                          </Link>
                        </div>
                        <div className="wishlist__product-rating">
                          <div className="wishlist__product-rating-stars">
                            <div className="rating">
                              <div className="rating__body">
                                <div className="rating__star rating__star--active"></div>
                                <div className="rating__star rating__star--active"></div>
                                <div className="rating__star rating__star--active"></div>
                                <div className="rating__star rating__star--active"></div>
                                <div className="rating__star"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--stock">
                        <div className="status-badge status-badge--style--success status-badge--has-text">
                          <div className="status-badge__body">
                            <div className="status-badge__text">In Stock</div>
                          </div>
                        </div>
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--price">
                        ${product?.product_detail?.regular_price}
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--button btn-mar-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary btn-all-add-to-cart"
                          onClick={() => {
                            addProductToCart(product, 1, "add");
                          }}
                        >
                          Add to cart
                        </button>
                      </td>
                      <td className="wishlist__column wishlist__column--body wishlist__column--remove">
                        <button
                          type="button"
                          className="wishlist__remove btn btn-sm btn-muted btn-icon"
                          onClick={() => removeFromWishlist(product, "remove")}
                          disabled={removeProductFromWishList?.loading}
                        >
                          {selectedProduct?.id === product?.id &&
                          action === "remove" &&
                          removeProductFromWishList?.loading ? (
                            <CircularLoader size={12} color="#6c757d" />
                          ) : (
                            <i
                              className="fa fa-minus-circle remove-icon"
                              aria-hidden="true"
                            ></i>
                          )}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {list?.findIndex((item) => {
            return item.isChecked;
          }) !== -1}

          {list?.findIndex((item) => {
            return item.isChecked;
          }) !== -1 && (
            <div className="mt-5">
              <select
                className="form-control col-xl-3 col-lg-3 col-md-3 col-4"
                id="exampleFormControlSelect1"
                onChange={(e) => {
                  setSelectedOption(e.target.value);
                }}
                value={selectedOption}
              >
                <option selected>Select option</option>
                <option value="addToCart">Add To Cart</option>
                <option value="remove">Remove</option>
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
}
