import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlockSpace from "../../components/blocks/BlockSpace";
import CircularLoader from "../../components/shared/CircularLoader";
import { triggerForgotPassword } from "../../redux/actions";
export default function ForgotPassord() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const { forgetPassword } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (forgetPassword?.error?.message?.email[0]) {
      toast.error(forgetPassword?.error?.message?.email[0], {
        toastId: "toastMsg",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setEmail("");
    }
  }, [forgetPassword?.error?.message?.email[0]]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (email !== "") {
      return dispatch(
        triggerForgotPassword(
          {
            email: email,
          },
          () => {
            setEmail("");
            setIsSubmit(false);
            toast.success(forgetPassword?.data?.message, {
              toastId: "toastMsg",
              autoClose: 2000,
              hideProgressBar: true,
            });
          }
        )
      );
    }
  };

  return (
    <div className="container ">
      <BlockSpace className="block-space--layout--before-footer" />

      <div className="col-md-6 m-auto offset-md-6">
        <div className="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4 login-page">
          <div className="card-body card-body--padding--2">
            <h3 className="card-title">Forgot Password</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="signin-email">Email Address</label>
                <input
                  id="signin-email"
                  type="email"
                  className="form-control"
                  placeholder=""
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {isSubmit && email === "" && (
                  <div className="invalid-feedback">Please enter email!</div>
                )}
              </div>

              <div className="form-group mb-0">
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={forgetPassword?.loading}
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-1"> Send Password Reset Link</div>
                    {forgetPassword?.loading && <CircularLoader size={15} />}
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BlockSpace className="block-space--layout--before-footer" />
    </div>
  );
}
