import React, { useState } from "react";
import { useLocation } from "react-router";
import config from "../utils/config";

export default function useSetMetaTags() {
  const location = useLocation();

  function setMetaTags(title, description, keyword, image, content) {
    document.title = title ? `${config.title} | ${title}` : config.title;
    document
      ?.querySelector('meta[name="description"]')
      ?.setAttribute("content", description || "");

    document
      ?.querySelector('meta[property="og:title"]')
      ?.setAttribute(
        "content",
        title ? `${config.title} | ${title}` : config.title
      );
    document
      ?.querySelector('meta[property="og:url"]')
      ?.setAttribute("content", `https://www.vanagain.com${location.pathname}`);

    document
      ?.querySelector('meta[property="og:image"]')
      ?.setAttribute("content", image || "");

    document
      ?.querySelector('meta[property="og:description"]')
      ?.setAttribute("content", content);
    document
      .querySelector("link[rel='canonical']")
      ?.setAttribute("href", `https://www.vanagain.com${location.pathname}`);
  }

  return [setMetaTags];
}



