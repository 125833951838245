import React, { useEffect, useState } from "react";
import {
  LayoutList16Svg,
  LayoutGridWithDetails16Svg,
  LayoutTable16Svg,
  LayoutGrid16Svg,
} from "../../svg/index";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { NUMBER_OF_PRODUCTS } from "../../utils/constants";

export default function ProductView({
  layout,
  changeLayout,
  startIndex,
  endIndex,
  totalCount,
  onNoOfProductsPerPage,
  productsPerPage,
}) {
  const [width, setWidth] = useState();

  useEffect(() => {
    handleResize();
    if (window.innerWidth < 768) {
      changeLayout({
        layout: "grid",
        icon: LayoutGrid16Svg,
        class: "product-grid",
      });
    }
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 400) {
      changeLayout({
        layout: "grid",
        icon: LayoutGrid16Svg,
        class: "product-grid",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const layoutButtons = [
    { layout: "grid", icon: LayoutGrid16Svg, class: "product-grid" },
    {
      layout: "grid-with-features",
      icon: LayoutGridWithDetails16Svg,
      class: "product-desc",
    },
    { layout: "list", icon: LayoutList16Svg, class: "d-block" },
    { layout: "table", icon: LayoutTable16Svg, class: "d-table" },
  ];
  return (
    <div className="product-view">
      {width > 550 && (
        <div className="view-options__layout layout-switcher">
          <div className="layout-switcher__list">
            {layoutButtons
              .map((button, i) => {
                const buttonClasses = classNames("layout-switcher__button", {
                  "layout-switcher__button--active": button.class === layout,
                });
                return (
                  <button
                    key={button.layout}
                    type="button"
                    className={buttonClasses}
                    onClick={() => changeLayout(button)}
                  >
                    <ReactSVG src={button.icon} />
                  </button>
                );
              })
              .filter((item, i) => {
                if (width > 710) {
                  return i || i == 0;
                } else {
                  return i == 0 || i == 1;
                }
              })}
          </div>
        </div>
      )}

      <div className="view-options__legend">
        Showing
        {startIndex && endIndex < totalCount
          ? " " + startIndex + "–" + endIndex + " of "
          : ""}
        {startIndex && endIndex > totalCount
          ? " " + startIndex + "–" + totalCount + " of "
          : ""}
        {totalCount} products
      </div>
      {width > 550 && <div className="view-options__spring"></div>}
      <div className="view-options__select">
        <label htmlFor="view-option-limit">Show:</label>
        <select
          id="view-option-limit"
          value={productsPerPage}
          className="form-control form-control-sm"
          onChange={(e) => onNoOfProductsPerPage(e)}
        >
          {NUMBER_OF_PRODUCTS.map((item, i) => {
            return (
              <option value={item} key={i}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
