import React, { useRef } from "react";
import "../../../assets/scss/pages/block/Block.scss";
import customer from "../../../assets/images/customers_say.png";
import { Link } from "react-router-dom";
import BlockReviewsCard from "./BlockReviewsCard";
import Slider from "react-slick";

export default function BlockReviews({ reviewList }) {
  var sliderRef = useRef();
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 1600,
    slidesToShow: 2,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="block-reviews-margin">
      <div className="block-reviews__content block-customers_review">
        <div className="block-reviews__header">
          <div className="block-reviews__subtitle">What Our Customers Say</div>
          <div className="block-reviews__controls">
            <div className="block-reviews__link">
              <Link to="/testimonials">View All</Link>
            </div>

            <div className="decor decor--type--center block-reviews__header-decor">
              <div className="decor__body">
                <div className="decor__start"></div>
                <div className="decor__end"></div>
                <div className="decor__center"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-reviews__body">
          <div
            className="block-reviews__image"
            style={{ backgroundImage: `url(${customer})` }}
          />
          <div className="container">
            <div className="block-reviews__list">
              <Slider {...settings} ref={sliderRef}>
                {reviewList?.length &&
                  reviewList
                    ?.map((slide, i) => {
                      return <BlockReviewsCard key={i} review={slide} />;
                    })
                    .filter((a, i) => i < 10)}
              </Slider>
            </div>
            <div className="block-reviews__loader" />
          </div>
        </div>
      </div>
    </div>
  );
}
